
import React from 'react'


const ContinueGate =(props) => {
	//props.continue && 
	return (
		<>
		{/*<button className="btn btn-light" style={{float: 'right'}} disabled>Continue</button> */}
	
		{props.showContinue ?
			<div className="d-flex justify-content-xxl-between">
					
						<div className="form-grou" style={{justifyContent: 'flex-start'}}>
						{
							props.showBack ?
							<>
							{ props.back ?
								<button className="btn btn-light btn-rounded meritic_dblue" onClick={()=> {props.onContinue(true)}} style={{justifyContent: 'flex-start'}}> Back </button> :
									<button className="btn btn-light" style={{justifyContent: 'flex-start'}} disabled>Back</button>
							}
							</> : null
						}
						</div>
				
						<div className="form-group" style={{justifyContent: 'flex-end'}}>
						{
							props.continue ?
							<button className="btn btn-light btn-rounded meritic_dblue" onClick={()=> { props.onContinue(true)}} style={{justifyContent: 'flex-end', float: 'right', maxHeight:"35px", maxWidth:"110px"}}> Continue </button> :
							null 
						}
							
						</div>
				
			
			</div>: null
		}
		</>
	);
}


export default ContinueGate;