

import React from 'react'
import Icon from "@mdi/react";


const RegisterSuccess = () => {
	

	return (
		
		<div class="account-pages mt-5 mb-5">
	            <div class="container">
	                <div class="row justify-content-center">
	                    <div class="col-lg-5">
	                        <div class="card">
	                            <div class="card-header pt-4 pb-4 text-center">
	                                <a href="/">
	                                    <span><img src="/brand_assets/Meritic.png"  height="41" alt="Meritic logo" /></span>
	                                </a>
                            		</div>
	
	                            <div class="card-body p-4">
	                                
	                                <div class="text-center w-75 m-auto">
	                                    <h4 class="text-dark-50 text-center mt-0 font-weight-bold">Thanks for verifying your account!</h4>
	                                    <p class="text-muted mb-4">Take me to the <a href="/">home page</a></p>
	                                </div>
	                            </div> 
	                        </div>
	 
	
	                    </div> 
	                </div>
	            </div>
	        </div>
	);
	
}




const RegisterVerify = (props) => {
    		
	return (

		<section className="account-pages mt-5 mb-5">
			<div className="account-pages mt-5 mb-5">
		          <div className="container">
		               <div className="row justify-content-center">
		                    <div className="col-lg-5">
		                        <div className="card">
		                            <div className="card-header pt-4 pb-4 text-center bg-default">
		                                <a href="/">
		                                    <span><img src="/brand_assets/Meritic.png" alt="" height="41" /></span>
		                                </a>
		                            </div>
		
		                            <div className="card-body p-4">
		                                
		                                <div className="text-center w-75 m-auto" id="sent_email_msg" >
		                                    <h4 className="text-dark-50 text-center mt-0 font-weight-bold">Verify your email</h4>
		                                    <p className="text-muted mb-4">Welcome to Meritic! Before continuing please verify your email address. </p>
		                                    <span className="text-muted mb-4"> We have just sent an email to </span>
		                                    <span className="font-weight-normal mb-4">{props.email}</span>
		                                    <span className="text-muted mb-4"> , the account you provided. Follow the link in our email to verify your account.</span>
		                                </div>
		                            </div>
		                        </div>
		                    </div>
		                </div>
		            </div>
		       </div>
	       </section>
	);
}


const RegisterForm = (props) => {

	return (
		<div className="row justify-content-center">
        	<div className="col-lg-5">
            	<div className="card">
               
                	<div className="card-body p-4">
                    	
                        <div className="text-center w-75 m-auto">
                            <h4 className="text-dark-50 text-center mt-0 font-weight-bold"><span style={{color:'#000080'}}>Sign Up</span></h4>
                            <p className="text-muted mb-4">Don't have an account? Register to access additional features of our platform, once available. </p>
                        </div>

                    	<form className="needs-validation" action="#/">

                            <div className="form-group">
                                <label htmlFor="fullname">Full Name</label>
                                <div className="d-flex">
                            		<input className="form-control p-2" onChange={props.handleFirstnameChange} type="text" id="firstname" name="firstname" placeholder="Your first name" required />
                            		<input className="form-control p-2" onChange={props.handleLastnameChange} type="text" id="lastname" name="lastname" placeholder="Your last name" required />
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="emailaddress">Email address</label>
                                <input className="form-control" onChange={props.handleEmailChange} type="email" id="emailaddress" name="emailaddress" required placeholder="Enter your email" />
                                <div id="email_validate" className={props.emailValidatorClass + ' d-block'} style={{visibility: props.showEmailValidator}}>{props.emailValidatorText}</div>
                            </div>
                        
                        
                        
                            <div className="form-group">
                                <label htmlFor="fullname">Organization (optional)</label>
                                	<input className="form-control p-2" onChange={props.handleOrgChange} type="text" id="company" name="company" placeholder="Enter your company" />
                            </div>
                        
                            <div className="form-group">
                                 <label htmlFor="username">Username</label>
                                 <div className="input-group">
                                     <div className="input-group-prepend">
                                         <span className="input-group-text" id="username_grouprepend">@</span>
                                     </div>
                                     <input type="text" onChange={props.handleUsernameChange} className="form-control" id="username" name="username"
                                         placeholder="Username" aria-describedby="username_grouprepend" required />
                                 </div>
                                 <div id="username_validate" className={props.usernameValidatorClass + ' d-block'} style={{visibility:props.showUsernameValidator}}> {props.usernameValidatorText} </div>
                             </div>

                            <div className="form-group">
                            		<div className="row">
									    <div className="col align-self-start">
									      <label htmlFor="password">Create password</label>
									    </div>
								    <div className="col align-self-end">
								    	<label htmlFor="password"><a id="auto_passwd_gen" onClick={props.handleAutoPasswdGen}>Generate one for me</a></label>
								    </div>
							  	</div>
                                 
                                <div className="input-group input-group-merge">
                                    <input type="password" id="password" onChange={props.handlePasswdChange} name="password" className="form-control" placeholder="Enter a strong password"  required />
                                    <div className="input-group-append" data-password="false">
                                        <div className="input-group-text">
                                            <span> <Icon path={props.mdiEye} title="Eye" size={0.75} /></span>
                                        </div>
                                    </div>
                          			<div id="password_validate" className={props.passwordValidatorClass + ' d-block'} style={{visibility:props.showPasswordValidator}}> {props.passwordValidatorText}</div>
                                </div>

                                <div className="input-group input-group-merge">
                                    <input type="password" id="confirm_password" onKeyUp={props.handleConfirmPasswdKeyUp} onChange={props.handleConfirmPasswdChange} className="form-control" placeholder="Re-enter your password" required />
                                    <div className="input-group-append" data-password="false"></div>
                                    <div id="confirm_password_validate" className={props.confirmPasswdValidatorClass + ' d-block'} style={{visibility:props.showConfirmPasswdValidator}}>{props.confirmPasswdValidatorText} </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" onChange={props.handleCheckBoxChange} className="custom-control-input" id="checkbox-signup" required />
                                    <label className="custom-control-label" htmlFor="checkbox-signup">I accept <a href="/terms-of-service" className="text-muted">Terms and Conditions</a></label>
                                </div>
                                <div className="invalid-feedback">
                                    You must agree before submitting.
                                </div>
                            </div>

                            <div className="form-group mb-0 text-center">
                            	{props.submittable ? <button className="btn btn-primary" type="submit" onClick={props.handleSubmit}> Sign Up </button> : <button className="btn btn-primary" type="submit" disabled> Sign Up </button> }
                            </div>

                    	</form>
                	</div>
            </div>
            <div className="row mt-3">
                <div className="col-12 text-center">
                    <p className="text-muted">Already have account? <a href="/login" className="text-muted ml-1"><span style={{color:'#000080'}}><b>Log In</b></span></a></p>
                </div> 
            </div>
        </div>
    </div>
	);
}





export { RegisterVerify, RegisterForm, RegisterSuccess }