
import React, {useState, useEffect, useRef	} from 'react'

import {Label, Input } from '../util/LineInput';
import axios from 'axios';
import ContinueGate from '../util/ContinueGate';
import AddTokenGroup from './AddTokenGroup';
import { PreferredTimeUnit } from './conditions/PreferredTimeUnit';
import { ValueCurrency } from './conditions/ValueCurrency';
import Icon from "@mdi/react";
import { mdiShapePolygonPlus, mdiEye } from "@mdi/js";
import { flushSync } from 'react-dom';



const SmartContract =(props) => {
	
	
	const [contractType, setContractType] = useState("");
	const [contractTimeUnit, setContractTimeUnits] = useState(null);
	const [valueCurrency, setValueCurrency] = useState(null)
	const [proceed, setProceed] = useState({tokenGrp: false, timeUnit: false, valueCurrency: false, tokenGrpName:false});
	const [showContinue, setShowContinue] = useState({contractStart: true, timeUnit: false, valueCurrency: false, tokenGrp: false});
	const [showContractDesc, setShowContractDesc] = useState(true)
	const [tokenTypeClassActive, setTokenTypeClassActive] = useState(Array(3).fill(false));
	
	const [showAddTokenGroupFields, setShowAddTokenGroupFields] = useState(false);
	const [timezones, setTimezones] = useState([]);
	
	const mountedAddTG = useRef(false);
	const [showAddTokenGrp, setShowAddTokenGrp] = useState(true);
	const [contractProperties, setContractProperties] = useState(undefined)
	const [showContractType, setShowContractType] = useState({time: true, cash: true, items: true, priority: true});
	
	const getTimezones = async() => {
		const response = await axios.get('/api/v1/timezones/')
		return response.data;
	}
	
	
	useEffect(() => {
		getTimezones().then(data => {
			setTimezones(data);
		});
	}, []);
	
	
	
	useEffect(() => {
		if(contractType == 'time'){
			setContractProperties({type: contractType, unit: contractTimeUnit})
		}else if(contractType == 'value'){
			setContractProperties({type: contractType, currency: valueCurrency})
		}
		
	}, [contractType, contractTimeUnit, valueCurrency]);
	
	
	const onContinue = (step) => {

		if(step == 'timeUnit'){
			var temp = {...proceed};
			temp.timeUnit = true;
			setProceed(temp);
			setShowContractDesc(false);
			temp = {...showContinue};
			temp.contractStart = false
			setShowContinue(temp);
			
			temp = {...showContractType};
			Object.keys(showContractType).forEach(x => {
				if(x == contractType){	temp[x] = true;	}
				else{ temp[x] = false; }
			});
			setShowContractType(temp);
		}else if(step == 'tokenGrp'){
			var temp = {...proceed};
			temp.tokenGrp = true;
			setProceed(temp);
			temp = {...showContinue};
			temp.valueCurrency = false
			temp.timeUnit = false
			setShowContinue(temp);
			setShowAddTokenGroupFields(true);
		}else if(step == 'valueCurrency'){
			var temp = {...proceed};
			temp.valueCurrency = true;
			setProceed(temp);
			setShowContractDesc(false);
			temp = {...showContinue};
			temp.contractStart = false;
			setShowContinue(temp);
		}else if(step == 'tokenGrpName'){
			var temp = {...proceed};
			temp.tokenGrpName = true;
			//flushSync
			setProceed(temp);
			temp = {...showContinue};
			temp.tokenGrpName = false
			setShowContinue(temp);
		}
	}
	
	
	
	
	const onChangeTimeUnit = (event, unit) => {
		setContractTimeUnits(unit);
		var temp = showContinue;
		temp.timeUnit = true
		setShowContinue(temp);
	
	}
	
	const onChangeValueCurrency = (event, currency) => {
		setValueCurrency(currency);
		var temp = showContinue;
		temp.valueCurrency = true
		setShowContinue(temp);
	}
	
	const onChangeContractType = (event, type) => {
		event.stopPropagation();
		event.preventDefault();

		var temp = Array(4).fill(false);
		temp[event.target.dataset.index] = true;
		setTokenTypeClassActive(temp);
		setContractType(type);
	}
	
	
	const addTokenGroupClick = (event) => {
		event.preventDefault();
		setShowAddTokenGroupFields(true);
	}
	
	
									
								    
	var tokenTypeOptions = [
			
			<div className="col-sm-2"></div>,
				<>
				{ showContractType['time'] ?
					<div className={'mb-3 col-sm-2 rounded-5 border border-5'}>
						<a type="button" key={0} data-index={0} onClick={(event) => {onChangeContractType(event, 'time')}} style={{color: '#fff'}} className={'btn-sm  btn-rounded font-16 font-weight-normal ' + (tokenTypeClassActive[0] == true ? 'service-sector-active' : '') }> 
							<img src="/images/contract/time.png" height="80" />
							<span key={0} data-index={0} onClick={(event) => {onChangeContractType(event, 'time')}}> Time credit </span> 
				  		</a>
				  	</div> : <></>
				  }
				  </>,
				  <>
				  { showContractType['cash'] ?
					  <div className={'mb-3 col-sm-2 rounded-5 border border-5'}>
					  	
						  <a type="button" key={1} data-index={1} onClick={(event) => {onChangeContractType(event, 'value')}} style={{color: '#fff'}} className={'btn-sm btn-rounded font-16 font-weight-normal ' + (tokenTypeClassActive[1] == true ? 'service-sector-active' : '') }>
							<img src="/images/contract/cash.png" height="80" />
							<span key={1} data-index={1} onClick={(event) => {onChangeContractType(event, 'value')}}> Cash credit </span> 
						  </a>
					  </div> : <></>
				  }
				  </>,
				  <>
				  { showContractType['items'] ?
				  	<div className={'mb-3 col-sm-2 rounded-5 border border-5'}>
					  <a type="button" key={2} data-index={2} onClick={(event) => {onChangeContractType(event, 'items')}} style={{color: '#fff'}} className={'btn-sm  btn-rounded font-16 font-weight-normal ' + (tokenTypeClassActive[2] == true ? 'service-sector-active' : '') }>
					  	<img src="/images/contract/items.png" height="80" />
					  </a>
					  <span key={2} data-index={2} onClick={(event) => {onChangeContractType(event, 'items')}}> Items credit</span>
					</div> : <></>
				  }</>,
				  <>
				  { showContractType['priority'] ?
			  		<div className={'mb-3 col-sm-2 rounded-5 border border-5'}>
						<a type="button" key={3} data-index={3} onClick={(event) => {onChangeContractType(event, 'ordered')}} style={{color: '#fff'}} className={'btn-sm btn-rounded font-16 font-weight-normal ' + (tokenTypeClassActive[3] == true ? 'service-sector-active' : '') }>
						  	<img src="/images/contract/priority.png" height="80" />
						</a>
					 	<span key={3} data-index={3} onClick={(event) => {onChangeContractType(event, 'ordered')}}>Priority credit</span>
			 	 	</div> : <></>
			 	  }</>,
			  <div className="col-sm-2"></div>
		];
			  
						  
						  
						  
	var tokenTypeDesc = {
		time : <div className="card-body" data-value="1"> 
			      	<h5 className="card-title meritic_dblue">Time-credits Smart Contract</h5>
			      	<p className="text-left">Create a smart contract for time credits, enabling you to issue or offer service time to users, customers, or followers. 
			    		With your smart contract, you can  
			    	</p>
			    		
			    	<ul class="list-group list-group-flush ml-3">
				        <li className="card-text text-left">Issue toknes, for time / calendar slots of availability, to users, customers, or followers. </li>
				        <li className="card-text text-left">Grow revenue by auctioning your availability for one-on-one meetings with your users or followers</li>
				        <li className="card-text text-left">Issue day-pass, week-pass, or month-pass tokens granting access to service events and activities </li>
			        </ul>
			   	</div>,
			   	
		value: <div className="card-body" data-value="1"> 
			      	<h5 className="card-title meritic_dblue">Spend-credits Smart Contract</h5>
			      	<p className="text-left">With the Spend-credit Smart Contract, you can issue tokens that store monetary value - just like electornic gift and prepaid cards - that can only be spent at your service. Unlike electronic gift-cards, these are programmable tokens, so you set what part of your service they can be used and you can set rules that govern how users can transfer or resell the tokens 
			    	</p>
			    		
			    	<ul class="list-group list-group-flush ml-3">
				        <li className="card-text text-left">Issue spend tokens as tickets to events, as tokens needed to make a reservation, or to access your content</li>
				        <li className="card-text text-left">Instead of refunding users in ETH, BTC, or USDC, which they can spend elswhere, prevent revenue flight by issueing equivalent value in your Spend token</li>
				        <li className="card-text text-left">Holders can sell or transfer them to others </li>
			        </ul>
			   	</div>,
		items: <div className="card-body" data-value="1"> 
			      	<h5 className="card-title meritic_dblue">Items-credits Smart Contract</h5>
			      	<p className="text-left">With the Items-credit Smart Contract, you can issue tokens that grant users access to a count of a specific service item, like a numbe of sessions of a class, a count of API hits or access to specifc content over a specified period. </p>
			    		
			    	<ul class="list-group list-group-flush ml-3">
				        <li className="card-text text-left">Issue Items tokens for free to new or select users / wallets as part of a promotion</li>
				        <li className="card-text text-left">Issues these tokens for sale </li>
				        <li className="card-text text-left">Specify in your smart contract, if holders are allowed to transfer or resell their tokens</li>
			        </ul>
			   	</div>,
		ordered: <div className="card-body" data-value="1"> 
			      	<h5 className="card-title meritic_dblue">Ordered-credits Smart Contract</h5>
			      	<p className="text-left">Ordered-credit Smart Contract, you issue tokens that hold  a position in a queue, waitlist, or ranking as token value.
			      	Your smart contract sets rules that govern length of waitlist or order, transfer or jumping the list, and other aspects of managing a list. </p>
			    		
			    	<ul class="list-group list-group-flush ml-3">
				        <li className="card-text text-left">Manage waitlists your content, events, or reserved services</li>
				        <li className="card-text text-left">List these tokens for sale/acution on Web3 marketplaces to enable your community to join your queues an waitlists.   </li>
			        </ul>
			   	</div>
	}
	
	
	
	
	
	
	return (
		
		<>
			<div className="form-group mt-0">
				<h3><span className="font-weight-normal meritic_dblue">Contracts</span></h3>
				
				<div className="container form-group mt-0" style={{width: '100%', textAlign: 'center'}}>
			    	
					<Label className="justify-content-center mb-0 meritic_dblue"  for="offering_type" text="Type of token in this group"></Label>
					<div className="row">
						{ tokenTypeOptions }
					</div>
					{
						showContractDesc ?
						<div className="card-group form-group text-center mt-3"  style={{width:'50%', margin: '0 auto'}}>
		     				<div className='card mb-3 rounded-5' data-value="1" style={{position: 'relative', marginRight:'15px'}}>
								{ contractType == 'time' ?
									tokenTypeDesc.time : 
									
									contractType == 'value' ?
										tokenTypeDesc.value :
										
										props.contractType == 'items' ?
											tokenTypeDesc.items:
											
											contractType == 'ordered' ?
												tokenTypeDesc.ordered:
												
												null
								}
							</div>
		     			</div> : null
					}	       			
				</div>
				{ contractType != "" ?
					<>
						{contractType == 'time' && contractTimeUnit == null ?
							<ContinueGate continue={contractType != ""} showContinue={showContinue['contractStart']} onContinue={()=>{onContinue('timeUnit')}}/>
							:  contractType == 'value' && valueCurrency == null ?
								<ContinueGate contractType={contractType} showBack={true} continue={contractType != ""} showContinue={showContinue['contractStart']} onContinue={()=>{onContinue('valueCurrency')}}/>
								: null
						}
						{ contractType == 'time' && proceed['timeUnit'] ? 
							<div className="form-group mt-4">
								<PreferredTimeUnit onChangeTimeUnit={onChangeTimeUnit} contractTimeUnit={contractTimeUnit} />
								<ContinueGate continue={contractTimeUnit !== null} showBack={true} showContinue={showContinue['timeUnit']} onContinue={()=>{onContinue('tokenGrp')}}/> 
							</div> : contractType == 'value' && proceed['valueCurrency'] ? 
								<div className="form-group mt-4">
									<ValueCurrency onChangeValueCurrency={onChangeValueCurrency} valueCurrency={valueCurrency} />
									<ContinueGate continue={valueCurrency !== null} showContinue={showContinue['valueCurrency']} onContinue={()=>{onContinue('tokenGrp')}}/> 
								</div> : null
						}
			
						
						
						{ proceed['tokenGrp'] ? 
							<>			
		                        <AddTokenGroup
		                        	slotId={props.providerObj.slot_id}
									showAddTokenGroupFields={showAddTokenGroupFields}
									setShowAddTokenGroupFields={setShowAddTokenGroupFields}
									imgThumbs={props.serviceObj.imgThumbs}
									serviceObj={props.serviceObj}
									mountedAddTG={mountedAddTG}
									setTokenGroup={props.setTokenGroup}
									serviceImageNameMap={props.serviceObj.imageNameMap}
									serviceMetadataURI={props.serviceObj.metadataURI}
									setShowAddTokenGrp={setShowAddTokenGrp}
									conditions={props.conditions}
					            	setConditions={props.setConditions}
									tokenGroup={props.tokenGroup}
									serviceOffer={props.serviceObj.serviceOffer}
									onSaveTokenGroups={props.onSaveTokenGroups}
									savedTokengGroups={props.savedTokengGroups}
									contractType={contractType}
									tokenTypeClassActive={tokenTypeClassActive}
									timezones={timezones}
									proceed={proceed}
									onContinue={onContinue}
									showContinue={showContinue}
									setShowContinue={setShowContinue}
									//setShowContinue={setShowContinue}
									/*valueGrpType={valueGrpType}
									timeGrpType={timeGrpType}
									onChangeValueGrpType={onChangeValueGrpType}
									onChangeTimeGrpType={onChangeTimeGrpType}
									*/
									contractProperties={contractProperties}
								>
								</AddTokenGroup>
								<div className="container" style={{float: 'left'}}>
		                			{showAddTokenGrp ? 
		                				<a href="#" onClick={addTokenGroupClick}>
											<span><Icon path={mdiShapePolygonPlus} title="Add" size={1} /> Add Token Group</span>
										</a> : null
		                			}
								</div> 
							</> : null
							}
					</> : null
				}
			</div>
		</>
	);
};


export { SmartContract }
