/**
 * 
 */

import React, { useState, useEffect } from 'react';
import genBins, { Bin, Bins } from '@visx/mock-data/lib/generators/genBins';
import { scaleLinear } from '@visx/scale';
import d3ToPng from 'd3-svg-to-png';
import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import {Label, Input, LineInput} from '../util/LineInput';
import axios from 'axios';
import Select from "react-dropdown-select";
import { Carousel } from 'react-responsive-carousel';

import Modal from 'react-modal';
import CircularSlider from '@fseehawer/react-circular-slider';
import BannerHeatmapCircle from './BannerHeatmapCircle';
import BannerGradient from './BannerGradient.js';



/*
import React,  { useRef, useEffect } from 'react';
import { RectClipPath } from '@visx/clip-path';
import * as d3 from 'd3';
*/

const hot1 = '#77312f';
const hot2 = '#f33d15';
const cool1 = '#122549';
const cool2 = '#b4fbde';


//type Datum = {  x: number;  y: number;  id: string; };



const fonts = ['Arial', 'Calibri', 'Century Gothic', 'Gill Sans', 'Tahoma', 'Trebuchet MS',
'Verdana', 'Book Antiqua', 'Cambria', 'Garamond', 'Georgia', 'Goudy Old Style',
'Lucida Bright', 'Palatino', 'Baskerville', 'TimesNewRoman', 'Courier New',
'Lucida Sans Typewriter', 'Copperplate', 'Brush Script MT']

export const background = '#28272c';
const binData = genBins(/* length = */ 30, /* height = */ 10);


function max(data, value) {
  return Math.max(...data.map(value));
}

function min(data, value) {
  return Math.min(...data.map(value));
}

const bins = (d) => d.bins;
const count = (d) => d.count;

const colorMax = max(binData, d => max(bins(d), count));
const bucketSizeMax = max(binData, d => bins(d).length);

// scales
const xScale = scaleLinear({
  domain: [0, binData.length],
});
const yScale = scaleLinear({
  domain: [0, bucketSizeMax],
});
const circleColorScale = scaleLinear({
  range: [hot1, hot2],
  domain: [0, colorMax],
});
const rectColorScale = scaleLinear({
  range: [cool1, cool2],
  domain: [0, colorMax],
});
const opacityScale = scaleLinear({
  range: [0.1, 1],
  domain: [0, colorMax],
});



/*export type HeatmapProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  separation?: number;
  events?: boolean;
};*/


function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}



const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    borderRadius:'25px',
    transform: 'translate(-50%, -50%)',
  },
};

const convertToSVG = (props) => {
	var svg = document.getElementById("svg");
	

    var serializer = new XMLSerializer();
	var source = serializer.serializeToString(svg);
	var imgsrc = 'data:image/svg+xml;charset=utf-8,'+ encodeURIComponent( source );
	//var url = "data:image/svg+xml;charset=utf-8,"+encodeURIComponent(source);

	var dl = document.createElement("a");
    document.body.appendChild(dl);
    dl.setAttribute("href", imgsrc);
    dl.setAttribute("download", 'D3 vis exported to PNG.png' );
    dl.click()
}














export const DefaultBanner = (props) => {
  // bounds

  
  const [isDragging, setIsDragging] = useState(false);
  const [textValue, setTextValue] = useState("");
  const [fontSize, setFontSize] = useState(26);
  const [textRotation, setTextRotation] = useState(0);
  const [fontFamily, setFontFamily] = useState('');
  
  const changeTextValue = (event)=> {
	  setTextValue(event.target.value);
  }
  
  const changeFontSize = (event) => {
	  setFontSize(event.target.value);
  }
  
  const setModalIsOpenToFalse =()=>{
	  props.onCloseGenerateBanner();
      //setModalIsOpen(false)
  }
  
  const onFontChange = (event) => {

	  if(event.target.dataset.value == undefined){
		  setFontFamily(event.target.attributes.face.value);
	  }else {
		  setFontFamily(event.target.dataset.value);
	  }
  }

    
  const width = props.width;
  const height = props.height;
  const margin = props.margin;
  const separation = props.separation;
  
  const size = width > margin.left + margin.right ? width - margin.left - margin.right - separation : width;
  const events = props.events;
  
  const xMax = size ;/// 2;
  const yMax = height - margin.bottom - margin.top;

  const binWidth = xMax / binData.length;
  const binHeight = yMax / bucketSizeMax;
  const radius = min([binWidth, binHeight], d => d) ;

  xScale.range([0, xMax]);
  yScale.range([yMax, 0]);
  

  

  const img = {
	  display: 'block',
	  width: 'auto',
	  height: '100%'
  };
  
  const onSelectBanner = () => {
	  var svg = document.getElementById("svg");
	  
	  d3ToPng(svg, 'name112', {
		  scale: 3,
		  format: 'png',
		  quality: 1,
		  download: false,
		  ignore: '.ignored',
		  background: ''
		}).then(fileData => {
			var file = dataURLtoFile(fileData, 'a.png');
			props.onImageCreate(file)
		});
  }


const fontOptions = fonts.map((f, idx) => (
	<li key={idx} data-value={f}><a className="dropdown-item" href="#" onClick={(e)=>{e.preventDefault();  onFontChange(e);}}   data-value={f}><font face={f}>{f}</font></a></li>   
));
  
  
  


  return ( width < 10 ? null : 
		<Modal isOpen={props.genBanner} style={customStyles}>
			<button type="button" className="btn-close" onClick={setModalIsOpenToFalse} aria-label="Close"></button>
			
			
			
			<div className="container" style={{width: '100%'}}>
				{/*<div style={{width: '100%'}}>
		            <SVGTextRows 
						numText={numTextRows}
						onTextChange={onTextChange}
						logoText={logoText}
						fontFamily={fontFamily}
						fontOptions={fontOptions}
						onChangeTextRotation={onChangeTextRotation}
						textRotationArr={textRotationArr}
						index={0} />
		             <a href="#" onClick={onAddMoreText} className="mt-0">
						<span><Icon path={mdiPlus} size={0.5}/> more text</span>
					</a>
				</div>*/}
				<Carousel>
	                    <BannerHeatmapCircle 
	                    	width={width}
	                    	height={height}
	                    	background={background}
	                    	margin={margin}
	                    	heatmapCircle={{binData: binData, 
	                    					xScale: xScale, yScale: yScale, 
	                    					circleColorScale: circleColorScale, 
	                    					opacityScale: opacityScale, radius: radius }}
	                    					
	                    	fontSize={fontSize} 
	                    	fontFamily={fontFamily}
			    			textRotation={textRotation}
			    			textValue={textValue}
			    			events={events}
	                    />
	                	<BannerGradient 
	                		width={width}
	                    	height={height}
	                	/>	
	            </Carousel>
		
			    
			  	<div className="d-flex">
			  		<div className="container">
			  			<div className="d-flex mt-1">
							<div className="form-group" style={{width:'10%'}}>
								<a href="#">
									<span><Icon path={mdiPlus} size={1} />Text</span>
								</a>
							</div>
							<div className="form-group">
								<Input className="form-control-sm input-sm" type="text" value={textValue} onChange={changeTextValue} size="25" style={{width:'40%'}}></Input>
							</div>
							<div className="form-group">
								<Input className="form-control-sm input-sm" type="text" value={fontSize} onChange={changeFontSize} size="5" style={{width:'40%'}}></Input>
							</div>
							
							<div className="dropdown form-group">
								<input  className="form-control-sm input-sm dropdown-toggle-sm" type="text" data-toggle="dropdown" value={fontFamily} placeholder='Select font' />
								<div className="dropdown-menu" style={{overflowY: 'scroll', height: '200px'}}>
								     {fontOptions}
								</div>
							</div>	
		
							<div className="form-group">
								<Input className="form-control" type="text" value={"Rotate: " + textRotation } size="10" style={{width:'40%'}}></Input>
							</div>
						</div>
			  		</div>
			  		<div className="container">
			  			<CircularSlider
			  				onChange={ value => { setTextRotation(value) } }
						    min={0}
						    max={360}
						    direction={-1}
						    knobPosition="right"
						    appendToValue="°"
						    valueFontSize="4rem"
						    trackColor="#eeeeee"
						    progressColorFrom={isDragging ? "#F0A367" : "#00bfbd"}
						    progressColorTo={isDragging ? "#F65749" : "#009c9a"}
						    labelColor={isDragging ? "#F0A367" : "#00bfbd"}
						    knobColor={isDragging ? "#F0A367" : "#00bfbd"}
						    value = {textRotation}
						    isDragging={(value) => setIsDragging(value)}
						/>
			  		</div>
			  	</div>
			</div>
  		</Modal>
  	
  );
  
  
}



