
import React, {useState, useEffect, useMemo } from 'react'
import axios from 'axios';





function StreamOrders(collection){

	const [streamData, setStreamData] = useState([]);
	const [time, setTime] = useState(Date.now());
	
	function generateRow(d) {
		return {
			address: d.wallet_address,
			quantity: d.quantity,
			bid: d.bid,
			bidTime: d.receive_tstamp,
			bidExpiration: d.expires_in.days,
			properties: JSON.parse(d.properties)
		}
	}
	

		
	const params = new URLSearchParams([['collection', collection]]);
	
	const loadData =() => {
		if(!streamData.length){
			const data =  axios.get("/api/v1/orders/*", { params }).then(res => {
				return res.data;
			});
			data.then(recs => {
				setStreamData(recs);
			});
		}
		
	}
	
	if(collection){ loadData(); }
	
	/*useEffect(() => {
	  const interval = setInterval(() => setTime(Date.now()), 5000);
	  const data =  axios.get("/api/v1/orders/*", { params }).then(res => {
			return res.data;
		});
		data.then(recs => {setStreamData(recs)});
	  return () => {
	    clearInterval(interval);
	  };
	}, [time]);*/
	
	return useMemo(() => (streamData.map((d) => generateRow(d))), [streamData]);

};


export default StreamOrders;