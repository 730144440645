

import React from 'react'






const PasswordReset = () => {
	
	
	return (
		<div className="account-pages mt-5 mb-5">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-5">
                    <div className="card">
                        <div className="card-header pt-4 pb-4 text-center bg-default">
                      
                            <a href="/">
                                <span><img src="/brand_assets/Meritic.png" alt="" height="41" /></span>
                            </a>
                        </div>

                        <div id="pwd_reset_body" className="card-body p-4">
                            <form id="set_pwd_form" className="needs-validation" method="POST">

                                <div class="form-group">
                                		<div className="row">
									    <div className="col align-self-start">
									      <label for="password">Create a new password</label>
									    </div>
									    <div classNae="col align-self-end">
									    	<label for="password"><a id="auto_passwd_gen">Generate one for me</a></label>
									    </div>
								  	</div>
                                     
                                    <div className="input-group input-group-merge">
                                        <input type="password" id="password" name="password" className="form-control" placeholder="Enter a strong password" required />
                                        <div className="input-group-append" data-password="false">
                                            <div className="input-group-text">
                                                <span className="password-eye"></span>
                                            </div>
                                        </div>
                              			<div id="password_validate" className="invalid-feedback"> </div>
                                    </div>

                                    <div className="input-group input-group-merge">
                                        <input type="password" id="confirm_password" className="form-control" placeholder="Re-enter your password" required />
                                        <div className="input-group-append" data-password="false">
                                        </div>
                                        <div id="confirm_password_validate" className="invalid-feedback"> </div>
                                    </div>
                                </div>
                                
                                <div className="form-group mb-0 text-center">
                                    <button className="btn btn-primary" type="submit">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 text-center">
                            <p className="text-muted">Back to  <a href="/login" className="text-muted ml-1"  style={{color:'#000080'}}><b>Log In</b></a></p>
                        </div>
                    </div>

                </div> 
            </div>
        </div>
    </div>
	);
}

export default PasswordReset;