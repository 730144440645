import React, {useState, useEffect, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import {Label, Input} from '../../util/LineInput';
import { get_gas_estimate, primary_transact } from '../../util/txact_process';
import Modal from 'react-modal';
import ScrollToBottom from 'react-scroll-to-bottom';
import { GoogleCalendar } from '../comm/GoogleCalendar'
import axios from 'axios';
import { css } from '@emotion/css';






		
		
		
const ReserveOrder = (props) => {

	const [modalIsOpen, setModalIsOpen] = useState(props.isOpen);
	const [bid, setBid] = useState(0);
	const [quantity, setQuantity] = useState(0);
	const [expiration, setExpiration] = useState(0);
	
	const [reservePrice, setReservePrice] = useState(0.0);
	const [reservePriceCurrency, setReservePriceCurrency] = useState();
	const [payCurrency, setPayCurrency] = useState();
	const [txactCurrency, setTxactCurrency] = useState('USDC');
	const [checkoutDisplay, setCheckoutDisplay] = useState('block');
	const [paymentDisplay, setPaymentDisplay] = useState('none');
	const [processDisplay, setProcessDisplay] = useState();
	const [commission, setCommission] = useState(0.0);
	const [txSuccessful, setTxSuccessful] = useState(false)
	const [txResult, setTxResult] = useState(undefined)
	const [pTCondition, setPTCondition] = useState(props.pTCondition);
	
	const [gasEstimate, setGasEstimate] = useState(undefined);
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	const setModalIsOpenToTrue =()=>{
        setModalIsOpen(true);
    }
    const setModalIsOpenToFalse =()=>{
        setModalIsOpen(false)
        props.setInitBid(false);
    }
    
    const onBidChange = (event) => {
		setBid(event.target.value);
	}
	
	const onQtyChange = (event) => {
		setQuantity(event.target.value);
	}
	
	const onExpirationChange = (event) => {
		setExpiration(event.target.value);
	}
	
	
    const customStyles = {
	  content: {
	    top: '50%',
	    left: '50%',
	    right: 'auto',
	    bottom: 'auto',
	    height: '80%',
	    marginRight: '-50%',
	    borderRadius:'25px',
	    transform: 'translate(-50%, -50%)',
	  }
	};

	//primary_transact = async function(charge, pay_currency, txact_currency, service, token_uuid)
	
	const onOrderSubmit = async(orderType) => {
		if(orderType == 'reserve'){
	
			const { tx_receipt, reserve_charge, net_charge } = await primary_transact(parseFloat(reservePrice), payCurrency, txactCurrency, props.collection_name, props.token.uuid);
	
			if(tx_receipt && tx_receipt.status == 1){

				setTxSuccessful(true);
				var service_hours = props.groupConditions.service_conditions.service_hours;
				var time_duration;
				if(service_hours && service_hours.length > 0){
					time_duration = service_hours[0].session_duration_hours
				}
				
				const params = new URLSearchParams([['tx', JSON.stringify(tx_receipt)], 
													['service', props.collection_name], 
													['token_uuid', props.token.uuid],
													['reserve_end', props.token.properties.reserve_end],
													['reserve_start', props.token.properties.reserve_start],
													['time_duration', time_duration],
													['tot_charge', net_charge ],
													['token_charge', reserve_charge],
													['contract_type', props.token.properties.contract_type],
													['currency', payCurrency]]);
													
				const response = await axios.get('/api/v1/mint/marketplace', { params });

				setTxResult(response.data);
			}
		}
	}
	
	
	/*setPaymentDisplay('inline-block');
		setProcessDisplay('inline-block')
		*/
	const handleReserveCheckout = () => {
		setCheckoutDisplay('none');
		setPaymentDisplay('inline-block')
 		
	}
	
	const handleReservePriceChange = (event) =>{
		setReservePrice(event.target.value);
	} 
	
	const handleReserveCurrencyChange = (event) => {
		setReservePriceCurrency(event.target.value);
	}
	
	
	
	
	
	const summarizeOrder = async function(pay_currency){

		setPayCurrency(pay_currency);
		setCommission(new Number(0.04 * reservePrice).toPrecision(3));

		const gas_fee_estimate = await get_gas_estimate(pay_currency, parseFloat(reservePrice), 0.04 * parseFloat(reservePrice));

		setGasEstimate(gas_fee_estimate);
	}
		
		
	function getWindowDimensions() {
		const { innerWidth: width, innerHeight: height } = window;
		return {
			
			height,
			width
		};
	}
	
	const { height, width } = windowDimensions;

	const ROOT_CSS = css({
	  height: height,
	});
	
	useEffect(() => {
	    function handleResize() {
	      setWindowDimensions(getWindowDimensions());
	    }
	
	    window.addEventListener('resize', handleResize);
	    return () => window.removeEventListener('resize', handleResize);
	}, []);
	
	
    return(
  
        	<Modal isOpen={modalIsOpen} style={customStyles}>
        		<div className="float-right">
        			<button type="button" className="btn-close" onClick={setModalIsOpenToFalse} aria-label="Close"></button>
        		</div>
        		<div>
        			<ScrollToBottom className={ROOT_CSS}>
					{props.token ? 
						<>
			                <h5>Bid for {props.token.name}</h5>
			                <p>Token: {props.token.token_id} </p>
			                
			                <hr style={{height:'1px', width:'100%', backgroundColor:'#000000'}}/>
			                <p>Submit a bid for token: {props.token.token_id} </p>
			                <p>of collection: {props.token.collection_name}  </p>
			                
			                
			                <div id="reserve_order">
						      	<div className="form-group d-flex" style={{textAlign:'left'}}>
							       	<label className="form-label" for="reserve_current_price" style={{width:'50%'}}>Current price </label>
							        <div style={{width:'50%'}}>
							        	<div className="input-group">
											<input type="text" className="form-control form-control-sm" id="reserve_current_price" onChange={handleReservePriceChange}    size="5"  placeholder="0.00" value={reservePrice}  aria-label="Text input with dropdown button" />
										  	<div className="input-group-append">							    	
										    	<select id="reserve_price_currency" className="form-select form-select-sm">
										      		<option value="USDC"><a className="dropdown-item" href="#">USDC</a></option>
										      		<option value="ETH"><a className="dropdown-item" href="#">ETH</a></option>
										      		<option value="MATIC"><a className="dropdown-item" href="#">MATIC</a></option>
										    	</select>
										  	</div>
										</div>
									</div>
								</div>
								<div className="form-group row" style={{textAlign:'left'}}>
									<label className="col-sm-6 col-form-label" for="bid_quantity">Quantity </label>
						            <div className="col-sm-6">
						                <input className="form-control form-control-sm" id="bid_quantity" 	name="bid_quantity" type="text" size="8" placeholder="1"  value="1" />
									</div>
								</div>
								<div className="form-group row"  style={{textAlign:'left'}}>
									<label className="col-sm-6 col-form-label" for="bid_expiration">Expires in </label>
						            <div className="col-sm-6">
							             <input className="form-control form-control-sm" id="bid_expiration" name="bid_expiration" type="text" size="8" placeholder="1" value="in 24 hrs" />
									</div>
								</div>
								<div className="form-group mt-0 mb-2" style={{textAlign:'left'}}>
								
									<div className="d-flex">
										<input className="form-control form-control-sm" id="firstname" name="firstname" type="text" placeholder="Firstname" />
										<input className="form-control form-control-sm" id="lastname" name="lastname" type="text" 	placeholder="Lastname" />
										<input className="form-control form-control-sm" id="email" name="email" type="email" placeholder="your email" />
									</div>
								</div>
								
								<div className="form-group mt-3 mb-2">
									<label className="mb-0" for="collection_description">Describe the service </label>
									<textarea className="form-group container form-control" id="service_description" name="service_description"> </textarea>
								</div>
						     </div>
				            
				            
				            <div class="modal-footer" id="checkout_div" style={{display: checkoutDisplay}}>
						     	<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
					        	<button type="button" className="btn btn-primary" onClick={handleReserveCheckout} id="proceed_to_reserve">Checkout</button>
						    </div>
							
				            {<div className="container" id="payment_div" style={{displalay: paymentDisplay}}>
						      	 <label className="mb-0" for=""> Make payment </label>
							     <div className="d-flex mt-3 text-center">
							      	<div style={{width:'100%'}}>	
						        		<a href="#" onClick={() => {summarizeOrder('MATIC')}}>
						               		<img src="/images/tokens/polygon_logo.png" alt="user-image" style={{width:'23%'}} />
						               </a>
						               <div>
						               		MATIC <span id="matic_price"> </span>
						               </div>
							     	</div>
							     	<div style={{width: '100%'}}>	
						        		<a href="#" onClick={() => {summarizeOrder('USDC')}}>
						                	<img src="/images/tokens/usdc_logo.png" alt="user-image" style={{width:'23%'}} />
						                </a>
						                <div>
						               		USDC <span id="usdc_price"> </span>
						               </div>
							  		</div>
							  		<div style={{width:'100%'}}>	
						               <a href="#" onClick={() => {summarizeOrder('ETH')}}>
						                  	<img src="/images/tokens/ethereum_logo.png" alt="user-image" style={{width:'23%'}} />
						                </a>
						                <div>
						               		ETH <span id="usdc_price"> </span>
						               </div>
						            </div>
								 </div>
								{gasEstimate && gasEstimate.status == 'success' ?
										<>
											<div className="form-group-sm row" style={{textAlign:'left'}}>
											 	<div className="col-6">
											 		<label className="form-label">Token value </label>
												</div>
												<div className="col-6"> <span>{reservePrice + ' ' + payCurrency}</span> </div>
											</div>
											<div className="form-group-sm row" style={{textAlign:'left'}}>
									 			<div className="col-6">
									 				<label className="form-label">Meritic commission </label>
									 			</div>
									 			<div className="col-6"><span>{commission + '  ' + payCurrency}</span></div>
									 		</div>
									 		<div className="form-group-sm row" style={{textAlign:'left'}}>
									 			<div className="col-6">
									 				<label className="form-label">Estimated gas fee </label>
									 			</div>
									 			<div className="col-6">
									 				<span>{gasEstimate.value + ' ' + payCurrency}</span>
									 			</div>
											 </div>
										 </> : 
										 <>
										 	{gasEstimate ?
											 <div className="col-12">
											 	<span>An error occured: {gasEstimate.error}</span>
											 </div> : null 
											}
										 
										 </>
									
								}
								<div className="float-right" style={{display: txSuccessful ? 'none': 'inline-block'}}>
					     			<a type="button" className="align-right btn-sm btn-light"  onClick={() => {onOrderSubmit('reserve')}} style={{float: 'right'}}>Reserve</a>
								 </div>
								{txResult ?
									<>
										 <div id="tx_result_div" className="form-group-sm mt-4" style={{display: txSuccessful ? 'inline': 'none'}}>				 	
										 	<h5 className="card-title meritic_dblue">Transaction completed successfully</h5>
									   		<div className="row"> <div className="col-3"> <p className="text-justify">Token holder: </p> </div><div className="col-9"><p className="text-justify" id="token_holder">{ txResult.holder }</p> </div> </div>
									   		<div className="row"> <div className="col-3"> <p className="text-justify">Token ID: </p> </div><div className="col-9"><p className="text-justify" id="token_id">{ txResult.token_id.toString() }</p> </div> </div>
									   		<div className="row"> <div className="col-3"> <p className="text-justify">Token value: </p> </div><div className="col-9"><p className="text-justify" id="token_value">{ txResult.token_value.toString() }</p> </div> </div>
									   		<div className="row"> <div className="col-3"> <p className="text-justify">Gas cost: </p> </div><div className="col-9"><p className="text-justify" id="tx_gas_cost">{ txResult.gas_cost.toString() } </p> </div> </div>
									   		<div className="row"> <div className="col-3"> <p className="text-justify">Block number: </p> </div><div className="col-9"><p className="text-justify" id="tx_block_number">{  txResult.block_number.toString() } </p> </div> </div>
									   		{/*<div className="row"> <div className="col-3"> <p className="text-justify">Transaction hash: </p> </div><div className="col-9"><p className="text-justify" id="tx_hash"> { txResult.tx_hash.toString() } </p> </div> </div>*/}
									   		<hr style={{height:'1px', width:'100%', backgroundColor:'#000000'}}/>
							     			<GoogleCalendar tokenId={props.token.token_id}  />	 
									     </div> 
									     
									    
										 
								  	</> : null 
								  }
							 </div>}
					 	</> : null
					 }
				 	</ScrollToBottom>
				</div>
            </Modal>
            
            
    );

};

export { ReserveOrder }