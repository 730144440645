
import React, {useState, useEffect, useRef	} from 'react'
import { flushSync } from 'react-dom';
import { useNavigate } from "react-router-dom"
import Icon from "@mdi/react";
import { mdiDotsGrid } from '@mdi/js';
import { mdiWallet } from '@mdi/js';
import { mdiAccountCircleOutline } from '@mdi/js';
import { BasicUserAvatar } from '../vis/user_avatar';
import { connectWallet, getCurrentWalletConnected, addWalletListener } from "./interact.js"; 
import './mainNav.css';






export default function MainNav (props) {
	
	const navigate = useNavigate();
	
	
	const [toggleShow, setToggleShow] = useState(false);
	/*const [wallet, setWallet] = useState({});
	const [displayWalletConnectError, setDisplayWalletConnectError] = useState(false);
	const [modalWalletConnectIsOpen, setModalWalletConnectIsOpen] = useState(false);
	
	
	
	
	const connectWalletPressed = async () => {
	
	  const walletResponse = await connectWallet();
	  if(!walletResponse.address){
		setDisplayWalletConnectError(true);
		setModalWalletConnectIsOpen(true);
	  }
	  setWallet(walletResponse);
	};
	

	
	const handleWalletChanged = (updatedStatus) => {
		setWallet(updatedStatus);
	}
	
	const handleStatusChanged = (updatedStatus) => {
		setWallet(updatedStatus);
	}
	

	const closeWalletConnectModal = () => {
		setModalWalletConnectIsOpen(false);
	}

	const updateWallet = async() => {
		const walletResponse = await getCurrentWalletConnected();
	    setWallet(walletResponse);
	    addWalletListener(handleWalletChanged, handleStatusChanged);
	}

	useEffect(() => {
	    updateWallet();
	}, []);


	const walletConnect = { displayError: displayWalletConnectError, 
							wallet: wallet,
							modalIsOpen: modalWalletConnectIsOpen,  
							closeModal: closeWalletConnectModal
						 };
						 

	//props.setWalletConnect(walletConnect);
	*/
	
	
	
	return (
		//navbar navbar-expand-lg navbar-light  
		
			<head className="navbar navbar-expand-lg navbar-light bg-white sticky-top flex-md-nowrap">
			
				<div className="container-fluid" style={{width: '90%'}}>
					<a className="navbar-brand mr-lg-5" href="/" >
						<img className="logo-light" src={props.brandimagepath}  alt="" height={props.brandimageheight} />
		            </a>
		            <div className={'navbar-collapse ' + (toggleShow ? 'show' : '')}>
		            	 <div class="offcanvas-header mt-3">  
					      <button className="btn btn-close float-right" onClick={() => {setToggleShow(!toggleShow) }}>  </button>
					    </div>
						<ul className="navbar-nav mr-auto ml-8 align-items-center">
					        {/*<li className="nav-item mx-lg-1">
					            <a className="nav-link" onClick={()=>navigate("/collections")} href="#!">Collections</a>
					        </li>*/}
					        <li className="nav-item mx-lg-1">
					            <a className="nav-link" href="/">Home</a>
					        </li>
					        <li className="nav-item mx-lg-1">
					            <a className="nav-link" href="/configuration">Register</a>
					        </li>
					      
							<li className="nav-item mx-lg-1">
			            		<a className="nav-link" href="https://docs.meritic.xyz">Docs</a>
			        		</li>
					       
					        
					        <li className="nav-item mx-lg-1">
					            <a className="nav-link" href="/#contact">Contact</a>
					        </li>
					    </ul>
					   { props.navDisplaySearch ?
						   <input className="form-control form-control-light w-50" type="text" placeholder="Search" aria-label="Search" /> : null
					   } 
					
			            {
							/*<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
							    aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
							    <i className="mdi mdi-menu"></i>
							</button>*/
						}
	
				
					</div> 
				    <div className="navbar-nav ml-auto">
					    <ul className="navbar-nav ml-auto align-items-center">
					
					    	{/*<li className="dropdown notification-list d-none d-sm-inline-block">
					            <a className="btn-light dropdown-toggle arrow-none" data-toggle="dropdown" href="/" role="button" aria-haspopup="false" aria-expanded="false">
					                <Icon path={mdiDotsGrid} title="Add" size={1} />
					            </a>
					            <div className="dropdown-menu dropdown-menu-right dropdown-menu-animated dropdown-lg p-0">
					
					                <div className="p-2">
					                    <div className="row no-gutters">
					                        <div className="col">
					                            <a className="dropdown-icon-item" onClick={(event)=>{event.preventDefault(); navigate("/configuration")}} href="#!">
					                                <i className="fa-solid fa-rectangle-vertical-history"></i>
					                                <span>Mint collection</span>
					                            </a>
					                        </div>
					                        <div className="col">
					                            <a className="dropdown-icon-item" href="/">
					                                <img src="" alt="Github"/>
					                                <span>Tokens</span>
					                            </a>
					                        </div>
					                        <div className="col">
					                            <a className="dropdown-icon-item" href="/">
					                                <img src="" alt="dribbble"/>
					                                <span>Service providers</span>
					                            </a>
					                        </div>
					                    </div>
					                </div>
					            </div>
					        </li> */}
				        
				        
					        <li className="nav-item pl-2">
					        	{props.walletConnected ? 
					            	<button className="btn btn-sm btn-dark btn-rounded d-none d-lg-inline-flex" onClick={props.connectWalletPressed} style={{backgroundImage: "url(/images/connected.png)", backgroundRepeat  : 'no-repeat', maxHeight:"35px", maxWidth:"120px"}} >
					                	<span className="d-flex" style={{color: 'black'}}><Icon path={mdiWallet} title="Connect" size={1} /> Connected </span>
					                </button> :
			
					                <button className="btn btn-sm btn-light btn-rounded d-none d-lg-inline-flex" onClick={props.connectWalletPressed} 
					                	style={{maxHeight:'35px', maxWidth:'107px'}}>
					                	<span className="d-flex" style={{color: 'black'}}><Icon path={mdiWallet} title="Connect" size={1} /> Connect </span>
					                </button> 
					                
					             }
					        </li> 
				        
					        {/*props.userData ?
					        	<BasicUserAvatar userInfo = {{name: props.userData.username, value:30}}  width = {45}  	height = {45} /> :
					        	
					        	<li className="dropdown notification-list d-none d-sm-inline-block">
						        	<a className="nav-link dropdown-toggle arrow-none" data-toggle="dropdown" href="/" role="button" aria-haspopup="false" aria-expanded="false">
						                <Icon path={mdiAccountCircleOutline} title="Add" size={1.25} />
						            </a>
						            <div className="dropdown-menu dropdown-menu-left dropdown-menu-animated dropdown-lg p-0">
						            	<div className="p-2">
						                    <div className="row no-gutters">
						                        <div className="col">
						                        	<a className="btn-sm btn-light btn-rounded d-none d-lg-inline-flex" onClick={(event)=>{event.preventDefault(); navigate("/register")}}  href="#!">
										                Sign-up
										            </a>
										        </div>
										        <div className="col">
										        	<a className="btn-sm btn-light btn-rounded d-none d-lg-inline-flex" onClick={()=>navigate("/login")}  href="#!">
										                Log-in
										            </a>
										        </div>
										     </div>
										  </div>
						            </div>
						        </li> */
					        }
					        {
								/*
								<li className="dropdown nav-item mr-0">
					             <a className="d-none d-lg-inline-flex" data-toggle="dropdown" id="topbar-userdrop" href="/" role="button" aria-haspopup="true" aria-expanded="false">
					                 <span className="account-user-avatar" id="avatar_svg">
					
					                 </span>
					             </a>
					             <div className="dropdown-menu dropdown-menu-right dropdown-menu-animated topbar-dropdown-menu profile-dropdown" aria-labelledby="topbar-userdrop">
					                 <div className=" dropdown-header noti-title">
					                     <h6 className="text-overflow m-0">Welcome !</h6>
					                 </div>
					                 <a className="dropdown-item notify-item" href="/user_profile">
					                     <i className="mdi mdi-account-circle mr-1"></i>
					                     <span>My Account</span>
					                 </a>
					                 <a className="dropdown-item notify-item" href="/logout">
					                     <i className="mdi mdi-logout mr-1"></i>
					                     <span>Logout</span>
					                 </a>
					             </div>
					         </li>
					         data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" 
					         */
							}
				     </ul>
				</div> 
				<button className="navbar-toggler" type="button" onClick={() => {setToggleShow(!toggleShow)}} aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
			      <span className="navbar-toggler-icon"></span>
			    </button>
				</div>
			  </head>
		
	
	);
}