

import React  from 'react'
import { useNavigate } from "react-router-dom"
import Icon from "@mdi/react";
import { mdiTwitter, mdiLinkedin} from '@mdi/js';



const Footer = () => {
	
	const navigate = useNavigate();
	
	return (
		<footer className="bg-dark py-5">
		    <div className="container">
		        <div className="row">
		            <div className="col-lg-6">
		                <img src="/brand_assets/meritic_word_bw.png" alt="" className="logo-dark" height="18" />
		                <p className="text-white-50 mt-1">A platform and marketplace for creators and service providers to issue tokenized credits to their users and followers. </p>
		

				            
				            
				            
		                <ul className="social-list list-inline mt-3"> 
		                    <li className="list-inline-item text-center">
		                        <a href="https://twitter.com/meritic_xyz"  className="border-primary text-primary">   <Icon path={mdiTwitter} title="Twitter" size={1} /> </a>
		                    </li>
		                    <li className="list-inline-item text-cent;er">
		                        <a href="https://www.linkedin.com/company/34598481/" className="border-primary text-primary"> <Icon path={mdiLinkedin} title="LinkedIn" size={1} /> </a>
		                    </li>
		                </ul>
						<p className="text-white-50">33 West 60th St, 2nd Fl, New York, NY 10023</p>
		            </div>
		
		            <div className="col-lg-3 mt-3 mt-lg-0">
		                <h5 className="text-white">Company</h5>
		
		                <ul className="list-unstyled pl-0 mb-0 mt-3">
		                    <li className="mt-2"><a href="/#contact" className="text-white-50">Contact Us</a></li>
		                </ul>
		
		            </div>
		
		            <div className="col-lg-3 mt-3 mt-lg-0">
		                <h5 className="text-white">Resources</h5>
		
		                <ul className="list-unstyled pl-0 mb-0 mt-3">
		                    <li className="mt-2"><a onClick={()=>navigate("/terms_of_service")} href="#/" className="text-white-50">Terms of Service</a></li>
		                    <li className="mt-2"><a onClick={()=>navigate("/privacy_policy")} href="#/" className="text-white-50">Privacy Policy</a></li>
		                </ul>
		            </div>
		
		        </div>
		
		        <div className="row">
		            <div className="col-lg-12">
		                <div className="mt-5">
		                    <p className="text-white-50  mt-4 text-center mb-0">© 2023 Meritic Corp.</p>
		                </div>
		            </div>
		        </div>
		    </div>
		</footer>
	)
}

export default Footer;
