
import React, {useState} from 'react'
import RadioOption from '../../util/RadioOption';
import { Label } from '../../util/LineInput';
import DTimePicker from '../../util/TimePicker';
import ToggleSwitch from '../../util/ToggleSwitch';
import '../style.css';
import dateFormat from 'dateformat';
import Parser from 'html-react-parser';







const EndDateHeader = (props) => {
	
	var temp='';
	
	if(props.end.select_end_before){
		temp += '<span class="mr-1"><span class="font-13 mr-0">End before:</span> <span class="text-muted font-13 ml-1">' + dateFormat(props.end.end_before, "dddd, mmmm dS, yyyy, h:MM:ss TT") + ' </span></span>';
	}else if(props.end.select_end_after){
		temp += '<span class="mr-1"><span class="font-13 mr-0">End after:</span> <span class="text-muted font-13 ml-1">' + dateFormat(props.end.end_after, "dddd, mmmm dS, yyyy, h:MM:ss TT") + ' </span></span>';
	}
	temp += '<span class="mr-1"><span class="font-13 mr-0">Exact end date:</span> <span class="text-muted font-13 ml-1"> Set by buyer </span></span>';
	temp = Parser(`<h4>${props.condition_name.split('_').join(' ')}</h4><div class="d-flex">${temp}`)
	return( temp );
	
}




const EndDate = (props) => {
	
	const [selectEndDateBefore, setSelectEndDateBefore] = useState(undefined);
	//const [selectEndDateAfter, setSelectEndDateAfter] = useState(undefined);
	const [endDateBefore, setEndDateBefore] = useState(new Date());
	const [endDateAfter, setEndDateAfter] = useState(new Date());
	
	
	
	const endDateBeforeChange = value => { setEndDateBefore(value); };
	//const endDateAfterChange = value => { setEndDateAfter(value); };
	
	const onChangeSelectEndDate = (event) => {
		if(event.target.dataset.value == 'end_date_before') {
			let tokengroup = parseInt(event.target.id.split('_')[3]);
			//setSelectEndDateAfter(false);
			setSelectEndDateBefore(true);

		}else if(event.target.dataset.value =='end_date_after') {
			let tokengroup = parseInt(event.target.id.split('_')[3]);
			//setSelectEndDateAfter(true);
			setSelectEndDateBefore(false);

		}
	}
	
	
	const onUpdateClick = (event) => {
		props.updateCondition('end_date', {
			end_before: endDateBefore, 
			//end_after: endDateAfter, 
			select_end_before: selectEndDateBefore, 
			//select_end_after: selectEndDateAfter
		})
		//props.setShowTokenGrpSave(true);
	}
	
	
	
	return (
		
		<div className="d-flex">
			<div class="list-group-horizontal d-flex rounded-5" role="tablist" style={{width: '90%'}}>
				<a class="list-group-item list-group-item-action"  onClick={onChangeSelectEndDate} data-value="end_date_before" role="tab">	    			
					<div onClick={onChangeSelectEndDate} data-value="end_date_before" >
						<Label for="end_date_before"  onClick={onChangeSelectEndDate} data-value="end_date_before" className="mb-0"  text="End date before" >	</Label>
						{ selectEndDateBefore ? <DTimePicker 	id="end_date_before"	onChange={endDateBeforeChange} value={endDateBefore}> </DTimePicker>  : null }
					</div>
				</a>
				{/*<a class="list-group-item  list-group-item-action"  onClick={onChangeSelectEndDate} data-value="end_date_after" role="tab">
					<div onClick={onChangeSelectEndDate} data-value="end_date_after">
						<Label for="end_date_after"  className="mb-0" onClick={onChangeSelectEndDate} data-value="end_date_after" text="End date after" >	</Label>
						{selectEndDateAfter ? <DTimePicker 	id="end_date_after" onChange={endDateAfterChange} value={endDateAfter}> </DTimePicker> : null	}
					</div>
				</a>*/}
			</div>
			<div style={{width: '10%'}}>
				<div>&#8205;</div>
				<button type="button" className="align-right btn btn-sm btn-light" onClick={onUpdateClick} 
					data-tokengroup={props.number} data-condition={props.condition_name}  data-condition_index={props.condition_index}  style={{float: 'right'}}>Update</button>
			
			</div>
			
		</div>
		
		
		
		
	);
}




export { EndDateHeader, EndDate }