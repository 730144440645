import React, {useState, useEffect, useRef } from 'react';
import ApiCalendar from "react-google-calendar-api";
import { gapi } from "gapi-script";

import { getMyGoogleCalendarsList } from './CalendarApi'



 
const GoogleCalendar = (props) => {
	
	const config = {
	  clientId: "459917919772-rb08v168v8nvc0e1fecqv1s8i3u76l57.apps.googleusercontent.com",
	  apiKey: "AIzaSyBwROq6olaCNvnAmbgP6Sxe8Q11Lg9b8Ys",
	  scope: "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
	  discoveryDocs: [
	    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
	  ],
	};
	
	const calendarID = '459917919772-rb08v168v8nvc0e1fecqv1s8i3u76l57.apps.googleusercontent.com';
	const apiKey = 'AIzaSyBwROq6olaCNvnAmbgP6Sxe8Q11Lg9b8Ys';
	const accessToken = 'ya29.a0AWY7Ckn_TOmcEk9gk5rRTB4aaONaK3L8ErBUoHx3xJXAB1degE8ReYfBt2WSnSZ9DnacV9xPyBzwoYwTdv-QEkGPYM-hQYHagSyHPQf0tHcdB3cfGyLpX_le0tRtYQnbeFDg39ZjOT-wV0AerV4QLcCbiSpOQ4waCgYKASESARASFQG1tDrpU5mlLAGGrV7SaTcg00ti9A0166';
	
	
	const [events, setEvents] = useState([]);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	
	
	
	
	const createGoogleAuthLink = async () => {
	    try {


	      const request = await fetch("/createAuthLink", {
	        method: "POST",
	        headers: { 'Content-Type': 'application/json' },
	        body: JSON.stringify({ token_id: props.tokenId})
	      });
	      const response = await request.json();	
	      window.location.href = response.url;
	    } catch (error) {
	      console.log("App.js 12 | error", error);
	      throw new Error("Issue with Login", error.message);
	    }
  };
  
  
  
	
  
  
  	
	
	const signOut = () => {
    	setIsLoggedIn(false);
    	sessionStorage.clear();
  	};
	  
  
  
	
	
	var apiCalendar;
	
	useEffect(() => {
		//apiCalendar = new ApiCalendar(config);
		//const events = getEvents(calendarID, apiKey);
    	//setEvents(events);
	}, []);
	
	
	
	/*const getEvents = (calendarID, apiKey) => {
		
		function initiate() {
			gapi.client.init({ apiKey: apiKey, }).then(function () {
				return gapi.client.request({
	          		path: `https://www.googleapis.com/calendar/v3/calendars/${calendarID}/events`,
	        	});
			}).then((response) => {
				let events = response.result.items;
				setEvents(events);

				},
				function (err) {
		          return [false, err];
		        }
			)
		};
		
		gapi.load("client", initiate);
	}
	
	
	const addEvent = (calendarID, event) => {
		function initiate() {
			gapi.client.request({
	        path: `https://www.googleapis.com/calendar/v3/calendars/${calendarID}/events`,
	        method: "POST",
	        body: event,
	        headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${accessToken}`,
			},
	      }).then((response) => { return [true, response]; }, function (err) { console.log(err); return [false, err]; });
 		 }
  	gapi.load("client", initiate);
	};*/
	
	
	
	
	
	/*const handleItemClick = async(event, eventType) => {
		if (eventType === 'sign-in') {
			
          const dd = await apiCalendar.handleAuthClick();
    
      
          const eventFromNow = {summary: "Poc Dev From Now", time: 480 };
          
          const eventWithVideoConference = {
			  summary: "Event With Google Meet Conference",
			  start: {
			    dateTime: new Date().toISOString(),
			    timeZone: "Europe/Paris",
			  },
			  end: {
			    dateTime: new Date(new Date().getTime() + 3600000).toISOString(),
			    timeZone: "Europe/Paris",
			  },
			  
		  };

          const eventOut = await apiCalendar.createEventWithVideoConference(eventWithVideoConference, config.clientId, 'none');
          console.log(eventOut);
          console.log('-----------')
		  
        } else if (eventType === 'sign-out') {
          await apiCalendar.handleSignoutClick();
        }
    }*/

	return (
		<>
			<div className="container">
				<h1>Google</h1>
			      {!isLoggedIn ? (
			        <button onClick={createGoogleAuthLink}>Login</button>
			      ) : (
			        <>
			          <button onClick={getMyGoogleCalendarsList}>
			            Get Google Calendars
			          </button>
			          <button onClick={signOut}>Sign Out</button>
			        </>
			      )}
    		</div>
		</>
		
	);	
}



export { GoogleCalendar };