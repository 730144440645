import React, {useState, useEffect, useRef	} from 'react'
import Parser from 'html-react-parser';
import MainNav from '../util/MainNav';
import BannerNav, { BannerAction } from '../util/BannerNav';
import axios from 'axios';
import { ForConsumers } from './ForConsumers';
import { ForProviders } from './ForProviders';
import { ContactUs } from './ContactUs';
import Footer from "../util/Footer";
import { connectWalletPressed, getCurrentWalletConnected, addWalletListener } from "../util/interact.js"; 
import Modal from 'react-modal';
import HowItWorks from "./HowItWorks";
import { flushSync } from 'react-dom';








function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    borderRadius:'25px',
    transform: 'translate(-50%, -50%)',
  },
};





export default function Meritic(props){
	const sub_title = `A platform for tokenizing time and service credits`;
	//const sub_title = `With service tokens you can <mark style="background-color: #CCCCE5; color:#000080">[reserve | use | transfer]</mark> services.`;
	//const banner_action_span_html = `Join our waitlist for &nbsp;  <a href="#contact" id="svc_provider_btn" class="btn-sm btn-dark btn-rounded" style="text-transform: none;">Service providers | Institutions</a> &nbsp; or make a &nbsp; <a href="#contact" id="general_inquiry_btn" class="btn-sm btn-dark btn-rounded" style="text-transform: none;" style="text-transform: none;">General Inquiry</a>`;	
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
	const [wallet, setWallet] = useState(props.wallet)
	const [walletConnect, setWalletConnect] = useState(props.walletConnect);
	const [displayWalletConnectError, setDisplayWalletConnectError] = useState(false);
	const [modalWalletConnectIsOpen, setModalWalletConnectIsOpen] = useState(false);
	
	
	const [providerContact, setProviderContact] = useState(false);
	const [genInquiryContact, setGeneralInquiryContact] = useState(true);
	const [contactTitle, setContactTitle] = useState("Get In Touch");
	const [contactTitleDesc1, setContactTitleDesc1] = useState("Please fill out the following form and we will get back to you shortly. For more");
	const [contactTitleDesc2, setContactTitleDesc2] = useState("information please contact us.");
	
	const [contactSubject, setContactSubject] = useState("");
	const [contactFullname, setContactFullname] = useState("");
	const [contactEMAIL, setContactEMAIL] = useState("");
	const [contactCompany, setContactCompany] = useState("");
	const [companyURL, setCompanyURL] = useState("");
	const [inquiryText, setInquiryText] = useState("");
	const [contactFormSubmitted, setContactFormSubmitted] = useState(false);
	const [contactRecievedObj, setContactRecievedObj] = useState({});
	//const [walletConnect, setWalletConnect] = useState()
	//const [recaptchaToken, setRecaptchaToken] = useState();
	
	
	const { height, width } = windowDimensions;
	const isLongScreen = window.innerWidth <  window.innerHeight;
	const isMobileWidth = window.innerWidth < 600 && isLongScreen;
	
	/*const verifyRecaptchaCallback = React.useCallback((token) => {
        setRecaptchaToken(token)
  	}, []);*/
      


	const updateContactSubject = (event) => {
		setContactSubject(event.target.value);
	}
	const updateContactFullname = (event) => {
		setContactFullname(event.target.value);
	}
	const updateContactEMAIL = (event) => {
		setContactEMAIL(event.target.value);
	}
	const updateContactURL = (event) => {
		setCompanyURL(event.target.value);
	}
	const updateContactCompany = (event) => {
		setContactCompany(event.target.value);
	}
	const updateContactMessage = (event) =>{
		setInquiryText(event.target.value);
	}
	
	
	const handleServiceProviderContact = () => {
		setProviderContact(true);
		setContactTitle("Join our waitlist for Service Providers and Institutions");
		setContactTitleDesc1("We wish to understand your service to ensure we build to address your needs.");
		setContactTitleDesc2("Please provide a relaible contact below.");
	}
	
	const handleGeneralInquiryContact = () => {

		setProviderContact(false);
		setGeneralInquiryContact(true);
		setContactTitle("Get In Touch");
		setContactTitleDesc1("Please fill out the following form and we will get back to you shortly.");
		setContactTitleDesc2("For more information please contact us.");
	}
	



	
	const handleContactSubmit = async (event) => {

		event.preventDefault(); 

		var contact_type = 'general_inquiry';
			
		if(contactCompany && !(contactCompany === '')){
			contact_type = 'waitlist';
		}
		
			
		var formData = {
			subject: contactSubject,
			fullname: contactFullname,
			email: contactEMAIL,
			company: contactCompany,
			companyurl: companyURL,
			inquiry_text: inquiryText,
			contact_type: contact_type //,
			//recaptcha_token: recaptchaToken
		};

		//https://meritic.xyz/contact_data
		axios.post("/contact_data", formData)
		.then(response =>  {
			
			setContactFormSubmitted(true);
			setContactRecievedObj({name: response.data.name, server_response: response.data.server_response});
			
		});
	}
		
	const handleBackToContactForm = () => {
		setContactFormSubmitted(false);
		setContactRecievedObj({});
	}
	
	var statusWalletConnected;
	if(walletConnect){
		statusWalletConnected = (walletConnect.displayError ? 'fail' : 'success');
	}else{
		statusWalletConnected = "";
	}

	const closeWalletConnectModal = () => {

		setWalletConnect({ displayError: displayWalletConnectError, 
								wallet: wallet,
								modalIsOpen: false,  
								closeModal: closeWalletConnectModal
							 });
	}
	
	const handleWalletChanged = (updatedStatus) => {
		setWallet(updatedStatus);
		setDisplayWalletConnectError(updatedStatus.status != 'success');
		setWalletConnect({ displayError: updatedStatus.status != 'success', 
									wallet: updatedStatus,
									modalIsOpen: modalWalletConnectIsOpen,  
									closeModal: closeWalletConnectModal
								 });
	}
	
	const handleStatusChanged = (updatedStatus) => {
		setWallet(updatedStatus);
		setDisplayWalletConnectError(updatedStatus.status != 'success');
		setWalletConnect({ displayError: updatedStatus.status != 'success', 
									wallet: updatedStatus,
									modalIsOpen: modalWalletConnectIsOpen,  
									closeModal: closeWalletConnectModal
								 });
	}
	const handleConnectWalletPressed = () => {
		connectWalletPressed({setConnectError: setDisplayWalletConnectError,
							setModalConnectIsOpen: setModalWalletConnectIsOpen,
							closeConnectModal: closeWalletConnectModal,
							setWallet: setWallet,
							setConnect : setWalletConnect
						});
	}
	
	
	
	
	
	
	useEffect(() => {
	    function handleResize() {
	      setWindowDimensions(getWindowDimensions());
	    }
	
		if(!walletConnect || walletConnect.displayError ){
			addWalletListener(handleWalletChanged, handleStatusChanged);
		
		    getCurrentWalletConnected().then( walletResponse => {
		  		setWallet(walletResponse);
		  		
		  		setDisplayWalletConnectError(walletResponse.status != 'success')
			    setWalletConnect({ displayError: walletResponse.status != 'success', 
									wallet: walletResponse,
									modalIsOpen: modalWalletConnectIsOpen,  
									closeModal: closeWalletConnectModal
								 });
			});
		}
		
	    window.addEventListener('resize', handleResize);
	    return () => window.removeEventListener('resize', handleResize);
	}, []);
	
	
	
	
	
	
	
	return (
		<div>
		
			
			<MainNav userData={props.userData} brandimagepath={"/brand_assets/Meritic.png"}  walletConnected={statusWalletConnected == 'success'}
						navDisplaySearch={false} brandimageheight={"40"}  connectWalletPressed={handleConnectWalletPressed}> </MainNav>
			
		  	<section className="p-2" style={{
				backgroundImage: "url('/images/banner_world_optimized.png')",
				backgroundPosition: 'center',
				backgroundSize: 'cover',
		        backgroundRepeat: 'no-repeat',
		        height: '650px'
				}}>
				
				
				<BannerNav 
					title_class="display-6 meritic_mpurple" 
					title="A world with tokenized services" 
					subtitle={Parser(sub_title)} 
					subtitle_class="lead meritic_dblue font-20" 
					navoption_ids={['/configuration']} 
					navoptions={['Register as a creator']} 
				>
				</BannerNav>
				{
					/*<BannerAction 
						span_class="font-weight-normal"
						handleServiceProviderContact={handleServiceProviderContact}
						handleGeneralInquiryContact={handleGeneralInquiryContact}
						providerContact={providerContact}
						genInquiryContact={genInquiryContact}
					/>*/
				}
				
				
			</section>
			
			<main className="sm-auto">
			
					
					<ForConsumers 
					 isMobileWidth={isMobileWidth}
					 windowWidth={window.innerWidth}
					/>
	
		        	<ForProviders 
		        	 isMobileWidth={isMobileWidth}
					 windowWidth={window.innerWidth}
		        	/>
		        	
		        	<HowItWorks 
		        	 isMobileWidth={isMobileWidth}
					 windowWidth={window.innerWidth}
		        	/>
		        	
		        <ContactUs 
		        	contactTitle={contactTitle}
		        	contactTitleDesc1={contactTitleDesc1}
		        	contactTitleDesc2={contactTitleDesc2}
		        	updateContactFullname={updateContactFullname}
		        	updateContactEMAIL={updateContactEMAIL}
		        	updateContactCompany={updateContactCompany}
		        	updateContactURL={updateContactURL}
		        	updateContactSubject={updateContactSubject}
		        	updateContactMessage={updateContactMessage}
		        	handleContactSubmit={handleContactSubmit}
		        	providerContact={providerContact}
		        	contactFormSubmitted={contactFormSubmitted}
		        	handleBackToContactForm={handleBackToContactForm}
		        	contactRecievedObj={contactRecievedObj}
		        >
		        </ContactUs>
		
		        
	        </main>
	        <Footer />
	     
	        { walletConnect && walletConnect.displayError ? 
	        	<Modal isOpen={walletConnect.modalIsOpen} style={customStyles}>
	               
	                <button type="button" className="btn-close float-right" onClick={walletConnect.closeModal} aria-label="Close"></button>
	                	<div className="container">
	                		{walletConnect &&
								walletConnect.wallet.status == 'Already processing eth_requestAccounts. Please wait.' ?
								<div>
									<p>Check your Metamask wallet App.</p> 
									<p>You may need to log-in to Metamask to connect your wallet </p>
								</div> : 
								
								<div>
									{walletConnect.wallet.status }
								</div>
							}
	                		
	               		</div>
	                
	            </Modal> : null
	        }
		</div>
	);
}