

import React, { useState } from 'react'
import MainNav from '../util/MainNav';
import Footer from "../util/Footer";
//import { useNavigate } from "react-router-dom"

const Login = () => {
	//const navigate = useNavigate();
	/*
	const [username, setUsername] = useState("")
	const [password, setPassword] = useState("")
	const [checkBox, setCheckBox] = useState("")
	
	
	
	const handleUsernameChange = (event) => {
		setUsername(event.target.value);
	}
	
	const handlePasswdChange = (event) => {
		setPassword(event.target.value);
	}
	
	const handleCheckBoxChange = (event) => {
		setCheckBox(event.target.checked);
	}
	
	async function handleSubmit(event){
		event.preventDefault();

		axios.post("https://meritic.xyz/login", {username: username, password: password})
		.then(response =>  {
			//setSuccessfulSubmit(response.data.valid);	
		});
		
	}
	
	useEffect(() => {
	    const delayDebounceFn = setTimeout(() => {
			
			
			
		}, 100)
	    
	    return () => clearTimeout(delayDebounceFn)
	    
	}, [username, password, checkBox])
	
	
	
	*/
	
		
	return (
		<section className="p-2">
			<MainNav brandimagepath="/brand_assets/Meritic.png" brandimageheight="40"> </MainNav>
			<div className="account-pages mt-5 mb-5">
	        	
	            <div className="container">
	                <div className="row justify-content-center">
	                    <div className="col-lg-5">
	                        <div className="card">
	                        
	                            <div className="card-body p-4">
	                                
	                                <div className="text-center w-75 m-auto">
	                                    <h4 className="text-dark-50 text-center mt-0 font-weight-bold" style={{color:'#000080'}}>Sign In</h4>
	                                    <p className="text-muted mb-4">Enter your username and password.</p>
	                                </div>
	
	                                <form action="/login_user" method="post" id="initial_user_credential">
	
	                                    <div className="form-group">
	                                        <label htmlFor="emailaddress">Username</label>
	                                        <input className="form-control" type="text"  placeholder="username" name="username" />
	                                    </div>
	
	                                    <div className="form-group">
	                                    	{/* <a href="javascript: void(0)" className="text-muted float-right" onClick={()=>navigate("/recoverpw")}><small>Forgot your password?</small></a>*/}
	                                        
	                                        <label htmlFor="password">Password</label>
	                                        <div className="input-group input-group-merge">
	                                            <input  className="form-control"  type="password"  name="password" placeholder="Password..." />
	                                            <div className="input-group-append" data-password="false">
	                                                <div className="input-group-text">
	                                                    <span className="password-eye"></span>
	                                                </div>
	                                            </div>
	                                        </div>
	                                    </div>
	
	                                    <div className="form-group mb-3">
	                                        <div className="custom-control custom-checkbox">
	                                            <input className="custom-control-input" type="checkbox" id="checkbox-signin" name="remember" checked />
	                                       
	                                            <label className="custom-control-label" htmlFor="checkbox-signin">Remember me</label>
	                                        </div>
	                                    
	                                    </div>
	
	                                    <div className="form-group mb-0 text-center">
	                                        <button className="btn-primary"  type="submit" > Log In </button>
	                                    </div>
	
	                                </form>
	                            </div> 
	                        </div>
	                        
							<div className="row mt-3">
	                            <div className="col-12 text-center">
	                                <p className="text-muted">Don't have an account? <a href="/register" className="text-muted ml-1" style={{color: '#000080'}}><span style={{color: '#000080'}}><b>Sign Up</b></span></a></p>
	                            </div>
	                        </div>
	                        
	                    </div> 
	                </div>
	            
	            </div>
	            
	        </div>
	     <Footer />
	  </section>
	)
}

export default Login;