
import React, {useState} from 'react';
import { Label } from '../../util/LineInput';
import DTimePicker from '../../util/TimePicker';
import ToggleSwitch from '../../util/ToggleSwitch';
import '../style.css';
import dateFormat, { masks } from 'dateformat';
import Parser from 'html-react-parser';
import { mdiTrashCanOutline} from "@mdi/js";
import Icon from "@mdi/react";






const StartDateHeader = (props) => {

	var temp='';
	if(props.start){
		if(props.start.select_start_before){
		temp += '<span class="mr-1"><span class="font-13 mr-0">Start before:</span> <span class="text-muted font-13 ml-1">' + dateFormat(props.start.start_before, "dddd, mmmm dS, yyyy, h:MM:ss TT") + ' </span></span>';
	}else if(props.start.select_start_after){
		temp += '<span class="mr-1"><span class="font-13 mr-0">Start after:</span> <span class="text-muted font-13 ml-1">' + dateFormat(props.start.start_after, "dddd, mmmm dS, yyyy, h:MM:ss TT") + ' </span></span>';
	}
	temp += '<span class="mr-1"><span class="font-13 mr-0">Exact start date:</span> <span class="text-muted font-13 ml-1"> Set by buyer </span></span>';
	temp = Parser(`<span class="font-16">${props.condition_name.split('_').join(' ')}</span><div class="d-flex">${temp}</div>`)

	return( temp );
	}
}




const StartDate = (props) => {
	
	//const [selectStartDateBefore, setSelectStartDateBefore] = useState(undefined);
	const [selectStartDateAfter, setSelectStartDateAfter] = useState(undefined);
	//const [startDateBefore, setStartDateBefore] = useState(new Date());
	const [startDateAfter, setStartDateAfter] = useState(new Date());
	const [startDateSetAtSell, setStartDateSetAtSell] = useState(false);
	
	//const startDateBeforeChange = value => { setStartDateBefore(value); };
	const startDateAfterChange = value => { setStartDateAfter(value); };
	
	
	
	
	const onChangeOptionStartDateSetAtSell = nextChecked => {
		setStartDateSetAtSell(nextChecked); 
	};
	
	const onChangeSelectStartDate = (event) => {
		if (event.target.dataset.value == 'start_date_before') {

			let tokengroup = parseInt(event.target.id.split('_')[3]);
			//setSelectStartDateBefore(true);
			setSelectStartDateAfter(false);

		}else if(event.target.dataset.value == 'start_date_after') {
			let tokengroup = parseInt(event.target.id.split('_')[3]);
			setSelectStartDateAfter(true);
			//setSelectStartDateBefore(false);
	
		}else if(event.target.dataset.value == 'set_at_purchase'){
			setSelectStartDateAfter(false);
			//setSelectStartDateBefore(false);
		}
	}

	const onUpdateClick = (event) => {
		props.updateCondition('start_date', {
				//start_before: startDateBefore, 
				start_after: startDateAfter, 
				start_setat_sell: startDateSetAtSell, 
				//select_start_before: selectStartDateBefore, 
				select_start_after: selectStartDateAfter
			});
			//props.setShowTokenGrpSave(true);
	}

	return (
		<div>
			<div className="d-flex">
				<div style={{width: '95%'}}>
				</div>
				<div data-tokengroup={props.token_group} data-cindex={props.cindex} data-name="start_date" onClick={props.handleRemoveCondition} style={{width: '5%'}}>
					<div data-tokengroup={props.token_group} data-cindex={props.cindex} data-name="start_date" onClick={props.handleRemoveCondition}>
						<a href="#" data-tokengroup={props.token_group} data-name="start_date" data-cindex={props.cindex} onClick={props.handleRemoveCondition}> 
							<span data-tokengroup={props.token_group} data-name="start_date" data-cindex={props.cindex} onClick={props.handleRemoveCondition}>
								<Icon data-tokengroup={props.token_group} data-name="start_date" data-cindex={props.cindex} onClick={props.handleRemoveCondition} path={mdiTrashCanOutline}  title="Remove" size={1} />
							</span> 
						</a> 
					</div>
				</div>
			</div>
			<div className="d-flex">
				<div className="list-group-horizontal d-flex rounded-5"  style={{overflow: 'hidden', width: '90%'}}>
					{/*
						<a className="list-group-item list-group-item-action"  onClick={onChangeSelectStartDate} data-value="start_date_before" role="tab">	    			
							<div onClick={onChangeSelectStartDate} data-value="start_date_before" >
								<Label for="start_date_before"  onClick={onChangeSelectStartDate} data-value="start_date_before" className="mb-0"  text="Start date before" >	</Label>
								{ selectStartDateBefore ? <DTimePicker 	id="start_date_before"	onChange={startDateBeforeChange} value={startDateBefore}> </DTimePicker> : null }
							</div>
						</a>
					*/}
					<a className="list-group-item  list-group-item-action"  onClick={onChangeSelectStartDate} data-value="start_date_after" role="tab">
						<div onClick={onChangeSelectStartDate} data-value="start_date_after">
							<Label for="start_date_after"  className="mb-0" onClick={onChangeSelectStartDate} data-value="start_date_after" text="Start date after" >	</Label>
							{selectStartDateAfter ? <DTimePicker id="start_date_after" onChange={startDateAfterChange} value={startDateAfter}> </DTimePicker> : null	}
						</div>
					</a>
					<a className="list-group-item list-group-item-action" onClick={onChangeSelectStartDate} data-value="set_at_purchase" role="tab">
						<Label for="start_set_atpurchase"  onClick={onChangeSelectStartDate} data-value="set_at_purchase" className="mb-0"  text="Set at token purchase"></Label>
						<div>
							{!selectStartDateAfter ? <ToggleSwitch id="start_set_atpurchase" disabled={false} onChange={onChangeOptionStartDateSetAtSell} checked={startDateSetAtSell} label="" />: null}
						</div>
					</a>
				</div>
				<div style={{width: '10%'}}>
					<div>&#8205;</div>
					<button type="button" className="align-right btn btn-sm btn-light" onClick={onUpdateClick} 
						data-tokengroup={props.number} data-condition={props.condition_name} data-condition_index={props.condition_index} style={{float: 'right'}}>Update</button>
				</div>
			</div>
		</div>
	);
}




export { StartDateHeader, StartDate }