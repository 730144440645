
import React, { useState	} from 'react'
import Paragraph from '../util/Paragraph';




export function ForConsumers(props){
	

	return (
		
		<section className="py-5 consumer_bg" id="consumers">
			<div className='container'>
				<div className="row" style={{marginTop:'-1em', marginBottom:'1em'}}>
	           		<div className="col-lg-6">
	           			<h1 className="justify-content-left"><span  style={{color:'#000080', fontFamily: 'Arial, Helvetica, sans-serif'}}>Creators, auction your time and service credits on our Web3 marketplace</span></h1>
	           		</div>
	          		<div className="col-lg-6"></div>
	          	</div>
	            <div className="container">
	            	<div className="row">
	           			<div className="col-lg-11">
	           			{/* <h2><span className="font-weight-normal font-24" style={{color: '#000080'}}>Service providers mint blockchain tokens that grant prevledges to use their service</span></h2>
							   */
						   }
	      					
						  	<div className="accordion accordion-flush">
							  <div className="accordion-item" style={{backgroundColor:'#eef2f6'}}>	
							    <h4 className="accordion-header" id="accordion_consumer_header_1_1">
							      <a
							        className="accordion-button collapsed font-16"
							        href="#"
							        data-mdb-toggle="collapse"
							        data-mdb-target="#accordion_consumer_collapse_1_1"
							        aria-expanded="false"
							        aria-controls="accordion_consumer_collapse_1_1"
							        style={{backgroundColor:'#eef2f6', color:'#000080'}}
							      >
							        Your time is an asset. Tokenize it.
							      </a>
							    </h4>
							    <div
							      id="accordion_consumer_collapse_1_1"
							      className="accordion-collapse collapse"
							      aria-labelledby="accordion_consumer_header_1_1"
							      data-mdb-parent="#accordion_consumer_header_1"
							    >
							      <div className="accordion-body font-16" style={{backgroundColor:'#eef2f6', color:'#000080'}}>
							      <div className="row">
								      <div className="col-lg-12">
								      	<ul class="list-group">
								      		<li className="list-group-item" style={{backgroundColor:'#eef2f6', color:'#000080'}}>Apps, prospects, and followers want to bid for your time. </li>
									      	
									        <li className="list-group-item" style={{backgroundColor:'#eef2f6', color:'#000080'}}>Each time token holds a duration of time credits for a meeting, event, or a service time.</li>
								
											<li className="list-group-item" style={{backgroundColor:'#eef2f6', color:'#000080'}}>Don't spend hours deciphering which DMs are legitimate inquiries. Grant meeting access only to those that value your time.</li> 
									     	<li className="list-group-item" style={{backgroundColor:'#eef2f6', color:'#000080'}}>Grow revenue by sharing your tokenized calendar with your community. </li>
									     </ul>
								      </div>
							      </div>
							      <div className="mt-2 container">
								      
								      	<div className="card-group form-group text-center justify-content-center mt-1 mb-0" style={{display:'flex', alignItems: 'center'}}>
	   										<div style={{width:"20%"}}>
												<img  src="/images/calendar/list.png" height="40" />
											</div>
											<div style={{width:"20%"}}>
												<img  src="/images/calendar/location.png" height="40" />
											</div>
											<div style={{width:"20%"}}>
												<img src="/images/calendar/record.png" height="40" />
											</div>
											<div style={{width:"20%"}}>
												<img  src="/images/calendar/money.png" height="40" />
											</div>
										</div>
								      
									   <img className="card-group form-group justify-content-center mt-0 mb-0" src="/images/calendar.png" width={ props.isMobileWidth ? ((props.windowWidth - props.windowWidth / 6) + "") :  Math.min((props.windowWidth - props.windowWidth / 5), 1000) + ''} /> 
									
								  </div>
							    </div>
							  </div>
							  <div className="accordion-item" style={{backgroundColor:'#eef2f6'}}>
							    <h4 className="accordion-header" id="accordion_consumer_header_1_2">
							      <a
							        className="accordion-button collapsed font-16"
									href="#" 
							        data-mdb-toggle="collapse"
							        data-mdb-target="#accordion_consumer_collapse_1_2"
							        aria-expanded="false"
							        aria-controls="accordion_consumer_collapse_1_2"
							        style={{backgroundColor:'#eef2f6', color:'#000080'}}
							      >
							        Tokenize your service credits.
							      </a>
							    </h4>
							    <div
							      id="accordion_consumer_collapse_1_2"
							      className="accordion-collapse collapse"
							      aria-labelledby="accordion_consumer_header_1_2"
							      data-mdb-parent="#accordion_consumer_header_1"
							    >
							    	
						    			<div className="accordion-body font-16" style={{backgroundColor:'#eef2f6', color:'#000080'}}>
						    				<div className="row">
						    					<div className="form-group col-lg-8">
						    						<ul class="list-group">
											      		<li className="list-group-item" style={{backgroundColor:'#eef2f6', color:'#000080'}}>Users purchase tokens that hold specified amounts of monetary or items credits </li>
											        	<li className="list-group-item" style={{backgroundColor:'#eef2f6', color:'#000080'}}>Token holders redeem credits when they use your service</li>
														<li className="list-group-item" style={{backgroundColor:'#eef2f6', color:'#000080'}}>Holders can transfer or sell unused credits in the secondary market </li>
														<li className="list-group-item" style={{backgroundColor:'#eef2f6', color:'#000080'}}>Issue tokens with a discount for promotional campaigns </li>
													</ul>
											     </div>
											     <div className="col-lg-4" style={{display:'flex', alignItems: 'center'}}>
									    			<img src="/images/service_credit.png" height="300" />
									    		 </div>
											 </div>
											 
						    			 </div>
							    		 
							      	
							    </div>
							  </div>
							  
							  <div className="accordion-item" style={{backgroundColor:'#eef2f6'}}>
							    <h4 className="accordion-header" id="accordion_consumer_header_1_3">
							      <a
							        className="accordion-button collapsed font-16"
									href="#" 
							        data-mdb-toggle="collapse"
							        data-mdb-target="#accordion_consumer_collapse_1_3"
							        aria-expanded="false"
							        aria-controls="accordion_consumer_collapse_1_3"
							        style={{backgroundColor:'#eef2f6', color:'#000080'}}
							      >
							        Create tokenized waitlists.
							      </a>
							    </h4>
							    <div
							      id="accordion_consumer_collapse_1_3"
							      className="accordion-collapse collapse"
							      aria-labelledby="accordion_consumer_header_1_3"
							      data-mdb-parent="#accordion_consumer_header_1"
							    >
							      <div className="accordion-body font-16" style={{backgroundColor:'#eef2f6', color:'#000080'}}>
							     	<div className="row">
							     		<div className='col-lg-7'>
							     			<ul class="list-group">
											  <li className="list-group-item" style={{backgroundColor:'#eef2f6', color:'#000080'}}>Create waitlists or queues to your resource</li>
											  <li className="list-group-item" style={{backgroundColor:'#eef2f6', color:'#000080'}}>Manage queue length and skipping </li>
											  <li className="list-group-item" style={{backgroundColor:'#eef2f6', color:'#000080'}}>A member joins a waitlist by holding your  newly minted priority token.</li>
											</ul>
							        		
							        	</div>
							        	<div className="col-lg-5">
							        		 <img src="/images/queue.png" height="150" />
							        	</div>
							        </div>
							      </div>
							    </div>
							  </div>
	
							</div>
			    		</div>
			    		</div>
			    		{
							/* <div className="col-lg-1"> <img src="/images/mobile_xact.png" height="550" /> </div> */
						}
			    		
	         		</div>
	         	
	        	</div>
        	</div>
        </section>
	);
	
}