
import React, {useState, useEffect, useMemo, useCallback, createContext, useRef	} from 'react'
import axios from 'axios';




const TimestampCell = ({ value }) => (
	<span className='Cell-Timestamp'>
		<span>{new Date(value).toLocaleString()}</span>
	</span>
);
const AddressCell  = ({ value }) => (
	<span className='badge bg-light text-dark'>
		{ value.substring(0, 4) + '...' + value.substring(value.length - 3, value.length) }
	</span>
);

const QuantityCell  = ({ value }) => (
	<span>
		{ value }
	</span>
);

const BidCell  = ({ value }) => (
	
	<span className="text-center">
		<span className="img-fluid">
			{value.currency == 'USDC' ? 
				<img src="/images/usdc_blue.png" width="19px" height="auto" alt="Responsive image"/> : 
				value.currency == 'ETH' ? 
					<img src="/images/eth_blue.png" alt="Responsive image"/> : null
			}
			
		</span>&nbsp; 
		{ value.value }
	</span>
);

const ExpirationCell  = ({ value }) => (
	<span>
		{value}
	</span>
);






const columns = [
	  { Header: 'BidTime', Cell: TimestampCell, accessor: 'bidTime' },
	  { Header: 'Bidder', Cell: AddressCell, accessor: 'address' },
	  { Header: 'Quantity',   Cell: QuantityCell,   accessor: 'quantity' },
	  { Header: 'Bid',  Cell: BidCell,  accessor: 'bid' },
	  { Header: 'Expiration',    Cell: ExpirationCell,    accessor: 'bidExpiration' },
	];

function StreamOrdersByToken(tokenKey){

	const [streamData, setStreamData] = useState([]);
	const [time, setTime] = useState(Date.now());
	
	
	function generateRow(d) {

		return {
			address: d.wallet_address,
			quantity: d.quantity,
			bid: d.bid,
			bidTime: d.receive_tstamp,
			bidExpiration: d.expires_in.days
		}
	}
	
	const loadData =() => {
		const data =  axios.get("/api/v1/orders/" + tokenKey).then(res => {
			let streams = res.data;
			return streams.bids;
		});
		data.then(recs => {setStreamData(recs)});
	}
		
	if(tokenKey){loadData();}
	
	useEffect(() => {
	  const interval = setInterval(() => setTime(Date.now()), 5000);
	  const data =  axios.get("/api/v1/orders/" + tokenKey).then(res => {
			let streams = res.data;
			return streams.bids;
		});
		data.then(recs => {setStreamData(recs)});
	  return () => {
	    clearInterval(interval);
	  };
	}, [time]);
	

	return useMemo(() => ({ columns, data: streamData.map((d) => generateRow(d)) }), [streamData]);
};


export default StreamOrdersByToken;