
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Icon from "@mdi/react";
import { mdiEye } from "@mdi/js";
import { RegisterVerify, RegisterForm } from './RegistrationForm';
import { passwordStrength } from 'check-password-strength';
import Footer from "../util/Footer";


const Registration = () => {
	
	
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	
	const [lastname, setLastname] = useState("");
	const [firstname, setFirstname] = useState("");
	const [organization, setOrganization] = useState("");
	const [email, setEmail] = useState("");
	const [varifiedEmail, setVarifiedEmail] = useState("");
	
	//const [emailChangeTime, setEmailchangeTime] = useState(new Date())
	
	const [emailValid, setEmailValid] = useState(false);
	
	const [username, setUsername] = useState("");
	const [varifiedUsername, setVarifiedUsername] = useState("")
	//const [usernameChangeTime, setUsernameChangeTime] = useState(new Date());
	const [usernameValid, setUsernameValid]  = useState(false);
	const [acceptCheck, setAcceptCheck] = useState(false);
	
	const [emailValidatorClass, setEmailValidatorClass] = useState("");
	const [emailValidatorText, setEmailValidatorText] = useState("");
	const [showEmailValidator, setShowEmailValidator] = useState("");

	const [passwordValidatorClass, setPasswordValidatorClass] = useState("");
	const [passwordValidatorText, setPasswordValidatorText] = useState("");
	const [showPasswordValidator, setShowPasswordValidator] = useState("");
	
	const [usernameValidatorClass, setUsernameValidatorClass] = useState("");
	const [usernameValidatorText, setUsernameValidatorText] = useState("");
	const [showUsernameValidator, setShowUsernameValidator] = useState("");
	
	const [confirmPasswdValidatorClass, setConfirmPasswdValidatorClass] = useState("");
	const [confirmPasswdValidatorText, setConfirmPasswdValidatorText] = useState("");
	const [showConfirmPasswdValidator, setShowConfirmPasswdValidator] = useState("");
	const [submittable, setSubmittable] = useState(false);
	
	const [successfulSubmit, setSuccessfulSubmit] = useState(false);
			
	
	async function handleAutoPasswdGen(event){
		event.preventDefault();

		await axios.get('https://meritic.xyz/user_password_auto_generator')
		.then(({ data }) => {
			setPassword(data.pwd);
		})
	}
	
	
	async function handlePasswdChange(event){
		
		if (event.target.value.length > 0){
			var currPassword = event.target.value;
			setPassword(currPassword);
			var pvalue = passwordStrength(currPassword).value;
		
			if(pvalue == 'Strong' || pvalue == 'Medium'){
				setPasswordValidatorClass('valid-feedback');
			}else{
				setPasswordValidatorClass("invalid-feedback");
			}
			setPasswordValidatorText(pvalue);
			setShowPasswordValidator("visible");
				
				
			// await axios.get('https://meritic.xyz/register_pwd_strength', {params: {pwdstr: currPassword}})
			// .then(({ data }) => {// });
			
		}
	}
	
	
	function handleConfirmPasswdChange(event){
		setConfirmPassword(event.target.value);
	}
	
	
	
	
	function handleLastnameChange(event){
		setLastname(event.target.value);
	}
	
	function handleFirstnameChange(event){
		setFirstname(event.target.value);
	}

	async function handleEmailChange(event){

		if (event.target.value.trim().length > 0){
			var currEmail = event.target.value.trim();
			setEmail(currEmail);
			//setEmailchangeTime(new Date());
		}
		
	}
	

	
	function handleConfirmPasswdKeyUp(event){
		
	}
	function handleOrgChange(event){
		setOrganization(event.target.value);
	}
	
	async function handleUsernameChange(event){
		
		if(event.target.value.trim().length >= 0){
			var currUsername = event.target.value.trim();
			setUsername(currUsername);
			//etUsernameChangeTime(new Date());
			
			
		};
	}
	
	

	
	function handleCheckBoxChange(event){
		setAcceptCheck(event.target.checked);
		
	}
	
	
	useEffect(() => {
	    const delayDebounceFn = setTimeout(() => {
			var passwordMatched = false;
			
			
			
			if (password.length > 0 &&  confirmPassword.length > 0 && (password == confirmPassword)) {
				setConfirmPasswdValidatorText("Matching")
				setConfirmPasswdValidatorClass("valid-feedback")
				setShowConfirmPasswdValidator('visible')
				
				passwordMatched = true;
		    }else{ 
				setConfirmPasswdValidatorText("Not Matching")
				setConfirmPasswdValidatorClass("invalid-feedback")
				setShowConfirmPasswdValidator('visible')
				setSubmittable(false)
				passwordMatched = false;
			}
			
			
			if(varifiedEmail != email){
				
				axios.get('https://meritic.xyz/register_check_email',   {params: {email: email}})
				.then(({ data }) => {
					setVarifiedEmail(email)
					setEmailValid(data.valid)
				});
			}
			
			if(varifiedUsername != username){
				axios.get('https://meritic.xyz/register_check_username', {params: {username: username}})
				.then(({ data }) => {
					setUsernameValid(data.valid);
					setVarifiedUsername(username)
				})
			}
			
			if(emailValid){
				setEmailValidatorClass('valid-feedback');
				setEmailValidatorText('Email is available');
				setShowEmailValidator("hidden");
			}else{
				setEmailValidatorClass("invalid-feedback");
				setEmailValidatorText('Email is not available');
				setShowEmailValidator("visible");
			}
			
			
			if(usernameValid){
				setUsernameValidatorClass('valid-feedback');
				setUsernameValidatorText('Username ' + username + ' is available');
				setShowUsernameValidator("hidden");
			}else{
				setUsernameValidatorClass("invalid-feedback");
				setUsernameValidatorText('Username ' + username + ' is not available');
				setShowUsernameValidator("visible");
			}
			
			// && passwordMatched && emailValid  && acceptCheck
			//console.log('usernameValid: ' + usernameValid + ' emailValid: ' + emailValid + ' acceptCheck: ' + acceptCheck);
			if((lastname.length > 0) && (firstname.length > 0) && usernameValid && passwordMatched && emailValid && acceptCheck){
				setSubmittable(true);
			}else{
				setSubmittable(false);
			}
			
		
	    }, 100)
	    
	    return () => clearTimeout(delayDebounceFn)
	    
	}, [password, confirmPassword, email, usernameValid, username, acceptCheck])
	   
	
	async function handleSubmit(event){
		event.preventDefault();

		var formData = {firstname: firstname, lastname: lastname, email: email, company: organization, username: username, password: password};
				
		axios.post("https://meritic.xyz/register_data", formData)
		.then(response =>  {
			setSuccessfulSubmit(response.data.valid);
			
		})
		
	}
	
	
	
	return (
		<div>
			<section className="account-pages mt-5 mb-5">
	        	<div className="container">
	        		{
						successfulSubmit ?	
	        				<RegisterVerify 
	        					email={email}
	        				/> :
	        			
	        				<RegisterForm
	        					handleFirstnameChange={handleFirstnameChange}
	        					handleLastnameChange={handleLastnameChange}
	        					handleEmailChange={handleEmailChange}
	        					emailValidatorClass={emailValidatorClass}
	        					showEmailValidator={showEmailValidator}
	        					emailValidatorText={emailValidatorText}
	        					handleOrgChange={handleOrgChange}
	        					handleUsernameChange={handleUsernameChange}
	        					usernameValidatorClass={usernameValidatorClass}
	        					showUsernameValidator={showUsernameValidator}
	        					usernameValidatorText={usernameValidatorText}
	        					handleAutoPasswdGen={handleAutoPasswdGen}
	        					handlePasswdChange={handlePasswdChange}
	        					mdiEye={mdiEye}
	        					passwordValidatorClass={passwordValidatorClass}
	        					showPasswordValidator={showPasswordValidator}
	        					passwordValidatorText={passwordValidatorText}
	        					handleConfirmPasswdKeyUp={handleConfirmPasswdKeyUp}
	        					handleConfirmPasswdChange={handleConfirmPasswdChange}
	        					confirmPasswdValidatorClass={confirmPasswdValidatorClass}
	        					showConfirmPasswdValidator={showConfirmPasswdValidator}
	        					confirmPasswdValidatorText={confirmPasswdValidatorText}
	        					handleCheckBoxChange={handleCheckBoxChange}
	        					handleSubmit={handleSubmit}
	        					submittable={submittable}
	        				>
	        				</RegisterForm>
	        		}
	
		        </div>
		        
		    </section>
		    <Footer />
	    </div>
	);
}



export default Registration;