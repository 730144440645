

import React, { useState	} from 'react'
import Paragraph from '../util/Paragraph';



function ContactForm(props){
	
	
	return (
        <div className="row align-items-center mt-3">
        	<div className="col-md-2"></div>
            <div className="col-md-8">
                <form id="contact_form" action="#/">
                    <div className="row mt-1">
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="fullname">Your Name</label>
                                <input className="form-control form-control-light" onChange={props.updateContactFullname} type="text" id="fullname" name="fullname" placeholder="Name..." />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="emailaddress">Your Email</label>
                                <input className="form-control form-control-light" onChange={props.updateContactEMAIL} type="email" required="" id="emailaddress" name="emailaddress" placeholder="Enter you email..." />
                            </div>
                        </div>
                    </div>
					{
						props.providerContact ?
						<div id="company_info" className="row d-flex mt-1">
	                        <div className="col-lg-6">
	                            <div className="form-group">
	                                <label htmlFor="companyname">Your Company</label>
	                                <input className="form-control form-control-light" onChange={props.updateContactCompany} type="text" id="companyname" name="companyname" placeholder="Company name..." />
	                            </div>
	                        </div>
	                        <div className="col-lg-6">
	                            <div className="form-group">
	                                <label htmlFor="companyurl">Company URL</label>
	                                <input className="form-control form-control-light" onChange={props.updateContactURL} type="url" id="companyurl" name="companyurl" placeholder="Company URL..." />
	                            </div>
	                        </div>
	                    </div> : null
					}
                    <div className="row mt-1" id="message_subject" >
                        <div className="col-lg-12">
                            <div className="form-group">
                                <label htmlFor="subject">Subject</label>
                                <input className="form-control form-control-light" onChange={props.updateContactSubject} type="text" id="subject" name="subject" placeholder="Enter subject..." />
                            </div>
                        </div>
                    </div>

                    <div className="row mt-1">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <label htmlFor="comments">Message</label>
                                <textarea className="form-control form-control-light" onChange={props.updateContactMessage} id="comments" rows="4" name="comments" placeholder="Type your message here..."></textarea>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-12 text-right">
                        	<button type="submit" className="btn btn-light btn-rounded g-recaptcha"  onClick={props.handleContactSubmit} data-sitekey="6LeCWGciAAAAAAYPgVzGuM5gLslRcdx_i4qF_0tj" style={{maxHeight:"38px", maxWidth:"100px"}} data-callback='contact_form_handler' data-action='submit'>Submit</button>
                            
                        </div>
                    </div>
                </form>
            </div>
            
            <div className="col-md-2">
                
            </div>
        </div>
            
	);
}



export function ContactUs(props){


	return (
		
		<section className="section-component py-5 border-top border-bottom border-light" id="contact" style={{backgroundColor:'#CCCCE5'}}>
			<div className="container">
				<div id="contact_msg_desc" className="row">
	                <div className="col-lg-12">
	                    <div className="text-center">
	                        <h3 id="wait_contact_title" style={{color: '#000080'}}>{props.contactTitle}</h3>
	                        <p className="lead mt-2" id="wait_contact_title_desc">
	                        	{props.contactTitleDesc1} <br/>{props.contactTitleDesc2}
	                        </p>
	                    </div>
	                </div>
	            </div>
	            
            	 {props.contactFormSubmitted ?
            		<div className="container" style={{display:'inline-block'}}>
		            	<div className="row mt-1">
		            		<div className="col-md-2"></div>
		                    <div id="receipt_greeting" className="col-lg-8">
		                    	{props.contactRecievedObj ? 
		                    		<p className="mb-1"> Hi { props.contactRecievedObj.name } <br /> { props.contactRecievedObj.server_response } </p> : null
		                    	} 
		                    </div>
		                    <div className="col-md-2"></div>
		        		</div>
		
		              	<div className="row mt-2">
		              		<div className="col-md-2"></div>
		                    <div className="col-8 text-right">
		                        <button onClick={props.handleBackToContactForm}  className="btn btn-light btn-rounded"  style={{maxHeight:"38px", maxWidth:"150px"}}>Back to form</button>
		                    </div>
		                    <div className="col-md-2"></div>
		                </div>
		        	</div> : 
		        	
            		<ContactForm 
            			updateContactFullname={props.updateContactFullname}
            			updateContactEMAIL={props.updateContactEMAIL}
            			providerContact={props.providerContact}
            			updateContactCompany={props.updateContactCompany}
            			updateContactURL={props.updateContactURL}
            			updateContactSubject={props.updateContactSubject}
            			updateContactMessage={props.updateContactMessage}
            			handleContactSubmit={props.handleContactSubmit}
            		/>            
            	}
            </div>
        </section>
	);
	
}
