
import React, {useState, useEffect, useRef	} from 'react'
import AccordionItem from '../util/AccordionItem';
import {Label, Input} from '../util/LineInput';
//import RadioOption, { RadioOptionGroup } from '../util/RadioOption';
import axios from 'axios';
//import Icon from "@mdi/react";
//import { mdiShapePolygonPlus } from "@mdi/js";
import Select from 'react-select';
import FileUpload from '../util/FileUpload';
//import { ProviderAvatar } from './ProviderAvatar'
//import { mdiPlus } from "@mdi/js";
//import countryCodes from 'country-codes-list';
import { flushSync } from 'react-dom';
import ContinueGate from '../util/ContinueGate';
import Skeleton from 'react-loading-skeleton';
import SkeletonBox from '../util/SkeletonBox';
import 'react-loading-skeleton/dist/skeleton.css';

import Modal from 'react-modal';
import './style.css';
//mport PasswordStrengthBar from 'react-password-strength-bar';
//import {CopyToClipboard} from 'react-copy-to-clipboard';
import { useFormik } from 'formik'


const customStyles = {
	  content: {
	    top: '50%',
	    left: '50%',
	    right: 'auto',
	    bottom: 'auto',
	    marginRight: '-50%',
	    borderRadius:'25px',
	    transform: 'translate(-50%, -50%)',
	  },
	};
	



const ProviderWalletModal = (props) => {
	

	const [displayMessage, setDisplayMessage] = useState('Select your preferred wallet address');
	const [listAddresses, setListAddresses] = useState(false);
	const [selectedWallet, setSelectedWallet] = useState();
	

	
    var walletAddresses = useRef(null);

	useEffect(()=> {
		if(props.wallet && props.wallet.address && props.wallet.address.length >= 1){

			var temp  = [];
			
			if(Array.isArray(props.wallet.address)){
				props.wallet.address.forEach(function(x, i){
					temp.push(
						<div className="row mb-1">
							<div className="form-group-sm col-sm">
								<a key={i} type="button" className="btn-sm btn-light btn-rounded mr-1" data-value={x} 
									onClick={(event) => {navigator.clipboard.writeText(x); setSelectedWallet(x); 
											props.onChangeProviderWallet(event); }}>
				                	{ x.substring(1, 5) + ' ... ' +  x.substring(x.length - 4, x.length)}
				                </a> 
				            </div>
			            </div>
			         );
				})
			}else{
				var x = props.wallet.address;
				temp.push(
					<div className="row">
						<div className="form-group-sm col-sm">
						<a key={0} type="button" className="btn-sm btn-light btn-rounded" data-value={x} data-clipboard-text={""}
								onClick={(event) => {navigator.clipboard.writeText(x); setSelectedWallet(x); props.onChangeProviderWallet(event)}}>
			                	{ x.substring(1, 5) + ' ... ' +  x.substring(x.length - 4, x.length)}
			                </a>
			            </div>
		            </div>
		         );
			}
			
			walletAddresses.current = temp;
			setListAddresses(true)

		}else{

			setDisplayMessage(
								<>
								<h5>Connect to your wallet</h5>
								{props.walletConnect.wallet.address == '' ? props.walletConnect.wallet.status : null}
								</>);
		}
		
	}, [])


	
	return (
		<Modal isOpen={props.walletModalIsOpen} style={customStyles}>
    		<div className="float-right">
    			<button type="button" className="btn-close" onClick={(event) => {props.setProviderWalletModalIsOpenToFalse(event); }} aria-label="Close"></button>
    		</div>
    		<div className="form-group">
    			{displayMessage}
    		</div>
    		{listAddresses ?
	    		<div className="container">
	    			{walletAddresses.current}
	    		</div> : null
    		}
    		
    		<div className="form-group mt-2 mb-0 d-flex">
    			{selectedWallet  ?
    				<span className="mr-3"><Label class="mb-2"  text="Provider address:"></Label> 
    				<span className="ml-1">
    					<a type="button" className="btn-sm btn-light btn-rounded" onClick={() => {navigator.clipboard.writeText(selectedWallet);}}>
			                	{ selectedWallet.substring(1, 5) + ' ... ' + selectedWallet.substring(selectedWallet.length - 4, selectedWallet.length)}
			                </a>
    				</span>
    				</span> : null
    			}
    			
    		</div>
    		
    		
    		{selectedWallet  ? 
    			<div className="form-group mt-2 mb-0">
				 	<hr style={{height:'1px', width:'100%', backgroundColor:'#000000'}}/>
	     			<a type="button" className="align-right btn-sm btn-light" onClick={(event) => {props.setProviderWalletModalIsOpenToFalse(event); 
	     								var temp = props.showContinue;
	     								temp.verification = false;
	     								temp.description = true;
	     								props.setShowContinue(temp)}}   style={{float: 'right'}}>Accept Selections</a>
				</div>: null
    		}
    	</Modal>
	)
	
}




const communityOptions = {
	
	twitter: { 
		has_twitter: false,
    	value: null,
    	onChangeValue: null,
    	label: 'Twitter handle',
    	place_holder: 'Twitter handle',
    	fa_twitter_icon: 'fa-brands fa-twitter fa-xl'
    },
  	discord: {
		  has_discord: false,
		  value: null,
		  onChangeValue: null,
		  label: 'Discord server',
		  place_holder: 'Discord handle',
		  fa_discord_icon: 'fa-brands fa-discord fa-xl'
	},
	
  	medium: {
		  has_medium: false,
		  value: null,
		  label: 'Medium handle',
		  place_holder: 'Medium handle',
		  fa_medium_icon: 'fa-brands fa-medium fa-xl'
		  
	},
  	telegram: {
		  has_telegram: false,
		  value: null,
		  label: 'Telegram handle',
		  place_holder: 'Telegram handle',
		  fa_telegram_icon: 'fa-brands fa-telegram fa-xl'
	},
  	linkedin: {
		  has_linkedin: false,
		  value: null,
		  label: 'Linkedin handle',
		  place_holder: 'LinkedIn account',
		  fa_linkedin_icon: 'fa-brands fa-linkedin fa-xl'
	},
  	youtube: {
		  has_youtube: false,
		  value: null,
		  label: 'Youtuvbe handle',
		  place_holder: 'Youtube account',
		  fa_youtube_icon: 'fa-brands fa-youtube fa-xl'
	},
  	instagram: {
		  has_instagram: false,
		  value: null,
		  label: 'Instagram handle',
		  place_holder: 'Instagram handle',
		  fa_instagram_icon: 'fa-brands fa-instagram fa-xl'
	}
};





const validate = (values) => {
  const errors = {}
	errors.providerContactEmail= '';
  if (!values.providerContactEmail) {
    errors.providerContactEmail = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.providerContactEmail)) {
    errors.providerContactEmail = 'Invalid email'
  }else{
	  errors.providerContactEmail = '';
  }

  return errors
}






export function Provider(props){
	
	const [companyName, setCompanyName] = useState("");
	const [companyWebsite, setCompanyWebsite] = useState("");
	
	const [providerDesc, setProviderDesc] = useState("")
	const [logoFromURI, setLogoFromURI] = useState(null)
	
	const [providerWallet, setProviderWallet] = useState(props.wallet);
	const [providerSelectedWallet, setProviderSelectedWallet] = useState(false);
	const [ltLogoFile, setLtLogoFile] = useState("");
	const [avatarLogoFile, setAvatarLogoFile] = useState(null);
	const [providerDataUpdated, setProviderDataUpdated] = useState(false);
	const accordionRef = useRef(null);
	//const communityAddLaterRef = useRef(null);
	const [addTwitter, setAddTwitter] = useState()
	const [addDiscord, setAddDiscord] = useState()
	const [addMedium, setAddMedium] = useState()
	const [addYoutube, setAddYoutube] = useState()
	const [addTelegram, setAddTelegram] = useState()
	const [addInstagram, setAddInstagram] = useState();
	const [addLinkedIn, setAddLinkedIn] = useState();
	const [discordValue, setDiscordValue] = useState();
	const [twitterValue, setTwitterValue] = useState();
	const [instagramValue, setInstagramValue] = useState();
	const [youtubeValue, setYoutubeValue] = useState();
	const [linkedinValue, setLinkedInValue] = useState();
	const [mediumValue, setMediumValue] = useState();

	const [creatorProviders, setCreatorProviders] = useState([]);
	const [countries, setCountries] = useState([])
	const [countryStates, setCountryStates] = useState([])
	const [providerCountry, setProviderCountry] = useState('');
	const [providerCountryState, setProviderCountryState] = useState('');
	const [community, setCommunity] = useState(communityOptions)
	const [addCommunityLater, setAddCommunityLater] = useState(true)
	
	const [providerContactEmail, setProviderContactEmail] = useState('')
	const [showGeography, setShowGeograph] = useState(false);
	const [nameExists, setNameExists] = useState(false);
	const [noThumbs, setNoThumbs] = useState(false);
	const [skeleton, setSkeleton] = useState(false);
	const [emailVerifySkeleton, setEmailVerifySkeleton] = useState(true);
	const [emailVerified, setEmailVerified] = useState(false);
	const [verifyEmailLater, setVerifyEmailLater] = useState(false);
	const [receivedEmailVerifiedResponse, setReceivedEmailVerifiedResponse] = useState(false);
	const [providerWalletModalIsOpen, setProviderWalletModalIsOpen] = useState(false);
	const [preferredVerification, setPreferredVerification] = useState('wallet');
	const [providerModalIsOpen, setProviderModalIsOpen] = useState(true);
	const [providerPassword, setProviderPassword] = useState();
	//const [providerVerified, setProviderVerified] = useState(false);
	const [proceed, setProceed] = useState({verification: false, description: false, proceedImage: false});
	const [showContinue, setShowContinue] = useState({verification: true, description: false});
	const [verificationCode, setVerificationCode] = useState('');
	
	const [emailValidError, setEmailValidError] = useState('');
	const [emailValid, setEmailValid] = useState();
	const [showVerification, setShowVerification] = useState();
	
	const [retypePassword, setRetypePassword] = useState();
	const [skipContinue, setSkipContinue] = useState(false);
	const [notSubmitted, setNotSubmitted] = useState(true);
	const [imgHeight, setImgHeight] = useState();
	const [imgWidth, setImgWidth] = useState();
	
	
	
	const formik = useFormik({
    	initialValues: {
      		providerContactEmail: '',
    	},
    	validate
  	})
	
	const onProviderContactEmailChange = (event) => {
		flushSync(()=> {
			setProviderContactEmail(event.target.value);
		});
		
	
		/*setEmailValidError('');
		setEmailValid();

		var temp = {...showContinue};
		temp.verification = true;
		setShowContinue(temp);*/
	}
	
	const updateCommunityOpt = (verData) => {
		for(let key of Object.keys(verData)){
			community[key].message = verData[key].message;
		}
		setCommunity(community);
	}
	
	
	function handleCompanyNameChange(event){
		setCompanyName(event.target.value);
	};
	
	function handleCompanyWebsiteChange(event){
		setCompanyWebsite(event.target.value);
	}
	
	const handleProviderDescriptionChange = (event) => {
		setProviderDesc(event.target.value);
	}
	
	const onAddCommunityLater = (event) => {
		setAddCommunityLater(event.target.value == 'community_add_later');
	}
	
	const onChangeProviderWallet = (selection) => {

		setProviderWallet({address: [selection.target.dataset.value], chainId: props.wallet.chainId, status: 'success'});
		setProviderSelectedWallet(true);
		
	}
	
	const onCountryChange = async(selection) => {

		flushSync(() => { setProviderCountry(selection);	});

		const countryStates = await loadStateData(selection.value);
		flushSync(() => { 
			setCountryStates(countryStates.map(x => ({label: x.name, value: x.state_code})));
		});
	}
	const onStateChange = async(selection) => {
		setProviderCountryState(selection);
	}
	

	const setProviderWalletModalIsOpenToFalse = (event) => {
	
		setProviderWalletModalIsOpen(false);
	}
	
	const onClickSetPrefferedProviderWallet = (event) => {
		setProviderWalletModalIsOpen(true);
	}
	
	

	
	const onAddTwitter =() =>{
		var temp = community;
		setAddTwitter(true);
		temp.twitter.has_twitter = true;
		setCommunity(temp);
	}
	
	const onAddDiscord =() =>{
		setAddDiscord(true);
		var temp = {...community};
		temp.discord.has_discord = true;
		setCommunity(temp);
	}
	const onAddMedium =() =>{
		setAddMedium(true);
		var temp = {...community};
		temp.medium.has_medium = true;
		setCommunity(temp);
	}
	const onAddInstagram =() =>{
		setAddInstagram(true);
		var temp = {...community};
		temp.instagram.has_instagram = true;
		setCommunity(temp);
	}
	
	const onAddYoutube =() =>{
		setAddYoutube(true);
		var temp = {...community};
		temp.instagram.has_youtube = true;
		setCommunity(temp);
	}
	const onAddTelegram =() =>{
		setAddTelegram(true);
		var temp = {...community};
		temp.instagram.has_telegram = true;
		setCommunity(temp);
	}
	const onAddLinkedIn =() =>{
		setAddLinkedIn(true);
		var temp = {...community};
		temp.instagram.has_linkedin = true;
		setCommunity(temp);
	}
	
	const onChangeDiscordValue = (event) => {
		setDiscordValue(event.target.value);
	}
	const onChangeTwitterValue = (event) => {
		setTwitterValue(event.target.value);
	}
	const onChangeInstagramValue = (event) => {
		setInstagramValue(event.target.value);
	}
	const onChangeYoutubeValue = (event) => {
		setYoutubeValue(event.target.value);
	}
	const onChangeMediumValue = (event) => {
		setMediumValue(event.target.value);
	}
	const onChangeLinkedInValue = (event) => {
		setLinkedInValue(event.target.value);
	}
	/*const handleUniqueSlotDigitsChange = (event) => {
		const val = event.target.value.trim();
		let isnum = /^\d+$/.test(val);
		
		if(isnum && val.length >= 5){
			setUniqSubSlotId(val);
			setSlotId('0' + '-' + MCCDCode + '-' +  val)
			//setNetworkOptions([{value: '0' + MCCDCode + val, label: '0' + MCCDCode + val}]);
		}
	}*/
	
	/*const handleSlotChange = (event) => {
		setSlotId(event.target.value)
		//setNetworkOptions([{value: event.target.value, label: event.target.value}]);
	}*/
	
	
	
	function handleLtLogoFileDrop(files) {
		if(files.length > 0){
			setLtLogoFile(files[0]);
		}
	};
	
	
	const onAvatarImageCreate = (file) => {

		setAvatarLogoFile(file);

	}
	
	
	
	/*
	function handleDkLogoFileDrop(files) {
		if(files.length > 0){
			setDkLogoFile(files[0]);
		}
	};*/
	
	
	
	
	
	const verifyEmail = (providerName, providerEmail) => {
	
		
		setEmailVerifySkeleton(true);
		axios.post("/verify_email", 
			{
				name: providerName,
				email: providerEmail,
				mode: 'new'
			}, {  "Content-Type": "application/json" }).then(response => {
				if(response.data.status == 400){
			
					flushSync(() => {
						setEmailValid(false);
						setShowVerification(false);
					});
					
					
				}else if(response.data.status == 200){
		
					setEmailValid(true);
					setShowVerification(true);
					setEmailVerifySkeleton(false);
				
				}
				
			});

	}
	
	

	
	const onChangeVerifyCode = (event)=> {
		setVerificationCode(event.target.value);

	}
	
	const verifyEmailCode = () => {
		setEmailVerifySkeleton(true);
		axios.post("/verify_email", 
			{
				name: companyName,
				email: providerContactEmail,
				code: verificationCode,
				mode:'verify'
			}, {  "Content-Type": "application/json" }).then(response => {
				if(response.data){ 
		
					//setEmailValidError('');
					setEmailVerified(response.data.verified);
					
					setReceivedEmailVerifiedResponse(true);
			
				}
				
				setEmailVerifySkeleton(false);
				
			});
		
	}
	
	

	async function getProvidersForAddress() {
	
		var selectProviders = [];
	
		if(props.wallet){
			const params = new URLSearchParams(props.wallet);
	
			const data = await axios.get('/api/v1/providers' , { params });
		
			if(data && data.data && data.data.providers && data.data.providers.length > 0){
				setSkipContinue(true)
				setEmailVerified(true);
				
			
				for(let i=0; i < data.data.providers.length; ++i){
		
					selectProviders.push({label: data.data.providers[i].provider, value: i + 1});
	
					props.handleProviderChange({
						logoFile: ltLogoFile,
						provider_uri: data.data.providers[i].provider_uri,
						provider: data.data.providers[i].provider,
						website: data.data.providers[i].provider_url,
						provider_desc: data.data.providers[i].description,
						provider_country: JSON.parse(data.data.providers[i].country),
						provider_state: JSON.parse(data.data.providers[i].state),
						isComplete: true
						//mcc_desc: data.data.providers[i].slot.description,
						//mcc_code: data.data.providers[i].slot.mcc,
						//slot_id: '0' + data.data.providers[i].slot.provider_uid + data.data.providers[i].slot.mcc
					});
				
					for(let j=0; j < props.wallet.address.length; ++j){
						if(props.wallet.address[j] == data.data.providers[i].wallet.address){
							var temp_address = props.wallet.address[0];
							props.wallet.address[0] = data.data.providers[i].wallet.address;
							props.wallet.address[j] = temp_address;
							break;
						}
						setProviderWallet(props.wallet);
					}
					setProviderCountryState(JSON.parse(data.data.providers[i].state));
					setProviderCountry(JSON.parse(data.data.providers[i].country));
					setCompanyWebsite(data.data.providers[i].provider_url);
					setCompanyName(data.data.providers[i].provider);
					setProviderDesc(data.data.providers[i].description);
					setProviderContactEmail(data.data.providers[i].email)
	
					
					/*setSlotId('0' + data.data.providers[i].slot.provider_uid + data.data.providers[i].slot.mcc)
				
					setUniqSubSlotId(data.data.providers[i].slot.provider_uid);
					setMCCDCode(data.data.providers[i].slot.mcc);
					
					setMCCDesc(data.data.providers[i].slot.description);*/
					
					props.setProviderIsSet(true);
				}
	
				setCreatorProviders(selectProviders);
			

				props.setProviderLoaded(true);
			
				var temp  = {...props.showContinue};
				temp.service = true;
				props.setShowContinue(temp);
			}else{
				accordionRef.current.click();
			}
			
			return data;
		}
		
	};
	
	
	
	
	
	
	
	const onProviderSubmit = async(event) => {
		const formData = new FormData();

		formData.append("lt_logo", ltLogoFile);
		formData.append("avatar_logo", avatarLogoFile);
		formData.append("strdata",  JSON.stringify({provider: props.provider}));
		
	
		setSkeleton(true);
	


		
		const response = await axios.post("/api/v1/providers", formData, {  "Content-Type": "multipart/form-data" });
		const resProvider = response.data.data.provider.provider;

		setSkeleton(false);

	
		props.handleProviderChange({
			logoFile: ltLogoFile,
			provider_uri: resProvider.metadata,
			wallet: providerWallet,
			email: resProvider.email,
			provider: resProvider.name,
			website: resProvider.provider_url,
			provider_desc: resProvider.description,
			provider_country: JSON.parse(resProvider.country),
			provider_state: JSON.parse(resProvider.country_state)
		});
		
	

		setProviderCountryState(JSON.parse(resProvider.country_state));
		setProviderCountry(JSON.parse(resProvider.country));
		setCompanyWebsite(resProvider.website);
		setCompanyName(resProvider.name);
		setProviderDesc(resProvider.description);
		setProviderContactEmail(resProvider.email)
		/*setSlotId('0' + data.data.providers[i].slot.provider_uid + data.data.providers[i].slot.mcc)
	
		setUniqSubSlotId(data.data.providers[i].slot.provider_uid);
		setMCCDCode(data.data.providers[i].slot.mcc);
		
		setMCCDesc(data.data.providers[i].slot.description);*/
		
		props.setProviderIsSet(true);
		
		accordionRef.current.click();
		props.setProviderLoaded(true);
		var temp = {...props.showContinue};
		temp.service = true;
		//temp.service = false;
		props.setShowContinue(temp);
		setNotSubmitted(false)
	};
	
	
	
	const loadCountryData = async() => {

		const response =  await axios.get('/api/v1/providers/countries');
		return response.data;

	}
	const loadStateData = async(country_id) => {

		const params = new URLSearchParams({country_id: country_id});

		const response = await axios.get('/api/v1/providers/countries', { params });

		return response.data;

	}
	
	const checkProviderNameUnique = async(providerName) => {

		//const params = new URLSearchParams({provider_name: country_id});
	
		const response = await axios.get('/api/v1/provider_name_exists/' + providerName);

		
		return response.data.exists;

	}
	
	
	const onGenCommunityVerification = async(event) => {


		const response = await axios.post("/api/v1/verify", 
						{platform: event.platform, account: event.account, provider_name: companyName}, {  "Content-Type": "application/json" });
		updateCommunityOpt(response.data);
		
	};
	
	const onVerifyEmailLater = (event) => {
		setVerifyEmailLater(true);
	}
	
	const onChangeVerificationMethod = (event) => {

		flushSync(()=>{
			setPreferredVerification(event.target.value)
		})
	
	}
	
	const onChangePassword = (event) => {
		setProviderPassword(event.target.value);
	}
	
	
	
	const onChangeRetypePassword = (event) => {
		setRetypePassword(event.target.value);
	}
	
	
	const onContinue = async(step) => {

		
		if(step == 'verification'){
	
			//setEmailValidError('');
			var temp = {...proceed};
			temp.verification = true;
			setProceed(temp);
			
			setEmailVerifySkeleton(true);
			setShowVerification(true);
			temp = {...showContinue}
			temp.verification = false;
			setShowContinue(temp);

		}else if(step == 'description'){
			//setEmailValidError('');
			var temp = {...proceed};
			temp.description = true;
			//temp.verification = false;
			temp.providerImage = true;
			setProceed(temp);
			temp = {...showContinue}
			temp.description = false;
			setShowContinue(temp);
			setShowVerification(false);
	
		}
	}
	
	
	
	const img = new Image();
	img.src = logoFromURI;
	
	img.onload = () => {
	  var imgAspectRatio = (img.width / img.height);
	  setImgHeight('50px')
	  setImgWidth(50 * imgAspectRatio + 'px');
	};
	
	
	
	useEffect(() => {
		getProvidersForAddress();
		
		if(props.provider.provider){

		}else{
			loadCountryData().then(countries => {
				setCountries(countries);
			});
		}
		
	}, []);
	


	useEffect(()=> {
	
		if(proceed['verification'] && !emailVerified){
			verifyEmail(companyName, providerContactEmail);
			//setEmailVerifySkeleton(false);
	
		}
		
		
	}, [proceed])
	
	
	
	useEffect(() => {
		
		if(providerContactEmail && formik.errors.providerContactEmail == ''){
			//var temp = existsEmail(providerContactEmail);

			const params = new URLSearchParams({email: providerContactEmail});
			axios.get('/api/v1/verify/email', { params } ).then(response => {
		
				if(response.data){
					setEmailValidError('Not available');
				}else{
					setEmailValidError('');
				}
			})
		
		
		}
	}, [providerContactEmail]);
		
	useEffect(() => {
		setProviderWallet(props.wallet);
		getProvidersForAddress();
		
	}, [props.wallet]);

	
	useEffect(() => {
		if(Object.keys(props.provider).includes('provider_uri')){
			
			fetch(props.provider.provider_uri).then((response) => response.json())
			.then((responseJson) => {
				setLogoFromURI(responseJson.image);
			})
			.catch((error) => {
				console.error(error);
			});
		
		
			//setProviderMetadataURI(props.provider.metadata_uri);
		}
	},[props.provider]);

	
	useEffect(() => {
		const tempShowGeo =  companyName && providerContactEmail  && (ltLogoFile !== "");

		setShowGeograph(tempShowGeo);
		
	}, [companyName, providerContactEmail, ltLogoFile, avatarLogoFile])


	useEffect(() => {
		
		if(companyName.length > 0){
			checkProviderNameUnique(companyName).then(response => {
	
				if(response){
					setNameExists(true);
				}else{
					setNameExists(false);
				}
			})
		}
		
		
		
	}, [companyName])
	
	
	useEffect(() => {
		//formData.append("dk_logo", dkLogoFile);
		if(!props.provider.isComplete){
			props.handleProviderChange({
				logo_file: ltLogoFile,
				avatar_logo_file: avatarLogoFile,
				provider: companyName,
				website: companyWebsite,
				email: providerContactEmail,
				email_verified: !verifyEmailLater,
				provider_desc: providerDesc,
				wallet: providerWallet,
				country: providerCountry,
				country_state: providerCountryState,	
				community: {
					twitter: twitterValue,
					discord: discordValue,
					youtube: youtubeValue,
					medium: mediumValue,
					linkedin: linkedinValue,
					instagram: instagramValue
				}
			});
		}

	
		if((companyName != props.provider.provider)
			|| (companyWebsite != props.provider.website) 
			|| (providerDesc != props.provider.provider_desc)) {
				
				setProviderDataUpdated(true);
			}
			
	}, [companyName, companyWebsite, ltLogoFile,  providerDesc, providerCountry, providerCountryState,
			twitterValue, discordValue, youtubeValue, mediumValue, linkedinValue, instagramValue]);


	const hasCommunity = (community.twitter.has_twitter 
							|| community.discord.has_discord 
								|| community.medium.has_medium 
									|| community.telegram.has_telegram 
										|| community.linkedin.has_linkedin 
											|| community.youtube.has_youtube || community.instagram.has_instagram); 


	return (
		<div>
			
				{Object.keys(props).includes('provider') && logoFromURI ? 
					<h3> <span className="font-weight-normal meritic_dblue"> Creator </span> </h3>  
						: <h4> <span className="font-weight-normal meritic_dblue"> Register as Creator or Service provider </span> </h4> }
			
			
				<div className="accordion accordion-flush"> 
					<AccordionItem
		            	heade_id="company_info_header"
		            	collapse_container_id="company_info_collapse_container"
		            	parent_id="company_info_accordion"
		            	header_text={ logoFromURI !== null ?
								<span style={{display: "flex"}}> <img src={ logoFromURI } className="img-fluid" style={{border: '2px solid #fff', width: imgWidth, height: imgHeight, borderRadius: '15%'}} alt="..." /> <h5 className="ml-1"> { props.provider.provider } </h5>  </span> 
								: "Creator details"
							}
		            	show_collapse="collapse"
		            	accordionRef={accordionRef}
		            	body={
							<ProviderDetails
								isMobileWidth={props.isMobileWidth}
								creatorProviders={creatorProviders}
								provider={props.provider}
								providerDesc={providerDesc}
								providerName={companyName}
								onLtLogoFileDrop={handleLtLogoFileDrop}
		        				onNameChange={handleCompanyNameChange} 
		        				onWebsiteChange={handleCompanyWebsiteChange} 
		        				handleProviderDescriptionChange={handleProviderDescriptionChange}
		        				onProviderSubmit={onProviderSubmit}
		        				providerDataUpdated={providerDataUpdated}
		        				onAvatarImageCreate={onAvatarImageCreate}
		        				countries={countries}
		        				countryStates={countryStates}
		        				providerCountry={providerCountry}
		        				onCountryChange={onCountryChange}
		        				onStateChange={onStateChange}
		        				providerCountryState={providerCountryState}
		        				community={community}
		        				onAddDiscord={onAddDiscord}
		        				onAddTwitter={onAddTwitter}
		        				addDiscord={addDiscord}
		        				addTwitter={addTwitter}
		        				addYoutube={addYoutube}
		        				onAddYoutube={onAddYoutube}
		        				addMedium={addMedium}
		        				onAddMedium={onAddMedium}
		        				addTelegram={addTelegram}
		        				onAddInstagram={onAddInstagram}
		        				onAddTelegram={onAddTelegram}
		        				onAddLinkedIn={onAddLinkedIn}
		        				addInstagram={addInstagram}
		        				discordValue={discordValue}
		        				twitterValue={twitterValue}
		        				addLinkedIn={addLinkedIn}
		        				onChangeDiscordValue={onChangeDiscordValue}
		        				onChangeTwitterValue={onChangeTwitterValue}
		        				onGenCommunityVerification={onGenCommunityVerification}
		        				onAddCommunityLater={onAddCommunityLater}
		        				addCommunityLater={addCommunityLater}
		        				hasCommunity={hasCommunity}
		        				onProviderContactEmailChange={onProviderContactEmailChange}
		        				providerContactEmail={providerContactEmail}
		        				showGeography={showGeography}
								nameExists={nameExists}
								noThumbs={noThumbs}
								onChangeVerificationMethod={onChangeVerificationMethod}
								emailVerified={emailVerified}
								setEmailVerified={setEmailVerified}
								providerWalletModalIsOpen={providerWalletModalIsOpen}
								setProviderWalletModalIsOpenToFalse={setProviderWalletModalIsOpenToFalse}
								onChangeProviderWallet={onChangeProviderWallet}
								preferredVerification={preferredVerification}
								providerModalIsOpen={providerModalIsOpen}
								providerWallet={providerWallet}
								providerSelectedWallet={providerSelectedWallet}
								wallet={props.wallet}
								walletConnect={props.walletConnect}
								onChangePassword={onChangePassword}
								providerPassword={providerPassword}
								retypePassword={retypePassword}
								onChangeRetypePassword={onChangeRetypePassword}
								emailValidError={emailValidError}
								setEmailValidError={setEmailValidError}
								emailValid={emailValid}
								setProviderWalletModalIsOpen={setProviderWalletModalIsOpen}
								setReceivedEmailVerifiedResponse={setReceivedEmailVerifiedResponse}
								proceed={proceed}
								showVerification={showVerification}
								onContinue={onContinue}
								showContinue={showContinue}
								setShowContinue={setShowContinue}
								onChangeVerifyCode={onChangeVerifyCode}
								verifyEmailCode={verifyEmailCode}
								verificationCode={verificationCode}
								verifyEmail={verifyEmail}
								setProceed={setProceed}
								skipContinue={skipContinue}
								emailVerifySkeleton={emailVerifySkeleton}
								setEmailVerifySkeleton={setEmailVerifySkeleton}
								skeleton={skeleton}
								notSubmitted={notSubmitted}
								formik={formik}
								verifyEmailLater={verifyEmailLater}
								onVerifyEmailLater={onVerifyEmailLater}
		        			/> 
		            	}
		            >
					</AccordionItem>
				</div> 
			
		</div>	
	);
}






export function ProviderDetails(props) {
	 
	
	
	/*const [passwdStrengthScore, setPasswdStrengthScore] = useState(1);
	const [showBar, setShowBar] = useState(true);

	const onChangePassworddStrength =(value)=>{
		setPasswdStrengthScore(value);
	}*/

	
	var communityHandles = [];
	
	if(props.addTwitter){
		communityHandles.push(
			<div className="d-flex">
				<div className="form-group d-flex" style={{width:'40%'}}>
					<span className="mr-2 mt-1"><i className={props.community.twitter.fa_twitter_icon}></i></span>
					<Input 
						class="form-control form-rounded" 
						type="text" 
						placeholder={props.community.twitter.place_holder}
						onChange={props.onChangeTwitterValue}
						value={props.twitterValue}
					/>
				</div>
				<div className="form-group ml-5 d-flex" style={{width: '60%'}}>
					<a type="button" href="#/" className="btn-sm btn-light mr-2" onClick={() => {
							props.onGenCommunityVerification({platform: 'discord', account: props.twitterValue});
						
						}}> Verify </a> <div className="text-wrap" style={{width: '30rem'}}> {props.community.twitter.message} </div>
				</div>
			</div> );
	}
		
		if(props.addDiscord){
			communityHandles.push(
				<div className="d-flex">
					<div className="form-group d-flex" style={{width:'40%'}}>
						<span className="mr-2 mt-1"><i className={props.community.discord.fa_discord_icon}></i></span>
						<Input 
							class="form-control form-rounded" 
							type="text" 
							placeholder={props.community.discord.place_holder}
							onChange={props.onChangeDiscordValue}
							value={props.discordValue}
						/>
					</div>
					<div className="form-group ml-5 d-flex" style={{width: '60%'}}>
						<a type="button" href="#/" className="btn-sm btn-light mr-2" onClick={() => {
								props.onGenCommunityVerification({platform: 'discord', account: props.discordValue});
							
							}}> Verify </a> <div className="text-wrap" style={{width: '30rem'}}> {props.community.discord.message} </div>
					</div>
				</div>);
		}
			
		if(props.addMedium){
			communityHandles.push(
				<div className="d-flex">
					<div className="form-group d-flex" style={{width:'40%'}}>
						<span className="mr-2 mt-1"><i className={props.community.medium.fa_medium_icon}></i></span>
						<Input 
							class="form-control form-rounded" 
							type="text" 
							placeholder={props.community.medium.place_holder}
							onChange={props.onChangeMediumValue}
							value={props.mediumValue}
						/>
					</div>
					<div className="form-group ml-5 d-flex" style={{width: '60%'}}>
						<a type="button" href="#/" className="btn-sm btn-light mr-2" onClick={() => {
								props.onGenCommunityVerification({platform: 'medium', account: props.mediumValue});
							
							}}> Verify </a> <div className="text-wrap" style={{width: '30rem'}}> {props.community.medium.message} </div>
					</div>
				</div> );
		}	
		
		if(props.addYoutube){
			communityHandles.push(
				<div className="d-flex">
					<div className="form-group d-flex" style={{width:'40%'}}>
						<span className="mr-2 mt-1"><i className={props.community.youtube.fa_youtube_icon}></i></span>
						<Input 
							class="form-control form-rounded" 
							type="text" 
							placeholder={props.community.youtube.place_holder}
							onChange={props.onChangeYoutubeValue}
							value={props.youtubeValue}
						/>
					</div>
					<div className="form-group ml-5 d-flex" style={{width: '60%'}}>
						<a type="button" href="#/" className="btn-sm btn-light mr-2" onClick={() => {
								props.onGenCommunityVerification({platform: 'youtube', account: props.youtubeValue});
							
							}}> Verify </a> <div className="text-wrap" style={{width: '30rem'}}> {props.community.youtube.message} </div>
					</div>
				</div> );
		}	
		
		if(props.addInstagram){
			communityHandles.push(
				<div className="d-flex" key="instagram">
					<div className="form-group d-flex" style={{width:'40%'}}>
						<span className="mr-2 mt-1"><i className={props.community.instagram.fa_instagram_icon}></i></span>
						<Input 
							class="form-control form-rounded" 
							type="text" 
							placeholder={props.community.instagram.place_holder}
							onChange={props.onChangeInstagramValue}
							value={props.instagramValue}
						/>
					</div>
					<div className="form-group ml-5 d-flex" style={{width: '60%'}}>
						<a type="button" href="#/" className="btn-sm btn-light mr-2" onClick={() => {
								props.onGenCommunityVerification({platform: 'instagram', account: props.instagramValue});
							
							}}> Verify </a> <div className="text-wrap" style={{width: '30rem'}}> {props.community.instagram.message} </div>
					</div>
				</div>);
		}	
		
		if(props.addTelegram){
			communityHandles.push(
				<div className="d-flex" key="telegram">
					<div className="form-group d-flex" style={{width:'40%'}}>
						<span className="mr-2 mt-1"><i className={props.community.telegram.fa_telegram_icon}></i></span>
						<Input 
							class="form-control form-rounded" 
							type="text" 
							placeholder={props.community.telegram.place_holder}
							onChange={props.onChangeTelegramValue}
							value={props.telegramValue}
						/>
					</div>
					<div className="form-group ml-5 d-flex" style={{width: '60%'}}>
						<a type="button" href="#/" className="btn-sm btn-light mr-2" onClick={() => {
								props.onGenCommunityVerification({platform: 'instagram', account: props.telegramValue});
							
							}}> Verify </a> <div className="text-wrap" style={{width: '30rem'}}> {props.community.telegram.message} </div>
					</div>
				</div>);
		}	
		
		if(props.addLinkedIn){
			communityHandles.push(
				<div className="d-flex" key="linkedin">
					<div className="form-group d-flex" style={{width:'40%'}}>
						<span className="mr-2 mt-1"><i className={props.community.linkedin.fa_linkedin_icon}></i></span>
						<Input 
							class="form-control form-rounded" 
							type="text" 
							placeholder={props.community.linkedin.place_holder}
							onChange={props.onChangeLinkedInValue}
							value={props.linkedinValue}
						/>
					</div>
					<div className="form-group ml-5 d-flex" style={{width: '60%'}}>
						<a type="button" href="#/" className="btn-sm btn-light mr-2" onClick={() => {
								props.onGenCommunityVerification({platform: 'instagram', account: props.linkedinValue});
							
							}}> Verify </a> <div className="text-wrap" style={{width: '30rem'}}> {props.community.linkedin.message} </div>
					</div>
				</div> );
		}



	
	return (
		<>
		{props.providerWallet && props.providerWallet.status == 'success' ?
		
		<div className="form-group mt-0">
		
			<div>
				{props.creatorProviders.length > 0 ?
					<>
					{/*
					<Select id="mcc_category" values={props.creatorProviders} options={props.creatorProviders} onChange={props.handleCreatorProviderChange} /> 
					<a href="#">
						<span><Icon path={mdiShapePolygonPlus} title="Add" size={1} /> Add new Service Provider</span>
					</a> 
					*/}
					</>: null
				}
			</div>
			<Label className="mb-1" for="company" text=""></Label>
			
			
			{!props.skeleton ?
				<div className="form-group-sm">
					<div className={props.isMobileWidth ? 'mt-0 mb-1' : 'd-flex mt-0 mb-1'}   style={{ fontSize: 20, lineHeight: 2 }}>
						<div className="container" style={{width:'100%'}}>
							{props.nameExists ? <span className="mb-0 text-danger" style={{fontSize:"14px"}}>Not available </span> : <span className="mb-0">&nbsp; </span>	}
							<Input 
								class="form-control form-rounded" 
								id="company" 
								name="company" 
								type="text"
								placeholder="Name . . ." 
								value={props.provider.provider ? props.provider.provider : ""}
								onChange={props.onNameChange}
							/> 
						</div>
						<div className="container" style={{width:'100%'}}>
							<span className="mb-0">&nbsp; </span>
							<Input 
								class="form-control form-rounded" 
								id="company_url" 
								name="company_url" 
								type="url" 
								placeholder="Website"
								value={props.provider.website ? props.provider.website : ""}
								onChange={props.onWebsiteChange}
							/>
						</div>
						<div className="container" style={{width:'100%'}}>
							<span>{props.formik.errors.providerContactEmail || props.emailValidError  ? 
								<span className="mb-0 text-danger" style={{fontSize:"14px"}}> 
									{props.formik.errors.providerContactEmail + ' '  + props.emailValidError } 
								</span> : <span className="mb-0">&nbsp; </span>	}&nbsp;</span>
							<Input 
								class="form-control form-rounded" 
								name="providerContactEmail" 
								type="email" 
								placeholder="Email address"
								value={props.providerContactEmail}
								onChange={(event) => {props.formik.handleChange(event); props.onProviderContactEmailChange(event)}}
							/>
						</div>
						{/*<EmailInput setEmailError={props.setEmailValidError} 
							value={props.providerContactEmail} 
							setShowContinue={props.setShowContinue}
							showContinue={props.showContinue}
							setProceed={props.setProceed}
							proceed={props.proceed}
							onProviderContactEmailChange={props.onProviderContactEmailChange} />*/}
			     	</div>
			     	{
						 !props.skipContinue && !(props.formik.errors.providerContactEmail ||  props.emailValidError || props.nameExists) ?
						 	<ContinueGate continue={!props.emailValidError && props.providerContactEmail.length > 0} 
			     					showContinue={ props.showContinue['verification']} 
			     					onContinue={()=>{props.onContinue('verification')}}
			     					showBack={false} />  : null
					 }
			     	
			    </div> : <Skeleton  height={30} />
	     	}
	     	{!props.skeleton ?
	     		<>
	     		
	     		{ props.proceed['verification'] || props.skipContinue ? 
	     			<>
			     		<div className="form-group mt-2">
			     		
			     		{props.showVerification  && !props.skipContinue ?
							<>
				     		{!props.emailVerifySkeleton && !props.skipContinue ?
								 <div className={'form-group ' + (props.isMobileWidth ? '' : 'd-flex')} style={{width:"100%", height:'100%', borderRadius:'10px', backgroundColor:'#CCCCE5'}}>
				     			
									<div className="form-group justify-content-center ml-1 mt-1" style={{width: '100%'}}>
										{!(props.emailVerified || props.verifyEmailLater)?
											<div className="form-group">
												<span className="form-group">We emailed you a verification code. Enter the code.
													<input className="form-control-sm ml-1 mr-1" type="text" onChange={props.onChangeVerifyCode}  minlength="4" maxlength="8" size="10"/> 
													
													<span>
														<button className="btn btn-sm btn-light btn-rounded" onClick={props.verifyEmailCode} style={{visibility: props.verificationCode.length > 0 ? 'visible': 'hidden', maxHeight:"38px", maxWidth:"100px"}}>Verify</button> 
														<button className="btn btn-sm btn-light btn-rounded ml-2" onClick={props.onVerifyEmailLater} style={{visibility: 'visible', maxHeight:"38px", maxWidth:"100px"}}>Verify later</button>
													</span>
												</span> 
											</div> : <div className="form-group"><span>{props.emailVerified ?  <span style={{color:'green'}}>Email Verified</span>: <span style={{color:'black'}}>Email Not verified</span>}</span></div>
											}
										
									</div>
									{(props.emailVerified || props.verifyEmailLater) && !props.skipContinue ?
										<>
											
											<div className="form-group justify-content-center ml-1 mt-1" style={{width: '100%'}}>
												<span className="form-group">Provide a wallet address for us to use to identify you?</span>
												<button className="btn btn-sm btn-rounded btn-light" onClick={props.setProviderWalletModalIsOpen} style={{visibility: (props.emailVerified || props.verifyEmailLater)  ? 'visible': 'hidden', maxHeight:"35px", maxWidth:"140px"}}>Select address</button>
												{/* <div className="d-flex">
														<span className="mr-2"> <input type="radio" name="provider_verification_method"
															value="wallet" autocomplete="off" onChange={props.onChangeVerificationMethod}
														 	/>Web3 wallet </span>
														 <span> <input  type="radio" name="provider_verification_method"
															value="email" autocomplete="off" onChange={props.onChangeVerificationMethod}
															 />Email / Password </span> 
												</div>*/}
											</div>
											{props.preferredVerification == 'wallet' ?
												<div className="form-group ml-1 mt-1" style={{width: '100%'}}>
													<ProviderWalletModal  
														walletModalIsOpen={props.providerWalletModalIsOpen}
														setProviderWalletModalIsOpenToFalse={props.setProviderWalletModalIsOpenToFalse} 
														wallet={props.wallet} 
														onChangeProviderWallet={props.onChangeProviderWallet} 
														providerWallet={props.providerWallet}  
														setShowContinue={props.setShowContinue} 
														showContinue={props.showContinue} 
														walletConnect={props.walletConnect}
													/> 
													{props.providerSelectedWallet ? 
														<>
															<Label class="mr-2 mt-1" text="Your wallet address"></Label> 
															<a type="button" className="btn-sm btn-light btn-rounded" onClick={() => {navigator.clipboard.writeText(props.providerWallet.address[0]);}}>
											                	{ props.providerWallet.address[0].substring(1, 5) + ' ... ' +  props.providerWallet.address[0].substring(props.providerWallet.address[0].length - 4, props.providerWallet.address[0].length)}
											                </a>
														</> : null
													}
													
									            </div> : null
													/*<div className="form-group col" style={{width: '100%'}}>
														<div className="form-group mt-1 d-flex">
							                                <div className="pwd-container">
							                                	<input type="password" name="password" className="form-control-sm" onChange={props.onChangePassword} size="15" placeholder="Enter a strong password" required />
							                                	{showBar ?
																	<PasswordStrengthBar password={props.providerPassword} 
																				onChangeScore={(event) => {
																					onChangePassworddStrength(event); 
																						if(passwdStrengthScore == 4){ 
																							setShowBar(false); 
																						}else{
																							setShowBar(true);
																						}
																					}
																				} /> : null
																}	
							                          			{passwdStrengthScore == 4 ?
								                                	<div className="pwd-container">
									                                    <input type="password" id="confirm_password" className="form-control-sm" onChange={props.onChangeRetypePassword} size="15" placeholder="Re-enter your password" required />		                               
								                                	</div>: null 
								                                }
							                                </div> 
							                                
							                                	<button className="btn btn-light-sm">Save password</button> 
							                                
							                            </div>
													</div>*/
												
											}
											
										</> : null
									}
								</div> : <> {!props.skipContinue ? <Skeleton  height={40}  width={"100%"} /> 
												: <> {props.emailVerifySkeleton ? <Skeleton  height={40}  width={"100%"} /> : null } </> 
											} </>
							}
							</> : 
								<>
								
								{(props.emailVerified  || props.verifyEmailLater) && props.providerWallet && props.providerWallet.address &&  props.providerWallet.address.length > 0 ?
									<div className='form-group d-flex'>
										<div className="container form-group">
										<span className="form-group"><Label className="mr-1" text="Email:"></Label>
										{props.emailVerified  ? <span className="ml-1" style={{color:'green'}}>Verified</span> : <span className="ml-1" style={{color:'black'}}>Not verified</span> }</span>
										</div>
										<div className="container form-group">
										<span><Label className="mr-1" text="Wallet address:"></Label> 
													<a type="button" className="ml-1 btn-sm btn-light btn-rounded" onClick={() => {navigator.clipboard.writeText(props.providerWallet.address[0]);}}>
										                	{ props.providerWallet.address[0].substring(1, 5) + ' ... ' +  props.providerWallet.address[0].substring(props.providerWallet.address[0].length - 4, props.providerWallet.address[0].length)}
										            </a> 
										</span>
										</div>
									</div> 
								: null}
								</>
							}
							{!props.skipContinue ?
								<ContinueGate continue={true} 
				     					showContinue={props.showContinue['description']} 
				     					onContinue={()=>{props.onContinue('description')}}
				     					showBack={false} /> 
				     			: null
			     			}
						</div>	
					</>: null
				}
				</> : <Skeleton  height={30} />
			 }
	     	{/*!props.skeleton ?
	     		<> 
	     		{ props.proceed['description'] ?
					 <>
					 	<div className="form-group mt-0">
							<Label class="mb-0" for="provider_description" text="Description"></Label>
							<textarea className="form-control" id="provider_description" name="provider_description" rows="4"  value={props.providerDesc} onChange={props.handleProviderDescriptionChange}></textarea>
						</div> 
					 </> : null
				 }
		     	
				</> :  <Skeleton  height={80} />
			*/}
			
			
	     	{!props.skeleton ?
	     		<>
	     		{ props.proceed['providerImage'] || props.skipContinue ?
			     	<div className="d-flex mt-0">
			     		
			     		<div className="form-group mt-0" style={{width: '100%', display:'block'}}>
				     		<Label class="form-group mb-0" for="company_logo" text=""></Label>
							<FileUpload 
								formgroup_width="100%" 
								instruction="Upload brand logo (click or drag here to upload)" 
								fieldname="company_light_bg_logo" 
								onFileDrop={props.onLtLogoFileDrop}
								noThumbs={props.noThumbs}
							> </FileUpload>
						</div>
						{
							/* <ProviderAvatar 
								instruction="No logo? Click here to generate a placeholder logo" 
								onImageCreate={props.onAvatarImageCreate}
								providerName={props.providerName}
							/>*/
						}
						
					</div> : null
				}
				</>: <div className="d-flex">
			     	<SkeletonBox>
			     		<Skeleton  height={80} width={"100%"} />
			     	</SkeletonBox>
			     	<SkeletonBox>
			     		<Skeleton  height={80} width={"100%"} />
			     	</SkeletonBox>
		     	</div>
		    }
		
			{ props.countries.length > 0 && props.showGeography || props.skipContinue ?
				<>
				{!props.skeleton ?
					<div className="form-group d-flex mb-2">
						<div style={{width: '50%'}}>
							<Label className="mb-1" for="provider_country" text="Your country"></Label>
							<Select id="provider_country" className="form-group" value={props.providerCountry} options={props.countries} onChange={props.onCountryChange}/>
							
						</div>
						{props.countryStates.length > 0 ?
							<div style={{width: '50%'}}>
								<Label className="mb-1" for="provider_state" text="Your State"></Label>
								<Select id="provider_state" className="form-group" value={props.providerCountryState} options={props.countryStates} onChange={props.onStateChange}/>
							</div>: null
						}
			     	</div> :
			     	
			     	<div className="d-flex">
				     	<SkeletonBox>
				     		<Skeleton  height={40} width={"100%"} />
				     	</SkeletonBox>
				     	<SkeletonBox>
				     		<Skeleton  height={40} width={"100%"} />
				     	</SkeletonBox>
			     	</div>
				}
					 
			     	
			     	
			     	{props.providerCountry && ((props.countryStates.length > 0 && props.providerCountryState) || props.countryStates.length == 0) ?
						<>
							{!props.skeleton ? 
						     	<div className="form-group d-flex">
									<Label class="mb-1"  text="Community"></Label> 
									
									<div className="container d-flex ml-4">
										<span> <input  className="mr-1" type="radio" name="community_add_later" 
												value="community_add_later" autocomplete="off" onChange={props.onAddCommunityLater}
												checked={props.addCommunityLater} />Add later </span>
									</div>
								</div> : <SkeletonBox>
						     				<Skeleton  height={40} width={"100%"} />
						     			</SkeletonBox>
							}
							{!props.addCommunityLater ?
				
								<div className="d-flex text-center mt-1">
									<div className="align-center" style={{width:'14.3%'}}>
										<a type="button" href="#/" onClick={props.onAddTwitter}><i className="fa-brands fa-twitter fa-xl"></i>
										<div>Twitter</div></a>
									</div>
									<div style={{width:'14.3%'}}>
										<a type="button" href="#/" onClick={props.onAddDiscord}>
											<i className="fa-brands fa-discord fa-xl"></i>
											<div>Discord</div>
										</a>
									</div>
									<div style={{width:'14.3%'}}>
										<a type="button" href="#/" onClick={props.onAddMedium}>
											<i className="fa-brands fa-medium fa-xl"></i>
											<div>Medium</div>
										</a>
									</div>
									<div style={{width:'14.3%'}}>
										<a type="button" href="#/" onClick={props.onAddYoutube}>
											<i className="fa-brands fa-youtube fa-xl"></i>
											<div>Youtube</div>
										</a>
									</div>
									<div style={{width:'14.3%'}}>
										<a type="button" href="#/" onClick={props.onAddInstagram}>
											<i className="fa-brands fa-instagram fa-xl"></i>
											<div>Instagram</div>
										</a>
									</div>
									<div style={{width:'14.3%'}}>
										<a type="button" href="#/" onClick={props.onAddTelegram}>
											<i className="fa-brands fa-telegram fa-xl"></i>
											<div>Telegram</div>
										</a>
									</div>
									<div style={{width:'14.3%'}}>
										<a type="button" href="#/" onClick={props.onAddLinkedIn}>
											<i className="fa-brands fa-linkedin fa-xl"></i>
											<div>LinkedIn</div>
										</a>
									</div>
								</div> : null
							} 
						
						
				    	<div className="form-group container">
							{communityHandles}
						</div>
				     	
				 		
						
				     	{/*providerDataUpdated*/}
				
				     	{ (!(props.creatorProviders.length == 0) || (props.hasCommunity || props.addCommunityLater) ) && props.notSubmitted && !props.skipContinue 
				     		&& !(props.nameExists || props.formik.errors.providerContactEmail || props.emailValidError) ?
							 <div>
							 	<hr style={{height:'1px', width:'100%', backgroundColor:'#000000'}}/>
				     	
				     			<a type="button" className="align-right btn-sm btn-light" onClick={props.onProviderSubmit} 
										data-tokengroup={props.token_group} data-condition={props.condition_name} style={{float: 'right'}}>Submit</a>
							 </div> : null
							 
						}
						
		     		</> : null 
		     		}
		     	</> : null
		     	
		     	
		     
			}
			
			
			
	     	
		</div>
		: null}
		</>
		
	);
};




/*
const SubsTokenNetwork = (props) => {

						
											
	const [country, setCountry] = useState();
	const [countrySubRegion, setCountrySubRegion] = useState('000000');
	const [mccCategory, setmccCategory] = useState();
	const [networkCode, setNetworkCode] = useState("0-0000-000000-0000");
	
	
	const handleAddSubregion = (event) 	=> {
		
		
	}
	
	const onChangeCountry = (selection) => {

		var cc = countryCode[selection[0].value];
		if(cc.length < 4){
			cc = (Array(4 - cc.length).fill('0').join('') + cc);
		}
		var spNetwork = networkCode.split('-');
		spNetwork[1] = cc
		setNetworkCode(spNetwork.join('-'))
		
		//countryCodes.customList()
	}
	
	const onChangeMCCCategory = (selection) => {
		var spNetwork = networkCode.split('-');
		spNetwork[3] = selection[0].value;
		setNetworkCode(spNetwork.join('-'))
	}
	
	return (
		<div className="d-flex" style={{width:'100%'}}>
			<div className="container">
				<Label for="provider_country"  class="mb-0"  text="Country of operation" >	</Label> 
				<Select id="provider_country" onChange={onChangeCountry} className="form-group" values={[{label: 'United States of America', value: 'US'}]} options={props.subs.country}  />
			</div>
			<div className="container">
				<Label for="provider_country_subregion"  class="mb-0"  text="Sub-region" >	</Label> 
				<Select id="provider_country_subregion" className="form-group" values={[{label: 'All Sub-regions', value: 'All'}]}   />
			</div>
			<div className="container">
				<Label for="provider_country_mcc"  class="mb-0"  text="MCC Category" >	</Label> 
				<Select id="provider_country_mcc" options={mccOptionsWithAll} onChange={onChangeMCCCategory} className="form-group" values={[{label: 'All Categories', value: '0000'}]}   />
			</div>
			<div className="container">
				<Label for="network_id"  class="mb-0"  text="Network ID" >	</Label> 
				 <input className="form-control" type="text" value={networkCode}></input>
			</div>
		</div>
	)
}*/

