
import React, {useEffect, useState} from 'react'
import AddPanel from './AddPanel';
import 'react-alice-carousel/lib/alice-carousel.css';

import './style.css';



import TokenServiceConditions from './TokenServiceConditions';

















const AddTokenGroup = (props) => {
	
	const [numTokens, setNumTokens] = useState(0);
	const [slot, setSlot] = useState(null);
	const [tokenGroupName, setTokenGroupName] = useState(0);
	const [numTokenType, setNumTokenType] = useState("varying");
	const [tokenGrpThumbs, setTokenGrpThumbs] = useState([])
	const [tokenGrpImage, setTokenGrpImage] = useState([])
	const [slotOptions, setSlotOptions] = useState([{value: 12345, label: 'Default slot'}]);
	const [tokenGroupImage, setTokenGroupImage] = useState("")
	const [valueGrpType, setValueGrpType] = useState(null);
	const [timeGrpType, setTimeGrpType] = useState(null);
	const [imgPreview, setImgPreview] = useState("")

	const onTokenImageClick = (event) => {

		setTokenGroupImage(event.target.dataset.name);
		
		if(event.target.dataset.imgindex){
			
			const index = parseInt(event.target.dataset.imgindex);
			
			if(tokenGrpThumbs && tokenGrpThumbs.length > 0){
				
				setImgPreview(tokenGrpThumbs[index].preview);
			}
			
			//setTokenGroupImageFile(event.target.dataset.preview);
		}
	}
	

	const onChangeValueGrpType = (event, type) => {
		setValueGrpType(type);
		var temp = props.showContinue;
		temp.tokenGrpName = true;
		props.setShowContinue(temp);
	}
	
	const onChangeTimeGrpType = (event, type) => {
		setTimeGrpType(type);
		var temp = props.showContinue;
		temp.tokenGrpName = true;
		props.setShowContinue(temp)
	}
	
	

	



	
	function handleNumTokenTypeChange(event){
		if(event.target.value === undefined){
			setNumTokenType(event.target.dataset.value);
		}else{
			setNumTokenType(event.target.value)
		}
	}	
	
	
	
	
	function handleTokenGroupNameChange(event){
		setTokenGroupName(event.target.value);
	};
	
	
	function handleNumTokenChange(event) {
		setNumTokens(parseInt(event.target.value));
		if(parseInt(event.target.value) > 0){
			setNumTokenType('fixed');
		}else{
			setNumTokenType('varying');
		}
	};
	
	
	const handleTokenGrpSlotChange = (selection) => {
		setSlot(selection);
	}
	
  	function handleCreateTokenGroup() {
		  var tokenGrpType;
		  if(props.contractProperties.type == 'value'){
			  tokenGrpType =  valueGrpType;
		  }else{
			  tokenGrpType = timeGrpType;
		  }
		  props.setTokenGroup([...props.tokenGroup, 
									{
										contractProperties: props.contractProperties,
										tokenGrpType: tokenGrpType,
										tokenGroupName: tokenGroupName, 
										slotName: slot.label,
										slotId: slot.value, 
										numTokens: numTokens, 
										numTokenType: numTokenType,
										groupImage: tokenGrpImage,
										tokenGroupImage: tokenGroupImage, 
										imgPreview: imgPreview
									}]);
									
			props.setConditions([...props.conditions, {}]);
			props.mountedAddTG.current = false;
			props.setShowAddTokenGroupFields(false);
	}
	
	


	return(
		<div id="add_token_group">
			
							
									
			{ props.showAddTokenGroupFields ? <AddPanel 
								slot={slot}
								imgThumbs={props.imgThumbs}
								tokenGroupName={tokenGroupName}
								tokenTypeClassActive={props.tokenTypeClassActive}
								handleTokenGroupNameChange={handleTokenGroupNameChange} 					
								handleNumTokenTypeChange={handleNumTokenTypeChange}
								handleCreateTokenGroup={handleCreateTokenGroup}
								numTokens={numTokens}
								handleNumTokenChange={handleNumTokenChange} 
								tokenGroupImage={tokenGroupImage}
								setTokenGrpImage={setTokenGrpImage}
								onTokenImageClick={onTokenImageClick}
								//onNumTokenTypeClicked={onNumTokenTypeClicked}
								handleTokenGrpSlotChange={handleTokenGrpSlotChange}
								handleSlotChange={props.handleSlotChange}
								setTokenGrpThumbs={setTokenGrpThumbs}
								numTokenType={numTokenType}
								slotOptions={slotOptions}
								//handleErc20CurrencyChange={handleErc20CurrencyChange}
								serviceImageNameMap={props.serviceImageNameMap}
								serviceMetadataURI={props.serviceMetadataURI}
								setShowAddTokenGrp={props.setShowAddTokenGrp}
								//onContinue={onContinue}
								proceed={props.proceed}
								contractType={props.contractType}
								onContinue={props.onContinue}
								showContinue={props.showContinue}
								
								valueGrpType={valueGrpType}
								timeGrpType={timeGrpType}
								onChangeValueGrpType={onChangeValueGrpType}
								onChangeTimeGrpType={onChangeTimeGrpType}
							/> : null 
			}
			
			
			<TokenServiceConditions 
            	conditions={props.conditions}
            	setConditions={props.setConditions}
				tokenGroup={props.tokenGroup}
				serviceOffer={props.serviceObj.serviceOffer}
				onSaveTokenGroups={props.onSaveTokenGroups}
				savedTokengGroups={props.savedTokengGroups}
				timezones={props.timezones}
            ></TokenServiceConditions> 
            
                                        
                                                                                                
                                                                                                
		</div>
	);
}

export default AddTokenGroup;