

import React, {useState, useEffect } from 'react'
import GridItem from './GridItem';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import "react-alice-carousel/lib/scss/alice-carousel.scss";




const responsive = {
    0: { items: 1 },
    itemsFit: 'fill',
    568: { items: 2 },
    1024: { items: 3 },
};



const Carousel = (props) => (
    <AliceCarousel
        mouseTracking={true}
        touchMoveDefaultEvents={true}
        disableDotsControls={true}
        disableButtonsControls={true}
        items={props.items}
        responsive={responsive}
        controlsStrategy="alternate"
    />
);




export default function Offering(props){
	
	//const [reservedItems, setReservedItems] = useState([]);
	const [collectionImageURLs, setCollectionImageURLs] = useState([]);
	const [collectionName, setCollectionName] = useState("");
	const [reserveDates, setReserveDates] = useState([]);
	//const [reservedTokens, setReservedTokens] = useState([]);
	

	
	
	
	const parsePeriod = (start, end) => {
		
		const stp = start.split(':');
		const edp = end.split(':');
		
		var vx = stp.slice(0, stp.length - 1).join(':') + ' - ' + edp.slice(0, edp.length - 1).join(':');
		return vx;
	}
	
	const setReservedCarousel = async () => {
		
		
		var tempDate = [];
		
		var temp = props.tokens.map(rec => (rec.properties.image_uri + '/' + rec.properties.imgname));

		/*for(let i=0; i < props.tokens.length; i++){
			
			var rec = props.tokens[i];
			if(i == 0){
				setCollectionName(rec.collection_name);
			}
			console.log(rec.properties.image_uri + '/' + rec.properties.imgname);
			console.log('#################')
			await fetch(rec.metadata_uri).then((response) => response.json())
		   	.then((responseJson) => {
					console.log(responseJson);
					console.log('&&&&&&&&')
				   const image_url = responseJson.image;
				   temp.push(image_url);
		   	}).catch((error) => {
				   console.error(error);
		   	});
		   	var res_start = rec.properties.reserve_start;
			var res_end = rec.properties.reserve_end;
			var res_start_sp = res_start.split('T');
			var res_end_sp = res_end.split("T");
			
			var date_str = new Date(res_start_sp[0]).toDateString();
			var period = parsePeriod(res_start_sp[1], res_end_sp[1]);
			
			var date_period = date_str + ', ' + period;
			tempDate.push(date_period);
		}*/
		
		setCollectionImageURLs([...temp]);
		//setReserveDates([...tempDate]);

	
		//var tempTokens = [];	
		//setReservedTokens(tempTokens);
	}
	
	
	useEffect(() => {
		setReservedCarousel();
	},[]);
	
	

	
	
	var offerTokens = [];
	
		
	for(let i=0; i < props.tokens.length; i++){
		offerTokens.push(
		<GridItem 
			index={i} 
			token={props.tokens[i]}
			wallet={props.wallet}
			collectionImageURL={collectionImageURLs[i]}
			collection_name={collectionName}
			tokenGroupConditions={props.tokenGroupConditions}
		/>);
	}
	
	
	
	
  /*function tileClassName({ date, view }) {
	  if (view === 'month') {
		  if (props.reservableDates.find(dDate => isSameDay(dDate, date))) {
			  return 'myClassName';
		  }
  	  }
   }*/
   

	return (
		<section className="p-2 justify-content-center">
				<div className="d-flex form-group">
					<div className="container table-responsive">
						<Carousel items={offerTokens} />
					</div>

				  </div>
			
		</section>
	);
		
};