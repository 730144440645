import React, {useState, useEffect, useMemo, useCallback, createContext, useRef	} from 'react'
import { FixedSizeList, VariableSizeList } from 'react-window';


import styles from './Table.module.css'
import Filter from './Filter.js';
import ColumnSelector from './ColumnSelector.js';
//import { useContainerSize } from './utils/useContainerSize';
//import { useScrollbarSize } from './utils/useScrollbarSize';


import {
  useTable,
  useFlexLayout,
  useGlobalFilter,
  useSortBy,
  useResizeColumns,
} from 'react-table';



const TableContext = createContext(null);




const ForcedScrollWrapper = React.memo((props, ref) => (
  // Instead of handling complexity with when the scrollbar is/isn't visible for this basic tutorial,
  // instead force the scrollbar to appear even when it isn't needed. Not great, but out of scope.
  <div {...props} style={{ ...props.style, overflowY: 'scroll' }} forwardedRef={ref}></div>
));

/**
 * By memoizing this, we ensure that react-window can recycle rendered rows that haven't changed when new data comes in.
 */
const BodyRow = React.memo(({ index, style }) => {
  const { rows, instance: { prepareRow } } = React.useContext(TableContext);
  const row = rows[index];
  prepareRow(row);
  return (
    <div className={styles.Row} {...row.getRowProps({ style })}>
      {row.cells.map(cell => (
        <div className={styles.BodyCell} {...cell.getCellProps()}>
          {cell.render('Cell')}
        </div>
      ))}
    </div>
  );
});








		//const response = await axios.get("/api/v1/orders/" + token_key);
		//const bids = response.data.orders.bids;	
		


/**
 * Determines the dimensions of the browser scrollbars when they would be shown.
 * Browsers may overlay scrollbars rather than putting them in-layout. In those cases they're treated as 0-width/height.
 */
 export function useScrollbarSize() {
  return React.useMemo(() => {
    const scroller = document.createElement('div');
    scroller.setAttribute('style', 'width: 100vw; height: 100vh; overflow: scroll; position: absolute; top: -100vh;');
    document.body.appendChild(scroller);
    const width = scroller.offsetWidth - scroller.clientWidth;
    const height = scroller.offsetHeight - scroller.clientHeight;
    document.body.removeChild(scroller);
    return { width, height };
  }, []);
}



export function useContainerSize(el) {
  return React.useMemo(() => {
    if (el) {
      return {
        width: el.clientWidth,
        height: el.clientHeight,
      };
    }

    return { width: 0, height: 0 };
  }, [el]);
}




const OrderTable = React.memo(({ data: { columns, data } }) => {

	
	
	const reactTable = useTable({
		columns,
		data,
		autoResetSortBy: false,
		autoResetResize: false,
		disableSortRemove: true,
		initialState: {
        	sortBy: [{ id: 'bidTime', desc: false }],
        },
    },
    useFlexLayout,
    useGlobalFilter,
    useSortBy,
    useResizeColumns
  );
		  
  const {
	  getTableProps,
	  getTableBodyProps,
	  headerGroups,
	  rows,
	  allColumns,
	  setGlobalFilter
	} = reactTable;
		
	const [latestFilter, setLatestFilter] = useState('');
		
		
	
		
	useEffect(() => {
    	setGlobalFilter(latestFilter);
  	}, [setGlobalFilter, latestFilter, data]);
  		
  	const { width: scrollbarWidth } = useScrollbarSize();
  	const [fillContainer, setFillContainer] = useState(null);
  	const fillContainerRef = useCallback((el) => setFillContainer(el), []);
  	const { height: fillHeight } = useContainerSize(fillContainer);
  
  	const [visibleStart, setVisibleStart] = useState(1);
  	const [visibleStop, setVisibleStop] = useState(1);
  		
  	const viewportDetails = useMemo(() => {
		  const count = visibleStop - visibleStart + 1;
		  let text = `Showing ${visibleStart + 1} - ${visibleStop + 1} / ${rows.length} records`;
		  if (rows.length === 500) text += ' (most recent only)';
		  if (count <= 0) {
			  text = 'No records to show';
		  } else if (count >= rows.length) {
			  text = '\xa0'; // non-breaking space
	      }
	    return text;
	  }, [rows.length, visibleStart, visibleStop]);
  
  	const onRowsRendered = useCallback(({ visibleStartIndex, visibleStopIndex }) => {
		  setVisibleStart(visibleStartIndex);
		  setVisibleStop(visibleStopIndex);
	}, []);
		
	const context = useMemo(() => ({
	    instance: reactTable,
	    rows,
	    // By also watching reactTable.state specifically, we make sure that resizing columns is reflected immediately.
	    // eslint-disable-next-line react-hooks/exhaustive-deps
	}), [reactTable, rows, reactTable.state]);
  
  
		//const writeStream = createWriteStream("dummy.pdf");
		//data.pipe(writeStream); 
		
		/*var tdata = [];
		for(let i=0; i < bids.length; ++i){
			var n = bids[i].wallet_address[i].length;
			var hn = bids[i].hash.length;
			var addr = bids[i].wallet_address.substring(0,2) + '...' + bids[i].wallet_address.substring(n-2,n);
			var hash = bids[i].hash.substring(0,2) + '...' + bids[i].hash.substring(hn-2,hn);
			var hash_link = `<a href=${bids[i].tx_link}>${hash}</a>`;
			tdata.push({address: addr, price: bids[i].price, qty: bids[i].quantity, exp: bids[i].expires_in, active: bids[i].active, hash: hash_link});                                                     
		}
		setBidTableData(tdata);
		*/
		
	return (
		<TableContext.Provider value={context}>
	      <div className={styles.root}>
	      {/*
	      	<header>
	          <ColumnSelector columns={allColumns} />
	          <Filter onChange={setLatestFilter} />
	        </header>
	      */}
	        
	        <div className={styles.fill} ref={fillContainerRef}>
	          <div {...getTableProps()} className={styles.Table}>
	            <div className={styles.TableHead}>
	              {headerGroups.map((group, gi) => (
	                <div className={styles.Row} {...group.getHeaderGroupProps()}>
	                  {group.headers.map((column, ci) => (
	                    <div className={styles.HeaderCell} {...column.getHeaderProps({
	                      style: (gi === headerGroups.length - 1 && ci === group.headers.length - 1)
	                      ? { marginRight: scrollbarWidth }
	                      :  {}
	                    })}>
	                      <div {...column.getSortByToggleProps()}>
	                        {column.render('Header')}
	                        <span>
	                          {column.isSorted ? (
	                            column.isSortedDesc ? ' 🔽' : ' 🔼'
	                          ): ''}
	                        </span>
	                      </div>
	                      <div {...column.getResizerProps()} className={[styles.ResizeHandle, column.isResizing && styles.ResizeHandleActive].filter(x=>x).join(' ')}>
	                        &#x22EE;
	                      </div>
	                    </div>
	                  ))}
	                </div>
	              ))}
	            </div>
	            <div className={styles.TableBody} {...getTableBodyProps()}>
	              <FixedSizeList
	                outerElementType={ForcedScrollWrapper}
	                itemCount={rows.length}
	                height={fillHeight - 56}
	                itemSize={34}
	                onItemsRendered={onRowsRendered}
	              >
	                {BodyRow}
	              </FixedSizeList>
	            </div>
	          </div>
	        </div>
	        <div className={styles.ViewportDetails} style={{ marginRight: scrollbarWidth }}>
	          {viewportDetails}
	        </div>
	      </div>
	   </TableContext.Provider>
	)
});

export { OrderTable };