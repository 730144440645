import React from 'react'




const SkeletonBox = ({ children } ) =>  {
  return (
    <div
      style={{
        border: '0px solid #ccc',
        display: 'block',
        lineHeight: 2,
        padding: '1rem',
        marginBottom: '0.5rem',
        width: "100%",
      }}
    >
      {children}
    </div>
  );
}


export default SkeletonBox;