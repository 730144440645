
import React from 'react';
import { Input, Label } from './LineInput';


const RadioOption = (props) => {
	
	return(
		<div className="form-check mr-4">
			<Input 
				class={props.class} 
				type={props.type}  
				name={props.name}
				id={props.id}
				value={props.value}
				checked={props.checked}
				onChange={props.onChange}
				onClick={props.onClick}				
			>
			</Input> <span className="ml-1" value={props.value} onClick={props.onClick}>{props.label}</span>
		</div>
	);
};




const RadioOptionGroup = (props) => {
	
	var components = [];

	
	for(const item of props.items){

		components.push(
			<RadioOption
					key={item.id}
					class="form-check-input" 
					type="radio" 
					name={props.name}
					id={item.id}
					value="contract" 
					label={item.label}
					checked={item.checked}
					onChange={item.onChange}
					onClick={item.onClick}
				> 
				</RadioOption>
		);
	}
	
	return (
		<div className="form-group mt-0">
			<Label class="mb-0" for={props.group_id} text={props.group_label}></Label>
     		<div className="d-flex" id={props.group_id}>
				{ components}
			</div>
		</div>
		
	);
};






const ButtonGroupSelect = (props) => {

	const navitems = [];

	if(props.isMobileWidth){
		for(let i=0; i <  props.navoptions.length; ++i){
			var item = props.navoptions[i];
			navitems.push(<div key={i}><a type="button" data-index={i} data-sector={item}  onClick={props.onClick} onMouseEnter={props.onMouseEnter} className={'btn-sm btn-light font-16 font-weight-normal ' + (props.classActive[i] == true ? props.classActiveValue : '') }>{item}</a></div>);
		}
	}else{
		for(let i=0; i <  props.navoptions.length; ++i){
			var item = props.navoptions[i];
			navitems.push(<div key={i} className="col"><span><a type="button" data-index={i} data-sector={item}  onClick={props.onClick} onMouseEnter={props.onMouseEnter}  className={'btn-sm btn-light font-16 font-weight-normal ' + (props.classActive[i] == true ? props.classActiveValue : '') }>{item}</a></span></div>);
		}
	}
	
						
	return (
		<div className="form-group">
            <div className="align-items-center">
	            {props.isMobileWidth ?
					<div>{ navitems }</div> : <div className="row mt-md-0"> {navitems}</div>
				}
            </div>
        </div>
	);
};




export default RadioOption;
export { RadioOptionGroup , ButtonGroupSelect};