/**
 * 
 */

 
import React, {useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { connectWallet, getCurrentWalletConnected, addWalletListener } from "../util/interact.js";
 
import Parser from 'html-react-parser';
import MainNav from '../util/MainNav';
import BannerNav, { BannerAction } from '../util/BannerNav';
import axios from 'axios';
import Footer from "../util/Footer";
import { useLocation } from 'react-router-dom';

	

export default function Marketplace(props){
	
	const sub_title = ``;
	const [collectionCategories, setCollectionCategories] = useState([]);
	const [featureImgURLs, setFeatureImgURLs] = useState([]);
	const [isSetFeatured, setIsSetFeatured] = useState(false);
	const [wallet, setWallet] = useState({});
	
	const collectionItemsGrid = [];
	
	const navigate = useNavigate();
	
	
	
	const handleWalletChanged = (updatedStatus) => {
		setWallet(updatedStatus);
	}
	
	const handleStatusChanged = (updatedStatus) => {
		setWallet(updatedStatus);
	}
	


	const updateWallet = async() => {
		const walletResponse = await getCurrentWalletConnected();
	    setWallet(walletResponse);
	    addWalletListener(handleWalletChanged, handleStatusChanged);
	}





	
	const uploadCollectionData = async(event) => {
		
		try{
	

			const response = await axios.get("/api/v1/collections");
			
			
			var collection_categories = response.data.collections.map(x=> (x.service_category.charAt(0).toUpperCase() + x.service_category.slice(1)));
			collection_categories.push('All');
			collection_categories.sort();
			
			setCollectionCategories(collection_categories);
			
			var imageUrls = [];
			response.data.collections.forEach(function(items_by_cat){

				items_by_cat.collections.forEach(async(item) => {

					await fetch(item.metadata).then((response) => response.json())
				   	.then((responseJson) => {
						   const image_url = responseJson.image;  
	
						   imageUrls.push({collection: responseJson.name, id: responseJson.id, image_url: image_url});
						   setFeatureImgURLs(imageUrls);
						   
						   setIsSetFeatured(true);
						   //handleSetNFTData(responseJson);
				   	}).catch((error) => {
						   console.error(error);
				   	});
		   	
				});
			});
		} catch(error){
			console.log(error);
		}
	};
	
	const handleFeatureClicked = async(event) => {
	
		const collection = event.target.getAttribute('value').toLowerCase();


		const response = await axios.get('/api/v1/collections/' + collection);

		const contracts = response.data.collection.collection.contracts;
		var tokens_by_group = JSON.parse(response.data.collection.tokens);
		//var token_groups = JSON.parse(response.data.collection.collection.token_groups);
		
		
		const group_conditions = JSON.parse(response.data.collection.group_conditions);

		
		var tok_by_date = new Map();
		
		for(let i=0; i < contracts.length; ++i){
		
			var contract_group_conditions = JSON.parse(group_conditions[contracts[i].id]);

			for(let j=0; j < contracts[i].token_groups.length; ++j){
				
				
				const group_id = contracts[i].token_groups[j].group_id;
				
				const token_group_conditions = contract_group_conditions[group_id];
				
		
				const token_group = contracts[i].token_groups[j];
				var time_tokens = tokens_by_group[group_id];
				

				if(contracts[i].contract_type == 'time'){
					response.data.collection.reserve = {}
					for(let j=0; j < time_tokens.length; ++j){
						var res_start = time_tokens[j].properties.reserve_start;

						if(res_start){
							if(tok_by_date.has(res_start[0].date)){
								var value = tok_by_date.get(res_start[0].date);
								value.push(time_tokens[j]);
								tok_by_date.set(res_start[0].date, value);
							}else{
								tok_by_date.set(res_start[0].date, [time_tokens[j]]);
							}
						}
					}
					

					response.data.collection.reserve.tokens = time_tokens;
					response.data.collection.reserve.token_by_date = tok_by_date;
					response.data.collection.reserve.group_conditions = token_group_conditions;
			
				}else if(contracts[i].contract_type == 'value' && token_group.token_type == 'offering'){
					response.data.collection.offering = {}
					response.data.collection.offering.tokens = tokens_by_group[group_id];
					response.data.collection.offering.group_conditions = token_group_conditions.service_conditions;
				}
			}
		}
		
		//response.data.collection.tokens = tokens_by_group;
		//response.data.collection.tokens = tokens;
		
		response.data.wallet = wallet

		navigate('/collection/' + collection.split(' ').join('_'), {state: response.data});
	
	}
	
	
	const displayFeatured = () => {

		for(let i = 0; i < featureImgURLs.length; i++) {
			const item = featureImgURLs[i];

			collectionItemsGrid.push(
	    	    <div key={i} className="col-md-4 col-lg-3">
		    		<div className="card">
		    		    <a href='#' onClick={handleFeatureClicked}>
		    		    	<img className="card-img-top feature-item" src={item.image_url} alt="Image" value={item.collection} style={{backgroundColor: '#b1b7ff'}} />
		    		    </a>
		    		</div>
	    	    </div>
	    	);
		}

	}
	
	useEffect(() => {
	
    	uploadCollectionData();
    	updateWallet();
  	}, []);
  
  	displayFeatured();
	
	
	return (
		<>
			<MainNav userData={props.userData} brandimagepath={"/brand_assets/Meritic.png"} brandimageheight={"40"}> </MainNav>
	
		  	<section className="p-2" style={{
				backgroundImage: "url('/images/banner_world_optimized.png')",
				backgroundPosition: 'center',
				backgroundSize: 'cover',
		        backgroundRepeat: 'no-repeat',
		        height: '650px'
				}}>
				
				
				<main class="sm-auto">
					<BannerNav 
						title_class="display-6 meritic_mpurple" 
						title="" 
						subtitle={Parser(sub_title)} 
						subtitle_class="lead meritic_dblue font-20" 
						navoption_ids={collectionCategories.map(x => ('/#' + x))} 
						navoptions={collectionCategories} 
					>
					</BannerNav>
					<div className="container">
						<div className="row trans-feature">
	                        <div className="col-lg-12 position-relative">
	                            <div className="card">
	                                <div className="card-body px-0">
	                                    <div className="row mt-4 px-4 mx-auto" style={{maxHeight: '700px',  overflowY: 'auto'}}>
	                                    	{collectionItemsGrid}
	                                    </div>
										<i className="close mdi mdi-close" style={{position: 'absolute', top: '20px',  right: '24px', fontSize: '20px'}}></i>
	                                </div>
	                            </div>
	                        </div>
	                    </div>
					</div>
				</main>
			</section>

	        <Footer />
		</>
	);
	
}