/**
 * 
 */
import React,  { useRef, useEffect, useLayoutEffect, useState } from 'react';
import * as d3 from 'd3';
import _ from 'lodash';


//const d3 = await import('d3');



function timeStringToFloat(time) {
  var hoursMinutes = time.split(/[.:]/);
  var hours = parseInt(hoursMinutes[0], 10);
  var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
  return (hours + minutes / 60).toFixed(2);
}


const verifyWeekSchedule = (hos) => {
	/*
	dayofweek: data.dayofweek, 
	start: data.start, 
	end: data.end,
	vacancy: data.vacancy,
	reoccurance: data.reoccurance,
	recommended_holder: data.recommended_holder,
	*/
	var hosbyday = new Map();
	for(let i=0; i < hos.length; ++i){
		if(hos[i].dayofweek != 'mon-fri' && hos[i].dayofweek != 'sat-sun'){
			if(!hosbyday.has(hos[i].dayofweek)){ hosbyday.set(hos[i].dayofweek, []); }
			hosbyday.get(hos[i].dayofweek).push(hos[i]);
		}
	}									  
	
	const akeys = Array.from(hosbyday.keys());
	
	var failcase = new Map();
	
	for(let i=0; i < akeys.length; ++i){
		const times = hosbyday.get(akeys[i]);
		
		for(let j=0; j < times.length; ++j){
			for(let k=0; k < times.length; ++k){
				if(j < k){
					if(times[j].start && times[k].start){
						const tfi_start = timeStringToFloat(times[j].start);
						const tfi_end = timeStringToFloat(times[j].end);
						
						const tfk_start = timeStringToFloat(times[k].start);
						const tfk_end = timeStringToFloat(times[k].end);
						
						if((tfi_start <= tfk_start && tfi_end >= tfk_start) || (tfi_start < tfk_start &&  tfi_end >= tfk_end)){
							if(!failcase.has(akeys[i])){ failcase.set(akeys[i], []); }
							failcase.get(akeys[i]).push(times[j]);
							failcase.get(akeys[i]).push(times[k]);
						}
					}
				}
			}
		}
	}

	
	const fkeys = Array.from(failcase.keys());
	var caseindex = [];
	
	for(let i=0; i < fkeys.length; ++i){
		const cases = failcase.get(fkeys[i]);
		for(let j=0; j < cases.length; ++j){
			for(let k=0; k < hos.length; ++k){
				if(_.isEqual(hos[k], cases[j])){
					caseindex.push(k);
				}
			}
		}
	}
	
	return caseindex;
							  
}






const WeekSchedule = (props) => {


	const d3Container = useRef(); 
	const chartConfig = useRef();
	
	const [chartDrawn, setChartDrawn] = useState(false);
	const [title, setTitle] = useState()
	var width = props.width
	var height = props.height
	

	var days = ['', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	
	
	//var week_mins_thirty = Array(8).fill().map((element, index) => index);
	
	var week = props.timeSlots;

	
	useLayoutEffect(() => {
		if (!chartDrawn) {
			/*const svg = d3.select(d3Container.current)
						.attr('width', width)
	   		 			.attr('height', height);*/
	
			const table = d3.select(d3Container.current);
			const header = table
							.append('thead');
							
							
			const body = table.append('tbody').style('overflow-y', 'auto');

			const htitle = header
			  .append('tr').attr('class', 'month')
			  .append('td')
			  .attr('colspan', 8)
			  .style('text-align', 'center')
			  .selectAll('h5')
				.data([props.title])
				.enter()
			  .append('h5')
			  .text(d => d)
			  .style('color', '#CCCCE5')
			
			header
			  .append('tr')
			  	.attr('class', 'weekdays')
			  .selectAll('td')
			  .data(days)
			  .enter()
			  .append('td')
			    .style('text-align', 'center')
			    .text(d => d);
			    
    		week.forEach((thirty_min_arr, i) => {
				body
				    .append('tr').attr('class', i % 2 == 0 ?  'halfhour' : 'days').style('height', '15px')
				    .selectAll('td')
				    .data(thirty_min_arr)
				    .enter()
				    .append('td').attr('rowspan', function(d) {
						return (d.class.includes('active') ? 2 : 1);
					}).attr('class', function(d, j) {
						return d.class  + ' row_' + i;
					}).html(function (d, j) {
						 if(d.curr_bid && d.curr_bid.value && i % 2 == 0){
							 var dcl = d.class + ' row_' + i;
							 return `<span class="${dcl}" style="font-size:17px;"><img src="/images/usdc_blue.png" alt="usdc" style="margin-right:4px;"/>${d.curr_bid.value} USDC</span>`;
						 }else if(d.text){
							 return `<span style="font-size:14px">${d.text}</span>`;
						 }
					})
				    
			});
    		
			setChartDrawn(true);
			setTitle(props.title)
			chartConfig.current = {table, htitle, body};
		}
	}, []);
	
	
	
	
	useEffect(() => {
	    if (!chartConfig.current) return;
	
	   	//if(props.title){
	   	const { table, htitle, body } = chartConfig.current;
	   	
    	var hselect = table.selectAll('h5').data([props.title]);
    	hselect.exit().remove();
    	
    	hselect
		  .text(d => d)
		  .style('color', '#CCCCE5');


		body.selectAll('tr').each(function(row, i){
			var crow = body.selectAll('.row_' + i).data(props.timeSlots[i]);
			crow.exit().remove();
			
			crow
				.attr('rowspan', function(d) {
					return (d.class.includes('active') ? 2 : 1);
				}).attr('class', function(d, j) {
					return d.class  + ' row_' + i;
				}).html(function (d) {
				 if(d.curr_bid && d.curr_bid.value && i % 2 == 0){
					 var dcl = d.class + ' d-flex justify-content-center';
					 return `<div class="${dcl}" style="margin-top:3px;margin-bottom:-2px"> <span style="font-size:14px; z-index:10">${d.curr_bid.value} USDC</span></div>`;
				 }else if(d.text){
					 return `<span style="font-size:12px">${d.text}</span>`;
				 }
			});
	
			
		});

			
		//}
	    
	
  	}, [props.timeSlots]);
  	
  	
  	
  	
  
	return (
		<>
			<div className="tableWrap rounded-4" style={{height: "300px"}}>
			   <table className="d3-component" ref={d3Container} style={{width:'100%', height: '100%'}} />
			</div>
			
		</>
    );
}


export { WeekSchedule, verifyWeekSchedule };
