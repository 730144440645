
import React, {useState} from 'react'






const ValueCurrency = (props) => {
	



	
	return (
		<div className="meritic_dblue">
			<h5>Value currency</h5>
	  		<p>This type of smart contract creates tokens that stores monetary value spent on your service offerings. Select one of the following crypto currencies to store token value. </p>
	  		<div className="row">
				<div className="col-sm-12 mt-3">
					<div className="btn-group text-center d-flex" role="group" aria-label="Basic radio toggle button group" style={{width:'50%', margin: 'auto'}}>
						<input type="radio" className="btn-check" autocomplete="off" />
						<a type="button" className={'btn-sm btn-outline-primary ' + (props.valueCurrency == 'usdc' ? 'time-unit-active' : '')} onClick={(event) => {props.onChangeValueCurrency(event, 'usdc')}}>USDC</a>
					
					  	<input type="radio" className="btn-check" autocomplete="off" />
					  	<a type="button" className={'btn-sm btn-outline-primary ' + (props.valueCurrency == 'eth' ? 'time-unit-active' : '')} onClick={(event) => {props.onChangeValueCurrency(event, 'eth')}}>ETH</a>
					</div>
				</div>
			</div>
		</div>
	);
	
}



export { ValueCurrency }