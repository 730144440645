
import React, {useState, useEffect, useRef, useCallback } from 'react'
import AccordionItem from '../util/AccordionItem';
import {Label, Input, LineInput} from '../util/LineInput';
import RadioOption, { ButtonGroupSelect, RadioOptionGroup } from '../util/RadioOption';
import axios from 'axios';
//import Select from "react-dropdown-select";
import FileUpload from '../util/FileUpload';
import Paragraph from '../util/Paragraph';
import { DefaultBanner } from '../vis/DefaultBanner';
//import Modal from 'react-modal';
import Skeleton from 'react-loading-skeleton';
import SkeletonBox from '../util/SkeletonBox';
import { BrowserProvider }  from 'ethers';
import Icon from "@mdi/react";
import { mdiPlus } from "@mdi/js";
import detectEthereumProvider from '@metamask/detect-provider';
import { ethers } from "ethers";
import MetaMaskOnboarding from "@metamask/onboarding";
import Modal from 'react-modal';
import Select from 'react-select';
import { flushSync } from 'react-dom';
import { useFormik } from 'formik'






const customStyles = {
	  content: {
	    top: '50%',
	    left: '50%',
	    right: 'auto',
	    bottom: 'auto',
	    marginRight: '-50%',
	    borderRadius:'25px',
	    transform: 'translate(-50%, -50%)',
	  },
	};
	
	
const serviceType = {
	fitness: ['Personal trainer', 'influencer', 'studio', 'gym'],
	health: [],
	nutrition: [],
	fashion: [],
	lifestyle: [],
	travel: []
}




const thumb = {
  display: 'inline-flex',
  borderRadius: 5,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 50,
  height: 50,
  padding: 4,
  boxSizing: 'border-box'
};

const clickedThumb = {
  display: 'inline-flex',
  borderRadius: 5,
  border: '1px solid #692727',
  marginBottom: 8,
  marginRight: 8,
  width: 50,
  height: 50,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};










var walletAddressOptions = [{ value: 'admin', label: 'Admin' }, { value: 'revenu', label: 'Revenue' } ];



const ServiceWalletModal = (props) => {
	
	var walletAddresses = useRef(null);
	
	const [displayMessage, setDisplayMessage] = useState('Select your Admin and Revenue wallet addresses');
	const [listAddresses, setListAddresses] = useState(false);
	const [revenueSelect, setRevenueSelect] = useState();
	const [adminSelect, setAdminSelect] = useState();
	const [adminIndex, setAdminIndex] = useState();
	const [revenueIndex, setRevenueIndex] = useState();
	const [chosenSelection, setChosenSelection] = useState(new Array(props.wallet.address.length, null)) //new Array(props.wallet.address.length));
	const [displayedWallets, setDisplayedWallets] = useState(walletAddresses.current);
	const [addWalletInstructions, setAddWalletInstructions] = useState(walletAddresses.current);
	
	
	
	
	const onSelectWalletOption = (selection) => {
		if(selection.value.split(':')[0] == 'admin'){
			const selIndex = parseInt(selection.value.split(':')[1]);
			const address = props.wallet.address[selIndex];
			const chainId = props.wallet.chainId;
			
			setAdminSelect({chainId: chainId, address: [address]});
	
			setAdminIndex(selIndex);
			flushSync(() => { 
				setChosenSelection([...chosenSelection.slice(0, selIndex), selection, ...chosenSelection.slice(selIndex + 1)]);
			});
			
		}else if(selection.value.split(':')[0] == 'revenue'){
			const selIndex = parseInt(selection.value.split(':')[1]);
			const address = props.wallet.address[selIndex];
			const chainId = props.wallet.chainId;
		
				setRevenueSelect({chainId: chainId, address: [address]});

				setRevenueIndex(selIndex);
			flushSync(() => { 
				setChosenSelection([...chosenSelection.slice(0, selIndex), selection, ...chosenSelection.slice(selIndex + 1)]);
			});
			
		}
	}
	
	const onAcceptSelections =(event)=>[
		props.onChangeServiceWallet({
			revenue: revenueSelect,
			admin: adminSelect
		})
	]
	
	const onClearSelections = (event) => {
			flushSync(() => { 
				setAdminSelect(undefined);
				setAdminIndex(undefined);
				setRevenueSelect(undefined);
				setRevenueIndex(undefined);
				setChosenSelection(new Array(props.wallet.address.length, null));
			});
		
	}
	

	
	useEffect(()=> {
		var addresses = [];
		if(Array.isArray(props.wallet.address)){
			addresses = props.wallet.address;
		}else{
			addresses.push(props.wallet.address)
		}
		
		


		if(props.wallet && addresses && addresses.length < 2){
			setDisplayMessage('Add another wallet address to your wallet');
			setAddWalletInstructions(true);
		}else if (addresses && addresses.length >= 2){
			setDisplayMessage('Select two addresses one for your revenue, another for your admin account');
			setAddWalletInstructions(false);
			var temp  = [];
		
			addresses.forEach(function(x, i){
		
				temp.push(
					<div className="row">
						<div className="form-group-sm col-sm">
							<a key={i} type="button" className="btn-sm btn-light btn-rounded" onClick={() => {navigator.clipboard.writeText(x);}}>
			                	{ x.substring(1, 5) + ' ... ' +  x.substring(x.length - 4, x.length)}
			                </a>
			            </div>
			            <div key={i + 'i'} className="form-group-sm col-sm">
				            {adminIndex === undefined && revenueIndex === undefined ?
				            	<Select key={i + 'is'} className="form-group-sm" value={chosenSelection[i]} options={
														[{ value: 'admin:' + i, label: 'Admin' }, { value: 'revenue:' + i, label: 'Revenue' } ]
											} onChange={onSelectWalletOption} /> : 
								adminIndex === undefined ?
								<Select key={i + 'is'} className="form-group-sm" value={chosenSelection[i]} options={
														[{ value: 'admin:'+i, label: 'Admin' } ]
											} onChange={onSelectWalletOption} />	:
				            	revenueIndex === undefined ?
				            	<Select key={i + 'is'} className="form-group-sm"  value={chosenSelection[i]} options={
														[{ value: 'revenue:' + i, label: 'Revenue' }  ]
											} onChange={onSelectWalletOption} />	: 
								<Select key={i + 'is'} className="form-group-sm" value={chosenSelection[i]} options={
														[{ value: 'admin:' + i, label: 'Admin' }, { value: 'revenue:' + i, label: 'Revenue' } ]
											} />
				            }
			            </div>
			            
		            </div>
		           );
			});
			walletAddresses.current = temp;
			setListAddresses(true);
			setDisplayedWallets(walletAddresses.current);
		}else{
			setDisplayMessage('Connect to your wallet');
		}
		
	}, [adminSelect, revenueSelect, revenueIndex, adminIndex, chosenSelection, props.wallet.address])


	
	return (
		<Modal isOpen={props.walletModalIsOpen} style={customStyles}>
    		<div className="float-right">
    			<button type="button" className="btn-close" onClick={props.setServiceWalletModalIsOpenToFalse} aria-label="Close"></button>
    		</div>
    		<h5> {displayMessage} </h5>
    		{addWalletInstructions ?
    			<div>
    				<ul>
    					<li>Open your wallet in the top right of your browser</li>
    					<li>Click on the account drop-down </li>
    					<li>Select an account then click  Connect</li>
    					<li>Alternativly you can add a new account </li>
    				</ul>
    			</div> : null
    		}
    		{listAddresses ?
	    		<div className="container">
	    			{displayedWallets}
	    		</div> : null
    		}
    		
    		<Label class="mb-0 mt-3"  text="Your selections"></Label> 
    		<div className="form-group mt-2 mb-0 d-flex">
    			{(adminSelect && adminSelect.address.length > 0) ?
    				<span className="mr-3"><Label class="mb-2"  text="Admin:"></Label> 
    				<span className="ml-1">
    					<a type="button" className="btn-sm btn-light btn-rounded" onClick={() => {navigator.clipboard.writeText(adminSelect.address[0]);}}>
			                	{ adminSelect.address[0].substring(1, 5) + ' ... ' + adminSelect.address[0].substring(adminSelect.address[0].length - 4, adminSelect.address[0].length)}
			                </a>
    				</span>
    				</span> : null
    			}
    			{(revenueSelect && revenueSelect.address.length > 0) ?
    				<span><Label class="mb-2"  text="Revenue:"></Label> 
	    				<span className="ml-1">
	    					<a type="button" className="btn-sm btn-light btn-rounded" onClick={() => {navigator.clipboard.writeText(revenueSelect.address[0]);}}>
				                	{ revenueSelect.address[0].substring(1, 5) + ' ... ' + revenueSelect.address[0].substring(revenueSelect.address[0].length - 4, revenueSelect.address[0].length)}
				                </a>
	    				</span>
    				</span> : null
    			}
    		</div>
    		{(adminSelect && adminSelect.address.length > 0) && ( revenueSelect && revenueSelect.address.length > 0) ? 
    			<div className="form-group mt-2 mb-0">
				 	<hr style={{height:'1px', width:'100%', backgroundColor:'#000000'}}/>
	     			<a type="button" className="align-left btn-sm btn-light" onClick={onClearSelections}  style={{float: 'left'}}>Clear Selections</a> <a type="button" className="align-right btn-sm btn-light" onClick={onAcceptSelections}  style={{float: 'right'}}>Accept Selections</a>
				</div>: null
    		}
    	</Modal>
	)
	
}






const existsSlot = async(slotId) => {

	const response = await axios.get('/api/v1/slot/' + slotId);
}


const existsServiceName = async(serviceName) => {
	const response = await axios.get('/api/v1/collections/' + serviceName);
	return (response.data.collection);

}

const existsServicSymbol = async(serviceSymbol) => {

	const response = await axios.get('/api/v1/service_symbol/' + serviceSymbol);
	return response.data;

}


const validate = (values) => {
  const errors = {}

  if(!(/^\d+$/.test(values.slotId))){
	  errors.slotId = 'Only numbers'
  }else  if (!values.slotId) {
	  errors.slotId = 'Required'
  }else if (values.slotId.length < 5) {
	  errors.slotId = 'Too short'
  }else if (values.slotId.length > 5) {
	  errors.slotId = 'Too long'
  }else if(values.slotId.length == 5) {
	  errors.slotId = '';
  }else{
	  errors.slotId = '';
  }
  
  
  if(!values.serviceName) {
	  errors.serviceName = 'Required'
  }else if(values.serviceName.length > 4){
	  errors.serviceName = '';
  }else{
	  errors.serviceName = '';
  }
  
  if(!values.serviceSymbol) {
	  errors.serviceSymbol = 'Required'
  }else if(values.serviceSymbol.length < 4){
	  errors.serviceSymbol = 'Too short'
  }else if(values.serviceSymbol.length > 4){
	  errors.serviceSymbol = 'Too long';
  }else if(values.serviceSymbol.length == 4){
	  errors.serviceSymbol = '';
  }else{
	  errors.serviceSymbol = '';
  }
  
  
  if(!values.serviceDescription || values.serviceDescription.length == 0) {
	  errors.serviceDescription = 'Required'
  }else{
	  errors.serviceDescription =  '';
  }
  
  
  if (!values.contactEmail) {
    errors.contactEmail = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.contactEmail)) {
    errors.contactEmail = 'Invalid email address'
  }else{
	  errors.contactEmail ='';
  }
  
  if (!values.contactFirst) {
  	 errors.contactFirst = 'Required'
  }else{
	  errors.contactEmail ='';
  }
  return errors
}


const SlotId = (props) => {
	
	
  	

	return (
		<>
			<span>
				<Label className="mb-1" text={"Slot Id: "} /> 
				{props.formik.errors.slotId + props.slotExistsMsg ? <span className="text-danger"> {props.formik.errors.slotId + ' ' + props.slotExistsMsg}  </span> : <span> &nbsp;  </span> }
			</span>
			<input 
				className={'form-control form-rounded'} 
				name={'slotId'}  
				value={props.tempDefaultSlotId}
				onChange={(event) => {props.formik.handleChange(event); props.onChangeSlotId(event)}} 
				onBlur={props.formik.handleBlur}/>
		</>
	)
}




export function Service(props){

	//const [walletModalIsOpen, setWalletModalIsOpen] = useState(false);
	const [serviceSector, setServiceSector] = useState([]);
	// const [serviceCategory, setServiceCategory] = useState();
	//const serviceAccordionRef = useRef(null);
	const [serviceOfferingOptions, setServiceOfferingOptions] = useState([]);
	const [serviceOffer, setServiceOffer] = useState([]);
	const [selectedSubsec, setSelectedSubsec] = useState({});
	const [serviceName, setServiceName] = useState("");
	const [bannerImage, setBannerImage] = useState("");
	const [isSetBannerImg, setIsSetBannerImg] = useState(false);
	const [bannerImageURI, setBannerImageURI] = useState(null);
	const [serviceDescription, setServiceDescription] = useState("");
	const [wallet, setWallet] = useState(props.wallet)
	//const [serviceDebitWallet, setServiceDebitWallet] = useState(props.wallet)
	//const [adminAddress, setAdminAddress] = useState()
	const [contactFirstName, setContactFirstName] = useState("");
	const [contactLastName, setContactLastName] = useState("");
	const [contactEmail, setContactEmail] = useState("");
	//const [contentFiles, setContentFiles] = useState([]);
	const [imgThumbs, setImgThumbs] = useState([]);
	const [imageNameMap, setImageNameMap] = useState();
	const [metadataURI, setMetadataURI] = useState();
	const [serviceSymbol, setServiceSymbol] = useState('');
	const [serviceImage, setServiceImage] = useState('');
	const [serviceImageSelect, setServiceImageSelect] = useState([])
	const [genBanner, setGenBanner] = useState(false);
	const [defaultSlotName, setDefaultSlotName] = useState('Default Slot');
	const [defaultSlotId, setDefaultSlotId] = useState();
	const [tempDefaultSlotId, setTempDefaultSlotId] = useState();
	const [sectors, setSectors] = useState([]);
	const [currentSector, setCurrentSector] = useState();
  	const accordionRef = useRef(null);
  	const [height, setHeight] = useState(null);
	const [width, setWidth] = useState(null);
	const [serviceSectorLabel, setServiceSectorLabel] = useState(Object.keys(sectors));
	const [serviceTypeClassActive, setServiceTypeClassActive] = useState(Array(Object.keys(sectors).length).fill(false));
	const [skeleton, setSkeleton] = useState(false);
	const [revenueWallet, setRevenueWallet] = useState();
	const [adminWallet, setAdminWallet] = useState();
	const [addServiceWalletNow, setAddServiceWalletNow] = useState();
	const [addServiceWalletLater, setAddServiceWalletLater] = useState();
	const [serviceModalIsOpen, setServiceModalIsOpen] = useState(false);
	const [serviceSymbolExistsMsg, setServiceSymbolExistsMsg] = useState('');
	const [imgHeight, setImgHeight] = useState();
	const [imgWidth, setImgWidth] = useState();
	const [serviceNameExistsMsg, setServiceNameExistsMsg] = useState('');
	const [slotExistsMsg, setSlotExistsMsg] = useState('')
	const isLongScreen = window.innerWidth <  window.innerHeight;
	const isMobileWidth = window.innerWidth < 600 && isLongScreen;
	
	
	
	
	
	
	const img = new Image();
	img.src = bannerImageURI;
	
	img.onload = () => {
	  var imgAspectRatio = (img.width / img.height);
	  setImgHeight('50px')
	  setImgWidth(50 * imgAspectRatio + 'px');
	};
	

	const formik = useFormik({
    	initialValues: {
      		serviceName: serviceName,
      		slotId: props.tempDefaultSlotId,
      		serviceDescription: serviceDescription,
      		contactFirst: contactFirstName,
      		contactLast: contactLastName,
      		contactEmail: contactEmail
    	},
    	validate
  	});
  	
  	useEffect(() => {
		  setWallet(props.wallet);
	}, [props.wallet]);
	
	

  	
  	
  	const removeItem = (event) => {
		  const sec = event.target.dataset.sector;
		  const val = event.target.dataset.value;
		  var temp = { ...selectedSubsec }
		  temp[sec].delete(val);
		  
		  if(temp[sec].size == 0){
			  delete temp[sec];
		  }
		  setSelectedSubsec(temp);
	}
	  
  	
	const onChangeServiceWallet = (selection) => {
		setRevenueWallet(selection.revenue); //{address: [selection.revenue.address], chainId: props.wallet.chainId});
		setAdminWallet(selection.admin); //{address: [selection.admin.address], chainId: props.wallet.chainId});
	}
	
	
	const onSubSectorClicked = (event, sector) => {
		event.preventDefault(); 
	
		var temp = {...selectedSubsec}
		if(Object.keys(temp).includes(sector)){
			temp[sector].add(event.target.text);
		}else{
			temp[sector] = new Set();
			temp[sector].add(event.target.text);
		}
		
		setSelectedSubsec(temp);
		flushSync(() => {
			setServiceOffer(event.target.text);
		});
		
	}
	
	const sectorOnMouseEnter = (event) => {
		
		serviceOfferingSelect = [...sectors[event.target.dataset.sector]];
		setCurrentSector(event.target.dataset.sector);
		
		/*for(const item of serviceOfferingSelect){
			
			var classSelected = null;
			if(serviceOffer == item) {classSelected = 'service-sector-active'}
			
			tempServiceOptions.push(<a key={item} href="#" 
										onClick={(event) => {onSubSectorClicked(event, event.target.dataset.sector)}} 
										data-value={item} 
										className={'badge badge-light font-16 font-weight-normal mr-1 ' + classSelected}
										style={{color: '#000080'}}
									>{item}</a>);
		}
		setServiceOfferingOptions(tempServiceOptions);
		*/
	}
	
	
	
	
	const serviceSectorClicked = (event) => {
		
		if(serviceTypeClassActive[event.target.dataset.index]){

			serviceTypeClassActive[event.target.dataset.index] = false;
			const rem = Object.keys(sectors)[event.target.dataset.index];
			setServiceSector(serviceSector.filter(x => {  return x !== rem;}));
			setServiceOfferingOptions([]);
		}else{
			serviceTypeClassActive[event.target.dataset.index] = true;
			
			setServiceTypeClassActive(serviceTypeClassActive);
			const currSect = Object.keys(sectors)[event.target.dataset.index];
			const selectedSectors = [...serviceSector, currSect];
			setServiceSector(selectedSectors);
			
			for(const idx in selectedSectors){
				serviceOfferingSelect = [...sectors[selectedSectors[idx].toLowerCase()]];
			}
	
			for(const item of serviceOfferingSelect){
				var classSelected = null;
				if(serviceOffer == item) {classSelected = 'service-sector-active'}
				
				tempServiceOptions.push(<a key={item} href="#" 
											onClick={(event) => {onSubSectorClicked(event, currSect)}} 
											data-value={item} 
											className={'badge badge-light font-16 font-weight-normal mr-1 ' + classSelected}
											style={{color: '#000080'}}
										>{item}</a>);
			}
			setServiceOfferingOptions(tempServiceOptions);
			
		}

		// setServiceSector([...Array(serviceSectorLabel.length).keys()].map(idx => (serviceTypeClassActive[idx] ? serviceSectorLabel[idx] : null )).filter(x => x))
		//setServiceSector([...Array(serviceSectorLabel.length).keys()].map(idx => (serviceTypeClassActive[idx] ? serviceSectorLabel[idx] : null )).filter(x => x))
		
	}
	
	const getThumbs = (thumbs) =>{
		setImgThumbs([...thumbs]);
	}
	
	useEffect(() => {
	}, [genBanner])




	useEffect(() => {
		var tempServiceOptions = [];
		if(currentSector){
			serviceOfferingSelect = [...sectors[currentSector]];

			for(const item of serviceOfferingSelect){
	
				var classSelected = null;
				if(serviceOffer == item) {classSelected = 'service-sector-active'}
				
				tempServiceOptions.push(<a key={item} href="#" 
											onClick={(event) => {onSubSectorClicked(event, currentSector)}} 
											data-value={item} 
											className={'badge badge-light font-16 font-weight-normal mr-1 ' + classSelected}
											style={{color: '#000080'}}
										>{item}</a>);
			}
			
			setServiceOfferingOptions(tempServiceOptions);
		}
		
		
	}, [currentSector, serviceOffer])
	
	
	
	var serviceOfferingSelect = [];
	var tempServiceOptions = [];
	
	
	
	
	
	
	
	
	const updateService = (service) => {

		var sectorBoolean = Array(serviceSectorLabel.length).fill(false);
		

		for(let key of Object.keys(service.service_type)){
			service.service_type[key] = new Set(service.service_type[key]);
		}
		
		setDefaultSlotName(service.default_slot.name);
		setDefaultSlotId(service.default_slot.slot_id);
		
		for(let x of service.sectors){
			sectorBoolean[serviceSectorLabel.indexOf(x)] = true;
		}
	

		flushSync(() => {
			setSelectedSubsec(service.service_type);
			setServiceTypeClassActive(sectorBoolean);
			setServiceSector(Object.keys(service.service_type));
		});
		
		
		setServiceName(service.name);
		setServiceSymbol(service.symbol.toUpperCase());
		setServiceDescription(service.description);
		setContactEmail(service.contact_email);
		setContactLastName(service.contact_lastname);
		setContactFirstName(service.contact_name);
		setServiceOffer(service.service_type); 

		var revenueWallet = service.revenue_wallet;
		revenueWallet.address = [revenueWallet.address ]
		setRevenueWallet(revenueWallet);
		var adminWallet = service.admin_wallet;
		adminWallet.address = [adminWallet.address];
		setAdminWallet(adminWallet);

		setMetadataURI(service.metadata_uri);
		setIsSetBannerImg(true);
		
		/*const map = new Map(Object.entries(JSON.parse(service.image_name_map)));
		const image_name_map = new Map(Array.from(map, a => a.reverse()));
		setImageNameMap(image_name_map)*/
		var imageURI = null;
		fetch(service.metadata_uri)
		.then((response) => response.json())
		.then((responseJson) => {
			flushSync(() => {
				setBannerImageURI(responseJson.image);
			})
			imageURI = responseJson.image;
			
		})
		.catch((error) => {
			console.error(error);
		});
		
	
		props.handleServiceChange({
			contactLastName: service.contact_lastname,
			contactEmail: service.contact_email,
			contactFirstName: service.contact_name,
			serviceAdminWallet: adminWallet,
			serviceRevenueWallet: revenueWallet,
			serviceDescription: service.description,
			bannerImageURI: imageURI,
			serviceName: service.name,
			serviceOffer: service.service_type,
			defaultSlotId: service.default_slot.slot_id,
			defaultSlotName: service.default_slot.name,
			serviceSymbol: service.symbol.toUpperCase(),
			serviceSector: Object.keys(service.service_type),
			metadataURI: service.metadata_uri,
			isComplete: true
		});
	}
	
	const getServiceSectors = async() => {
		const response = await axios.get('/api/v1/service_sectors/');
		var sobj = {};

		for(let i=0; i < response.data.length; ++i){
			const sect = response.data[i];
			
			if(!Object.keys(sobj).includes(sect.sector)){
				sobj[sect.sector] = [];
			}
			if(sect.subsector){
				sobj[sect.sector].push(sect.subsector);
			}
		}
		setServiceSectorLabel(Object.keys(sobj));

		setSectors(sobj);
		/*
		if(Object.keys(sobj).length > 0){
			var temp = {...props.showContinue};
			temp.contract = true;
			props.setShowContinue(temp);
			
			props.setServiceLoaded(true);
		}*/
		return sobj;
	}
	
	
	
	
	
	
	async function getServiceForAddress() {

		const params = new URLSearchParams(props.wallet);
	
		const response = await axios.get('/api/v1/collections/', { params });
	
		if(response.data.collections.length > 0){
			var service = response.data.collections[0].collections[0];
			service.service_type = JSON.parse(service.service_type);
			updateService(service);
			
		}else{
			accordionRef.current.click();
		}
	}
	
	
	
	
	useEffect(() => {
		getServiceSectors()
		if(props.wallet){
			getServiceForAddress();
		}
	}, []);
	

	
		
	const onServiceSubmit = async(event) => {

		event.preventDefault();
		setSkeleton(true);
		var formData = new FormData();

		formData.append("banner_image", props.serviceObj.bannerImage);
		
		/*for(const item of props.serviceObj.contentFiles){
			formData.append("content_image", item);
		}*/

		try{
	
			var strdata = JSON.stringify({
				service: props.serviceObj
			});
	
			formData.append("strdata", strdata);
			const response = await axios.post("/api/v1/collections", formData, {  "Content-Type": "multipart/form-data" });
		
			setSkeleton(false);
			//accordionRef.current.click();
	
			var service = response.data.collection[0].collections[0];
			service.service_type = JSON.parse(service.service_type);
			updateService(service);
		
			var temp = {...props.showContinue};
			temp.contract = true;
			props.setShowContinue(temp);
			
			props.setServiceLoaded(true);
		} catch(error){
			setSkeleton(false);
		}
		accordionRef.current.click();
	}
	

	
	useEffect(() => {
		var kselectedSubsec = {...selectedSubsec};
		
		for(let key of Object.keys(kselectedSubsec)){
			kselectedSubsec[key] = Array.from(kselectedSubsec[key]);
		}
		if(!props.serviceObj.isComplete){
				props.handleServiceChange({
					contactLastName: contactLastName,
					//content_files: contentFiles,
					providerEmail: props.providerObj.email,
					providerWallet: props.providerObj.wallet,
					contactEmail: contactEmail,
					contactFirstName: contactFirstName,
					serviceAdminWallet: adminWallet,
					serviceRevenueWallet: revenueWallet,
					serviceDescription: serviceDescription,
					bannerImage: bannerImage,
					serviceName: serviceName,
					serviceOffer: kselectedSubsec,
					defaultSlotId: defaultSlotId,
					defaultSlotName: defaultSlotName,
					serviceSymbol: serviceSymbol,
					serviceSector: serviceSector,
					imgThumbs: imgThumbs,
					imageNameMap: imageNameMap,
					metadataURI: metadataURI,
					serviceImage: serviceImage	
			});
		}
		
		
	
	}, [contactLastName,/* contentFiles,*/ contactEmail, contactFirstName, adminWallet, revenueWallet,
		serviceDescription, bannerImage, serviceName, serviceSymbol, selectedSubsec, imgThumbs, serviceSector,
		props.providerObj.email, props.providerObj.wallet])
	
	
	
	
	
	

	
	
	const div = useCallback(node => {
	    if (node !== null) {
	      setHeight(node.getBoundingClientRect().height);
	      setWidth(node.getBoundingClientRect().width);
	    }
  	}, []);
  
  
	
	function handleServiceNameChange(event){
		setServiceName(event.target.value);
	};

	
	function handleBannerImageDrop(files) {
		setBannerImage(files[0]);
		setIsSetBannerImg(true);
	};
	

	/*function handleCreateBannerImage(file) {
		setBannerImage(file);
	}*/
	const handleDescriptionChange = (event) => {
		setServiceDescription(event.target.value);
	}
	
	
	/*function handleContentFileDrop(files){
		setContentFiles([...files]);
	};*/
	
	function handleContactFirstNameChange(event){
		setContactFirstName(event.target.value);
	}
	function handleContactLastNameChange(event){
		setContactLastName(event.target.value);
	}
	function handleContactEmailChange(event){
		setContactEmail(event.target.value);
	}
	function handleServiceSymbolChange(event){
		setServiceSymbol(event.target.value.toUpperCase());
	}
	
	
	const onGenerateBanner = (event) => {
		setGenBanner(true);
	}
	const onCloseGenerateBanner = (event) => {
		setGenBanner(false);
	}
	
	const onImageClick = (event) => {
		setServiceImage(event.target.dataset.name);
	}
	
	const onChangeSlotName = (event) => {
		setDefaultSlotName(event.target.value);
	}
	
	const onChangeSlotId = (event) => {
		setTempDefaultSlotId(event.target.value)
		if(event.target.value.length == 5){
			setDefaultSlotId(event.target.value);
		}
		
	}
	
	const onAddRevenueWallet = async(event) => {
		
	}
	const onAddServiceWalletNow = async(event) => {

		setAddServiceWalletLater(false);
		setAddServiceWalletNow(true);
		setServiceModalIsOpen(true);
	}
	const onAddServiceWalletLater = (event) => {
		setAddServiceWalletLater(true);
		setAddServiceWalletNow(false);
		setServiceModalIsOpen(false);
	}
	const changeAddServiceWalletLater = (event) => {
		setAddServiceWalletLater(!addServiceWalletLater);
	}
	const setServiceWalletModalIsOpenToFalse = (event) => {
	
		setServiceModalIsOpen(false);
	}
	
	//const onAddAdminWallet = async(event) => {
		/*var walletProvider = await detectEthereumProvider();
		
		const chainId = await walletProvider.request({method: 'eth_chainId'});
		//const addressArray = await walletProvider.request({ method: "eth_accounts",});
		const addressArray = await walletProvider.request({ method: "eth_requestAccounts"});
		//await walletProvider.getSigner();
		//const provider = new BrowserProvider(window.ethereum);
		const accts = 	request({ method: 'eth_requestAccounts' });*/
		
		//const ether = await window.ethereum.enable()
		//const provider = new ethers.providers.Web3Provider(window.ethereum);
		//const signer = ether.getSigner();

		//const signer = await provider.getSigner();
		//console.log(signer);
		//alert('yes')
		//var walletProvider = await detectEthereumProvider();
		//const addressArray = await walletProvider.request({ method: "eth_requestAccounts"});
		//const signer = await walletProvider.getSigner();
		//console.log('-----------')
		//console.log(signer);
		//console.log("$$$$$$$$$$$$$$$$$$$$$$")
	//}*/
	/*function handleOfferingTypeChange(event){
		if(event.target.value === undefined){
			setOfferingType(event.target.dataset.value);
		}else{
			setOfferingType(event.target.value);
		}
	};*/
	
	/*function handleServiceDebitWalletAddressChange(event){
		setServiceDebitWallet(event.target.value);
	}*/
	
	
	useEffect(() => {
		const serviceImages = imgThumbs.map(file => (
		  <div className="form-group item justify-content-center" style={{margin: '0 auto'}}>
		  	<a href="#/" onClick={onImageClick} data-name={file.name}>
				<span key={file.name} style={{marginRight: "1rem"}}>
				    <div style={serviceImage == file.name ? clickedThumb : thumb} key={file.name}>
				      <div style={thumbInner}>
				        <img
				          data-name={file.name}
				          src={file.preview}
				          style={img}
				          //onLoad={() => { URL.revokeObjectURL(file.preview) }}
				        />
				      </div>
				    </div>
			    </span>
		    </a>
	    </div>
	  ));
	  
	  setServiceImageSelect(serviceImages);
	},[imgThumbs, serviceImage])
	
	
	var sectorSelection = [];
	
	for(const sec of Object.keys(selectedSubsec)){
		var secItems = [];
		for(let item of Array.from(selectedSubsec[sec])){
			secItems.push(<p key={item} className="mt-0 mb-0"><span>{item} <a href='#'  onClick={removeItem}><i data-sector={sec}  data-value={item} className="fa-solid fa-trash ml-1"></i></a> </span></p>)
		}
		
		sectorSelection.push(
						<div key={sec} className="col">
							<h5>{sec}</h5>
							<div>
								{secItems}
							</div>
						</div>
						);
	}
	if(!formik.errors.serviceSymbol){
		
		axios.get('/api/v1/service_symbol/' + serviceSymbol).then(response => {
			setServiceSymbolExistsMsg((response.data.symbol != '' ? 'Not available' : ''));
		});
	}
	
	
	if(!formik.errors.serviceName){
		///const params = new URLSearchParams(props.wallet);
		
		axios.get('/api/v1/collections/' + serviceName,).then(response => {
			if(response.data.collection && Object.keys(response.data.collection).length > 1){
				setServiceNameExistsMsg('Not available');
			}else{
				setServiceNameExistsMsg('');
			}
		});
	}
	
	
	if(!formik.errors.slotId){
		///const params = new URLSearchParams(props.wallet);
	
		axios.get('/api/v1/slot/' + defaultSlotId,).then(response => {
	
			if(response.data.slotId.length > 0){
				setSlotExistsMsg('Not available');
			}else{
				setSlotExistsMsg('');
			}
		});
	}
	

	return (
		<div>
			<h3><span className="font-weight-normal meritic_dblue">Service</span></h3>
							
			<div className="accordion accordion-flush mb-4" id="service_info_accordion"> 
				<AccordionItem
	            	heade_id="service_name_type_header"
	            	header_text={ bannerImageURI !== null ?
							<span style={{display: "flex"}}> <img src={ bannerImageURI } className="img-fluid" width="50" height="50" style={{border: '2px solid #fff', width: imgWidth, height: imgHeight, borderRadius: '15%'}} alt="..." /> <h4 className="ml-1"> { serviceName } </h4>  </span> 
							: "Service content"
						}
						
	            	collapse_container_id="service_name_type_collapse_container"
	            	parent_id="service_info_accordion"
	            	show_collapse="collapse"
	     			accordionRef={accordionRef}
	            	body={ 
							<div>
								{!skeleton ?
									<>
									<div className='form-group'>
										<Label class="mb-2" for="service_sector_select" text="Service categories"></Label>
										<div className={'container text-center' + (isMobileWidth ? 'd-flex' : '')}>
											<ButtonGroupSelect 
												id="service_sector_select"
												navoptions={Object.keys(sectors)}
												classActive={serviceTypeClassActive} 
												classActiveValue="service-sector-active"
												onClick={serviceSectorClicked}
												isMobileWidth={isMobileWidth}
												onMouseEnter={sectorOnMouseEnter}
											>
											</ButtonGroupSelect>
											<div className="form-group text-center mt-2">
											
												<div className="border border-black rounded-4" id="service_offerings"  style={{backgroundColor:'#fff'}}>
													<Label class="mt-1 mb-2" for="service_offerings" text="What service are you offering?"></Label>
													<p className="justify-content-center">
														{serviceOfferingOptions}
													</p>
												</div>
											</div> 
										</div>
									</div> 	
									<div className="form-group row text-center mt-2 mb-2 rounded-4" style={{ margin:'auto', backgroundColor: '#CCCCE5'}}>
										{sectorSelection}
									</div>
								
									{!skeleton ?
										<>
										{serviceSector.length > 0 && Object.keys(selectedSubsec).length > 0 ?
											<>
											<div className="d-flex">
												<div style={{width:'70%'}}>
													<div className="form-group mt-0">
														<span><Label  class={'mb-0'} text={'Service name'} />	
															{formik.errors.serviceName + serviceNameExistsMsg ? <span className="text-danger" > {formik.errors.serviceName + ' ' + serviceNameExistsMsg}  </span> : null }
														</span>
											         	<Input 
											         		class={'form-control form-rounded'} 
											         		type={'text'}  
											         		id={props.id} 
											         		name={'serviceName'} 
											         		placeholder={'Service name'}
											         		value={serviceName}
											         		onChange={(event) => {formik.handleChange(event); handleServiceNameChange(event);} }
											         		onBlur={formik.handleBlur}
											         	></Input>
											        </div>
												        
												</div>
												<div style={{width:'30%'}}>
													<div className="form-group mt-0">
														<span><Label  class={'mb-0'} text={'Service symbol'} />	
															{ formik.errors.serviceSymbol + serviceSymbolExistsMsg ? <span className="text-danger"> {formik.errors.serviceSymbol + ' '  + serviceSymbolExistsMsg}  </span> : <span>  &nbsp; </span> }
														</span>
														<Input 
											         		class={'form-control form-rounded'} 
											         		type={'text'}  
											         		name={'serviceSymbol'} 
											         		placeholder={'Enter symbol'}
											         		value={serviceSymbol}
											         		onChange={(event) => {formik.handleChange(event); handleServiceSymbolChange(event); }}
											         	></Input>
													</div>
														
												</div>
											</div> 
											{!skeleton ?
												<>
												{serviceName && serviceSymbol ?
													<>
													<div className="form-group d-flex mb-2">
														<div className="mr-2" style={{width: '35%'}}>
															<Label className="mb-1" for="mcc_category" text="Default Slot"></Label>
															<p>Provide a five digit ID to represent the default slot for this service. </p>
														</div>
														
														<div style={{width: '45%'}}>
															<Label className="mb-1" text="Slot name"></Label>
															<Input 
																class="form-control form-rounded" 
																type="text"
																value={serviceName}
																onChange={onChangeSlotName}
															></Input>
														</div>
														<div style={{width: '20%'}}>
															<SlotId tempDefaultSlotId = {tempDefaultSlotId} 
																	onChangeSlotId={onChangeSlotId} 
																	formik={formik}
																	slotExistsMsg={slotExistsMsg} />
														</div>
											     	</div> 
											     
											     	{!skeleton ?
											     		<>
											     		{defaultSlotId ? 
															 <>
															 	<div className="form-group mt-0">
																	<span><Label  class={'mb-0'} text={'Service description'} />	
																		{formik.touched.serviceDescription && formik.errors.serviceDescription ? <span className="text-danger"> {formik.errors.serviceDescription}  </span> : <span> &nbsp; </span> }
																	</span>
														
																	<textarea className="form-group container form-control"  name="serviceDescription" 
																							value={serviceDescription} rows="4"  onChange={(event) => {formik.handleChange(event); handleDescriptionChange(event)}}></textarea>
																</div> 
																
																{!skeleton ?
																	<>
																	{serviceDescription ?
																		<>
																			<Label label="Background banner image" for="banner_image" class="mb-0" text="Background banner image"></Label>
																			<div className="list-group-horizontal container d-flex rounded-5" role="tablist">
																				
																				<a className="list-group-item list-group-item-action"  data-value="start_date_before" role="tab">	    			
																					<div>
																						
																						<FileUpload 
																							form_id="banner_image"
																							formgroup_width="100%" 
																							instruction="Click to upload image" 
																							fieldname="banner_image" 
																							onFileDrop={handleBannerImageDrop}
																							getThumbs={getThumbs}
																							backImg={bannerImageURI}
																						>
																						</FileUpload>
																					</div>
																				</a>
																				{
																					/*
																					<a className="list-group-item list-group-item-action" onClick={onGenerateBanner} >
																						<div className="container form-group mt-0">
																							<div className="dropzone"  style={{borderRadius:'25px', textAlign: 'center', verticalAlign: 'middle'}}>
																								<h5>Click to upload image</h5>
																							</div>
																						</div>
																					</a>
																					
																					<DefaultBanner 
																									genBanner={genBanner}
																									onCloseGenerateBanner={onCloseGenerateBanner}
																									width={800} 
																									height={250}
																									events={false}
																									onImageCreate={handleCreateBannerImage}
																									margin ={{ top: 10, left: 20, right: 20, bottom: 20 }}
																									separation={0}
																								/> 
																					*/
																					
																				}
																				
																			</div>
																			{!skeleton ?
																				<>
																				{serviceDescription && isSetBannerImg ?
																					<>
																						<div className="form-group mt-0">
																							<Label className="mb-0" for="" text="Service contact"></Label>
																							<Paragraph 
																								class="text-muted font-14 mb-0" 
																								text="Primary service contact (only known to Meritic)"
																							>
																							</Paragraph>
																							
																							<div className="d-flex">
																								<div style={{width:'100%'}}>
																									{formik.errors.contactFirst ? <span className="text-danger"> {formik.errors.contactFirst}  </span> : <span> &nbsp; </span> }
																									<Input class="form-control form-rounded"  name="contactFirst" type="text" value={contactFirstName} placeholder="First name" onChange={(event) => {formik.handleChange(event); handleContactFirstNameChange(event)}} />
																								</div>
																		                        <div style={{width:'100%'}}>
																									{formik.errors.contactLast ? <span className="text-danger"> {formik.errors.contactLast}  </span> : <span> &nbsp; </span> }
																		                        	<Input class="form-control form-rounded"  name="contactLast" type="text" value={contactLastName} placeholder="Last name" onChange={(event) => {formik.handleChange(event); handleContactLastNameChange(event)}} />
																		                        </div>
																		                 		<div style={{width:'100%'}}>
																									{formik.errors.contactEmail ? <span className="text-danger"> {formik.errors.contactEmail}  </span> : <span> &nbsp; </span> }
																		                 			<Input class="form-control form-rounded" name="contactEmail" type="email" value={contactEmail} placeholder="Contact email" onChange={(event) => {formik.handleChange(event); handleContactEmailChange(event)}} />
																		                 		</div>
																		                     </div>
																						</div> 
																
																						{!skeleton ?
																							<> 
																								<div className="form-group-sm d-flex">
																									<Label class="mb-1 mr-3"  text="Service wallets"></Label> 
																									
																										{!(revenueWallet && revenueWallet) || !(adminWallet.address  && revenueWallet.address) ? 
																											<>
																												<a href="#" 
																													type="button"
																													onClick={onAddServiceWalletNow} 
																													className={'badge badge-light btn-rounded font-16 font-weight-normal mr-2 '}
																													style={{color: '#000080'}}
																												>Add now </a>
																												{/*<span> <input className="mr-1" type="radio" name="service_wallet_add_later" 
																														value="community_add_later" autocomplete="off" onChange={onAddServiceWalletLater}
																														checked={addServiceWalletLater} onClick = {changeAddServiceWalletLater} />Add later </span> */}
																											</> : null
																										}
																								</div> 
																								
																									<div className="form-group d-flex mt-3" style={{width: '100%'}}>
																						
																									{/*<LineInput 
																										input_id="service_debit_wallet" 
																										label_class="mb-0" 
																										label="Service debit wallet address" 
																										paragraph_class="text-muted font-14" 
																										description="" 
																										class="form-control form-rounded" 
																										type="text" 
																										id="service_debit_wallet" 
																										name="service_debit_wallet" 
																										placeholder="Click to connect wallet" 
																										value={props.wallet.address}
																										//onChange={handleServiceDebitWalletAddressChange}
																									></LineInput>
																									*/}
																									
																									{(adminWallet && revenueWallet) && (adminWallet.address && revenueWallet.address) ?
																										<>
																											<div className="d-flex" style={{width: "50%"}}>
																												<span className="mr-2">Admin wallet address</span>
																												<a type="button" className="btn-sm btn-light btn-rounded" onClick={() => {navigator.clipboard.writeText(adminWallet.address[0]);}}>
																								                	{ adminWallet.address[0].substring(1, 5) + ' ... ' +  adminWallet.address[0].substring(adminWallet.address[0].length - 4, adminWallet.address[0].length)}
																								                </a>
																											</div>
																											<div className="d-flex" style={{width: "50%"}}>
																												<span className="mr-2"> Revenue wallet address</span>
																												<a type="button" className="btn-sm btn-light btn-rounded" onClick={() => {navigator.clipboard.writeText(revenueWallet.address[0]);}}>
																								                	{ revenueWallet.address[0].substring(1, 5) + ' ... ' +  revenueWallet.address[0].substring(revenueWallet.address[0].length - 4, revenueWallet.address[0].length)}
																								                </a>
																											</div>
																										</> : <>
																												{addServiceWalletNow ? 
																												  <ServiceWalletModal  walletModalIsOpen={serviceModalIsOpen}
																														setServiceWalletModalIsOpenToFalse={setServiceWalletModalIsOpenToFalse} 
																														wallet={props.wallet} onChangeServiceWallet={onChangeServiceWallet} /> : null
																												}
																												 </>
																											 
																									}
																								</div> 
																							</> : <SkeletonBox> <Skeleton  height={30} width={"50%"} /> </SkeletonBox>
																						}
																					</>: null
																				}
																				</>: <div className="d-flex">
																					     	<SkeletonBox>
																					     		<Skeleton  height={30} width={"100%"} />
																					     	</SkeletonBox>
																					     	<SkeletonBox>
																					     		<Skeleton  height={30} width={"100%"} />
																					     	</SkeletonBox>
																					     	<SkeletonBox>
																					     		<Skeleton  height={30} width={"100%"} />
																					     	</SkeletonBox>
																				     	</div>
																			}
																		</>: null
																	}</> : <div className="d-flex">
																		     	<SkeletonBox>
																		     		<Skeleton  height={100} width={"100%"} />
																		     	</SkeletonBox>
																		     	<SkeletonBox>
																		     		<Skeleton  height={100} width={"100%"} />
																		     	</SkeletonBox>
																	     	</div>
																	
													
																 }
														
														
														
														 	</> : null
														} </>: <SkeletonBox> <Skeleton  height={100}  width={"100%"}/> </SkeletonBox>
													}
											     	</>: null
												}
										     	</> : <SkeletonBox> <Skeleton  height={30}  width={"100%"}/> </SkeletonBox>
									     	} </>: null
										}
										</>  : <SkeletonBox> <Skeleton  height={30}  width={"100%"}/> </SkeletonBox>
								     }
									</> : 
									<div>
										<Skeleton  height={100} width={"100%"} />
										<SkeletonBox> <Skeleton  height={30}  width={"100%"}/> </SkeletonBox>
										<SkeletonBox> <Skeleton  height={30}  width={"100%"}/> </SkeletonBox>
										<SkeletonBox> <Skeleton  height={100}  width={"100%"}/> </SkeletonBox>
										<div className="d-flex">
									     	<SkeletonBox>
									     		<Skeleton  height={100} width={"100%"} />
									     	</SkeletonBox>
									     	<SkeletonBox>
									     		<Skeleton  height={100} width={"100%"} />
									     	</SkeletonBox>
								     	</div>
								     	<div className="d-flex">
									     	<SkeletonBox>
									     		<Skeleton  height={30} width={"100%"} />
									     	</SkeletonBox>
									     	<SkeletonBox>
									     		<Skeleton  height={30} width={"100%"} />
									     	</SkeletonBox>
									     	<SkeletonBox>
									     		<Skeleton  height={30} width={"100%"} />
									     	</SkeletonBox>
								     	</div>
								     	<SkeletonBox> <Skeleton  height={30} width={"50%"} /> </SkeletonBox>
									</div>
								}
					
								{/* The service (or project) name will be the title of the NFT collection */}
								
								
								
						     	{
									addServiceWalletLater || ( adminWallet && adminWallet.address && revenueWallet && revenueWallet.address )
									&& !(formik.errors.serviceSymbol || formik.errors.serviceName || formik.errors.slotId 
											|| formik.errors.serviceDescription || formik.errors.contactFirst || formik.errors.contactEmail ) ? 
									
										<div className="form-group mt-10 mb-20">
										 	<hr style={{height:'1px', width:'100%', backgroundColor:'#000000'}}/>
							     			<a type="button" className="align-right btn-sm btn-light" onClick={onServiceSubmit}  style={{float: 'right'}}>Submit</a>
										</div> : null
								
								 }
								

							</div>
							
							
						}
					>
					</AccordionItem>
					
					
			{ props.serviceObj.isComplete ?
				<h5 className="font-weight-normal meritic_dblue">Thank you for registering. We will notify you soon to configure your service smart contracts.	</h5> : null
			}			 
			</div>
		</div>
			
	)
}