
import React, {useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import Parser from 'html-react-parser';
import MainNav from '../util/MainNav';
import axios from 'axios';
import Footer from "../util/Footer";
import { BannerAction2 } from '../util/BannerNav';
import TokenView from "./TokenView";
import { TabbedMarketContent } from './TabbedMarketContent';
import { useLocation } from 'react-router-dom';
import Reserve from './Reserve';
import Offering from './Offering';
import Icon from '@mdi/react';
import StickyBox from "react-sticky-box";
import { mdiWeb, mdiInstagram, mdiTwitter, mdiMapMarkerCircle } from '@mdi/js';
import { connectWallet, getCurrentWalletConnected, addWalletListener } from "../util/interact.js"; 
import { HuddleStream } from './comm/Huddle';












export default function CollectionAta(props){
	
	const { state } = useLocation();
	const { collection, tokenKey } = useParams();
	
	return (
		<>
		{tokenKey == undefined ? 
			<Collection state={state}  collection={collection}  /> : <TokenView  state={state}  collection={collection} tokenKey={tokenKey} userData={props.userData} />
		}
		</>
	);
}





function Collection(props){
	
	const state = props.state;
	
	//const collection = state.collection;

	const [collection, setCollection] = useState(undefined) ;
	const [reservableDates, setReservableDates] = useState("");
	const [tokens, setTokens] = useState(undefined);
	const [tokenGroupConditions, setTokenGroupConditions] = useState("");
	const [collectionBrief, setCollectionBrief] = useState("");
	const [wallet, setWallet] = useState({});
	const [tabSelect, setTabSelect] = useState("");
	const [subTabSelect, setSubTabSelect] = useState("");
	const [reserveDate, setReserveDate] = useState(new Date());
	const [optionsTimeSelect, setOptionsTimeSelect] = useState([]);
	const [bannerImgURI, setBannerImageURI] = useState("");
	const [brandLogoURI, setBrandLogo] = useState("");
	//const [mainContent, setMainContent] = useState([]);
	const [huddle, setHuddle] = useState(false);
	
	
	

	
	const handleWalletChanged = (updatedStatus) => {
		setWallet(updatedStatus);
	}
	
	const handleStatusChanged = (updatedStatus) => {
		setWallet(updatedStatus);
	}
	
	const connectWalletPressed = async () => {
	  const walletResponse = await connectWallet();
	  setWallet(walletResponse);
	};
	
	
	
	const updateWallet = async() => {

		const walletResponse = await getCurrentWalletConnected();
	
	    setWallet(walletResponse);
	    addWalletListener(handleWalletChanged, handleStatusChanged);
	}
	
	
	

	const onReserveDateChange = (value) => {
		var options = [];
		
		const reserveTokens = state.collection.reserve.tokens.get(value.toDateString());
		const offeringTokens = state.collection.offering.tokens.get(value.toDateString());
		
		if(reserveTokens){
			for(let i=0; i < reserveTokens.length; ++i){
				var cond = reserveTokens[i].token_conditions.filter(x => (x.name.trim() == 'reserve_start'));
				if(cond){
					const val = cond[0].value.split('T')[1].split(':');
					options.push({label: val[0] + ':' + val[1], value: options.length});
				}
			}
			setOptionsTimeSelect(options);
		}
		

		
	}
	
	const handleTabSelectChange = (event) => {

		var item = event.target.getAttribute('value');
		var minitem = item.toLowerCase().replace(/\s/g, '');
		setTabSelect(minitem);
	}
	
	const handleSubTabSelectChange = (event) => {
	
		var item = event.target.getAttribute('value');
		var parenttab = event.target.getAttribute('parenttab');
		
		item = item.toLowerCase().replace(/\s/g, '');
		parenttab = parenttab.toLowerCase().replace(/\s/g, '');

		setSubTabSelect(parenttab + '-' + item);
	}
	
	const onHuddleStart = (event) => {
		setHuddle(true);
	}
	
	var mainContent = [];
	
	useEffect(() => {
		if(props.state){

			setCollection(state.collection.collection);
			
			if(state.collection.reserve){
				setTokens(state.collection.reserve.tokens);
				
				setReservableDates((Array.from(state.collection.reserve.tokens.keys())).map(x => new Date(x)));
				setTokenGroupConditions(state.collection.reserve.group_conditions);
			}else{
				setTokens(state.collection.offering.tokens);
				setTokenGroupConditions(state.collection.offering.group_conditions);
				setReservableDates([]);
			}
			
			fetch(state.collection.collection.metadata_uri)
			.then((response) => response.json())
			.then((responseJson) => {
				setBannerImageURI(responseJson.image);
			})
			.catch((error) => {
				console.error(error);
			});
			
			
			fetch(state.collection.collection.provider_metadata_uri)
			.then((response) => response.json())
			.then((responseJson) => {
				setBrandLogo(responseJson.image);
			})
			.catch((error) => {
				console.error(error);
			});
			
			updateWallet();
			
			setCollectionBrief(state.collection.collection);
			
		}
		
	}, [props.state, tokens]);
	
	
	
	

	var pkey = new Map();
	
	pkey.set('items-spend', 0);
	pkey.set('items-reserve', 1);
	pkey.set('items-rewards', 2);
	
	var dt = new Date();
	





	return (
		<div>
			<MainNav userData={props.userData} brandimagepath={"/brand_assets/Meritic.png"} brandimageheight={"40"}> </MainNav>
			<section className="p-2" style={{
				backgroundImage: 'url(' + bannerImgURI + ')',
				backgroundPosition: 'center',
				backgroundSize: 'cover',
		        backgroundRepeat: 'no-repeat',
		        height: '400px'
				}}>
				
				<div className="float-start" style={{position:'relative', left:'10%', bottom: '-70%'}}>
					<img src={  brandLogoURI } className="img-fluid"  style={{border: '2px solid #fff', borderRadius: '15%'}} alt="..." />
				</div>
				
			</section>
			
			<div className="container-fluid">
				<div style={{display: "flex", alignItems: "flex-start"}}>
					<StickyBox offsetTop={100} offsetBottom={20}>
						<div className="list-group list-group-flush nav nav-pills">
							<li className="nav-item">
		                        <a href="#" className="nav-link align-middle px-0">
		                            <Icon path={mdiMapMarkerCircle} size={1} /> <span className="ms-1 d-none d-sm-inline">Service venue</span>
		                        </a>
		                    </li>

		                    <li className="nav-item">
		                        <a href="#" className="nav-link px-0 align-middle">
		                            <i className="fs-4 bi-table"></i> <span className="ms-1 d-none d-sm-inline">Orders</span></a>
		                    </li>

		    
		                    <li className="nav-item"> 
		                        <a href="#" className="nav-link px-0 align-middle">
		                            <i className="fs-4 bi-people"></i> <span className="ms-1 d-none d-sm-inline">Customers</span> </a>
		                    </li>
		                    
		                    {(wallet && Object.keys(wallet).includes('address') && wallet.address.length > 0) ? <li className="nav-item"> 
		                        <a href="#/" onClick={onHuddleStart} className="nav-link px-0 align-middle">
		                            <i className="fs-4 bi-people"></i> <span className="ms-1 d-none d-sm-inline">Huddle</span>
		                            	{huddle ?  <HuddleStream wallet={ wallet } collection={collection} userData={props.userData}
    																walletConnect = {props.walletConnect}
																	connectWalletPressed={ props.connectWalletPressed}
																	/> : null}
		                            </a>
		                    </li> : null}
						</div>
			                
					</StickyBox>
					{
						tokens && tokens.length  && mainContent ? 
							<div className="container-md py-3">
								<TabbedMarketContent
									pkey={pkey}
									handleTabSelectChange={handleTabSelectChange}
									handleSubTabSelectChange={handleSubTabSelectChange}
									tabSelect={tabSelect}
									subTabSelect={subTabSelect}
									mainContent={
										[<Offering 
											tokens={tokens}
											tokenGroupConditions={tokenGroupConditions}
											wallet={wallet} 
										/>,
										<Reserve 
											tokens={tokens}
											collection={collection}
											groupConditions={tokenGroupConditions}
											optionsTimeSelect={optionsTimeSelect}
											onReserveDateChange={onReserveDateChange} 
											reserveDate={dt}
											tokenGroupConditions={tokenGroupConditions}
											wallet={wallet} 
										/>]
										
									}
									navoptions={["Collection"]}
									suboptions={[["Offerings", "Reserve"]]}
									navRoot="root"									
								/>
							</div> : null
					}
					
					<StickyBox offsetTop={100} offsetBottom={20}  style={{alignSelf: "flex-start"}}>
					    <div className="list-group list-group-flush nav nav-pills">
							<li className="nav-item">
		                        <a href="#" className="nav-link align-middle px-0">
		                            <Icon path={mdiWeb} size={1} />  <span className="ms-1 d-none d-sm-inline"></span>
		                        </a>
		                    </li>
		                    <li className="nav-item">
		                        <a href="#" className="nav-link px-0 align-middle">
		                            <Icon path={mdiTwitter} size={1} />  <span className="ms-1 d-none d-sm-inline"></span>
		                        </a>
		                    </li>
		                    <li className="nav-item"> 
		                        <a href="#" className="nav-link px-0 align-middle">
		                            <i className="fa-brands fa-discord"></i> <span className="ms-1 d-none d-sm-inline"></span>
		                        </a>
		                    </li>
		                    
		                    <li className="nav-item">  
		                        <a href="#" className="nav-link px-0 align-middle">
		                            <Icon path={mdiInstagram} size={1} /><span className="ms-1 d-none d-sm-inline"></span>
		                        </a>
		                    </li>
						</div>
					</StickyBox>
				</div>
			</div>
	        <Footer />
        </div>
	);
	
}