

import React, {useEffect, useState} from 'react'
import {Label, Input } from '../../util/LineInput';
import FileUpload from '../../util/FileUpload';
import VideoUpload from "../vendor/livepeer/Upload"
const pattern = new RegExp('test.*regular');

const thumb = {
  display: 'inline-flex',
  borderRadius: 5,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 50,
  height: 50,
  padding: 4,
  boxSizing: 'border-box'
};

const clickedThumb = {
  display: 'inline-flex',
  borderRadius: 5,
  border: '1px solid #692727',
  marginBottom: 8,
  marginRight: 8,
  width: 50,
  height: 50,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};




const ContentHeader = (props) => {

	const serviceImages = props.content.contentFiles.map((file,idx) => (
				  <div className="form-group item justify-content-center" style={{margin: '0 auto'}}>
				  	<a href="#/" data-name={file.name} data-imgindex={idx + ''}>
						<span key={file.name} style={{marginRight: "1rem"}}>
						    <div style={thumb} key={file.name}>
						      <div style={thumbInner}>
						        <img
						          data-name={file.name}
						          data-imgindex= {idx + ''}
						          src={file.preview}
						          style={img}
						          onLoad={() => { URL.revokeObjectURL(file.preview) }}
						        />
						      </div>
						    </div>
					    </span>
				    </a>
			    </div>
			  ));
	
	return (
		
		<div className="form-group container mt-0"  style={{width:'70%', textAlign: 'center', margin: '0 auto'}}>
			<div id="token_group_iamge" className="d-flex" style={{overflowX: "auto", textAlign: 'center'}}>
				{serviceImages}
			</div>
		</div>
	);
			  
			  
			  
};


const Content = (props) => {
	
	const [imgThumbs, setImgThumbs] = useState(props.imgThumbs)
	const [contentAccess, setContentAccess] = useState('anyone')
	const [contentFiles, setContentFiles] = useState([]);
	const [videoFiles, setVideoFiles] = useState([]);
	
	//const pattern = new RegExp('test.*regular');
	
	function handleContentFileDrop(files){
		const videoMimePattern = new RegExp('video/*');
		setVideoFiles([...files.filter(x => videoMimePattern.test(x.type))]);
		
		const noVideoFiles = files.filter(x => !videoMimePattern.test(x.type));
		
		setContentFiles([...noVideoFiles]);
		setImgThumbs(noVideoFiles)
	};
	
	
	const onChangeContentAccess = (event, type) => {
		setContentAccess(type);
	}
	
	const onUpdateClick = (event) => {
		props.updateCondition('content', {
				contentFiles: contentFiles, 
				contentAccess: contentAccess
			});
			//props.setShowTokenGrpSave(true);
	}
	
	
	
	return (
		<>
			<div className="form-group mt-0" style={{width: '100%', display:'block'}}>
	     		<p>Who can view or access this content?  </p>
		  		<div className="row">
					<div className="col-sm-12 mb-2">
						<div className="btn-group text-center d-flex" role="group" aria-label="Basic radio toggle button group" style={{width:'50%', margin: 'auto'}}>
							<input type="radio" className="btn-check" autocomplete="off" />
							<a type="button" className={'btn-sm btn-outline-primary ' + (contentAccess == 'anyone' ? 'time-unit-active' : '')} onClick={(event) => {onChangeContentAccess(event, 'anyone')}}>Anyone</a>
						
						  	<input type="radio" className="btn-check" autocomplete="off" />
						  	<a type="button" className={'btn-sm btn-outline-primary ' + (contentAccess == 'holders' ? 'time-unit-active' : '')} onClick={(event) => {props.onChangeContentAccess(event, 'holders')}}>Token holders</a>
						</div>
					</div>
				</div>
	     		
				<FileUpload 
					id="company_logo"
					formgroup_width="100%" 
					instruction="Upload brand logo (click or drag here to upload)" 
					fieldname="company_light_bg_logo" 
					onFileDrop={handleContentFileDrop}
				> </FileUpload>
			</div>
			{videoFiles.length > 0 ? 
				<VideoUpload video={videoFiles[0]} /> : null
			}
			
			<div className="row">
				<div className="col-sm-12">
					<div className="float-right">
					<div>&#8205;</div>
					<button type="button" className="align-right btn btn-sm btn-light" onClick={onUpdateClick} 
						data-tokengroup={props.number} data-condition={props.condition_name} style={{float: 'right'}}>Update</button>
					</div>
				</div>
			</div>
		
		</>
	)
}


export { Content, ContentHeader};