import React from 'react';



export default function AccordionItem(props){
	
	return (
		<div className="accordion-item plain-bg" style={{width:'100%', backgroundColor: '#fafcff'}}>
			<a
			        className="accordion-button collapsed plain-bg"
			        href="#/"
			        ref={props.accordionRef} 
			        data-mdb-toggle="collapse"
			        data-mdb-target={'#' + props.collapse_container_id}
			        aria-expanded='false'
			        aria-controls={props.collapse_container_id}
			        style={{color: '#000080', backgroundColor: '#fafcff', display:'block'}}
			      >
			      {props.header_text}
		      	  </a>
		      	  
	    	<div className="container" id={props.header_id} style={{width:'100%', backgroundColor: '#fafcff'}}></div>
	    	
	    		<div
			      id={props.collapse_container_id}
			      className={"accordion-collapse " + props.show_collapse}
			      aria-labelledby={props.header_id}
			      data-mdb-parent={'#' + props.parent_id}
			    >
		  	    	<div className="accordion-body plain-bg" style={{color:'#000080', backgroundColor: '#fafcff'}}>
						{props.body}
		      		</div>
		    </div> 
	    
	    
  </div>);
}