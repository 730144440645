import React, { useState, useEffect } from "react";
import detectEthereumProvider from '@metamask/detect-provider';




export const addWalletListener = async(handleWalletChanged, handleStatusChanged) => {
	var walletProvider = await detectEthereumProvider();

  	if (walletProvider) {
		  walletProvider.on("accountsChanged", async(accounts) => {
			  const chainId = await walletProvider.request({method: 'eth_chainId'});
			  if (accounts.length > 0) {
				  handleWalletChanged({status: 'success', address: accounts, chainId: chainId });
			  } else {
				  handleWalletChanged({status: 'fail', address: "", chainId: null });
				  
			  }
		});
  	} else {
		  handleStatusChanged(
			  <span>
		          <p>
		            <a target="_blank" href={`https://metamask.io/download.html`}>
		            	<img src="/images/wallet/metamask.png" /> 
		            </a>
		            <a target="_blank" href={`https://metamask.io/download.html`}>
		              Install Metamask, a virtual Ethereum wallet, in your
		              browser.
		            </a>
		          </p>
		        </span>
		      );
  	}
};
	



export const connectWallet = async () => {
	

	
	var walletProvider = await detectEthereumProvider();

	if (walletProvider) {
		try {

			
			var chainId = await walletProvider.request({method: 'eth_chainId'});
			if(chainId != '0x89'){
				await walletProvider.request({
				  method: 'wallet_addEthereumChain',
				  params: [{
				    chainName: 'Polygon Mainnet',
				    chainId: '0x89',
				    nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
				    rpcUrls: ['https://polygon-rpc.com/']
				  }]
				});
			}
			
			chainId = await walletProvider.request({method: 'eth_chainId'});
			
			const addressArray = await walletProvider.request({ method: "eth_requestAccounts"});
			const obj = { status: 'success', address: addressArray,};
			
      		return {chainId: chainId, status: obj.status, address: obj.address };
    	} catch (err) {

			return { address: "",  status: err.message, chainId: null};
    	}
    } else {
    	return {
			address: "",
      		status: (
		        <span>
		          <p>
		            <a target="_blank" href={`https://metamask.io/download.html`}>
		            	<img src="/images/wallet/metamask.png" /> 
		            </a>
		            <a target="_blank" href={`https://metamask.io/download.html`}>
		              Install Metamask, a virtual Ethereum wallet, in your
		              browser.
		            </a>
		          </p>
		        </span>
      		),
    	};
  	}
};




export const getCurrentWalletConnected = async () => {
	var walletProvider = await detectEthereumProvider();
	if (walletProvider) {

		try {
			var chainId = await walletProvider.request({method: 'eth_chainId'});
			if(chainId != '0x89'){
				await walletProvider.request({
				  method: 'wallet_addEthereumChain',
				  params: [{
				    chainName: 'Polygon Mainnet',
				    chainId: '0x89',
				    nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
				    rpcUrls: ['https://polygon-rpc.com/']
				  }]
				});
			}
			
			chainId = await walletProvider.request({method: 'eth_chainId'});
			const addressArray = await walletProvider.request({ method: "eth_accounts",});
			if (addressArray.length > 0) {
				return {
					chainId: chainId,
					address: addressArray,
					status: "success",
				};
			} else {
				return {
					chainId: null,
					address: "",
					status: "🦊 Connect to Metamask using the top right button.",
				};
			}
		} catch (err) {
			return {
				chainId: null,
				address: "",
				status: "😥 " + err.message,
			};
		}
	} else {
    	return {
      		address: "",
      		status: (
		        <span>
		        	<p>
		        	<a target="_blank" href={`https://metamask.io/download.html`}>
		        		<img src="/images/wallet/metamask.png"/> 
		        	</a>
		            <a target="_blank" href={`https://metamask.io/download.html`}>
		              Install Metamask, a virtual Ethereum wallet, in your
		              browser.
		            </a>
		          </p>
		        </span>
      		),
    	};
  	}
};


export const connectWalletPressed = (props) => {

	  connectWallet().then(walletResponse => {
		 
		  if(!walletResponse.address){
		
			//flushSync(() => {
			props.setConnectError(true);
			props.setModalConnectIsOpen(true);
			//walletRef.current = walletResponse;
			props.setWallet(walletResponse);
			  
		  	props.setConnect({ displayError: true, 
							wallet: walletResponse,
							modalIsOpen: true,  
							closeModal: props.closeConnectModal
						 });
			//});
		
	
		  }else{
			  props.setConnectError(false);
			  props.setModalConnectIsOpen(false);
			  props.setWallet(walletResponse);
			  props.setConnect({ displayError: false, 
								wallet: walletResponse,
								modalIsOpen: false,  
								closeModal: props.closeConnectModal
							 });
		  }
		  
	  });
	  
	};

