
import React from 'react'
import Paragraph from '../util/Paragraph';

export function Label (props) {
	return(<label htmlFor={props.for} className={props.class}>{props.text}</label>);
};


export function Input(props) {
	return (<input 
				className={props.class} 
				type={props.type} 
				id={props.id} 
				size={props.size}
				name={props.name}  
				placeholder={props.placeholder} 
				checked={props.checked}
				value={props.value ? props.value : ""}
				onChange={props.onChange}/>);
};

export function LineInput(props){
	return (
		<div className="form-group mt-0">
			<Label for={props.input_id} class={props.label_class} text={props.label} >	</Label>
         	{/*<Paragraph className={props.paragraph_class} text={props.description}></Paragraph>*/}
         	<Input 
         		class={props.class} 
         		type={props.type}  
         		id={props.id} name={props.name} 
         		placeholder={props.placeholder}
         		value={props.value ? props.value : ""}
         		onChange={props.onChange}
         	></Input>
        </div>
	);
}