import React, {useEffect, useState} from 'react'

import {Label, Input } from '../util/LineInput';
import Paragraph from '../util/Paragraph';
//import Select from "react-dropdown-select";
import Select from 'react-select';
import RadioOption from '../util/RadioOption';
import DTimePicker from '../util/TimePicker';
import { ButtonGroupSelect } from '../util/RadioOption';
import AliceCarousel from 'react-alice-carousel';
import { ValueGrpType, TimeGrpType} from './conditions/TokenGrpType';
import ContinueGate from '../util/ContinueGate';
import FileUpload from '../util/FileUpload';






const thumb = {
  display: 'inline-flex',
  borderRadius: 5,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 50,
  height: 50,
  padding: 4,
  boxSizing: 'border-box'
};

const clickedThumb = {
  display: 'inline-flex',
  borderRadius: 5,
  border: '1px solid #692727',
  marginBottom: 8,
  marginRight: 8,
  width: 50,
  height: 50,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};





const AddPanel = (props) => {

	const [imgThumbs, setImgThumbs] = useState(props.imgThumbs)
	const [imgFromFiles, setImgFromFiles] = useState(true)
	const [svcImageNameMap, setSvcImageNameMap ] = useState(props.serviceImageNameMap)
	const [serviceMetadataURI, setServiceMetadataURI] = useState(props.serviceMetadataURI)
	const [tokenGrpImageSelect, setTokenGrpImageSelect] = useState([]);
	
	
	
	
	

	
	const handleTokenImageFiledrop = (files) => {
		props.setTokenGrpImage([files[0]]);
		setImgThumbs([files[0]]);
	}
	
	
	
	useEffect(() => {
		if(props.serviceImageNameMap != undefined && props.serviceImageNameMap.size  > 0){
			setImgFromFiles(false);
		}
		props.setShowAddTokenGrp(false);
		
		props.setTokenGrpThumbs(imgThumbs);
		
		if(imgFromFiles){
		
			serviceImages = imgThumbs.map((file,idx) => (
				  <div className="form-group item justify-content-center" style={{margin: '0 auto'}}>
				  	<a href="#/" onClick={props.onTokenImageClick} data-name={file.name} data-imgindex={idx + ''}>
						<span key={file.name} style={{marginRight: "1rem"}}>
						    <div style={props.tokenGroupImage == file.name ? clickedThumb : thumb} key={file.name}>
						      <div style={thumbInner}>
						        <img
						          data-name={file.name}
						          data-imgindex= {idx + ''}
						          src={file.preview}
						          style={img}
						          onLoad={() => { URL.revokeObjectURL(file.preview) }}
						        />
						      </div>
						    </div>
					    </span>
				    </a>
			    </div>
			  ));
			  
			  setTokenGrpImageSelect(serviceImages)
		}else{

			var metadata;
			var image_cid;
			if(serviceMetadataURI != undefined){
		
				fetch(serviceMetadataURI)
				.then((response) => response.json())
				.then((responseJson) => {
		
					metadata = responseJson;
					image_cid = responseJson.properties.image_cid;
					
					if(metadata != undefined){
						var images = metadata.properties.images.filter(name => !name.includes('banner'));
						
						serviceImages = images.map(ipfs_img_name => (
						  <div key={ipfs_img_name}  className="form-group item justify-content-center" style={{margin: '0 auto'}}>
						  	<a href="#/" onClick={props.onTokenImageClick} data-name={ipfs_img_name}>
								<span key={ipfs_img_name} style={{marginRight: "1rem"}}>
								    <div style={props.tokenGroupImage == ipfs_img_name ? clickedThumb : thumb} key={ipfs_img_name}>
								      <div style={thumbInner}>
								        <img
								        	src = {image_cid + '/' + ipfs_img_name}
									        data-name={ipfs_img_name}
									        style={img}
								        />
								      </div>
								    </div>
							    </span>
						    </a>
					    </div>
					  ));
					  
					  setTokenGrpImageSelect(serviceImages)
					}
				})
				.catch((error) => {
					console.error(error);
				});
			}
		}
		
		
		
	}, [props.serviceImageNameMap, props.tokenGroupName, props.tokenGroupImage, imgThumbs])
	

	var serviceImages;
	

							   

	
	/*
	typeTokenOptions['spend'] = <div className="form-group d-flex mt-0">
										<div className="form-group mt-0 d-flex" style={{width:'30%'}}>
											{props.spendOn == 'all' ?
												<>
													<div className="form-group" style={{width:"100%"}}>
														<Label for="spend_value_on" className="form-group mb-0" text="Spend value on"></Label>
														<Select id="spend_value_on" options={spendOptions} onChange={props.onSpendOptionChange}  />
												    </div>
												    <div className="form-group" style={{width:"100%"}}>
														<Label for="notional_value" className="form-group mb-0" text="Value currency"></Label>
														<Select id="value_currency" options={tokenOptions}  />
												    </div>
												</> :
												props.spendOn == 'wait' ?
												<>
													<div className="form-group" style={{width:"100%"}}>
														<Label for="spend_value_on" className="form-group mb-0" text="Spend value on"></Label>
														<Select id="spend_value_on" options={spendOptions} onChange={props.onSpendOptionChange}  />
												    </div>
												</>: 
												<div className="form-group" style={{width:"100%"}}>
													<Label for="spend_value_on" className="form-group mb-0" text="Spend value on"></Label>
													<Select id="spend_value_on" options={spendOptions} onChange={props.onSpendOptionChange}  />
											    </div>
											}
											
									    </div>
									    <div className="form-group" style={{width:'50%'}}>
									    	
										    <div className="form-group container mt-0" style={{width:'50%', textAlign: 'center'}}>
										    	{props.spendOn == 'all' ?
											    	<>
									     				<Label for="issuance" text="Number of tokens"></Label>
										                <div id="issuance" className="form-group container d-flex" style={{ textAlign: 'center'}}>
										                    {issuanceNavOptions}
										                </div>
									                </> : 
									               props.spendOn == 'wait' ?
									               <>
										               <div className="form-group" style={{width:"100%"}}>
												    		<Label for="waitlist_length_type" className="form-group mb-0" text="Max length"></Label>
												    		<div id="waitlist_length_type" className="form-group d-flex">
												   				{waitListOptions}
												   			</div>
												   		</div>
									               </> : 
									               props.spendOn == 'reserve' ?
									               <>
									     				<Label for="issuance" text="Number of tokens"></Label>
										                <div id="issuance" className="form-group container d-flex" style={{ textAlign: 'center'}}>
										                    {issuanceNavOptions}
										                </div>
								                	</> : null
									              }
											</div>
											
										</div>
										<div style={{width:'20%'}}>
											{
												props.spendOn == 'wait' ?
													<>
														<Label for="issuance" text="Number of tokens"></Label>
										                <div id="issuance" className="form-group container d-flex" style={{ textAlign: 'center'}}>
										                    {issuanceNavOptions}
										                </div>
									                </> : null
									               
											}
											
										</div>
									</div>
		typeTokenOptions['credit'] = <>
										<div className="form-group mt-0 d-flex">
											<div className="d-flex" style={{width:'30%'}}>
												{props.creditFor == 'money' ? 
													<>
														<div className="form-group" style={{width:"100%"}}>
															<Label for="credit_for_select" className="form-group mb-0" text="Credit for"></Label>
															<Select id="credit_for_select" onChange={props.onCreditForOptionChange} options={creditOptions}  />
													    </div>
												    	<div className="form-group" style={{width:"100%"}}>
															<Label for="notional_value" className="form-group mb-0" text="Value currency"></Label>
															<Select id="value_currency" options={tokenOptions}  />
													    </div>
													</> :
													props.creditFor == 'wait'|| props.creditFor == 'reserve' ? 
													<div className="form-group" style={{width:"100%"}}>
														<Label for="credit_for_select" className="form-group mb-0" text="Credit for"></Label>
														<Select id="credit_for_select" onChange={props.onCreditForOptionChange} options={creditOptions}  />
													</div> :
													<div className="form-group" style={{width:"100%"}}>
														<Label for="credit_for_select" className="form-group mb-0" text="Credit for"></Label>
														<Select id="credit_for_select" onChange={props.onCreditForOptionChange} options={creditOptions}  />
												    </div>
												}
											</div>
											<div className="form-group" style={{width:'50%', textAlign: 'center'}}>
												<div className="form-group container mt-0" style={{width:'50%', textAlign: 'center'}}>
													{props.creditFor == 'money' || props.creditFor == 'reserve' ? 
														<>
										     				<Label for="issuance" text="Number of tokens"></Label>
											                <div id="issuance" className="form-group d-flex" style={{width:'100%', textAlign: 'center'}}>
											                    {issuanceNavOptions}
											                </div>
									                	</> :
									                	props.creditFor == 'wait' ? 
									                	<div className="form-group" style={{width:"100%"}}>
												    		<Label for="waitlist_length_type" className="form-group mb-0" text="Max length"></Label>
												    		<div id="waitlist_length_type" className="form-group d-flex">
												   				{waitListOptions}
												   			</div>
												   		</div>
												   		: null
												   	}
												</div>
											</div>
											<div className="form-group" style={{width:'20%'}}>
													{props.creditFor == 'money' ?
														<>
															<Label for="credit_balance" className="form-group mb-0" text="Value per token"></Label>
															 <Input 
													     		class="form-control form-rounded"
													     		type="number" 
													     		min="0"
													     		value={props.waitListLength}
													     		step="1"
													     		id="credit_balance" 
													     		placeholder="0"
													     		onChange={props.handleWaitListLengthChange}
													     	></Input> 
								   						</> :
														props.creditFor == 'wait' ?
														<>
															<Label for="issuance" text="Number of tokens"></Label>
											                <div id="issuance" className="form-group container d-flex" style={{ textAlign: 'center'}}>
											                    {issuanceNavOptions}
											                </div>
										                </> : null
													}
												</div>
										</div>
									</>

	*/
	
	// props.showContinue['tokenGrpName']
	
	return (
		<div className="form-group meritic_dblue mt-0 mb-0">
			<div className="mt-4">
				<h5> Token groups </h5>
				<p>The service rules you lay out are encoded into you smart contract according to groups of tokens which the rules apply. 
					Your smart contract may contain as many token groups as you deisire. You will now define a token group and associated rules </p>
					
					
				{props.contractType == 'value' ? 
					<div className="form-group mt-4">
						<ValueGrpType onChangeValueGrpType={props.onChangeValueGrpType} valueGrpType={props.valueGrpType} /> 
						{ props.contractType == 'value' && props.valueGrpType != null ? 
							<>
							<ContinueGate continue={props.valueGrpType !== null} showBack={props.timeGrpType == null} showContinue={props.showContinue['tokenGrpName']} onContinue={()=>{props.onContinue('tokenGrpName')}}/>
							</> : null 
						}
					</div> : 
					props.contractType == 'time' ? 
					<div className="form-group mt-4">
						<TimeGrpType onChangeTimeGrpType={props.onChangeTimeGrpType} timeGrpType={props.timeGrpType} /> 
						{ props.contractType == 'time' && props.timeGrpType != null ? 
							<>
							<ContinueGate continue={props.timeGrpType !== null} showBack={!props.proceed.tokenGrpName && props.timeGrpType}  showContinue={props.showContinue['tokenGrpName']} onContinue={()=>{props.onContinue('tokenGrpName')}}/>
							</> : null 
						}
					</div> : null
				}
				{props.proceed.tokenGrpName ?
					<>
						<div className="form-group d-flex mt-0 mb-0">
							<div className="form-group mt-0" style={{width: '50%'}}>
								<Label for="token_group_input" className="form-group mb-0" text="Token group name"></Label>
						     	<Input 
						     		class="form-control form-rounded" 
						     		type="text" 
						     		id="token_group_input" 
						     		placeholder="Enter name"
						     		value={props.tokenGroupName}
						     		onChange={props.handleTokenGroupNameChange}
						     	></Input>
						    </div>
						    
							<div className="form-group" style={{width: '30%'}}>
								<label>Select slot</label>
								<Select className="form-group" defaultValue={props.slot ? props.slot.label : 'Select'} options={props.slotOptions} onChange={props.handleTokenGrpSlotChange}/>
							</div>
							{(props.contractType == 'value') || (props.contractType == 'time' && props.timeGrpType == 'nocal' ) ?
							<div className="form-group" style={{width: '20%'}}>
								<Label className="mb-0" for="slot_network" text="Num. of tokens"></Label>
									<Input 
							     		class="form-control form-rounded" 
							     		type="text" 
							     		size={5}
							     		onChange={props.handleNumTokenChange} 
							     		placeholder="Qty"
							     		value={props.numTokens}
							     	/>
							</div> : null
							}
						</div>
						
						<div className="form-group  d-flex">
							<div className="form-group mb-0 mt-0 d-flex" style={{width:"30%"}}>
								<Label for="token_group_iamge" text="Token image"> </Label>
								<p className="ml-1">Select an image to represent this group of tokens</p>
							</div>
							<div className="form-group container mt-0"  style={{width:'70%', textAlign: 'center', margin: '0 auto'}}>
								<div id="token_group_iamge" className="d-flex" style={{overflowX: "auto", textAlign: 'center'}}>
									{tokenGrpImageSelect}
								</div>
							</div>
							
						</div>
						<div className="form-group mt-0" style={{width: '100%', display:'block'}}>
				     		<Label class="form-group mb-0" for="company_logo" text=""></Label>
							<FileUpload 
								id="company_logo"
								formgroup_width="100%" 
								instruction="Upload brand logo (click or drag here to upload)" 
								fieldname="company_light_bg_logo" 
								onFileDrop={(files) => {handleTokenImageFiledrop(files)}}
							> </FileUpload>
						</div>
						<div className="form-group d-flex" style={{width:'100%', justifyContent: 'flex-end'}}>
							<button id="" className="btn btn-light" style={{justifyContent: 'flex-end'}} onClick={props.handleCreateTokenGroup}>Create group</button>
						</div>
					</> : null
				} 
				
			</div> 
						
			
		</div>
	);
	
}



export default AddPanel;