
import React, {useState, useEffect, useRef	} from 'react'
import { flushSync } from 'react-dom';
import TimePicker from 'react-time-picker';
import RadioOption from '../../util/RadioOption';
import { Label, Input } from '../../util/LineInput';
import { mdiTrashCanOutline, mdiPlus} from "@mdi/js";
import Icon from "@mdi/react";
//import Select from "react-dropdown-select";
import { v4 as uuidv4 } from 'uuid';
import Carousel from 'react-bootstrap/Carousel';
import { WeekSchedule, verifyWeekSchedule } from '../../vis/weekly_schedule';
import './hosWeeklyStyle.css';
import timezones from 'timezones-list';
import Select from 'react-select';
import AliceCarousel from 'react-alice-carousel';
import { CalendarLegend } from '../../vis/calendar_legend';
import { ServiceVenueHeader, ServiceVenue } from "./ServiceVenue.js"



var construct2DArray = function(original, m, n) {
    if (original.length !== (m*n)) return []
    let result = []
    let arr = []

    for (let i = 0; i < original.length; i++){

        arr.push(original[i])
        if (arr.length == n){
            result.push(arr)
            arr = []
        }
    }

    return result
};













const HoursOfServiceHeader = (props) => {


	const slotsPerWeek = 8 * 48;
	const [index, setIndex] = useState(0);
	const [cutOffs, setCutOffs] = useState([]);
	const [indexMax, setIndexMax] = useState(1);
	const [minAnyBid, setMinAnyBid] = useState(0);
	const [minOrgBid, setMinOrgBid] = useState(0);
	const [allDataUpdated, setAllDataUpdated] = useState(false);
	const [calWeeks, setCalWeeks] = useState([]);
	const [timeSlots2d, setTimeSlots2d] = useState([])
	//const [ordersUpdated, setOrdersUpdated] = useState(false);

	const onCalendarNextWeek = () =>{
		if(index < indexMax - 1){
			setIndex(index + 1);
		}else{
			setIndex(0);
		}
	}
	
	const onCalendarPrevWeek = () =>{
		if(index > 0){
			setIndex(index - 1)
		}
	}
	

	props.start_date.start_after = new Date(Date.parse(props.start_date.start_after));
	props.start_date.select_start_after = true;
	props.end_date.end_before = new Date(Date.parse(props.end_date.end_before));
	props.end_date.select_end_before = true
		

  	
  	var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  	var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun','Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  	var start_date;
  	var end_date;
  	var cal_start_date;
  	var cal_end_date;
 
  	if(props.start_date && Object.keys(props.start_date).length > 0){
		
		if(props.start_date.select_start_before){
		  start_date = props.start_date.start_before;
		}else if(props.start_date.select_start_after){
			start_date = props.start_date.start_after;
		}
		
		var d = new Date(start_date);
		
		if(days[d.getDay()] == 'Sunday'){cal_start_date = d;}
		else if(days[d.getDay()] == 'Monday'){var newd = new Date(); newd.setDate(d.getDate() - 1); cal_start_date = newd;}
		else if(days[d.getDay()] == 'Tuesday'){var newd = new Date(); newd.setDate(d.getDate() - 2); cal_start_date = newd;}
		else if(days[d.getDay()] == 'Wednesday'){var newd = new Date(); newd.setDate(d.getDate() - 3); cal_start_date = newd;}
		else if(days[d.getDay()] == 'Thursday'){var newd = new Date(); newd.setDate(d.getDate() - 4); cal_start_date = newd;}
		else if(days[d.getDay()] == 'Friday'){var newd = new Date(); newd.setDate(d.getDate() - 5); cal_start_date = newd;}
		else if(days[d.getDay()] == 'Saturday'){var newd = new Date(); newd.setDate(d.getDate() - 6); cal_start_date = newd;}

	}

	
	if(props.end_date && Object.keys(props.end_date).length > 0){

		if(props.end_date.select_end_before){
			end_date = props.end_date.end_before;
		}else if(props.end_date.select_end_after){
			end_date = props.end_date.end_after;
		}

		
		var d = new Date(end_date);
	 
		if(days[d.getDay()] == 'Saturday'){cal_end_date = d;}
		else if(days[d.getDay()] == 'Sunday'){var newd = new Date(); newd.setDate(d.getDate() + 6); cal_end_date = newd;}
		else if(days[d.getDay()] == 'Monday'){var newd = new Date(); newd.setDate(d.getDate() + 5); cal_end_date = newd;}
		else if(days[d.getDay()] == 'Tuesday'){var newd = new Date(); newd.setDate(d.getDate() + 4); cal_end_date = newd;}
		else if(days[d.getDay()] == 'Wednesday'){var newd = new Date(); newd.setDate(d.getDate() + 3); cal_end_date = newd;}
		else if(days[d.getDay()] == 'Thursday'){var newd = new Date(); newd.setDate(d.getDate() + 2); cal_end_date = newd;}
		else if(days[d.getDay()] == 'Friday'){var newd = new Date(); newd.setDate(d.getDate() + 1); cal_end_date = newd;}

	}
	
	var dateStart = new Date(cal_start_date);
	var tempTimeSlots2d = [];
	var tempCutOffs = [];
	while(cal_end_date.getDate() - dateStart.getDate() >= 6 ){
		var currDate = new Date(dateStart.getTime());
		dateStart.setDate(dateStart.getDate() + 6);
		var endWeek = new Date(dateStart.getTime());
		
		tempCutOffs.push({start: currDate, end: endWeek});
		dateStart.setDate(dateStart.getDate() + 1);
		dateStart = new Date(dateStart.getTime());
	}

	if(cal_end_date.getDate() - dateStart.getDate() > 0 ){
		var currDate = new Date(dateStart.getTime());
		dateStart.setDate(dateStart.getDate() + 6);
		var endWeek = new Date(dateStart.getTime());
		tempCutOffs.push({start: currDate, end: endWeek});
	}

	useEffect(() => {
		flushSync(() => {
			setCutOffs(tempCutOffs)
		})
	}, []);
	
	var tempCalWeeks = [];
	
	useEffect(() => {
		
		for(let i=0; i < cutOffs.length; ++i){
			var timeSlots = [];
			for(let j=0; j < slotsPerWeek; ++j){
				var slotHr;
				if((j % 8) == 0){ slotHr = (j / 8); }
				
				if((j % 16) == 0){
					var hour = j / 16;
					var am_pm = 'AM';
					if(hour == 0){ hour = 12}
					if(hour > 12){
						am_pm = 'PM';
						if(hour > 12){
							hour = hour % 12;
						}
					}
					if(hour % 2 == 0){
						timeSlots.push({class: 'hihours', text: null, use_span: false });
					}else{
						timeSlots.push({class: 'hihours', text: hour + ' ' + am_pm, use_span: true });
					}
				}else if(j % 8 == 0){
					timeSlots.push({class: '', text: null, use_span: false });
				}else if(j % 8 != 0){
					var li_element = undefined; 
				
					for(let k=0; k < props.service_hours.length; ++k){
						var sh = props.service_hours[k];
				
						if(sh.recommendedHolder == 'groups' && sh.min_bid > 0 && minOrgBid == 0){
							setMinOrgBid(sh.min_bid);
						}else if(sh.recommendedHolder == 'groups' && sh.min_bid > 0 && sh.min_bid < minOrgBid ){
							setMinOrgBid(sh.min_bid);
						}
						
						if((sh.recommendedHolder == 'individuals' || sh.recommendedHolder == 'anyone') && sh.min_bid > 0 && minAnyBid == 0){
							setMinAnyBid(sh.min_bid);
						}else if((sh.recommendedHolder == 'individuals' || sh.recommendedHolder == 'anyone') && sh.min_bid > 0 && sh.min_bid < minAnyBid ){
							setMinAnyBid(sh.min_bid);
						}
						
						if((sh.dayofweek == 'sunday' && j % 8 == 1) 
							|| (sh.dayofweek == 'monday' && j % 8 == 2)
							|| (sh.dayofweek == 'tuesday' && j % 8 == 3)
							|| (sh.dayofweek == 'wednesday' && j % 8 == 4)
							|| (sh.dayofweek == 'thursday' && j % 8 == 5)
							|| (sh.dayofweek == 'friday' && j % 8 == 6)
							|| (sh.dayofweek == 'saturday' && j % 8 == 7)){
								
							var shHour = parseInt(sh.start.split(':')[0]) * 2;
							var seHour = parseInt(sh.end.split(':')[0]) * 2;
					
							if (slotHr >= shHour && (slotHr < seHour )){
				
								if(sh.recommendedHolder == 'groups'){
									li_element = {class: 'active-' + sh.recommendedHolder, text: null, use_span: true } 
								}else{
									li_element = {class: 'active-anyone', text: null, use_span: true } 
								}
								if(props.orderData && props.orderData.length){
						
									setAllDataUpdated(true)
									for(let d of props.orderData){
										
										const start_time = new Date(Date.parse(d.properties.reserve_start));
										const end_time = new Date(Date.parse(d.properties.reserve_end));
									
										const oshHour = start_time.getHours() * 2;
										const oseHour = end_time.getHours() * 2;
										
										if((days[end_time.getDay()].toLocaleLowerCase() == sh.dayofweek) && (slotHr == oshHour) 
											&& (new Date(d.properties.reserve_start) >= cutOffs[i].start)
											&& (new Date(d.properties.reserve_end) <= cutOffs[i].end)){
											li_element['curr_bid']=  d.bid;
										}
									}
								}else{
									setAllDataUpdated(true)
								}			
							}
						}
					}
					if(li_element){
						timeSlots.push(li_element);
					}else{
						timeSlots.push({class: 'hihours', text: null, use_span: true });
					}
				}else{
					timeSlots.push({class: '', text: null, use_span: false });
				}
			}
			
			
			tempTimeSlots2d.push(construct2DArray(timeSlots, 48, 8));
			
			var tmpDt = cutOffs[i].start;
		  	var newt = new Date();
		  	
	
		  	while(tmpDt < cutOffs[i].end){
				  var strtWeekDt = new Date(tmpDt);
				  
				  newt.setDate(tmpDt.getDate() + 6);
				  tmpDt = new Date(newt);
				  
				  var endWeekDt = new Date(tmpDt);
				  
				  var strMon = monthNames[strtWeekDt.getMonth()];
				  var strDay = strtWeekDt.getDate();
				  var strYear = strtWeekDt.getFullYear();
				  var endMon = monthNames[endWeekDt.getMonth()];
				  var endDay = endWeekDt.getDate();
				  var endYear = endWeekDt.getFullYear();
				  
				  var weekStr; 
				  var yearStr;
				  if((strMon == endMon) && (strYear == endYear)){
					  weekStr = strMon + '. ' +  strDay + ' - ' + endDay;
					  yearStr = ', ' + strYear;
				  }else if(strYear == endYear){
					  weekStr = strMon + '. ' +  strDay + ' - ' + endMon + '. ' + endDay;
					  yearStr = ', ' + strYear;
				  }else if(strYear != endYear){
					  weekStr = strMon + '. ' +  strDay + ' ,' + strYear +  ' - ' + endMon + '. ' + endDay + ', ' + endYear;
					  yearStr = null;
				  }
				  
			
				  tempCalWeeks.push({
					  				start: strtWeekDt,
					  				end: endWeekDt,
					  				week: weekStr,
					  				year: yearStr,
					  				slots: timeSlots
					  			});
			}
		}
		
		setCalWeeks(tempCalWeeks);
		setTimeSlots2d(tempTimeSlots2d);
	}, [cutOffs, tempTimeSlots2d]);
	

	useEffect(() => {
		setIndexMax(calWeeks.length);
	}, [calWeeks])
	
	
	//let result = calWeeks[0].week.replace(/\s/g, "").replace(/\-/g, "").replace(/\./g, "").toLowerCase();


	/*useEffect(() =>{
		element = <WeekSchedule index={index} title={calWeeks[index].week} timeSlots={timeSlots2d[index]} containerId={result} />
	},[index])*/

	
	
	/*
	var carouselItems = [];

	for(var j=0; j < 1; ++j){

		var week = calWeeks[j];
		var keyId  = uuidv4();
		
		let result = week.week.replace(/\s/g, "").replace(/\-/g, "").replace(/\./g, "").toLowerCase();
		
			
			

		//{
    	//<Carousel slide={false}  autoplay={false} activeIndex={index} indicators={false} onSelect={handleSelect}> 
    	//</Carousel>
    	//}

		carouselItems.push(
			<WeekSchedule title={week.week} timeSlots={timeSlots2d} containerId={result} />
		);
	}*/
	
	

	
	var anyBidText = `Individuals \n Floor price: ${minAnyBid} USDC`;
	var orgBidText = `Organizations \n Floor price: ${minOrgBid} USDC`
	return (
    
    <>
    	
		
    	{ calWeeks.length > 0 && calWeeks[index].week && allDataUpdated ? 
    		<>
    		<div className="d-flex" style={{display:"flex", height: "100%", alignItems: "center", justifyContent: "center"}}>
    			<div className="container" style={{width:"2%"}}>
    				<a href="#" onClick={onCalendarPrevWeek}><i class="fa-duotone fa-circle-chevron-left fa-2xl"></i></a>
    			</div>
    			<div className="container" style={{width:"96%"}}>
     				<WeekSchedule index={index} title={calWeeks[index].week} timeSlots={timeSlots2d[index]} />
     			</div>
     			<div className="container" style={{width:"2%"}}>
     				<a href="#" onClick={onCalendarNextWeek}><i class="fa-duotone fa-circle-chevron-right fa-2xl"></i></a>
     			</div>
     		</div>
     		<CalendarLegend  width = {700}  	
			   					height = {50}
			   					individuals = {{text: anyBidText, color: '#CCCCE5'}}
			   					orgs = {{text: orgBidText, color: '#000080' }}
			   					 /> 
			</>
     		: null 
     	}
     
     
	</>
    
	);
	
}



/*const customStyles = {
  control: base => ({
    ...base,
    height: 35,
    minHeight: 35
  })
};*/			

const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '30px',
      height: '30px',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
    }),
  };
  
  
  
  
  
  
const HoursOfService = (props) => {
	

	let weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Mon-Fri', 'Sat-Sun'];
	let abbrevDays = {sunday:'Sun.', monday:'Mon.', tuesday:'Tues.', wednesday:'Wed.', thursday:'Thur.', friday:'Fri.', saturday: 'Sat.'}
				
	
	const [hoursOfService, setHoursOfService] = useState([]);
	const [hosArr, setHosArr] = useState([]);
	const [timezone, setTimezone] = useState();
	const [invalidHos, setInvalidHos] = useState([]);
	
	
	//const [removeHOS, setRemoveHOS] = useState([]);
	//const [selectServiceHoursOption, setSelectServiceHoursOption] = useState([]);
	
	
	const onChangeTimezone = (selection) => {
		setTimezone(selection.value);
	}
	
	const updateHoursOfService = (data, index) => {

		setHoursOfService([...hoursOfService.slice(0,index), 
										{ dayofweek: data.dayofweek, 
										  start: data.start, 
										  end: data.end,
										  vacancy: data.vacancy,
										  reoccurance: data.reoccurance,
										  recommendedHolder: data.recommendedHolder,
										  minBid: data.minBid,
										  bidDuration: data.bidDuration,
										  sessionDurationHours: parseFloat(data.sessionDurationHours)
										}, 
										...hoursOfService.slice(index + 1)
									]);
	}
	
	
	const onUpdateClick = (event) => {
	
		props.updateCondition('service_hours', hoursOfService);
		//props.setShowTokenGrpSave(true)
	}
	
	
	
	
	function handleRemoveHOS(event){
		event.preventDefault();
		event.stopPropagation();
		
		var removeIdx =  event.target.dataset.value;
	
		if(removeIdx !== undefined){

			var temp = [...hoursOfService];
			if(temp.length == 1 && removeIdx == 0){
				temp = []
			}else{
				temp.splice(removeIdx, removeIdx);
			}
			
			setHoursOfService(temp);
			
			/*setRemoveHOS([...Array(removeHOS.slice(0, removeIdx).length).fill(false),
							true, 
							...removeHOS.slice(removeIdx + 1)
						]);*/
		}
		
	}
	
	
	
	
	
	const handleAddHoursOfServiceEntry = (event) => {
		
		//setRemoveHOS([...removeHOS, false]);
		event.preventDefault();
		event.stopPropagation();
		
		const failcases = verifyWeekSchedule(hoursOfService);
		setInvalidHos(failcases);
		
		if(failcases.length == 0){
			
			setHoursOfService([...hoursOfService, {
												dayofweek: 'Select', 
												hselection_type: null, 
												start: null, 
												end: null,
												sessionDurationHours: null
											}
								]);	
		}
		
		
	}
	
	
	
	useEffect(() => {
		var thosArr = [];

		for (let j=0; j < hoursOfService.length; j++) {
	
			thosArr.push(<ServiceHour
							key={j}
							invalid = {invalidHos.includes(j)}
							hos={hoursOfService[j]}
							hos_index={j}
							token_group={props.token_group}
							noLabels={j == 0 ? false : true}
							handleRemoveHOS={handleRemoveHOS}
							weekdays={weekdays}
							updateCondition={props.updateCondition}
							updateHoursOfService={updateHoursOfService}
							venueOptions={props.venueOptions}
						>
						</ServiceHour>);	
		}
		setHosArr([...thosArr]);

	}, [hoursOfService, invalidHos])
	
	
	
	
	
	return (
		<>
			<div className="form-group mb-1" style={{width:'30%'}}>
				{/*<Select isSearchable={true} options={props.timezones} onChange={onChangeTimezone} style={customStyles} /> */}
			</div>
			{ hosArr }
			<div className="d-flex mb-0">
				<div className="form-group-sm ml-2 mb-0" style={{width: '95%'}}>
					<a href="#" onClick={(e) =>{e.preventDefault(); handleAddHoursOfServiceEntry(e)}}  data-tokengroup={props.number}>
						<span data-tokengroup={props.number}><Icon path={mdiPlus}  title="Add" size={1} data-tokengroup={props.number} />Add Entry</span>
					</a>
				</div>
				{hoursOfService.length > 0 ?
					<div className="container mb-0" data-tokengroup={props.token_group} data-name={props.condition_name} onClick={props.handleRemoveCondition} style={{width: '5%'}}>
						{hosArr.length == 0 ?
							<a href="#" data-tokengroup={props.token_group} data-name={props.condition_name} onClick={props.handleRemoveCondition}> <span data-tokengroup={props.token_group} data-name={props.condition_name} onClick={props.handleRemoveCondition}><Icon data-tokengroup={props.token_group} data-name={props.condition_name} onClick={props.handleRemoveCondition} path={mdiTrashCanOutline}  title="Remove" size={1} /></span> </a>
						: null	
						}
					</div> : null
				}
			</div>
			{hoursOfService.length > 0 ?
				<div className="row">
					<div className="col-sm-5"></div>
					<div className="col-sm-5"></div>
					<div className="col-sm-2">
						<div>
							<div>&#8205;</div>
							<button type="button" className="align-right btn btn-sm btn-light" onClick={onUpdateClick} 
								data-tokengroup={props.number} data-condition={props.condition_name} style={{float: 'right'}}>Update</button>
						</div>
					</div>
				</div> : null
			}
			
		</>
	)
}








const ServiceHour = (props) => {

	const [sessionDurationHours, setSessionDurationHours] = useState(props.hos.session_duration_hours);
	const [dayOfWeek, setDayOfWeek] = useState(props.hos.dayofweek.length > 0 ? props.hos.dayofweek : "Select");
	const [hopStarttime, setHOPStarttime] = useState("")
	const [hopEndtime, setHOPEndtime] = useState("")
	const [sessionVacancy, setSessionVacancy] = useState(1)
	const [reoccurance, setReoccurance] = useState();
	const [recommendedHolder, setRecommendedHolder] = useState()
	const [minBid, setMinBid] = useState();
	const [bidDuration, setBidDuration] = useState();
	const [serviceVenue, setServiceVenue] = useState('');
	
	const daysOfWeek = [
					{value: 'sunday', label: 'Sunday'},
					{value: 'monday', label: 'Monday'},
					{value: 'tuesday', label: 'Tuesday'},
					{value: 'wednesday', label: 'Wednesday'},
					{value: 'thursday', label: 'Thursday'},
					{value: 'friday', label: 'Friday'},
					{value: 'saturday', label: 'Saturday'},
					{value: 'mon-fri', label: 'Mon-Fri'},
					{value: 'sat-sun', label: 'Sat-Sun'}
				];
				
				
	
	const onServiceDayOfWeekChange = (selection) => {
		setDayOfWeek(selection.value);
	}
	
	const serviceHOPEndtimeChange = (value) => {setHOPEndtime(value);}
	
	const serviceHOPStarttimeChange = (value) => {setHOPStarttime(value);}
	
	const reserveSessionChange = (event) => {
		setSessionDurationHours(parseFloat(event.target.value));
	}
	
	const sessionVacancyChange = (event) => {
		setSessionVacancy(parseFloat(event.target.value));
	}
	const onChangeReoccurance = (event, value) => {
		setReoccurance(value);
	}
	const onServiceVenueChange = (selection) => {
		setServiceVenue(selection.value.toLowerCase());
	}
	/*const onChangeOptionServiceHours = (event) => {
		if (event.target.matches('[type="radio"][id^="hoursoption24"]')) {
			setHselectionType(false);
		}else if(event.target.matches('[type="radio"][id^="hoursoptionselect"]')) {
			setHselectionType(true);
		}
	}*/
	
	
	useEffect(() => {
		//if((sessionDurationHours > 0 && dayOfWeek == "")  && ((hselectionType !== null) || (hselectionType && hopStarttime != "" && hopEndtime != ""))){
			
			let daysLowercase = props.weekdays.map(v => v.toLowerCase());
	
			//if(daysLowercase.includes(dayOfWeek) && !(hselectionType == null)){
			if(daysLowercase.includes(dayOfWeek)){
			
					props.updateHoursOfService({
												dayofweek: dayOfWeek, 
												reoccurance: reoccurance,
												recommendedHolder: recommendedHolder,
												minBid: minBid,
												bidDuration: bidDuration,
												start: hopStarttime, 
												end: hopEndtime,
												vacancy: sessionVacancy,
												sessionDurationHours: parseFloat(sessionDurationHours)
					}, props.hos_index);
			
			}
		//}
		//[sessionDurationHours, dayOfWeek, hopStarttime, hopEndtime, hselectionType]
	}, [dayOfWeek, hopStarttime, hopEndtime, sessionDurationHours, bidDuration, minBid, recommendedHolder, reoccurance]);
	
	
	
	return (
		<div className="form-group" style={{backgroundColor: '#f1f3f8'}}>
		
		<div className="row">
			<div className="col-sm-11 mt-2" style={{ border: (props.invalid ? '1px solid red' : '')}}>
				<div className="row">
					<div className="col-sm-2">
				
						{ props.noLabels ? null : <Label for="service_day_select"  class="mb-0"  text="Day of week" >	</Label> }
				
				
						<Select isSearchable={true} options={daysOfWeek} onChange={onServiceDayOfWeekChange} style={customStyles} />
							
					</div>


					<div className="col-sm-10">
				
						<div className="row">
							
							<div className="col-sm mb-0">
								{ props.noLabels ? null : <Label for={'service_hop_starttime_' + props.token_group + '_' + props.hos_index}  class="mb-0"  text="Start">	</Label> }
								<TimePicker id={'service_hop_starttime_' + props.token_group + '_' + props.hos_index}  disableClock={true} onChange={serviceHOPStarttimeChange}/>
							</div>
							
							<div className="col-sm mb-0">
								{ props.noLabels ? null : <Label for="service_hop_endtime"  class="mb-0"  text="End">	</Label> }
								<TimePicker id={'service_hop_endtime_ ' + props.token_group + '_' + props.hos_index} disableClock={true} onChange={serviceHOPEndtimeChange} />
							</div>
							<div className="col-sm mb-0">
							</div>
							<div className="col-sm mb-0">
								{ props.hos_index == 0 ? <Label for="session"  class="mb-0"  text="Num. sessions">	</Label> : null }
								<input id={'session' + props.token_group + '_' + props.hos_index} type="text"  className="form-control-sm" size="10"  value={sessionVacancy} onChange={sessionVacancyChange} />
							</div>
							<div className="col-sm mb-0">
								{ props.hos_index == 0 ? <Label for="slot_duration"  class="mb-0"  text="Session duration">	</Label> : null}
								<input id={'slot_duration_' + props.token_group + '_' + props.hos_index} type="text"  className="form-control-sm" size="10" onChange={reserveSessionChange} />
							</div>
							
						</div>
						
						
					</div> 
				</div>
				
				<div className="row">
					<div className="col-sm-3">
					{!props.venueFixed ?
						<>
							<div className="container">
								<Label for="service_venue_type_select"  class="mb-0"  text="Venue type" >	</Label>
								<Select id="spend_value_on" options={props.venueOptions} onChange={onServiceVenueChange}  />	
							</div>
							
						</> : null
						
						/*<div className="col-sm-3">
						<Label  text="Availability reoccurance" />
						<div className="btn-group d-flex" role="group" aria-label="Basic radio toggle button group">
						  <input type="radio" className="btn-check" name="btnradio" autocomplete="off"  />
						  <a type="button" className={'btn-sm btn-outline-primary' + (reoccurance == 'once' ? ' active' : '')} onClick={(event)=>{onChangeReoccurance(event, 'once')}}>Once</a>
						
						  <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autocomplete="off" />
						  <a type="button" className={'btn-sm btn-outline-primary' + (reoccurance == 'reoccuring' ? ' active' : '')}  onClick={(event)=>{onChangeReoccurance(event, 'reoccuring')}}>Reoccuring</a>
						</div>
						</div>
						*/}
					
					</div>
					
					<div className="col-sm-5" >
						<Label  text="Who should book this slot" />
						
						<div className="btn-group d-flex" role="group" aria-label="Basic radio toggle button group">
						  <input type="radio" className="btn-check" name="btnradio" autocomplete="off"  />
						  <a type="button" className={'btn-sm btn-outline-primary' + (recommendedHolder == 'anyone' ? ' active' : '')} onClick={()=>{setRecommendedHolder('anyone')}}>Anyone</a>
						
						  <input type="radio" className="btn-check" name="btnradio" autocomplete="off" />
						  <a type="button" className={'btn-sm btn-outline-primary' + (recommendedHolder == 'individuals' ? ' active' : '')}  onClick={()=>{setRecommendedHolder('individuals')}}>Individuals</a>
						
						  <input type="radio" className="btn-check" name="btnradio" autocomplete="off" />
						  <a type="button"  className={'btn-sm btn-outline-primary' + (recommendedHolder == 'groups' ? ' active' : '')}  onClick={()=>{setRecommendedHolder('groups')}}>Orgs / Groups</a>
						</div>
					
					</div>
					
					<div className="col-sm">
						<Label text="Min bid" />
						<Input 
							class="form-control-sm" 
							type="text"
							size="11"
							value={minBid}
							onChange={(event) => {setMinBid(event.target.value)}}
						></Input>
					</div>
					<div className="col-sm">
						<Label text="Bid active" />
						<Input 
							class="form-control-sm" 
							type="text" 
							placeholder="24 hrs"
							size="11"
							onChange={(event) => {setBidDuration(event.target.value)}}
						></Input>
			     	</div>
			     	
				</div>
			</div>
	
			<div className="col-sm-1">
				<div className=" mb-0" data-tokengroup={props.token_group} data-value={props.hos_index} onClick={props.handleRemoveHOS}>
					{ props.noLabels ? null : <Label for={'service_hop_' + props.token_group + '_' + props.hos_index}  className="mb-0"  text="Remove">	</Label> }
					<div id={'service_hop_' + props.token_group + '_' + props.hos_index} data-tokengroup={props.token_group} data-value={props.hos_index} onClick={props.handleRemoveHOS}><a href="#" data-tokengroup={props.token_group} data-value={props.hos_index} onClick={props.handleRemoveHOS}> <span data-tokengroup={props.token_group} data-value={props.hos_index} onClick={props.handleRemoveHOS}><Icon data-tokengroup={props.token_group} data-value={props.hos_index} onClick={props.handleRemoveHOS} path={mdiTrashCanOutline}  title="Remove" size={1} /></span> </a> </div>
				</div>
			</div>
			
		</div>
		{serviceVenue ?
			<ServiceVenue
					token_group={props.token_group}
					condition_name={'service_venue'}
					updateCondition={props.updateCondition}
					serviceVenue={serviceVenue}
					places={[]}
			/> 
		
			: null
		}
		</div>
	)
}



export { HoursOfService, HoursOfServiceHeader };