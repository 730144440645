
import React, {useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import dateFormat, { masks } from 'dateformat';









const GridItem = (props) => {
	
	const navigate = useNavigate();
	
	const [token, setToken] = useState(props.token);
	const [conditions, setConditions] = useState(props.tokenGroupConditions);
	const [imgAspectRatio, setImgAspectRatio] = useState();
	const [imgHeight, setImgHeight] = useState();
	const [imgWidth, setImgWidth] = useState();
	


	const img = new Image();
	img.src = props.collectionImageURL;
	
	img.onload = () => {
	  var imgAspectRatio = (img.width / img.height);
	  setImgHeight('275px')
	  setImgWidth(275 * imgAspectRatio + 'px');
	};




	const onCollectionItem = async(event) => {
	
		if(props !== null && props.collection_name){
			var collection_min_name = props.collection_name.toLowerCase().replace(/\s/g, '_');
			navigate(`/collection/${collection_min_name}/${token.uuid}`, {state: props });
		}
	}

	


	return (
		<div key={props.index} className="item table-cell" data-value={props.index} style={{height: '100%', margin: 'auto', position: 'relative'}}>
			
			<div className="card" style={{height: '100%', margin: '20px', borderRadius: '25px'}}>
				<div className="card-body p-0" style={{height: '100%',  margin: '0px'}}>
					{/* <div className="justify-content-between align-items-start mt-1"> {props.date_period} </div> */}
					<a href='#/' onClick={onCollectionItem} style={{height: '100%', margin: 'auto'}}>
				    	<img className="card-img-top" src={props.collectionImageURL} alt="Image" value={props.collection_name} style={{backgroundColor: '#b1b7ff', width: imgWidth, height: imgHeight, borderRadius: '25px 25px 0px 0px'}} />
				    </a>
				    
				    <div>
						<h5 class="mt-2 mb-2 ml-2">
	                        <a href="#" data-toggle="modal" data-target="#task-detail-modal" class="text-body">{token.name}</a>
	                    </h5>
						
	                    <p class="mb-2 ml-2">
	                    <div>
		                    <span class="pr-2 text-nowrap mb-2 d-inline">
	                            Slot / Network
	                        </span>
	                        <span class="text-nowrap mb-2 d-inline">
	                            <b>{token.properties.slot_name}</b>
	                        </span>
                        </div>
                        <div>
		                    <span class="pr-2 text-nowrap mb-2 d-inline">
	                            Can reserve
	                        </span>
	                        <span class="text-nowrap mb-2 d-inline">
	                            <b>{token.properties.recommendedHolder}</b>
	                        </span>
                        </div>
	                    {conditions.pricing && conditions.pricing.is_initial_price ? 
	                    	<>
		                    	<span class="pr-2 text-nowrap mb-2 d-inline">
		                            Price
		                        </span>
		                        <span class="text-nowrap mb-2 d-inline">
		                            <b>{conditions.pricing.price}</b> {token.properties.currency}
		                        </span>
	                        </> : conditions.pricing && conditions.pricing.is_auction ? 
	                        		<>
	                        			<div>
		                        			<span class="pr-2 text-nowrap mb-2 d-inline">
			                            		Min bid: 
			                        		</span>
			                        		<span class="text-nowrap mb-2 d-inline">
					                            <b>{conditions.pricing.min_bid}</b> {token.properties.currency}
					                        </span>
				                        </div>
				                        <div>
					                        <span class="pr-2 text-nowrap mb-2 d-inline">
			                            		Auction starts: 
			                        		</span>
			                        		<span class="text-nowrap mb-2 d-inline">
					                            <b>{ dateFormat(conditions.pricing.bid_start, "mmm dS, yyyy, h:MM TT")}</b> 
					                        </span>
				                        </div>
	                        		</> : null
	                    }
	                        
	                    </p>
					</div>
				</div>
				
			</div>
			
		</div>
	);
}


export default GridItem;