
//import Parser from 'html-react-parser';
import React, {useState, useEffect, useRef	} from 'react'

import MainNav from '../util/MainNav';
import BannerNav from '../util/BannerNav';
//import useWindowDimension from '../util/WindowDimension';
import { connectWalletPressed, getCurrentWalletConnected, addWalletListener } from "../util/interact.js"; 
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import axios from 'axios';

import { Provider } from './Provider';
import { Service } from './Service';
import { SmartContract } from './SmartContract';
import './style.css';

import { BrowserProvider }  from 'ethers';
import {ethers} from 'ethers';
import ContinueGate from '../util/ContinueGate';
import 'react-vertical-timeline-component/style.min.css';
import { css } from '@emotion/css';
import ScrollToBottom from 'react-scroll-to-bottom';
import Footer from "../util/Footer";
import LeftPositionedTimeline from './TimeLine';

import Modal from 'react-modal';




const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    borderRadius:'25px',
    transform: 'translate(-50%, -50%)',
  },
};


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}



 


export default function MintCollection(props) {


	
	const [serviceObj, setService] = useState({serviceOffer: null});
	const [providerObj, setProvider] = useState({});
	const [providerIsSet, setProviderIsSet] = useState(false);
	const [wallet, setWallet] = useState(props.wallet)
	const [mintOption, setMintOption] = useState()
	const [conditions, setConditions] = useState([])
	const [tokenGroup, setTokenGroup] = useState([]);
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
	const [walletConnect, setWalletConnect] = useState(props.walletConnect);
	const [displayWalletConnectError, setDisplayWalletConnectError] = useState(false);
	const [modalWalletConnectIsOpen, setModalWalletConnectIsOpen] = useState(false);
	const [savedTokengGroups, setSavedTokenGroups] = useState(false);
	const [statusWalletConnected, setStatusWalletConnected] =useState('');
	const [deploy, setDepoly] = useState(false);
	const [mint, setMint] = useState(false);
	
	const [proceed, setProceed] = useState({service: false, contract: false });
	const [showContinue, setShowContinue] = useState({service: false, contract: false});
	const [deployReceipt, setDeployReceipt] = useState(undefined)
	
	
	const [providerLoaded, setProviderLoaded] = useState(false);
	const [serviceLoaded, setServiceLoaded] = useState(false);
	const { height, width } = windowDimensions;

	const ROOT_CSS = css({ height: height, width: width	});
	

	const windowSize = useRef([window.innerWidth, window.innerHeight]);
	const isLongScreen = window.innerWidth <  window.innerHeight;
	const isMobileWidth = window.innerWidth < 600 && isLongScreen;






	const onContinue = (step) => {
	
		var temp = {...proceed}
		temp[step] = true;
		setProceed(temp);
		temp = {...showContinue}
		temp[step] = false;
		setShowContinue(temp);
			
			
		/*if(step == 'service'){
			var temp = {...proceed}
			temp.service = true;
			setProceed(temp);
			temp = {...showContinue}
			temp.service = false;
			setShowContinue(temp);
		}else if(step == 'contract'){
			var temp = {...proceed}
			temp.service = true;
			setProceed(temp);
			temp = {...showContinue}
			temp.service = false;
			setShowContinue(temp);
		}*/
	}
	
	
	

	const closeWalletConnectModal = () => {
	
		setWalletConnect({ displayError: displayWalletConnectError, 
								wallet: wallet,
								modalIsOpen: false,  
								closeModal: closeWalletConnectModal
							 });
	}
	
	const handleWalletChanged = (updatedStatus) => {
		setWallet(updatedStatus);
		setDisplayWalletConnectError(updatedStatus.status != 'success');
		setWalletConnect({ displayError: updatedStatus.status != 'success', 
									wallet: updatedStatus,
									modalIsOpen: modalWalletConnectIsOpen,  
									closeModal: closeWalletConnectModal
								 });
	}
	
	const handleStatusChanged = (updatedStatus) => {
		setWallet(updatedStatus);
		setDisplayWalletConnectError(updatedStatus.status != 'success');
		setWalletConnect({ displayError: updatedStatus.status != 'success', 
									wallet: updatedStatus,
									modalIsOpen: modalWalletConnectIsOpen,  
									closeModal: closeWalletConnectModal
								 });
	}
	const handleConnectWalletPressed = () => {
		connectWalletPressed({setConnectError: setDisplayWalletConnectError,
							setModalConnectIsOpen: setModalWalletConnectIsOpen,
							closeConnectModal: closeWalletConnectModal,
							setWallet: setWallet,
							setConnect : setWalletConnect
						});
		
	}
	

	
	useEffect(() => {
	    function handleResize() {
	      setWindowDimensions(getWindowDimensions());
	    }
		
		if(!walletConnect || walletConnect.displayError ){
			
			addWalletListener(handleWalletChanged, handleStatusChanged);

		    getCurrentWalletConnected().then( walletResponse => {
			
		  		setWallet(walletResponse);
		  		setDisplayWalletConnectError(walletResponse.status != 'success')
				setModalWalletConnectIsOpen(walletResponse.status != 'success')
				
			    setWalletConnect({ displayError: walletResponse.status != 'success', 
									wallet: walletResponse,
									modalIsOpen: walletResponse.status != 'success',  
									closeModal: closeWalletConnectModal
								 });
				
			});
		}
		
	    window.addEventListener('resize', handleResize);
	    return () => window.removeEventListener('resize', handleResize);
	}, []);
	
	
	useEffect(() => {
		if(wallet){
			setStatusWalletConnected(walletConnect.displayError ? 'fail' : 'success');
			//setDisplayWalletConnectError(wallet.status != 'success');
			//setModalWalletConnectIsOpen(wallet.status != 'success');
		}
		
	},[walletConnect])
	

	
						
						


	const handleProviderChange = (providerObj) => {
		setProvider(providerObj);

	}
	
	
	const handleServiceChange = (serviceObj) => { 
		setService(serviceObj);
	}

	
	


			
	const signTransaction = async(event) => {
		event.preventDefault();
	
		var registryContractAddress = '0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0';
		var swapContractAddress = '0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512';
		const abi = ["function wrapEther() external payable returns (bool)"]

		const provider = new BrowserProvider(window.ethereum);
		const contract = await new ethers.Contract(swapContractAddress, abi, provider);
		
		const accts = await window.ethereum.request({ method: 'eth_requestAccounts' });
		const signer = await provider.getSigner();

        await contract.connect(signer).wrapEther({value: ethers.parseUnits("10.0")});

        
		//await provider.send("eth_requestAccounts", []);
		//const signer = provider.getSigner();
		//const address = await signer.getAddress();
		//const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
		
		//const provider = new BrowserProvider(window.ethereum, "any");
		
		/*await provider.send("eth_requestAccounts", []);
		const signer = await provider.getSigner();
		const address = await signer.getAddress();
		
		console.log("Account:", address);
		
		const params = new URLSearchParams({collection: 'abc', address: address});
		
		
		try{
			const response = await axios.get('/api/v1/mint/localhost', { params });
			
		} catch(error){
			console.log(error);
		}*/
	};		
			
			
	const onProceedToDeploy = async(event) => {
		event.preventDefault();
		setDepoly(true)
	}
			
				
	const mintCollection = async(event) => {
		event.preventDefault();
		/*
		const provider = new BrowserProvider(window.ethereum);
		const signer = provider.getSigner();
		*/
		const params = new URLSearchParams([['collection', 'apple'], ['mint_option', 1] ]);
		try{
			const response = await axios.get('/api/v1/mint/localhost', { params });
			
		} catch(error){
			console.log(error);
		}
	};			
	
	const onDeployContract = async(event) => {

		const params = new URLSearchParams([['collection', serviceObj.serviceName], ['contract_type', tokenGroup[0].contractProperties.type]]);
		var response = await axios.get('/api/v1/deploy/localhost', { params });
		
		var depObj = response.data.tx_receipt;
		var gasUsed = parseInt(depObj.gasUsed.hex, 16);
		var gasPrice = ethers.formatEther(depObj.effectiveGasPrice.hex);

		depObj.maticGasCost = parseFloat(gasUsed * gasPrice).toFixed(6);
		setDeployReceipt(depObj);

		
	}
	
	const onMintOptionSubmit = async(event) => {
		var option = parseInt(event.target.dataset.value);
		/*
		const provider = new BrowserProvider(window.ethereum);

		const signer = await provider.getSigner();

		var collection = 'Uber Voucher' //serviceObj.serviceName.toLowerCase().trim();
		collection = collection.toLowerCase().trim();
		
		const response = await axios.get('/api/v1/collections/' + collection);
		
		console.log(response);
		const params = new URLSearchParams([['collection', 'Uber Voucher'], 
											['mint_option', option]
											['signer', signer.serialize()]]);
		*/
		const params = new URLSearchParams([['collection', serviceObj.serviceName], 
											['mint_option', option] ]);
		const response = await axios.get('/api/v1/mint/localhost', { params });
		
	}
    
    
    
                 
	const onMintOption = (event) => {

		var option = parseInt(event.target.dataset.value);
		setMintOption(option);
	}

	
	
	const uploadCollectionData = async(event) => {
		event.preventDefault();

		var formData = new FormData();

		formData.append("logo", providerObj.logoFile);
		formData.append("banner_image", serviceObj.bannerImage);
		
		for(const item of serviceObj.contentFiles){
			formData.append("content_image", item);
		}
		

		try{
			
			var strdata = JSON.stringify({
				provider: providerObj,
				service: serviceObj,
				token_groups: tokenGroup,
				conditions: conditions
			});

		
			formData.append("strdata", strdata);
			
			const response = await axios.post("/api/v1/collections", formData, {  "Content-Type": "multipart/form-data" });

		} catch(error){
			console.log(error);
		}
	};
	
	
	
	const onSaveTokenGroups = async(event) => {

		var formData = new FormData();
	
		var strdata = JSON.stringify({
			token_groups: tokenGroup,
			conditions: conditions,
			service_name: serviceObj.serviceName
		});
		formData.append("strdata", strdata);
		
		for(let i=0; i < tokenGroup.length; ++i){
			formData.append('token_image_' + i, tokenGroup[i].groupImage[0]);
			if(Object.keys(conditions[i]).includes('content')){
				for(let j=0; j < conditions[i].content.contentFiles.length; ++j){
					formData.append('content_' + i + '_' + j, conditions[i].content.contentFiles[j]);
				}
			}
		}
		
		
		const response = await axios.post("/api/v1/token_groups", formData, {  "Content-Type": "multipart/form-data"  });
		setSavedTokenGroups(true);
	}
	
	
	
	return (
		<div>
			<MainNav 
					brandimagepath="/brand_assets/Meritic.png" 
					brandimageheight="40"
					connectWalletPressed={handleConnectWalletPressed}
					walletConnected={statusWalletConnected == 'success' && wallet.status == 'success'}
					//walletConnected={wallet && wallet.status == 'success'}
					navDisplaySearch={false} /> 
					
			<ScrollToBottom className={ROOT_CSS}>
			<section className="p-5 rounded-3 mb-4" style={{height: height + 'px'}}>
				
			
						
				{/*<BannerNav 
					navoption_ids={['/#consumers', '/#providers']} 
					navoptions={['Register a Service Provider', 'Mint Collection']} 
				>
				</BannerNav>*/}
			

											
				{/*<div style={{display: "flex", alignItems: "flex-start"}}>*/}
				
					<section style={{width:"100%"}}>
						<div className="row">
							{isLongScreen ? null : <div className="col-2"></div>}
							<div className={isLongScreen ? 'col-12' : 'col-8'} >
								<div className="form-group mt-0">
									
									<Provider 
										handleProviderChange={handleProviderChange}
										wallet={wallet}
										walletConnect={walletConnect}
										provider={providerObj}
										providerIsSet={providerIsSet}
										setProviderIsSet={setProviderIsSet}
										setProviderLoaded={setProviderLoaded}
										setShowContinue={setShowContinue}
										showContinue={showContinue}
										isMobileWidth={isMobileWidth}
									/>
									{ !providerObj.isComplete ?
										<ContinueGate continue={ providerLoaded } 
				     					showContinue={showContinue['service']} onContinue={()=>{onContinue('service')}} showBack={false} />  : null
									}
									
									{ proceed['service'] ||  providerObj.isComplete ?
										<>
											<Service 
												providerObj={providerObj}
												handleServiceChange={handleServiceChange}
												wallet={wallet}
												walletConnect={walletConnect}
												setShowContinue={setShowContinue}
												showContinue={showContinue}
												serviceObj={serviceObj}
												setServiceLoaded={setServiceLoaded}
											></Service> 
											<ContinueGate continue={ false } 
				     							showContinue={showContinue['contract']} onContinue={()=>{onContinue('contract')}} showBack={false} /> 
											{proceed['contract'] ?
												<SmartContract 
													providerObj={providerObj}
													serviceObj={serviceObj}
													imgThumbs={serviceObj.imgThumbs}
													serviceImageNameMap={serviceObj.imageNameMap}
													serviceMetadataURI={serviceObj.metadataURI}
													conditions={conditions}
									            	setConditions={setConditions}
													tokenGroup={tokenGroup}
													setTokenGroup={setTokenGroup}
													onSaveTokenGroups={onSaveTokenGroups}
													savedTokengGroups={savedTokengGroups}
												/> : null
											}
											
				                        </> : null
									}
									
								</div>
							</div>
							{ isLongScreen ? null : <div className="col-2"></div> }
						</div>
						
		                <div className="container">
		                	
		                	{ savedTokengGroups ?
		                		<>
			                		<hr style={{height:'1px', width:'100%', backgroundColor:'#000000'}}/>
			                		<div className="container d-flex">
			                			
					                	<div style={{float: 'right'}}>
					                		<a type="button" onClick={onProceedToDeploy} className="align-right btn-sm btn-light">Deploy</a>
									    </div> 
								    </div> 
							    </> : null
					    	}	
		                	{
								
								/*<hr style={{width:'100%'}}></hr>
			                	<div className="d-flex">
				                  	<button className="btn btn-primary" id="create_submit"  onClick={uploadCollectionData}>Submit</button>
				                  	<button className="btn btn-primary" id="create_mint"  onClick={mintCollection}>Mint</button>
				                  	<button className="btn btn-primary" id="create_mint"  onClick={signTransaction}>Purchase</button>
				                </div>*/
				                
				                deploy ? 
				                <div>
								 	<hr style={{height:'1px', width:'100%', backgroundColor:'#000000'}}/>
								 	
								 	<div className="card-group form-group text-center"  style={{width: (deployReceipt === undefined) ? '30%' : '80%', margin: '0 auto'}}>
					     				<div className={'card mb-3 rounded-5' + (mintOption == 1 ? ' border border-5': '' )} data-value="1" onClick={onMintOption} style={{position: 'relative',  marginRight:'15px'}}>
											<div className="card-body" data-value="1" onClick={onMintOption} > 
										      	<h5 className="card-title meritic_dblue">Deploy your Smart Contract to Polygon blockchain network</h5>
										      	<ul class="list-group list-group-flush">
											        <li className="card-text text-left">Contract must be deployed before you can mint tokens</li>
										        </ul>
										   	</div>
										   	<div className="container d-flex">
										        <div class="card-footer border border-5 rounded-5 mr-2 ml-2 mt-2 mb-2">
											        <img src="images/deploy_to_poly.png" className="img-fluid rounded-start"  alt="..." style={{marginBottom: '7%'}}/>
											        {deployReceipt === undefined ? 
											        	<a href="#/" type="button" onClick={onDeployContract} className="btn-sm btn-light">Deploy</a> : <span>Deployed</span>
											        }
										        </div>
										        {deployReceipt !== undefined ? 
										        	<div class="card-footer border border-5  rounded-5 mr-2 ml-2 mt-2 mb-2">
										        		<h5 className="card-title meritic_dblue">Contract deployed successfully</h5>
										        		<div className="row"> <div className="col-3"> <p className="text-justify">Contract address: </p> </div><div className="col-9"><p className="text-justify">{deployReceipt.contractAddress}</p> </div> </div>
										        		<div className="row"> <div className="col-3"> <p className="text-justify">Block number: </p> </div><div className="col-9"><p className="text-justify">{deployReceipt.blockNumber}</p> </div> </div>
										        		<div className="row"> <div className="col-3"> <p className="text-justify">Gas used: </p> </div><div className="col-9"><p className="text-justify">{deployReceipt.maticGasCost + ' MATIC'} </p> </div> </div>
										        		<div className="row"> <div className="col-3"> <p className="text-justify">Transaction hash: </p> </div><div className="col-9"><p className="text-justify">{deployReceipt.transactionHash } </p> </div> </div>
										        		{
															!mint? <a href="#/" type="button" onClick={() => {setMint(true)}} className="btn-sm btn-light">Mint tokens</a>: null
														}
										        		 
										        	</div> : null
											     }
									        </div>
					     				</div>
					     			</div>
								 </div> : null
				                
				            }
				            {	mint ?
								 <>
								 	<h5  className="text-center"> <span className="font-weight-normal meritic_dblue"> Select one of the following options </span></h5>
			     			
					     			<div className="card-group form-group text-center">
					     				<div className={'card mb-3 rounded-5' + (mintOption == 1 ? ' border border-5': '' )} data-value="1" onClick={onMintOption} style={{position: 'relative', marginRight:'15px'}}>
											<div className="card-body" data-value="1" onClick={onMintOption} > 
										      	<h5 className="card-title meritic_dblue">Mint tokens without listing on marketplace</h5>
										      	<ul class="list-group list-group-flush">
											        <li className="card-text text-left">Tokens will be minted to Polygon blockchain</li>
											        <li className="card-text text-left">The service wallet address will  own all minted tokens</li>
										        </ul>
										   	</div>
									        <div class="card-footer border border-5  rounded-5 mr-2 ml-2 mt-2 mb-2" data-value="1" onClick={onMintOption} style={mintOption == 1 ? {backgroundColor: '#CCCCE5'} : null}>
										        <img src="images/to_poly.png" className="img-fluid rounded-start" data-value="1" onClick={onMintOption}   alt="..." style={{marginBottom: '7%'}}/>
										        {mintOption == 1 ? <a href="#/" type="button" data-value="1" onClick={onMintOptionSubmit} className="btn-sm btn-light">Submit</a> : null }
									        </div>
					     				</div>
					     			
				     					<div className={'card mb-3 rounded-5' + (mintOption == 2 ? ' border border-5': '')} data-value="2" onClick={onMintOption} style={{ marginLeft:'15px', marginRight:'15px'}}>
									      <div className="card-body" data-value="2" onClick={onMintOption} >
									        <h5 className="card-title meritic_dblue">Mint tokens and list on marketplace</h5>
									        <ul class="list-group list-group-flush" data-value="2" onClick={onMintOption} >
									        	<li className="card-text text-left">Tokens will be minted to Polygon blockchain</li>
									        	<li className="card-text text-left">The service wallet address will  own all minted tokens</li>
									        	<li className="card-text text-left">Tokens will be listed for sale on Meritic marketplace. You grant Meritic the right to adminster </li>
									        </ul>
									        <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
									       </div>
									        <div class="card-footer border border-5  rounded-5 mr-2 ml-2 mt-2 mb-2" data-value="2" onClick={onMintOption}  style={mintOption == 2 ? {backgroundColor: '#CCCCE5'} : null}>
										        <img src="images/to_poly_merit.png" className="img-fluid rounded-start" data-value="2" onClick={onMintOption}   alt="..." style={{marginBottom: '7%'}} />
										        {mintOption == 2 ? <a href="#/" type="button"  data-value="2" onClick={onMintOptionSubmit}  className="btn-sm btn-light">Submit</a> : null }
									        </div>
				     					</div>
					     			
				     					<div className={'card mb-3 rounded-5' + (mintOption == 3 ? ' border border-5': '')}  data-value="3" onClick={onMintOption}  style={{marginLeft:'15px'}}>
				     					
										      <div className="card-body" data-value="3" onClick={onMintOption} >
										        <h5 className="card-title meritic_dblue">List on marketplace, mint when purchased</h5>
										        <ul class="list-group list-group-flush" data-value="3" onClick={onMintOption} >
										        	<li className="card-text text-left">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</li>
										       	</ul>
										       	<p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
										       </div>
										        
										        <div class="card-footer border border-5  rounded-5 mr-2 ml-2 mt-2 mb-2" data-value="3" onClick={onMintOption}  style={mintOption == 3 ? {backgroundColor: '#CCCCE5'} : null}>
											        <img src="images/to_merit_poly.png" className="img-fluid rounded-start" alt="..." data-value="3" onClick={onMintOption}  style={{marginBottom: '7%'}} />
											        {mintOption == 3 ? <a href="#/" type="button"  data-value="3" onClick={onMintOptionSubmit}  className="btn-sm btn-light">Submit</a> : null }
											    </div>
									 	</div>
					     			</div>
								 </> : null
									
							}
			                
			                
		                </div>
		                
					</section> 
					
					{/*<StickyBox offsetTop={10} offsetBottom={10} bottom style={{alignSelf: "flex-start", width:"15%"}}>
						<LeftPositionedTimeline />
					</StickyBox>*/}
			
				{/*</div>*/}
				</section>
				
			</ScrollToBottom>
			{ walletConnect && walletConnect.displayError ? 
	        	<Modal isOpen={walletConnect.modalIsOpen} style={customStyles}>
	               
	                <button type="button" className="btn-close float-right" onClick={walletConnect.closeModal} aria-label="Close"></button>
	                	<div className="container">
	                		{walletConnect &&
								walletConnect.wallet.status == 'Already processing eth_requestAccounts. Please wait.' ?
								<div>
									<p>Check your Metamask wallet App.</p> 
									<p>You may need to log-in to Metamask to connect your wallet </p>
								</div> : 
								
								<div>
									{walletConnect.wallet.status }
								</div>
							}
	                		
	               		</div>
	                
	            </Modal> : null
	        }
		</div>
		
	);
};