import React, { useState } from "react";
import Switch from "react-switch";


const ToggleSwitch = (props) => {

  return (
  <label htmlFor="small-radius-switch">
  	<span>{props.label}</span>
  	<Switch
  		disabled={props.disabled}
	    checked={props.checked}
	    onChange={props.onChange}
	    height={24} 
	    width={48} 
	    uncheckedIcon={
	      <div
	      	style={{
	          display: "flex",
	          justifyContent: "center",
	          alignItems: "center",
	          height: "100%"
	        }}
	      >
	        No
	      </div>
	    }
	
	    checkedHandleIcon={
	      <div
	        style={{
	          display: "flex",
	          justifyContent: "center",
	          alignItems: "center",
	          height: "100%"
	        }}
	      >
	        Yes
	      </div>
	    }
	    className="react-switch"
	    id={props.id}
	/>
</label>
  );
};


/* styles.css */


export default ToggleSwitch;
