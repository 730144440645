import React, {useState, useEffect, useRef } from 'react';
//import { useEventListener, useHuddle01 } from '@huddle01/react';
//import { useLobby, useAudio, useVideo, useRoom, 
//			usePeers, camStream, micStream, useMeetingMachine } from '@huddle01/react/hooks';
import { useNavigate } from "react-router-dom"
import axios from 'axios';




 
// const HUDDLE01_PROJECT_ID = 'Wdl89cHs7-SrYQIgR_qi4wPRodjAje07';





 
const HuddleStream = (props) => {

	const navigate = useNavigate();
	
	const [roomId, setRoomId] = useState(null);
	// const { initialize, isInitialized } = useHuddle01();
	const [goToLobby, setGoToLobby] = useState(false);
	
	// const { state: meetingRoomState, send } = useMeetingMachine();
	
	
  
  
  
	
	
	
	const onJoinLobby = () => {
		setGoToLobby(true)
	}
	
	const createRoom = async() => {

		const roomId = await fetch("/api/v1/comms/create_room", {
						      method: "POST",
						      headers: {
						        "Content-Type": "application/json",
						      },
						      body: JSON.stringify({
						        hostWallets: [props.wallet.address],
						      }),
						    }).then(function(response){
								return response.json();
							}).then(function(data) {
								return data.roomId;
							});
	
		setRoomId(roomId);

	}



	/*useEffect(() => {
		if(!isInitialized){
			initialize(HUDDLE01_PROJECT_ID);
			createRoom();
		}
		
	},[]);*/
	



	return (
		<>
			<div className="card">
				<div className="card-body">
				    <div className="container">
				    {
						/*
							<div>{isInitialized ? 'Hello World!' : 'Please initialize'}</div> 
					   		<h2 className="text-2xl">Me Id</h2>
					        <div className="break-words">
					          {JSON.stringify(state.context.peerId)}
					        </div>
				        */
					}
				   		
				   		<div>
				   			{
							   /*
							   	Me Video:
						        <video ref={videoRef} autoPlay muted></video>
						        <div className="grid grid-cols-4">
						          {Object.values(peers)
						            .filter(peer => peer.cam)
						            .map(peer => (
						              <Video
						                key={peer.peerId}
						                peerId={peer.peerId}
						                track={peer.cam}
						                debug
						              />
						            ))}
						          {Object.values(peers)
						            .filter(peer => peer.mic)
						            .map(peer => (
						              <Audio key={peer.peerId} peerId={peer.peerId} track={peer.mic} />
						            ))}
						        </div>*/
						   }
					        
					      </div>
				   		<button  onClick={onJoinLobby}> Join Lobby  </button>
				   		{ goToLobby ?
				   			navigate('/room/' + roomId, {state: {collection: props.collection, 
    																userData: props.userData,
    																walletConnect: props.walletConnect, 
																	connectWalletPressed: props.connectWalletPressed,
																	wallet: props.wallet},
																	}) : null
						}
					   	
				        {
							/*
											        <button disabled={!fetchVideoStream.isCallable} onClick={fetchVideoStream}>
				        	FETCH_VIDEO_STREAM
				        </button>
				        
				        <button disabled={!joinRoom.isCallable} onClick={joinRoom}>
			          		JOIN_ROOM 
			        	</button>
			        	
			        	<button disabled={!leaveRoom.isCallable} onClick={leaveRoom}>
			          		LEAVE_ROOM 
			        	</button>
			        	
			        	<button disabled={!produceVideo.isCallable} onClick={() => produceVideo(camStream)}>
			          		Produce Cam  
			        	</button>
			 
			        	<button disabled={!produceAudio.isCallable} onClick={() => produceAudio(micStream)}>
			          		Produce Mic  
			        	</button>
			 
				        <button disabled={!stopProducingVideo.isCallable} onClick={stopProducingVideo}>
				          Stop Producing Cam  
				        </button>
			 
				        <button disabled={!stopProducingAudio.isCallable} onClick={stopProducingAudio}>
				          Stop Producing Mic  
				        </button>*/
						}

				    </div> 
	    		</div>
	    		<div className="container">
	    			<a href="#" className="nav-link px-0 align-middle">
                        <span className="ms-1 d-none d-sm-inline">Room: {roomId}</span>
                    </a>
	 
	    		</div>
	    	</div>
	    </>
	);
};



export { HuddleStream }