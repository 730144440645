/**
 * 
 */
 import React from 'react'
 import Paragraph from './Paragraph';
 import { HashLink as Link } from 'react-router-hash-link';
 import AccordionItem from '../util/AccordionItem';
 
 export default function BannerNav(props){
	const  title_objs = [];
	const navitems = [];
	const number = 1;
	const number2 = 2;
	
	if(props.title){
		title_objs.push(<h2 key={number.toString()} className={props.title_class}>{props.title}</h2>);
	}
	if(props.subtitle){
		title_objs.push(<Paragraph key={number2.toString()} class={props.subtitle_class} text={props.subtitle}></Paragraph> );
	}
	var idx=0;
	for(const item of props.navoptions){
		navitems.push(<a key={item} href={props.navoption_ids[idx]} className="btn-sm btn-light btn-rounded banner_btn">{item}</a>);
		idx += 1;
	}
						
	return (
		<div className="container">
            <div className="align-items-center">
                <div className="mt-md-4">
                    	{title_objs}
                    	<br/>
                    	{navitems}
                </div>
            </div>
        </div>
	);
};










export function BannerAction(props){
	
	return (
		<div className="d-flex" style={{position:'absolute', display:'inline', top:'620px', right:'200px'}}>
        		<Link to="/#contact" onClick={props.handleServiceProviderContact} className="btn-sm btn-dark btn-rounded" style={{textTransform: 'none', display:'inline'}}> Service providers </Link>  
        		&nbsp; 
        		<Link to="/#contact" onClick={props.handleGeneralInquiryContact} className="btn-sm btn-dark btn-rounded" style={{textTransform: 'none', display:'inline'}}> General Inquiry </Link>
        	
        	
        </div>
	);
}



export function BannerAction2(props){
	
	return (
		<div className="d-flex float-end" style={{position:'relative'}}>
			<div className="btn-group" role="group">
			  <button type="button" className="btn-dark btn-rounded">Left</button>
			  <button type="button" className="btn-dark btn-rounded">Middle</button>
			  <button type="button" className="btn-dark btn-rounded">Right</button>
			</div>
		      
        	
        </div>
	);
}