import React, {useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation, useParams } from "react-router-dom"
import CommNav from '../../util/CommNav';
import axios from 'axios';
import Icon from '@mdi/react';
import { mdiWeb, mdiInstagram, mdiTwitter, mdiMapMarkerCircle } from '@mdi/js';
import { connectWallet, getCurrentWalletConnected, addWalletListener } from "../../util/interact.js"; 
//import { HuddleStream } from './Huddle';
/*
import { useEventListener, useHuddle01 } from '@huddle01/react';
import { useLobby, useAudio, useVideo, useRoom, 
			usePeers, camStream, micStream, useMeetingMachine } from '@huddle01/react/hooks';
import { Video, Audio } from '@huddle01/react/components'

import { Lobby } from './Lobby';
import { Label } from '../../util/LineInput';

const HUDDLE01_PROJECT_ID = 'Wdl89cHs7-SrYQIgR_qi4wPRodjAje07';
*/
















const Room = (props) => {
	
	/*const testRoomId = 'sen-rtdc-axe'
	const navigate = useNavigate();
	
	const [hostLogoFromURI, setHostLogoFromURI] = useState(null)
	const [hostName, setHostName] = useState("");
	const [collection, setCollection] = useState(null)
	const [userWallet, setUserWallet] = useState(null)
	
	
	const { state: locationState} = useLocation();
	const { roomId } = useParams();
	const { joinLobby } = useLobby();
	
	const { fetchAudioStream, stopAudioStream, error: micError, produceAudio, stopProducingAudio, stream: micStream, } = useAudio();
  	const { fetchVideoStream, stopVideoStream, error: camError, produceVideo, stopProducingVideo, stream: camStream  } = useVideo();
	
	const { state: neetingState, send } = useMeetingMachine();
	const videoRef = useRef(null);
	const { initialize, isInitialized } = useHuddle01();
	const [enterRoom, setEnterRoom] = useState(false);
	const [exitRoom, setExitRoom] = useState(false);
	
	
	const { joinRoom, leaveRoom } = useRoom();
	const { peers } = usePeers();


	useEventListener('lobby:cam-on', () => {
	    if (neetingState.context.camStream && videoRef.current)
	      videoRef.current.srcObject = neetingState.context.camStream;
	});
	
	
	const onJoinRoom = () => {
		setEnterRoom(true);
		setExitRoom(false);
		joinRoom(testRoomId);
	}
	
	const onLeaveRoom = () => {
		setExitRoom(true);
		setEnterRoom(false);
	}

	useEffect(() => {
		if(locationState){	
			
			if(!isInitialized){
				initialize(HUDDLE01_PROJECT_ID);
			}
	
		
			props.collection = locationState.collection
			props.wallet = locationState.wallet
			setCollection(props.collection)
			setUserWallet(props.wallet)
			
			fetch(props.collection.provider_metadata_uri).then((response) => response.json())
			.then((responseJson) => {
				setHostLogoFromURI(responseJson.image);
				setHostName(responseJson.name)
		
			})
			.catch((error) => {
				console.error(error);
			});
		}
		
	},[locationState]);


	useEffect(() => {

		joinLobby(testRoomId);
		
	}, [joinLobby.isCallable]);
	


	useEffect(() =>{
		console.log(neetingState);
		console.log('^^^      ^^^^      ^^^')
	}, [neetingState.context])


	*/
	
	return (
		<>
		{/*
		<div className="container-fluid" style={{
						backgroundImage: 'url("/images/lobby_bg.png")', 
						backgroundPosition: 'center', 
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
						width: '100vw',
						height: '100vh' }}>
			<CommNav userData={props.userData} brandimagepath={"/brand_assets/Meritic.png"} brandimageheight={"40"} /> 
			{!enterRoom ?
				<Lobby 
					hostLogoFromURI={hostLogoFromURI}
					neetingState={neetingState}
					userWallet={userWallet}
					videoRef={videoRef}
					fetchAudioStream={fetchAudioStream}
					fetchVideoStream={fetchVideoStream}
					stopAudioStream={stopAudioStream}
					stopVideoStream={stopVideoStream}
					onJoinRoom={onJoinRoom}
					hostName={hostName}
					roomId={testRoomId}
				/> : null
			}
			{enterRoom ?
				<div className="container">
					<div className="card bg-dark">            	
				        
						<div className="grid grid-cols-4">
				          {Object.values(peers)
				            .filter(peer => peer.cam)
				            .map(peer => (
				              <Video
				                key={peer.peerId}
				                track={peer.cam}
				                debug
				              />
				            ))}
				          {Object.values(peers)
				            .filter(peer => peer.mic)
				            .map(peer => (
				              <Audio key={peer.peerId} peerId={peer.peerId} track={peer.mic} />
				            ))}
				        </div>
					    
					</div>
					<div className="container form-group bg-dark mx-auto text-center">				
	            		<div className="bg-dark mx-auto">
	            			<ul className="list-group list-group-horizontal list-unstyled align-items-center">
						        <li className="mx-lg-1">
						        	<a  className="btn-sm btn-dark btn-rounded d-none d-lg-inline-flex" href="#/" disabled={!produceAudio.isCallable} onClick={() => produceAudio(micStream)}>
						        		<span className="text-light"><i className="fa-thin fa-microphone fa-xl"></i></span>
						        	</a>
						        </li>
						        <li className="mx-lg-1">
						            <a className="btn-sm btn-dark btn-rounded d-none d-lg-inline-flex" href="#/" disabled={!produceVideo.isCallable} onClick={() => produceVideo(camStream)}>
						        		<span className="text-light"><i className="fa-duotone fa-video fa-xl"></i></span>
						        	</a>
						        </li>
						        <li className="mx-lg-1">
						            <a className="btn-sm btn-dark btn-rounded d-none d-lg-inline-flex" href="#/" disabled={!leaveRoom.isCallable} onClick={onLeaveRoom}>
						        		<span className="text-light"><i className="fa-thin fa-door-open fa-xl"></i></span>
						        	</a>
						        </li>
						        
					        </ul>
	            		</div>
					</div>
				</div> : null
			}
        </div>
        */}
        </>
	);
	
	
	
	
}

export { Room }