import React, {useEffect, useState} from 'react'
import Map from '../../map/Map';
import { Label, Input } from '../../util/LineInput';
import Parser from 'html-react-parser';
import Select from 'react-select';
import VirtualServiceVenue from './VirtualServiceVenue';
import { flushSync } from 'react-dom';





const ServiceVenueHeader = (props) => {
	
	var temp='';
	
	if(props.service_venue.physical){
		for(const place of props.service_venue.physical){
			temp += '<p><span class="mr-1"><span class="font-13 mr-0">' + place.name + '</span> <span class="text-muted font-13 ml-1">' + place.address + ' </span></span></p>';
		}
	}
	temp = Parser(`<div class="container"><h4>${props.condition_name.split('_').join(' ')}</h4><div style="display:block; overflow-y: auto;">${temp}</div></div>`)

	return( temp );
	
}












const ServiceVenue = (props) => {
	

	//const props = {tokenGroup, conditionName,updateCondition, places, setShowTokenGrpSave }
	//var addRemRef = useRef(null);

	const [mapPlaces, setMapPlaces] = useState(props.places);
	
	const [metaAddress, setMetaAddress] = useState("");
	const [physicalAddress, setPhysicalAddress] = useState([]);
	const [physicalAddressSet, setPhysicalAddressSet] = useState(new Set());
	const [markerLabels, setMarkerLabels] = useState(null);
	

	
	
	
	
	useEffect(() => {
		alert(props.showTokenGrpSave)
	}, [props.showTokenGrpSave])
	
	function updateMapPlaces(places){

		setMapPlaces([...places]);
	}
	
	
	
	
	const onUpdateClick = (event) => {
		const name_address = [];

		for(const address of physicalAddress){
			for(const place of mapPlaces){
				
				if(place.formatted_address == address){

					name_address.push({name: place.name, address: address, 
										place_id: place.place_id, geometry: place.geometry});
				}
			}
		}

		props.updateCondition("service_venue", {physical: name_address});
		//props.onShowTokenGrpSave(true);
	}
	
	
	
	const handleAddRemoveMapLocation = (event) => {
		event.preventDefault();
		
		const address = event.target.dataset.address;
		//let tokengroup = parseInt(event.target.id.split('_')[4]);
		
		let token_group_addresses = new Set(physicalAddress);

		if (!token_group_addresses.has(address)){
			setPhysicalAddress([...physicalAddress, address]);
			setPhysicalAddressSet(new Set([...physicalAddress, address]));
		}else{

			var group_addresses = new Set(physicalAddress);
			group_addresses.delete(address);
			setPhysicalAddress(Array.from(group_addresses));
			setPhysicalAddressSet(group_addresses);
			
		}

	}
	
	
	
	const updateLabels = () => {
		var markerLabel = [];
		if(mapPlaces && mapPlaces[0]){
			for (const place of mapPlaces) {
			
				
				markerLabel.push(
					<a key={place.place_id} href="#" className="text-body">
						<div className="media p-2 border border-light">
							<img src={place.icon} className="mr-2 rounded-circle" height="15" />
							<div className="media-body">
								<div className="row">
									<div className="col-sm-6"><h5 className="mt-0 mb-0 font-14">{ place.name}</h5></div>
									<div className="col-sm-6">{props.places.length == 0 ? 
												<button type="button" onClick={handleAddRemoveMapLocation} 
													className={physicalAddressSet.has(place.formatted_address) ? 'btn btn-dark btn-rounded btn-sm font-11' : 'btn btn-outline-dark btn-rounded btn-sm font-11' }
													data-address={place.formatted_address} 
														id={'add_remove_address_btn_' + props.token_group}  style={{maxHeight:'28px', maxWidth:'103px', textTransform: 'none'}}>
														{physicalAddressSet.has(place.formatted_address) ? 'Drop Location' : 'Add Location' }
												</button> : null }</div>
								</div>
								<p className="mt-1 mb-0 text-muted font-14">
			                    	<span className="w-75">{place.formatted_address ? place.formatted_address : place.address}</span>
			                	</p>
			                	
							</div>
						</div>
					</a>);
		
			}
			setMarkerLabels(markerLabel);
		}
		
		
	}

	useEffect(() => {
		updateLabels();
	}, [mapPlaces, physicalAddressSet, physicalAddress])
	


	return (
		<div className="form-group">
			
			{props.serviceVenue == 'virtual' ? 
				<VirtualServiceVenue /> :
				props.serviceVenue == 'physical' ?
				<div className="form-group" style={{backgroundColor: '#f1f3f8'}}>
					<div className="d-flex" style={{backgroundColor: '#f1f3f8', height: '550px', width: '100%' }}>
				      
				      	<div data-simplebar className="border border-light" style={{maxHeight:'550px', overflowY: 'auto'}}>
				      		{markerLabels}
				      	</div>
				      	<Map updatePlaces={updateMapPlaces} serverPlaces={props.places}/> 
				    </div>
				    <div style={{backgroundColor: '#f1f3f8'}}>
						<button type="button" className="align-right btn btn-sm btn-light" onClick={onUpdateClick} 
							data-tokengroup={props.token_group} data-condition={props.condition_name} style={{float: 'right'}}>Update</button>
					</div>
			    </div>
				: null
			}
		</div>
	);
}


export { ServiceVenueHeader, ServiceVenue }