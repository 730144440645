
import React, { useState	} from 'react'
import axios from 'axios';




const PasswordRecovery = () => {
	
	
	
	const [email, setEmail] = useState("");
	const [recoveryEmailSuccess, setRecoveryEmailSuccess] = useState(false);
	const [recoveryEmailSent, setRecoveryEmailSent] = useState(false);

	function handleEmailChange(event){
		setEmail(event.target.value);
	}
	
	
	
	async function handleEmailSubmit(event){
		
		event.preventDefault();
		
		axios.post("https://meritic.xyz/recoverpw_user", {emailaddress: email})
		.then(response =>  {
			setRecoveryEmailSent(true);
			
			if(response.data.success){
				setRecoveryEmailSuccess(true);
			}else{
				
				setRecoveryEmailSuccess(false);
			}
		});
	};


	
	return (
		
		<div className="account-pages mt-5 mb-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-5">
                        <div className="card">
                            <div className="card-header pt-4 pb-4 text-center">
                                <a href="/">
                                    <span><img src="/brand_assets/Meritic.png"  height="41" alt="Meritic logo"/></span>
                                </a>
                            </div>
                            {
								recoveryEmailSuccess ?
								<div className="text-center w-75 m-auto">
                                    <h4 className="text-dark-50 text-center mt-0 font-weight-bold">Reset Password</h4>
                                    <p className="text-muted mb-4" id="reset_info">We have sent you an email with instructions to reset your password.</p>
                                </div> : 
                                
                                <div className="card-body p-4">
                                
	                                <div className="text-center w-75 m-auto">
	                                    <h4 className="text-dark-50 text-center mt-0 font-weight-bold">Reset Password</h4>
	                                    {!recoveryEmailSent ?
	                                    	<p className="text-muted mb-4" id="reset_info">Enter your email address and we'll send you an email with instructions to reset your password.</p>
	                                    	:
											<p className="text-muted mb-4" id="reset_info">We are unable to varify your email address.</p>
										}
	                               
	                                </div>
									
	                                <form id="reset_email" action="" method="POST">
	                                    <div className="form-group mb-3">
	                                        <label htmlFor="emailaddress">Email address</label>
	                                        <input className="form-control" onChange={handleEmailChange} type="email" name="emailaddress" id="emailaddress" required="" placeholder="Enter your email" />
	                                    </div>
	
	                                    <div className="form-group mb-0 text-center">
	                                        <button className="btn-primary" onClick={handleEmailSubmit} id="reset_submit" type="button">Reset Password</button>
	                                    </div>
	                                </form>
	                            </div>
									
							}
                            
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 text-center">
                                <p className="text-muted">Back to <a href="/login" className="text-muted ml-1"><b>Log In</b></a></p>
                            </div>
                        </div>

                    </div> 
                </div>
            </div>
        </div>
	)
}

export default PasswordRecovery;