


//import Parser from 'html-react-parser';
import React, {useState, useEffect, useRef	} from 'react'


import Paragraph from '../util/Paragraph';
import Dropzone from '../util/Dropzone';






export default function FileUpload(props) {
  
	return (
		<div className="container form-group mt-0" style={{width: props.formgroup_width}}>
	            <Dropzone onDrop={props.onFileDrop} backImg={props.backImg} 
	            	noThumbs={props.noThumbs}
	            	getThumbs={props.getThumbs ?  props.getThumbs : () => {}} 
	            	instruction={props.instruction}></Dropzone>
	
 		</div>
 	);
};