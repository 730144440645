

import React, {useState, useEffect } from 'react'
import RadioOption from '../../util/RadioOption';
import { Label } from '../../util/LineInput';
import DTimePicker from '../../util/TimePicker';
import ToggleSwitch from '../../util/ToggleSwitch';
import '../style.css';
import dateFormat, { masks } from 'dateformat';
import Parser from 'html-react-parser';
import { mdiTrashCanOutline} from "@mdi/js";
import Icon from "@mdi/react";
import Select from 'react-select';
import axios from 'axios';





const PricingHeader = (props) => {

 	const biddingStartStr = dateFormat(props.bid_start, "dddd, mmmm dS, yyyy, h:MM:ss TT");
 	const biddingEndStr = dateFormat(props.bid_end, "dddd, mmmm dS, yyyy, h:MM:ss TT");

 	const pricing = props.pricing
	return (
		
		<div>
			<span class="font-16">{props.condition_name.split('_').join(' ')}</span>
			<div class="d-flex">
			
				
				{pricing.is_initial_price ? 
				 <span class="text-muted font-13 mr-1">{pricing.price + ' ' + pricing.charge_frequency } </span> :
					<>
					<span class="font-13 mr-1">Auction </span> 
					<span class="text-muted font-13 mr-1">Min bid: </span><span class="text-muted font-13 ml-1 nr-1">{pricing.min_bid} </span> 
					<span class="font-13 mr-2 ml-2">Bidding start: </span><span class="text-muted font-13 ml-1">{biddingStartStr} </span> 
					<span class="font-13 mr-2 ml-2">Bidding end: </span><span class="text-muted font-13 ml-1">{biddingEndStr} </span> 
					</>
				}
			</div>
		
		</div>
		
	);
	
}


const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '30px',
      height: '30px',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
    }),
  };
  

const Pricing = (props) => {

	const [selectInitialPrice, setSelectInitialPrice] = useState(false);
	const [selectAuction, setSelectAuction] = useState(false);
	const [chargeFreq, setChargeFreq] = useState(undefined);
	const [bidEndTime, setBidEndTime] = useState(new Date());
	const [bidStartTime, setBidStartTime] = useState(new Date());
	const [price, setPrice]	= useState()
	const [minBid, setMinBid] = useState()
	const [timezones, setTimezones] = useState([]);
	const [bidTimezone, setBidTimezone] = useState();
	
	
	const onChangeBidStart = (value) => {
		setBidStartTime(value);
	}
	
	const onChangeBidEnd =(value) => {
		setBidEndTime(value);
	}
	
	const onChangeChargeFrequency = (event, value) => {
		event.preventDefault();
		event.stopPropagation();
		setChargeFreq(value); 
	};
	
	const onChangeMinBid = (event) => {
		setMinBid(event.target.value);
	}
	
	const onChangePricingOption = (event) => {
		if (event.target.dataset.value == 'initial_price') {
			setSelectInitialPrice(true);
			setSelectAuction(false);

		}else if(event.target.dataset.value == 'auction') {
			setSelectAuction(true);
			setSelectInitialPrice(false);
		}
	}
	
	const onChangePrice = (event) => {
		setPrice(event.target.value);
	}
	
	
	
	
	const getTimezones = async() => {
		const response = await axios.get('/api/v1/timezones/')
		return response.data;
	}
	
	
	useEffect(() => {
		getTimezones().then(data => {
			setTimezones(data);
		});
		
	}, []);
	
	const onChangeTimezone = (selection) => {
		setBidTimezone(selection.value);
	}

	const onUpdateClick = (event) => {
		
		props.updateCondition('pricing', {
				bid_start: bidStartTime, 
				bid_end: bidEndTime, 
				is_initial_price: selectInitialPrice,
				is_auction: selectAuction,
				charge_frequency: chargeFreq,
				price: price,
				min_bid: minBid,
				timezone: bidTimezone
			});
			//props.setShowTokenGrpSave(true);
	}
	
	
	
	
	

	return (
		<div>
			<div className="d-flex">
				<div style={{width: '95%'}}>
				</div>
				<div data-tokengroup={props.token_group} data-cindex={props.cindex} data-name="pricing" onClick={props.handleRemoveCondition} style={{width: '5%'}}>
					<div data-tokengroup={props.token_group} data-cindex={props.cindex} data-name="pricing" onClick={props.handleRemoveCondition}>
						<a href="#" data-tokengroup={props.token_group} data-name="pricing" data-cindex={props.cindex} onClick={props.handleRemoveCondition}> 
							<span data-tokengroup={props.token_group} data-name="pricing" data-cindex={props.cindex} onClick={props.handleRemoveCondition}>
								<Icon data-tokengroup={props.token_group} data-name="pricing" data-cindex={props.cindex} onClick={props.handleRemoveCondition} path={mdiTrashCanOutline}  title="Remove" size={1} />
							</span> 
						</a> 
					</div>
				</div>
			</div>
			<div className="d-flex">
				<div className="list-group-horizontal d-flex"  style={{width: '100%'}}>
					<a className="list-group-item list-group-item-action"  onClick={onChangePricingOption} data-value="initial_price" role="tab">	    			
						<div className="container" onClick={onChangePricingOption} data-value="initial_price">
							<Label for="initial_price"  onClick={onChangePricingOption} data-value="initial_price" className="form-group mb-0"  text="Set initial price" >	</Label>
							{ selectInitialPrice ? 
							<div className="row form-group-sm">
								<div className="col-sm-3">
									<Label  onClick={onChangePricingOption} data-value="initial_price" className="form-group mb-0"  text="Price" />
									<input  type="text"  value={price} onChange={onChangePrice} className="form-control" size="15"  />
								</div>
							
								<div className="col-sm-9 text-center">
									<Label  onClick={onChangePricingOption} data-value="initial_price" className="form-group mb-0"  text="Charge frequency" />
									<div className="btn-group text-left d-flex" role="group" aria-label="Basic radio toggle button group" style={{width:'50%', margin: 'auto'}}>
										<input type="radio" className="btn-check" autocomplete="off" />
										<a type="button" className={'btn-sm btn-outline-primary ' + (chargeFreq == 'once' ? 'time-unit-active' : '')} onClick={(event) => {onChangeChargeFrequency(event, 'once')}}>once</a>
									
									  	<input type="radio" className="btn-check" autocomplete="off" />
									  	<a type="button" className={'btn-sm btn-outline-primary ' + (chargeFreq == 'daily' ? 'time-unit-active' : '')} onClick={(event) => {onChangeChargeFrequency(event, 'daily')}}>daily</a>
									 	
										<input type="radio" className="btn-check" autocomplete="off" />
									  	<a type="button" className={'btn-sm btn-outline-primary ' + (chargeFreq == 'monthly' ? 'time-unit-active' : '')} onClick={(event) => {onChangeChargeFrequency(event, 'monthly')}}>monthly</a>
						
										<input type="radio" className="btn-check" autocomplete="off" />
									  	<a type="button" className={'btn-sm btn-outline-primary ' + (chargeFreq == 'yearly' ? 'time-unit-active' : '')} onClick={(event) => {onChangeChargeFrequency(event, 'yearly')}}>yearly</a>
									</div>
								</div>
							</div>
							: null }
						</div>
					</a>
					<a className="list-group-item  list-group-item-action"  onClick={onChangePricingOption} data-value="auction" role="tab">
						<div onClick={onChangePricingOption} data-value="auction">
							<Label for="auction"  className="mb-0" onClick={onChangePricingOption} data-value="auction" text="Set auction pricing" >	</Label>
							{selectAuction ? 
								<>
									<div className="row form-group-sm">
										<div className="container form-group col-sm-6">
											<Label  onClick={onChangePricingOption} data-value="min_bid" className="mb-0 mr-3"  text="Min bid" />
											<input  type="text" value={minBid} onChange={onChangeMinBid} className="form-control" size="10"  />
										</div>
										<div className="form-group col-sm-6">
											<Label  onClick={onChangePricingOption} data-value="min_bid" className="mb-0 mr-3"  text="Timezone" />
											<Select isSearchable={true} options={timezones} onChange={onChangeTimezone} style={customStyles} />
										</div>
									</div>
								<div className="row form-group-sm">
									
									<div className="col-sm-6">
										<Label  onClick={onChangePricingOption} data-value="min_bid" className="form-group mb-0"  text="Bidding start" />
										<DTimePicker onChange={onChangeBidStart} value={bidStartTime}/> 
									</div>
									<div className="col-sm-6">
										<Label  onClick={onChangePricingOption} data-value="min_bid" className="form-group mb-0"  text="Bidding end" />
										<DTimePicker onChange={onChangeBidEnd} value={bidEndTime} /> 
									</div>
								</div>
								</>: null	
							}
						</div>
					</a>
				</div>
				<div style={{width: '10%'}}>
					<div>&#8205;</div>
					<button type="button" className="align-right btn btn-sm btn-light" onClick={onUpdateClick} 
						data-tokengroup={props.number} data-condition={props.condition_name} data-condition_index={props.condition_index} style={{float: 'right'}}>Update</button>
				</div>
			</div>
		</div>
	);
}




export { PricingHeader, Pricing }