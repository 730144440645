import React, {useEffect, useState, useMemo} from 'react'

import {
  LivepeerConfig,
  createReactClient,
  studioProvider,
  useCreateAsset,
  Player
} from '@livepeer/react';
import '@rajesh896/video-thumbnails-generator';
import axios from 'axios';


const livepeerClient = () => {
	/*const client =  axios.get('http://localhost:3001/api/v1/vendor_client/livepeer', {}).then(response => {
		return response.data;
	});
	return client;
	*/
	
	
	/*if(response.status == 201){
		return response.data.client;
	}*/
	
	const client = createReactClient({
	  provider: studioProvider({
	    apiKey: '',
	  }),
	});
	
	return client;

}
	
const CreateAndViewAsset = (props) => {

		
	const { mutate: createAsset,
			data: assets,
    		status,
    		progress,
    		error,
  		} = useCreateAsset( props.video ? { sources: [{ 
			  										name: props.video.name, 
			  										file: props.video, 
  													storage: { ipfs: true,
											  					metadata: { 
																	  name: 'interesting video',
																	  description: 'a great description of the video',
											                	}
              												}
            									}]
        							} : null,
  						);

  
	

	const progressFormatted = useMemo(() =>
	      progress?.[0].phase === 'failed' ? 'Failed to process video.' 
	      : progress?.[0].phase === 'waiting' ? 'Waiting'
	      : progress?.[0].phase === 'uploading' ? `Uploading: ${Math.round(progress?.[0]?.progress * 100)}%`
	      : progress?.[0].phase === 'processing' ? `Processing: ${Math.round(progress?.[0].progress * 100)}%`
	      : null,
	    [progress],
	  );


	return (
		<>
			{props.video ? <p>{props.video.name}</p> : <p>Select a video file to upload.</p>}
      		{progressFormatted && <p>{progressFormatted}</p>}
 
		      <button
		        onClick={() => {
					//createAsset?.();
		        }}
		        disabled={!createAsset || status === 'loading'}
		      >
		        Upload
		      </button>
		      
		      {assets?.map((asset) => (
		        <div key={asset.id}>
		          <div>
		            <div>Asset Name: {asset?.name}</div>
		            <div>Playback URL: {asset?.playbackUrl}</div>
		            <div>IPFS CID: {asset?.storage?.ipfs?.cid ?? 'None'}</div>
		          </div>
		        </div>
		      ))}
		</>
	)
};


const VideoUpload = (props) => {
	
	const [client, setClient] = useState();
	
	useState(()=>{
		setClient(livepeerClient());
	}, []);
	
	
	/*if(client){
		
		const client2 = createReactClient({
		  provider: studioProvider({
		    apiKey: '883ba129-8aec-4957-8fdf-376c67c6377d',
		  }),
		});
	}*/
	
	
	return (
		<>
		{
			client ?
				<LivepeerConfig client={client}>
			      <CreateAndViewAsset video={props.video} />
			    </LivepeerConfig> : null
		}
		</>
	);
		
}

export default VideoUpload;