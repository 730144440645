
import React, {useState} from 'react'




const TimeGrpType = (props) => {
	



	
	return (
		<div className="meritic_dblue">
			<h5>Type of time token</h5>
	  		<p>Is this service available 24 hours a day, everyday or set from a calendar </p>
	  		<div className="row">
				<div className="col-sm-12 mt-3">
					<div className="btn-group text-center d-flex" role="group" aria-label="Basic radio toggle button group" style={{width:'50%', margin: 'auto'}}>
						<input type="radio" className="btn-check" autocomplete="off" />
						<a type="button" className={'btn-sm btn-outline-primary ' + (props.timeGrpType == 'nocal' ? 'time-unit-active' : '')} onClick={(event) => {props.onChangeTimeGrpType(event, 'nocal')}}>24 hours per day</a>
					
					  	<input type="radio" className="btn-check" autocomplete="off" />
					  	<a type="button" className={'btn-sm btn-outline-primary ' + (props.timeGrpType == 'calendar' ? 'time-unit-active' : '')} onClick={(event) => {props.onChangeTimeGrpType(event, 'calendar')}}>Set on calendar</a>
					</div>
				</div>
			</div>
		</div>
	);
	
}


const ValueGrpType = (props) => {
	



	
	return (
		<div className="meritic_dblue">
			<h5>Type of service token</h5>
	  		<p>Select whether this token group is a unique service offering or token group for Discount tokens </p>
	  		<div className="row">
				<div className="col-sm-12 mt-3">
					<div className="btn-group text-center d-flex" role="group" aria-label="Basic radio toggle button group" style={{width:'50%', margin: 'auto'}}>
						<input type="radio" className="btn-check" autocomplete="off" />
						<a type="button" className={'btn-sm btn-outline-primary ' + (props.valueGrpType == 'offering' ? 'time-unit-active' : '')} onClick={(event) => {props.onChangeValueGrpType(event, 'offering')}}>Unique offerings</a>
					
					  	<input type="radio" className="btn-check" autocomplete="off" />
					  	<a type="button" className={'btn-sm btn-outline-primary ' + (props.valueGrpType == 'discount' ? 'time-unit-active' : '')} onClick={(event) => {props.onChangeValueGrpType(event, 'discount')}}>Discount token</a>
					</div>
				</div>
			</div>
		</div>
	);
	
}



export { ValueGrpType, TimeGrpType }