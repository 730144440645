
import React, {useState, useEffect } from 'react'
import AccordionItem from '../util/AccordionItem';
import { ServiceVenueHeader, ServiceVenue } from "../mint_collections/conditions/ServiceVenue.js"
import { HoursOfServiceHeader } from "../mint_collections/conditions/HoursOfService"



 export function TabbedMarketContent(props){
	const navitabs = [];
	const tabcontent = [];
	const suboptions = [];
	const sub_tabcontent = [];
	
	//const collection = props.collection
	

	for(let i=0; i < props.navoptions.length; i++){
		sub_tabcontent.push([]);
		var item = props.navoptions[i];
		var minitem = item.toLowerCase().replace(/\s/g, '');
		var sitems = props.suboptions[i];
		
		for(var j=0; j < props.suboptions[i].length; j++){
			
			var sminitem = sitems[j].toLowerCase().replace(/\s/g, '');
			
			suboptions.push(
				<li key={sminitem} className="nav-item">
					<a key={minitem + '-' + sminitem} href="#/" onClick={props.handleSubTabSelectChange} id={minitem + '-' + sminitem + "-tab"} className={"nav-link " + (props.subTabSelect == (minitem + '-' + sminitem) ? "active": null)} parenttab={item} value={sitems[j]} data-bs-target={'#' + minitem  + '-tab-pane-' + sminitem}>{sitems[j]}</a>
				</li>
			);

			sub_tabcontent[i].push(
				<div key={minitem + '-' + sminitem} className={"tab-pane fade show " + (props.subTabSelect == (minitem + '-' + sminitem) ? "active": "") } id={minitem + '-tab-pane-' + sminitem} role="tabpanel" aria-labelledby={minitem + '-' + sminitem + "-tab"} tabIndex="0">
					{props.mainContent[j]}
				</div>
			);
		}
		navitabs.push(
			<li key={minitem} className="nav-item">
			<a key={minitem} href="#/" onClick={props.handleTabSelectChange} id={minitem + "-tab"} className={"nav-link " + (props.tabSelect == minitem ? "active": 'active')} value={item} data-bs-target={'#' + props.navRoot + '-tab-pane-' + minitem}>{item}</a>
		  </li>
		);

		tabcontent.push(
			<div key={minitem} className={"tab-pane fade show " + (props.tabSelect == minitem ? "active": "active") } id={'root-tab-pane-' + minitem} role="tabpanel" aria-labelledby={minitem + "-tab"} tabIndex="0">
				<ul className="nav">
					{suboptions}
				</ul>
			</div>
		);
	}
	
	
	return (
		
		<div>
			{/*<ul className="nav">
				{navitabs}
			</ul>*/}
			
			<div className="tab-content">
			  	{tabcontent}
			</div>
			<div className="tab-content">
				{sub_tabcontent}
				
			</div>
        </div>
	);
};