
import React, {useState} from 'react'






const PreferredTimeUnit = (props) => {
	



	
	return (
		<div className="meritic_dblue">
			<h5>Preferred time unit</h5>
	  		<p>This type of smart contract creates tokens that store value in units of time. If, for instance, the preferred unit is <q>hours</q> then time value of a token will be displayed in hours. Select a preferred time unit for the contract.  </p>
	  		<div className="row">
				<div className="col-sm-12 mt-3">
					<div className="btn-group text-center d-flex" role="group" aria-label="Basic radio toggle button group" style={{width:'50%', margin: 'auto'}}>
						<input type="radio" className="btn-check" name="btnradio"  autocomplete="off" />
						<a type="button" className={'btn-sm btn-outline-primary ' + (props.contractTimeUnit == 'minutes' ? 'time-unit-active' : '')} onClick={(event) => {props.onChangeTimeUnit(event, 'minutes')}}>Minutes</a>
					
					  	<input type="radio" className="btn-check" name="btnradio" autocomplete="off" />
					  	<a type="button" className={'btn-sm btn-outline-primary ' + (props.contractTimeUnit == 'hours' ? 'time-unit-active' : '')} onClick={(event) => {props.onChangeTimeUnit(event, 'hours')}}>Hours</a>
					
					  	<input type="radio" className="btn-check" name="btnradio" autocomplete="off" />
					  	<a type="button"  className={'btn-sm btn-outline-primary ' + (props.contractTimeUnit == 'days' ? 'time-unit-active' : '')} onClick={(event) => {props.onChangeTimeUnit(event, 'days')}}>Days</a>
					  	
					  	<input type="radio" className="btn-check" name="btnradio" autocomplete="off" />
					  	<a type="button"  className={'btn-sm btn-outline-primary ' + (props.contractTimeUnit == 'weeks' ? 'time-unit-active' : '')} onClick={(event) => {props.onChangeTimeUnit(event, 'weeks')}}>Weeks</a>
					  	
					  	<input type="radio" className="btn-check" name="btnradio" autocomplete="off" />
					  	<a type="button"  className={'btn-sm btn-outline-primary ' + (props.contractTimeUnit == 'months' ? 'time-unit-active' : '')} onClick={(event) => {props.onChangeTimeUnit(event, 'months')}}>Months</a>
					  	
					  	<input type="radio" className="btn-check" name="btnradio" autocomplete="off" />
					  	<a type="button"  className={'btn-sm btn-outline-primary ' + (props.contractTimeUnit == 'years' ? 'time-unit-active' : '')} onClick={(event) => {props.onChangeTimeUnit(event, 'years')}}>Years</a>
					</div>
				</div>
					
					
					
			
				{/*
					<div className="col-sm-2">
						<div>&#8205;</div>
						<button type="button" className="align-right btn btn-sm btn-light" data-tokengroup={props.number}  style={{float: 'right'}}>Update</button>
					</div>
				*/}
			</div>
		</div>
	);
	
}



export { PreferredTimeUnit }