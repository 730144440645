import React, {useState} from 'react'
import { Label, Input } from '../../util/LineInput';
import DatePicker from 'react-date-picker';
import { HoursOfService, HoursOfServiceHeader }  from './HoursOfService';
import { ServiceVenueHeader, ServiceVenue } from "./ServiceVenue.js"
import AccordionItem from '../../util/AccordionItem';
import { ContentHeader, Content } from "./Content.js"


const { timeZone } = Intl.DateTimeFormat().resolvedOptions();




const EventConditions = (props) => {
	
	const venueOptions = [{label: 'Online / Virtual', value: 'virtual'} , {label: 'Physical', value:'physical'} ];
	
	const [reoccurance, setReoccurance] = useState()
	const [startDate, setStartDate] = useState()
	const [endDate, setEndDate] = useState()
	const [venueFixedOption, setVenueFixedOption] = useState()
	const [venuFixed, setVenueFixed] = useState(true)
	
	

	const onChangeReoccurance = (event, value) => {
		setReoccurance(value);
	}
	
	const onStartDateChange = (value) => {
		setStartDate(value);
	}
	
	const onEndDateChange = (value) => {
		setEndDate(value);
	}
	
	
	return (
		<>
			<div className="d-flex">
	
				<div className="form-group container">
					<Label  class="mb-0"  text="Frequency" />
					<div className="form-group-sm text-center" role="group" aria-label="Basic radio toggle button group">
					  <input type="radio" className="btn-check" name="btnradio" autocomplete="off"  />
					  <a type="button" className={'btn-sm btn-outline-primary' + (reoccurance == 'once' ? ' active' : '')} onClick={(event)=>{onChangeReoccurance(event, 'once')}}>Once</a>
					
					  <input type="radio" className="btn-check" name="btnradio"  autocomplete="off" />
					  <a type="button" className={'btn-sm btn-outline-primary' + (reoccurance == 'reoccuring' ? ' active' : '')}  onClick={(event)=>{onChangeReoccurance(event, 'reoccuring')}}>Reoccuring</a>
					</div>
				</div>
				<div className="container">
					<Label  class="mb-0"  text="Start date" />
					<div className="form-group-sm"> 
						<DatePicker  onChange={onStartDateChange} value={startDate} /> 
					</div> 
				</div>
				<div className="container">
					<Label  class="mb-0"  text="End date" />
					<div className="form-group-sm">
						<DatePicker  onChange={onEndDateChange} value={endDate} /> 
					</div> 
				</div>
			</div>
			<div className="d-flex">
				<div className="form-group container" style={{width:"100%"}}>
					<Label class="mb-1"  text="Service venue"></Label> 
					<div className="btn-group d-flex" role="group" aria-label="Basic radio toggle button group">
					  <input type="radio" className="btn-check" name="btnradio" autocomplete="off"  />
					  <a type="button" className={'btn-sm btn-outline-primary' + (venueFixedOption == 'fixed' ? ' active' : '')} onClick={()=>{setVenueFixedOption('fixed'); setVenueFixed(true); }}>Fixed</a>
					
					  <input type="radio" className="btn-check" name="btnradio" autocomplete="off" />
					  <a type="button" className={'btn-sm btn-outline-primary' + (venueFixedOption == 'varies' ? ' active' : '')}  onClick={()=>{setVenueFixedOption('varies'); setVenueFixed(false); }}>Varies by service hour</a>
					</div>
				</div>
				{ venuFixed ?
					<div className="form-group container" style={{width:"100%"}}>
					{('service_venue' in props.conditions[props.tokengroup]) 
				    					&& Object.keys(props.conditions[props.tokengroup]['service_venue']).length > 0 ?
						<ServiceVenueHeader 
		    				token_group={props.tokengroup}
		    				service_venue={props.conditions[props.tokengroup].service_venue}
		    				condition_name={'service_venue'}
	    				></ServiceVenueHeader> : null }
						<ServiceVenue
								tokenGroup={props.tokengroup}
								conditionName={'service_venue'}
								updateCondition={props.updateCondition}
								places={[]}
								onShowTokenGrpSave={props.onShowTokenGrpSave} 
						/>
						
					</div> : null
				}
			</div>
			
			<div className="form-group-sm container" style={{width:"100%"}}>
				<span>
					<Label class="mb-1 mr-3"  text="Service hours"></Label>
					<Label class="mb-0"  text="Timezone"></Label>
				 	<span className="ml-1"> { timeZone }</span>
				</span>
				<HoursOfService
					number={props.tokengroup}
					token_group={props.tokengroup}
					condition_name="service_hours"
					updateCondition={props.updateCondition}
					handleRemoveCondition={props.handleRemoveCondition}
					setShowTokenGrpSave={props.setShowTokenGrpSave}
					timezones={props.timezones}
					venuFixed={venuFixed}
					venueOptions={venueOptions}
				/>
					
					
				{/*<AccordionItem
			    	header_text={props.conditions[props.tokengroup] && ('service_hours' in props.conditions[props.tokengroup]) 
				    					&& Object.keys(props.conditions[props.tokengroup]['service_hours']).length > 0 ?
			    			<HoursOfServiceHeader 
			    				token_group={props.tokengroup}
			    				condition_name={'service_hours'}
			    				start_date={startDate}
			    				end_date={endDate}
			    			></HoursOfServiceHeader> : 'Service hours'} 
			    			
			    	show_collapse={'show'}
			    	body={ 
				*/}
				
			</div>
			<div className="form-group-sm container" style={{width:"100%"}}>
				<Label class="mb-1 mr-3"  text="Content"></Label>
				{/*<AccordionItem
			    	collapse_container_id={'content_collapse_container'} 
			    	parent_id={'content_' + props.tokengroup} 
			    	header_text={(props.conditions.length > 0 && props.conditions[props.tokengroup] 
			    					&& Object.keys(props.conditions[props.tokengroup]).includes('content')) 
			    					&& (Object.keys(props.conditions[props.tokengroup]['content']).length > 0) ? 
		    			<ContentHeader 
		    				token_group={props.tokengroup}
		    				content={props.conditions[props.tokengroup].content}
		    				condition_name={'content'}
		    			/> : 'Content'} 
		    			
			    	show_collapse={'show'}
			    	body={} 
			    	/> */	
				}
				<Content
					token_group={props.tokengroup}
					cindex={props.tokengroup}
					number={props.number}
					condition_name={'content'}
					handleRemoveCondition={props.handleRemoveCondition}
					updateCondition={props.updateCondition}
					setShowTokenGrpSave={props.onShowTokenGrpSave}
				/>
			</div>
			
			
					
				
		    
		</>
	);
};

export default EventConditions;