



import React, {useState} from 'react'







const VirtualServiceVenue = (props) => {
	
	
	return (
		<div className="form-group">
			<p>Once a counterparty holds one of your time tokens, the party is granted access to you for time specified on the token.</p>
			<div className="card-group form-group text-center" style={{width: '70%', margin: '0 auto'}}>
				<div className={'card mb-3 rounded-5 border border-5'} data-value="1" style={{marginLeft:'15px', marginRight:'15px'}}>
					<div className="card-body" data-value="1"> 
				      	<h5 className="card-title meritic_dblue">Use Google Meet </h5>
				      	<div className="text-left">
				      		<p>Hold your virtual service-meetings on Google Meet. Your counterparties will see your name and/or email address in the meeting invite </p>
				      	</div>
				   	</div>
			        <div class="card-footer border border-5  rounded-5 mr-2 ml-2 mt-2 mb-2" data-value="1">
				        <img src="images/google_meets.png" className="img-fluid rounded-start" data-value="1"   alt="..." style={{marginBottom: '7%'}}/>
			        </div>
				</div>
			
				<div className={'card mb-3 rounded-5 border border-5'} data-value="2"  style={{ marginLeft:'15px', marginRight:'15px'}}>
			      <div className="card-body" data-value="2">
			        <h5 className="card-title meritic_dblue">Use Meritic x Huddle01</h5>
			        <div className="text-left">
			        	<p>Meritic is integrating with Huddle01 to enable you to host Web3 live video  streams  with your counterparties. 
			        	This means you will be able hold, secure,  your service meetings with just your wallet address. 
			        	Meritic will send you and your parties a meeting invite via email, but your counterparties will not see your email address  </p>
			        </div>
			       
			       </div>
			        <div class="card-footer border border-5  rounded-5 mr-2 ml-2 mt-2 mb-2" data-value="2">
				        <img src="images/meritic_huddle.png" className="img-fluid rounded-start" data-value="2" alt="..." style={{marginBottom: '7%'}}/>
			        </div>
				</div>
			
				
			</div>
		</div>
	)
};

export default VirtualServiceVenue;