import React, {useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation, useParams } from "react-router-dom"
import CommNav from '../../util/CommNav';
import axios from 'axios';
import Icon from '@mdi/react';
/*
import { mdiWeb, mdiInstagram, mdiTwitter, mdiMapMarkerCircle } from '@mdi/js';
import { connectWallet, getCurrentWalletConnected, addWalletListener } from "../../util/interact.js"; 
import { HuddleStream } from './Huddle';

//import { useEventListener, useHuddle01 } from '@huddle01/react';
import { useLobby, useAudio, useVideo, useRoom, 
			usePeers, camStream, micStream, useMeetingMachine } from '@huddle01/react/hooks';
import { Video, Audio } from '@huddle01/react/components'

*/
import { Label } from '../../util/LineInput';

const HUDDLE01_PROJECT_ID = 'Wdl89cHs7-SrYQIgR_qi4wPRodjAje07';

















const Lobby = (props) => {
	
	/*const navigate = useNavigate();
	
	const [hostLogoFromURI, setHostLogoFromURI] = useState(null)
	const [hostName, setHostName] = useState("");
	const [collection, setCollection] = useState(null)
	const [userWallet, setUserWallet] = useState(null)
	
	
	const { state: locationState} = useLocation();
	const { roomId } = useParams();
	const { joinLobby } = useLobby();
	
	const { fetchAudioStream, stopAudioStream, error: micError, produceAudio, stopProducingAudio, stream: micStream, } = useAudio();
  	const { fetchVideoStream, stopVideoStream, error: camError, produceVideo, stopProducingVideo, stream: camStream  } = useVideo();
	
	const { state: neetingState, send } = useMeetingMachine();
	const videoRef = useRef(null);
	const { initialize, isInitialized } = useHuddle01();
	const [goToRoom, setGoToRoom] = useState(false);
	*/
	

	
	return (
		<div>						
			<div style={{margin: "auto", width:"50%"}}>
				<div className="d-flex">
					<div className="container">
						<div className="form-group-sm row">
		                	<Label class="col-sm-3 col-form-label"  text="Host"></Label>
		                	<div className="col-sm-9">
		                		<div className="d-flex">
		                		{
									props.hostLogoFromURI != null ?
										<span style={{display: "flex"}}> <img src={ props.hostLogoFromURI } className="img-fluid" width="25" height="25" style={{border: '2px solid #fff', borderRadius: '15%'}} alt="..." /> <h4> { props.hostName} </h4>  </span>
										: null
								}
		                		</div>
							</div>
						</div>
						<div className="form-group-sm row">
		                	<Label class="col-sm-3 col-form-label"  text="Host address"></Label>
		                	<div className="col-sm-9">
				                { props.neetingState.context.peerId }
							</div>
						</div>
						<div className="form-group-sm row">
		                	<Label class="col-sm-3 col-form-label" text="Room Id"></Label>
		                	<div className="col-sm-9">
				                {props.roomId}
							</div>
						</div>
						<div className="form-group-sm row">
		                	<Label class="col-sm-3 col-form-label" text="Scheduled start"></Label>
		                	<div className="col-sm-9">
				                
							</div>
						</div>
					
						
						<div className="container">
							<div className="form-group-sm row">
			                	<Label class="col-sm-3 col-form-label"  text="Your name"></Label>
			                	<div className="col-sm-9">
			                		<div className="d-flex">
			                
			                		</div>
								</div>
							</div>
							{
								props.userWallet ?
									<div className="form-group row">
					                	<Label class="col-sm-3 col-form-label"  text="Your address"></Label>
					                	<div className="col-sm-9">
					                		<a type="button" className="btn-lg btn-dark btn-rounded" onClick={() => {navigator.clipboard.writeText(props.userWallet.address);}}>
							                	{ props.userWallet.address.substring(1, 5) + ' ... ' +  props.userWallet.address.substring(props.userWallet.address.length - 4, props.userWallet.address.length)}
							                </a>
										</div>
									</div> : null
							}
						</div>
		        	</div>
		            <div className="card bg-dark">
		            	<video ref={props.videoRef} autoPlay muted></video> 
					</div>
            	</div>
            
            	
				<div className="container form-group bg-dark mx-auto text-center">
					<Label class="form-group"  text="Test your configs"></Label>					
            		<div className="bg-dark mx-auto">
            			<ul className="list-group list-group-horizontal list-unstyled align-items-center">
					        <li className="mx-lg-1">
					        	<a  className="btn-sm btn-dark btn-rounded d-none d-lg-inline-flex" href="#/" disabled={!props.fetchAudioStream.isCallable} onClick={props.fetchAudioStream}>
					        		<span className="text-light"><i className="fa-thin fa-microphone fa-xl"></i></span>
					        	</a>
					        </li>
					        <li className="mx-lg-1">
					            <a className="btn-sm btn-dark btn-rounded d-none d-lg-inline-flex" href="#/" disabled={!props.fetchVideoStream.isCallable} onClick={props.fetchVideoStream}>
					        		<span className="text-light"><i className="fa-duotone fa-video fa-xl"></i></span>
					        	</a>
					        </li>
					        <li className="mx-lg-1">
					            <a className="btn-sm btn-dark btn-rounded d-none d-lg-inline-flex" href="#/" disabled={!props.fetchVideoStream.isCallable} onClick={props.onJoinRoom}>
					        		<span className="text-light"><i className="fa-thin fa-door-open fa-xl"></i></span>
					        	</a>
					        </li>
					        
				        </ul>
            		</div>
				</div>
      
			</div>
    	</div>
	);
	
	
	
	
}

export { Lobby }