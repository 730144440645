
import React, {useState} from 'react'
import RadioOption from '../../util/RadioOption';
import DTimePicker from '../../util/TimePicker';
import dateFormat from 'dateformat';

import Parser from 'html-react-parser';



const ExpirationDateHeader = (props) => {

	var temp;
	temp = '<span class="mr-1"><span class="font-13 mr-0">Expiration</span> <span class="text-muted font-13 ml-1">' + dateFormat(props.expiration.exp_date, "dddd, mmmm dS, yyyy, h:MM:ss TT") + ' </span></span>';
	temp = Parser(`<h4>${props.condition_name.split('_').join(' ')}</h4><div class="d-flex">${temp}`)
	return( temp );
};




const ExpirationDate = (props) => {
	
	const [expDate, setExpDate] = useState(new Date());
	const [expDaysFromPurchase, setExpDaysFromPurchase] = useState(0);
	const [expirationFixed, setExpirationFixed] = useState(undefined);
	
	
	
	
	const onUpdateClick = (event) => {
	
		props.updateCondition('expiration_date', 
			{
				exp_days_from_purchase: expDaysFromPurchase,
				exp_date: expDate,
				expiration_fixed: expirationFixed
			}
		);
		
		
	}
	
	
	const onChangeOptionExpiration = (event) => {
		if (event.target.matches('[type="radio"][id^="select_fixed_expiration"]')) {
			//let tokengroup = parseInt(event.target.id.split('_')[3]);
			setExpirationFixed(true);
		}else if(event.target.matches('[type="radio"][id^="select_varying_expiration"]')) {
			//let tokengroup = parseInt(event.target.id.split('_')[3]);
			setExpirationFixed(false);
		}
	}
	
	
	
	const expDateChange = value => {setExpDate(value); };
	const expDaysFromPurchaseChange = event => {setExpDaysFromPurchase(event.target.value); };




	
	return (
		<div>
			<table>
		        <thead>
		        	<tr>
		        		<th style={{width: '100px', paddingLeft: '50px'}}><div>Fixed</div><div>expiration</div></th>
		        		<th style={{width: '250px', paddingLeft: '50px'}}> {expirationFixed ? <div><div>Expiration</div><div>date</div> </div>: null }</th>
		        		<th style={{width: '100px', paddingLeft: '50px'}}><div>Varying</div><div>expiration</div></th> 
		        		<th style={{width: '200px', paddingLeft: '50px'}}> {!expirationFixed && !(expirationFixed === undefined) ? <div><div>Days</div><div>from purchase</div></div> : null }</th> 
		        	</tr>
		        </thead>
		        <tbody>
					<tr>
		              <td style={{width: '100px', paddingLeft: '50px'}}>
		              	<RadioOption
							class="form-check-input" type="radio" 
							name={'selectexpirationoption_' + props.token_group} 
							id={'select_fixed_expiration_' + props.token_group}
							value="select_fixed_expiration" 
							label="" 
							onChange={onChangeOptionExpiration}
						> 
						</RadioOption>
		              </td>
		              <td style={{width: '250px', paddingLeft: '50px'}}>
		              	{expirationFixed ? 
			              	<DTimePicker 
								id="exp_date" 
				    			onChange={expDateChange}
								value={expDate}> 
							</DTimePicker> : null 
						}
		              </td>
		              <td style={{width: '100px', paddingLeft: '50px'}}>
		              	<RadioOption
							class="form-check-input" 
							type="radio" 
							name={'selectexpirationoption_' + props.token_group} 
							id={'select_varying_expiration_' + props.token_group}
							value="select_varying_expiration" 
							label=""
							onChange={onChangeOptionExpiration}
						> 
						</RadioOption>
		              </td>
		              
			          <td style={{width: '200px', paddingLeft: '50px'}}>
			             {!expirationFixed && !(expirationFixed === undefined) ? 
							<input 
								className="form-control form-control-sm" 
								id="expD_days_from_purchase" 
								type="number" 
								size="10" 
								onChange={expDaysFromPurchaseChange}
								value={expDaysFromPurchase}
								placeholder="365" /> : null
							}
			           </td>
			              
		              
		            </tr> 
			        
		        </tbody>
		        
	  		</table>
	  		
	  		<div className="row">
				<div className="col-sm-2"></div>
				<div className="col-sm-3"></div>
				<div className="col-sm-2"></div>
				<div className="col-sm-3"></div>
				<div className="col-sm-2">
						<div>&#8205;</div>
						<button type="button" className="align-right btn btn-sm btn-light" onClick={onUpdateClick} 
							data-tokengroup={props.number} data-condition={props.condition_name} style={{float: 'right'}}>Update</button>
				</div>
			</div>
		</div>
	);
	
}


//export default ExpirationDate
export { ExpirationDateHeader, ExpirationDate }