import React, { useState, useEffect, useRef } from "react";
import {BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { connectWalletPressed, getCurrentWalletConnected, addWalletListener } from "./util/interact.js"; 
import MintCollection from "./mint_collections/MintCollection";
import Marketplace from "./marketplace/Marketplace";
import CollectionAta from "./marketplace/CollectionAta";
import TokenView from "./marketplace/TokenView.js";
import { RegisterSuccess } from "./registration/RegistrationForm";
import Registration from "./registration/Registration";
import Login from "./registration/Login";
import PrivacyPolicy from "./legal/PrivacyPolicy";
import TermsOfService from "./legal/TermsOfService";
import PasswordRecovery from "./registration/PasswordRecovery"
import PasswordReset from "./registration/PasswordReset"
//import { useSearchParams } from 'react-router-dom';
import './App.css';
import Meritic from './home/Meritic';
//import "@fortawesome/fontawesome-free/css/all.min.css";
import env from "react-dotenv";

import Modal from 'react-modal';
import { flushSync } from 'react-dom';
import { Lobby } from './marketplace/comm/Lobby';
import { Room } from './marketplace/comm/Room';


	
	
	






function App() {
	
	//const [searchParams, setSearchParams] = useSearchParams();

	const [userData, setUserData] = useState(false);
	const query = new URLSearchParams(window.location.search);
	const username = query.get('username');
	
	const [wallet, setWallet] = useState({});
	const [displayWalletConnectError, setDisplayWalletConnectError] = useState(false);
	const [modalWalletConnectIsOpen, setModalWalletConnectIsOpen] = useState(false);
	
	const closeWalletConnectModal = () => {
		//setModalWalletConnectIsOpen(false);
		
		setWalletConnect({ displayError: displayWalletConnectError, 
								wallet: wallet,
								modalIsOpen: false,  
								closeModal: closeWalletConnectModal
							 });
	}
	
	const [walletConnect, setWalletConnect] = useState();
	
	if(username != null){
		setUserData({username: username, email: query.get('email'), role: parseInt(query.get('role'))})
	}
	
	

	const handleWalletChanged = (updatedStatus) => {
		setWallet(updatedStatus);
	}
	
	const handleStatusChanged = (updatedStatus) => {
		setWallet(updatedStatus);
	}
	
	
	const walletRef = useRef(null);
	
	
	
	
	

	/*const updateWallet =() => {
		const walletResponse =  getCurrentWalletConnected();
		flushSync(() => {
			setWallet(walletResponse);
		});
	    
	    addWalletListener(handleWalletChanged, handleStatusChanged);
	}*/

	useEffect(() => {
		addWalletListener(handleWalletChanged, handleStatusChanged);
		
	    getCurrentWalletConnected().then( walletResponse => {
			//flushSync(() => { setWallet(walletResponse); });
			
			walletRef.current = walletResponse;
			
	  		setWallet(walletResponse);
	
		    setWalletConnect({ displayError: displayWalletConnectError, 
								wallet: walletResponse,
								modalIsOpen: modalWalletConnectIsOpen,  
								closeModal: closeWalletConnectModal
							 });
		});

	}, []);
	
	{/*
		connectWalletPressed({setConnectError: setDisplayWalletConnectError,
							setModalConnectIsOpen: setModalWalletConnectIsOpen,
							closeConnectModal: closeWalletConnectModal,
							setWallet: setWallet,
							setConnect : setWalletConnect
						});
	*/}

	
  return (

	
    <Router>
  		<Routes>
    		
    		<>
    		
			<Route exact path="/configuration" element={
							<MintCollection 
								userData={userData}
								walletConnect={walletConnect} 
								wallet={walletRef.current} 
							/>} />
    		<Route exact path="/collections" element={<Marketplace  userData={userData} />} />
    		<Route exact path="/collection/:collection/:tokenKey" element={<TokenView  userData={userData}/>} /> 
			<Route exact path="/collection/:collection" element={<CollectionAta  />} />
    		<Route exact path="/register" element={<Registration />} />
    		<Route exact path="/login" element={<Login />} />
    		<Route exact path="/recoverpw" element={<PasswordRecovery />} />
    		<Route exact path="/register_success" element={<RegisterSuccess />} />
    		<Route exact path="/privacy_policy" element={<PrivacyPolicy userData={userData} />} />
    		<Route exact path="/terms_of_service" element={<TermsOfService userData={userData} />} />
    		<Route exact path="/recoverpw_email_verification" element={<PasswordReset />} />
    		<Route exact path="/room/:roomId" element={<Room userData={userData} />} />
    		<Route exact path="/" element={
							<Meritic 
	    						userData={userData} 
	    						navDisplaySearch={false}
	    						walletConnect={walletConnect} 
								wallet={walletRef.current} 
	    						/>}/> </> 
	    	
  		</Routes>
	</Router>

  );
}

export default App;
