import React, {useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import {Label, Input} from '../../util/LineInput';
import Modal from 'react-modal';
import Select from "react-dropdown-select";
import { get_gas_estimate, primary_transact } from '../../util/txact_process';








const BidOrder = (props) => {

	const [modalIsOpen, setModalIsOpen] = useState(props.isOpen);
	const [bid, setBid] = useState(0);
	const [quantity, setQuantity] = useState(0);
	const [expiration, setExpiration] = useState(0);
	const [paymentDisplay, setPaymentDisplay] = useState('none');
	const [bidCurrency, setBidCurrency] = useState('USDC')
	const [payCurrency, setPayCurrency] = useState();
	const [commission, setCommission] = useState(0.0);
	const [gasEstimate, setGasEstimate] = useState();
	const [txSuccessful, setTxSuccessful] = useState(false)
	const [txResult, setTxResult] = useState(undefined)
	
	
	const setModalIsOpenToTrue =()=>{
        setModalIsOpen(true);
    }
    const setModalIsOpenToFalse =()=>{
        setModalIsOpen(false)
        props.setInitBid(false);
    }
    
    const onBidChange = (event) => {
		setBid(event.target.value);
		if(parseFloat(event.target.value) > 0){
			setPaymentDisplay('inline-block')
		}else{
			setPaymentDisplay('none')
		}
	}
	
	const onQtyChange = (event) => {
		setQuantity(event.target.value);
	}
	
	const onExpirationChange = (event) => {
		setExpiration(event.target.value);
	}
	
	
    const customStyles = {
	  content: {
	    top: '50%',
	    left: '50%',
	    right: 'auto',
	    bottom: 'auto',
	    marginRight: '-50%',
	    borderRadius:'25px',
	    transform: 'translate(-50%, -50%)',
	  },
	};
	
	const onOrderSubmit = async(orderType) => {
		
		
		const { tx_receipt, reserve_charge, net_charge } = await primary_transact(parseFloat(bid), payCurrency, bidCurrency, props.collection_name, props.token.uuid);

		if(tx_receipt && tx_receipt.status == 1){
			
			setTxSuccessful(true);
				
			const response = await props.onOrderSubmit({
				type: orderType,
				bid: bid,
				quantity: quantity,
				expiration: expiration,
				tx: tx_receipt,
				service: props.collection_name,
				token_key: props.token.uuid,
				tot_charge: net_charge.toString(),
				token_charge: reserve_charge.toString(),
				currency: payCurrency
			});
			
			setTxResult(response.data);
		}
	}
    
    
    
    const handleBidCurrencyChange = (selection) => {
		setBidCurrency(selection[0].value);
	}
	
	
	
	
    const summarizeOrder = async function(pay_currency){
		setPayCurrency(pay_currency);
		setCommission(new Number(0.04 * bid).toPrecision(3));
		const gas_fee_estimate = await get_gas_estimate(pay_currency, parseFloat(bid), 0.04 * parseFloat(bid));
		setGasEstimate(gas_fee_estimate);
	}
	
	
	
	
	
    
    return(
        <>

            <Modal isOpen={modalIsOpen} style={customStyles}>
                
                <div className="float-right">
        			<button type="button" className="btn-close" onClick={setModalIsOpenToFalse}  aria-label="Close"></button>
        		</div>
                <h5>Bid for {props.token.name}</h5>
                <p>Token: {props.token.token_id} </p>
                
                <hr style={{height:'1px', width:'100%', backgroundColor:'#000000'}}/>
                <p>Submit a bid for token: {props.token.token_id} </p>
                <p>of collection: {props.token.collection_name}  </p>
                <div className="container-fluid">
                	<div className="form-group row">
	                	<Label class="col-sm-3 col-form-label" for="bid_price" text="Bid price"></Label>
	                	<div className="col-sm-9">
	                		<dib className="d-flex">
	                			<Input 
									class="form-control" 
									id="bid_price" 
									name="bid_price" 
									type="text"
									size="5"
									placeholder="0.00" 
									value={bid}
									onChange={onBidChange}
								></Input>
								<Select options={props.tXactCurrencyOptions} onChange={handleBidCurrencyChange}  />
	                		</dib>
						</div>
						
					</div>
					<div className="form-group row">
	                	<Label class="col-sm-3 col-form-label" for="bid_quantity" text="Quantity"></Label>
	                	<div className="col-sm-9">
			                <Input 
								class="form-control" 
								id="bid_quantity" 
								name="bid_quantity" 
								type="text"
								size="8"
								placeholder="1" 
								value={quantity}
								onChange={onQtyChange}
							></Input>
						</div>
					</div>
					<div className="form-group row">
	                	<Label class="col-sm-3 col-form-label" for="bid_expiration" text="Expires in"></Label>
	                	<div className="col-sm-9">
			                <Input 
								class="form-control" 
								id="bid_expiration" 
								name="bid_expiration" 
								type="text"
								size="8"
								placeholder="1" 
								value={expiration}
								onChange={onExpirationChange}
							></Input>
						</div>
					</div>
	            </div>
	            <div className="container-fluid" id="payment_div" style={{displalay: paymentDisplay}}>
			      	 <label className="mb-0" for=""> Make payment </label>
				     <div className="d-flex mt-3">
				      	<div style={{width:'100%'}}>	
			        		<a href="#" onClick={() => {summarizeOrder('MATIC')}}>
			               		<img src="/images/tokens/polygon_logo.png" alt="user-image" style={{width:'23%'}} />
			               </a>
			               <div>
			               		MATIC <span id="matic_price"> </span>
			               </div>
				     	</div>
				     	<div style={{width: '100%'}}>	
			        		<a href="#" onClick={() => {summarizeOrder('USDC')}}>
			                	<img src="/images/tokens/usdc_logo.png" alt="user-image" style={{width:'23%'}} />
			                </a>
			                <div>
			               		USDC <span id="usdc_price"> </span>
			               </div>
				  		</div>
				  		<div style={{width:'100%'}}>	
			                <a href="#" onClick={() => {summarizeOrder('ETH')}}>
			                  	<img src="/images/tokens/ethereum_logo.png" alt="user-image" style={{width:'23%'}} />
			                </a>
			                <div>
			               		ETH <span id="usdc_price"> </span>
			               </div>
			            </div>
					 </div>
					{gasEstimate && gasEstimate.status == 'success' ?
						<>
							<div className="form-group-sm row mt-3" style={{textAlign:'left'}}>
							 	<div className="col-6">
							 		<label className="form-label">Token value </label>
								</div>
								<div className="col-6"> <span>{bid + ' ' + payCurrency}</span> </div>
							</div>
							<div className="form-group-sm row" style={{textAlign:'left'}}>
					 			<div className="col-6">
					 				<label className="form-label">Meritic commission </label>
					 			</div>
					 			<div className="col-6"><span>{commission + '  ' + payCurrency}</span></div>
					 		</div>
					 		<div className="form-group-sm row" style={{textAlign:'left'}}>
					 			<div className="col-6">
					 				<label className="form-label">Estimated gas fee </label>
					 			</div>
					 			<div className="col-6">
					 				<span>{gasEstimate.value + ' ' + payCurrency}</span>
					 			</div>
							 </div> 
						 </> : 
						 <>
						 	{gasEstimate ?
							 <div className="col-12">
							 	<span>An error occured: {gasEstimate.error}</span>
							 </div> : null 
							}
						 
						 </>
					}
					 
				 </div>
				 
	            <div>
				 	<hr style={{height:'1px', width:'100%', backgroundColor:'#000000'}}/>
	     	
	     			<a type="button" className="align-right btn-sm btn-light"  onClick={() => {onOrderSubmit('bid')}} style={{float: 'right'}}>Submit</a>
				 </div>
            </Modal>
        </>
    );

};

export { BidOrder }