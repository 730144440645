import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {Input} from './LineInput';


const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};


export default function Dropzone(props) {
  const [files, setFiles] = useState([]);
  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      'image/*': [],
      'video/mp4': []
    },
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
      props.onDrop(acceptedFiles)
    }
  });
  
  var thumbs = files.map(file => (
	  <div className="d-flex">
		<span key={file.name} style={{marginRight: "1rem"}}>
		    <div style={thumb} key={file.name}>
		      <div style={thumbInner}>
		        <img
		          src={file.preview}
		          style={img}
		          // Revoke data uri after image is loaded
		          //onLoad={() => { URL.revokeObjectURL(file.preview) }}
		        />
		      </div>
		    </div>
		    <div>
		    {file.name}
		    </div>
	    </span>
    </div>
  ));
  
  if(props.noThumbs){
	  thumbs = null
  }
  useEffect(() => {
	 if(files.length > 0){
		 props.getThumbs([...files]);
	 }
  }, [files]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
  
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);
  


  return (

  
  <div {...getRootProps({className: 'dropzone'})}  
  		style={{borderRadius:'25px', textAlign: 'center', verticalAlign: 'middle', backgroundImage: props.backImg ? 'url(' + props.backImg + ')': null}}>
  
    <input {...getInputProps()} />
    <h5>{props.instruction}</h5>
    <aside style={thumbsContainer}>
    	{thumbs}
  	</aside>
  </div>

  );
}