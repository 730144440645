/**
 * 
 */
import React,  { useRef, useEffect } from 'react';
import * as d3 from 'd3';




function expand(data){
	var result = [];
	data.forEach(function(item, i){
		var sx;
		var mlength = 180;
		if(i == 0){
			sx = item.text.split('\n').map((text, idx) =>  ({text: text, x: item.x - mlength - 40, y: item.y + 20*idx - 5}) );
		}else{
			sx = item.text.split('\n').map((text, idx) => ({text: text, x: item.x, y: item.y + 20*idx - 5}) );
		}
		
		result = [...result, ...sx]
	})

	return result;
}


export const CalendarLegend = (props) => {


	const d3Container = useRef(null);
	var indis = {};
	var orgs = {};
	var width = props.width
	var height = props.height
	
	indis.x = width / 3;
	indis.y = height / 2;
	indis.text = props.individuals.text;
	indis.color = props.individuals.color;
	
	orgs.x = 2 * width / 3;
	orgs.y = height / 2;
	orgs.text = props.orgs.text;
	orgs.color = props.orgs.color;

	
	
	useEffect(() => {
		if (d3Container.current) {
			const svg = d3.select(d3Container.current)
						.attr('width', width)
	   		 			.attr('height', height)
			
			var node = svg.selectAll('.node')
					    .data([indis, orgs])
					    .enter().append('g')
					    .attr('transform', function(d) { return 'translate(-15,-15)'; });
					      
			node.append('rect')
			  .attr('x', function(d) { return d.x; })
			  .attr('y', function(d) { return d.y; })
			  .attr('width', 30)
			  .attr('height', 30)
			  .attr('stroke', 'white')
			  .attr('fill', function(d) { return d.color; })
  			
  			svg.selectAll("text")
  				.data(expand([indis, orgs]))
  				.enter().append('g')
  				.append('text')
  				.text(function(d) {  return d.text })
				.style('font-size', '15px')
				.attr("x", function(d) { return d.x + 30 + 3; })
				.attr("y", function(d) { return d.y; })
				
				
		}
	});
	
	
	
	return (
		<div className="text-center">
	        <svg
	            className="d3-component" ref={d3Container}
	        />
        </div>
    );

}



