import React, { useState, useCallback, useEffect } from 'react';
import { GoogleMap, useJsApiLoader, StandaloneSearchBox, Marker, MarkerClusterer, InfoWindow } from '@react-google-maps/api';
import axios from 'axios';
import _ from 'lodash'
import Modal from 'react-modal';


const containerStyle = {
  width: '100%',
  height: '100%'
};
const customStyles = {
	  content: {
	    top: '50%',
	    left: '50%',
	    right: 'auto',
	    bottom: 'auto',
	    marginRight: '-50%',
	    borderRadius:'25px',
	    transform: 'translate(-50%, -50%)',
	  },
	};
	


function Map(props) {
	
	//AIzaSyCAgqRVM2Uet20hzFSK80S2J0JSvm_CVX4
  //googleMapsApiKey: "AIzaSyDoUsvKP5AJBINn-ZD_8JzGBKc8gEzNTlI", 

  const { isLoaded } = useJsApiLoader({ id: 'google-map-script',
  												 	googleMapsApiKey: "AIzaSyC4fnOf57B-IAPp5D5PLQW8fkg0FalO3vU", 
  													libraries: ['places'], });

  
  const [searchBox, setSearchBox] = useState(null);
  const [map, setMap] = useState(null)
  const [bounds, setBounds] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [longitude, setLongitude] = useState(0);
  const [latitude, setLatitude] = useState(0);
  const [serverPlaces, setServerPlaces] = useState(props.serverPlaces);
  const [showPlaceTips, setShowPlaceTips] = useState(new Array(props.serverPlaces.length).fill(false));
  const [center, setCenter] = useState(0);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(true);
  const [errorMsg, setErrorMsg] = useState();
  
  const onSBLoad = ref => {
	  setSearchBox(ref);
	};
  
  
  function success(position) {
	 setLongitude(position.coords.longitude);
	 setLatitude(position.coords.latitude);

	 const bounds = new window.google.maps.LatLngBounds({lat: position.coords.latitude, lng: position.coords.longitude});
  }
  function error(err) {
	  console.error(`ERROR(${err.code}): ${err.message}`);
  }
  
  function genMarkers(places){
	  var ltlng = places.map(place => (new window.google.maps.LatLng(place.geometry.location.lat, place.geometry.location.lng)))
	 
	  const bounds = new window.google.maps.LatLngBounds(ltlng);
	  var nextMarkers = places.map(place => ({position: place.geometry.location, place_id: place.place_id}));
	  setBounds(bounds);
	  map.fitBounds(bounds);
	  setMarkers(nextMarkers);
	  setMap(map);
	  //console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^')
	  //nextMarkers.push({position: geolocation.geometry.location, place_id: geolocation.place_id})
  }
  
  async function processPlaces(places){
	  
	if (places.length == 0) {
      return;
    }
	
  	var nextMarkers = [];
	if(props.updatePlaces){
		props.updatePlaces(places);
	}

	for (const place of places) {
	
		/*if (!place.geometry || !place.geometry.location) {
	        console.log("Returned place contains no geometry");
	        return;
	    }*/
	   
	    if (!place.formatted_address) {
	        console.log("Returned place contains no geometry");
	        return;
	    }
	   
	
	    await axios.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + place.formatted_address.split(' ').join('+') + '&key=AIzaSyDoUsvKP5AJBINn-ZD_8JzGBKc8gEzNTlI')
		.then(requestResponse => {
		
			const response = requestResponse.data;

			if (response.results.length){
				var geolocation = response.results[0];
				bounds.extend(geolocation.geometry.location);
			    nextMarkers.push({position: geolocation.geometry.location, place_id: geolocation.place_id})
			}

		}).catch((err) => console.log(err));
	};


	map.fitBounds(bounds);
    setMarkers(nextMarkers);
	setMap(map);
	setBounds(bounds);
  }
  
  
  async function onPlacesChanged(){
	const places = searchBox.getPlaces();
	await processPlaces(places);
  }
  
  const setErrorModalIsOpenToFalse = (event) => {
	
		setErrorModalIsOpen(false);
	}
  
  
  function handleToggleOpen(placeIndex) {

	  if(!showPlaceTips[placeIndex]){
		  setShowPlaceTips([...showPlaceTips.slice(0, placeIndex), true, ...showPlaceTips.slice(placeIndex+1)]);
	  }else{
		  setShowPlaceTips([...showPlaceTips.slice(0, placeIndex), false, ...showPlaceTips.slice(placeIndex+1)]);
	  }
  }
  
 var renderedMarkers = markers.map((location, index) => (<Marker key={location.place_id} 
		   									position={location.position}
		   									onClick={() => handleToggleOpen(index)}>
		   									{showPlaceTips[index] ? <InfoWindow
									            key={location.place_id}
									            position={location.position}
									            visible={true}>
									            <div>
									            	<div id="content">
									            		<div id="siteNotice"></div>
									            		<h5 id="firstHeading" className="firstHeading">Uluru</h5>
									            		<div id="container">
									            			<p><b>Uluru</b>, also referred to as <b>Ayers Rock</b>, <br/>
									            				is a large sandstone rock formation in the southern part of the 
									            			</p>
									            		</div>
									            	</div>
									            </div>
									          </InfoWindow> : null }
		   									</Marker>))

  
  
  
  useEffect(() => {
	  
	 if(props.serverPlaces.length > 0 && props.serverPlaces[0]){
		 const center = {
		  lat: props.serverPlaces[0].geometry.location.lat,
		  lng: props.serverPlaces[0].geometry.location.lng
		};
		setCenter(center);
	 }
  }, []);

	

    /*var infowindow = new window.google.maps.InfoWindow({
      content: contentString
    });*/
    
  return isLoaded ? (
	  
	  <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        //center={center}
        onLoad={map => {
				
				if(serverPlaces.length == 0){

					if(navigator.geolocation){

						//navigator.geolocation.getCurrentPosition(success, error, { enableHighAccuracy: false, timeout: 5000,maximumAge: 0});
						navigator.geolocation.getCurrentPosition((position) => {
							const bounds = new window.google.maps.LatLngBounds({lat: position.coords.latitude, lng: position.coords.longitude});
							map.fitBounds(bounds);
							setMap(map);
							setBounds(bounds);
						}, (err) => {
								if(err.message == 'User denied Geolocation'){
									setErrorMsg(
										<>
											<h5>{'Google Maps needs your location'}</h5>
											<p>{'Please update your Security and Privacy settings. Please enable Location Services for your browser and system settings.'}</p>
										</>
										);
								}
								
								//console.error(`ERROR(${err.code}): ${err.message}`);
							});
			
					}else{
						
						var bounds = new window.google.maps.LatLngBounds();
						map.fitBounds(bounds);
						
					}
				}else{
					if(props.serverPlace){
						var loc = props.serverPlaces[0];
						const bounds = new window.google.maps.LatLngBounds({lat: loc.geometry.location.lat, lng: loc.geometry.location.lng});
						map.fitBounds(bounds);
						setMap(map);
						setBounds(bounds);
						var nextMarkers = serverPlaces.map(place => ({position: place.geometry.location, place_id: place.place_id, title:"Hello World!"}));
						setMarkers(nextMarkers);
					}
				}
				
			  }}
      >
      	{serverPlaces && serverPlaces.length == 0 ? 
	      	<StandaloneSearchBox
		      onLoad={onSBLoad}
		      onPlacesChanged={
		        onPlacesChanged
		      }
		    >
		      <input
		        type="text"
		        placeholder="Customized your placeholder"
		        style={{
		          boxSizing: `border-box`,
		          border: `1px solid transparent`,
		          width: `350px`,
		          height: `32px`,
		          padding: `0 12px`,
		          borderRadius: `3px`,
		          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
		          fontSize: `14px`,
		          outline: `none`,
		          textOverflow: `ellipses`,
		          position: "absolute",
		          left: "50%",
		          marginLeft: "-120px"
		        }}
		      />
		    </StandaloneSearchBox> : null
	    }
	    {
			   renderedMarkers
	
		}
       	{/*
			   <MarkerClusterer
			      averageCenter={true} 
			      enableRetinaIcons={true}
			      gridSize={60}
			    >
			    	{(clusterer) =>
			    		markers.map((location) => (<Marker key={location.place_id} position={location.position} clusterer={clusterer} ></Marker>))
					}
			    	
			    </MarkerClusterer>
		*/}
    
        <></>
      </GoogleMap>
      {errorMsg ? 
      	<Modal isOpen={errorModalIsOpen} style={customStyles}>
	      	<div className="float-right">
				<button type="button" className="btn-close float-right" onClick={(event) => {setErrorModalIsOpenToFalse(event); }} aria-label="Close"></button>
			</div>
			<hr className="mt-3" style={{height:'1px', width:'100%', backgroundColor:'#000000'}}/>
			{ errorMsg }
	  	</Modal> : null
      }
      
      </>
  ) : <></>
}

export default React.memo(Map)




