
import React from 'react'
import { ethers, BrowserProvider } from 'ethers';
import axios from 'axios';
/* global BigInt */



const decimals = new Map(Object.entries({USDC: 6, ETH: 18, MATIC: 18}));







const primary_transact = async function(charge, pay_currency, txact_currency, service, token_uuid){

	// var txact_currency = document.getElementById("reserve_price_currency").value
	var commission = charge * 0.04;
	
	if(txact_currency == pay_currency){
	
		var dec_net_charge = ethers.parseUnits((charge + commission).toString(), decimals.get(pay_currency));
	 	var dec_reserve_charge =  ethers.parseUnits(charge.toString(), decimals.get(pay_currency));

		const provider = new BrowserProvider(window.ethereum);
		// const accts = await window.ethereum.request({ method: 'eth_requestAccounts' });

		const signer = await provider.getSigner();
		const signer_address = await signer.getAddress();
		const matic_balance = await provider.getBalance(signer_address);
		const ERC20ABI = await fetch("/js/erc20.abi.json");
		const MERITIC_TEST_MKT_SERVICE_ADDRESS='0x8Ec18A052CDD6C2aFb74A899295FE3A6B1389B7F';					
		const UNDERLYING_TEST_USDC='0xeD97f5a6eafC2Ec7412bBe38a67d60B628344512';

		
		const out = await fetch("/js/erc20.abi.json")
						.then(res => res.json()).then(async (data) => {
	
			const ERC20ABI = data;
			const USDC = new ethers.Contract(UNDERLYING_TEST_USDC, ERC20ABI, signer);
			const usdc_balance = await USDC.balanceOf(signer_address);
	
			const estimation_transfer  = await USDC.transfer.estimateGas(MERITIC_TEST_MKT_SERVICE_ADDRESS, dec_net_charge);
			//const estimation_transfer = await USDC.connect(signer).estimateGas.transfer(MERITIC_TEST_MKT_SERVICE_ADDRESS, dec_net_charge);
			
			const fee = await provider.getFeeData('latest');
			const transfer_gas_fee = estimate_fee(estimation_transfer, fee);

			const est_gas_fee = ethers.formatUnits(transfer_gas_fee.estimated_gas * transfer_gas_fee.max_fee_per_gas, decimals.get(pay_currency));

			const trans_out = await USDC.connect(signer).transfer(MERITIC_TEST_MKT_SERVICE_ADDRESS, dec_net_charge, {
											gasLimit: transfer_gas_fee.estimated_gas,
											maxFeePerGas: transfer_gas_fee.max_fee_per_gas,
										 	maxPriorityFeePerGas: transfer_gas_fee.max_priority_fee_per_gas
										});
			var tx_receipt = await trans_out.wait();

			return { tx_receipt: tx_receipt, reserve_charge: dec_reserve_charge, net_charge: dec_net_charge }
		});

		return out;
	
	}
}







const estimate_fee = (gas_estimation, fee) => {

	
	const estimated_gas = gas_estimation * BigInt('150') / BigInt('100');
	const max_fee_per_gas = fee.maxFeePerGas * BigInt('150') / BigInt('100');
	const max_priority_fee_per_gas = fee.maxPriorityFeePerGas * BigInt('150') / BigInt('100');

	return {estimated_gas: estimated_gas, max_fee_per_gas: max_fee_per_gas, max_priority_fee_per_gas: max_priority_fee_per_gas};
}


const cex_rate = async(currency_pair) => {
	// var e = document.getElementById("reserve_price_currency");
	
	const result = await fetch(`https://api.coinbase.com/v2/prices/${currency_pair}/buy`)
					.then(res => res.json()).then(data => {
						return data;
					})
	return result;
}


/*const summarize_order = async function(pay_currency){
	var d1 = document.getElementById('charge_summary');
 	var reserve_charge = parseFloat(document.getElementById('reserve_current_price').value);
	var txact_currency = document.getElementById("reserve_price_currency");
	var gas_fee_estimate;
	
	async function start(){
		var commission = new Number(0.04 * reserve_charge).toPrecision(3);
		gas_fee_estimate = await window.get_gas_estimate(pay_currency);
		if(gas_fee_estimate.status == 'success'){
	 		var html_string = `<div class="col-6"><label class="form-label">Token value </label></div><div class="col-6">	<span>${reserve_charge}&nbsp;&nbsp;${pay_currency}</span></div>
	 							<div class="col-6"><label class="form-label">Meritic commission </label></div><div class="col-6"><span>${commission}&nbsp;&nbsp;${pay_currency}</span></div>
	 							<div class="col-6"><label class="form-label">Estimated gas fee </label></div><div class="col-6"><span>${gas_fee_estimate.value}&nbsp;&nbsp;${pay_currency}</span></div>
	 							`;
		}else{
			var html_string = `<div class="col-12"><span>An error occured: ${gas_fee_estimate.error}</span></div>`;
		}
	 	d1.innerHTML =  html_string;
	}
	await start();
}*/


const get_gas_estimate = async function(pay_currency, charge, commission){
	
	//var txact_currency = document.getElementById("reserve_price_currency");

	var dec_net_charge = ethers.parseUnits((charge + commission).toString(), decimals.get(pay_currency));
	var dec_reserve_charge =  ethers.parseUnits(charge.toString(), decimals.get(pay_currency));

	//const UNDERLYING_TEST_USDC='0xeD97f5a6eafC2Ec7412bBe38a67d60B628344512';
	const UNDERLYING_TEST_USDC='0x6d07C1bCE010C38d4a066De453A1Bfd91FfE35B8';

	
	const provider = new BrowserProvider(window.ethereum);
	await provider.send('eth_requestAccounts', []);
	const signer = await provider.getSigner();
	

	const val = await fetch("/js/erc20.abi.json")
	.then(res => res.json()).then(async (data) => {
		
		const ERC20ABI = data;
	
		const USDC = new ethers.Contract(UNDERLYING_TEST_USDC, ERC20ABI, signer);
		
		//const USDC = await new ethers.Contract(UNDERLYING_TEST_USDC, ERC20ABI, provider);
		const MERITIC_TEST_MKT_SERVICE_ADDRESS ='0x8Ec18A052CDD6C2aFb74A899295FE3A6B1389B7F';

		try{
	
			// const accounts = await provider.send("eth_requestAccounts");

			const estimation_transfer  = await USDC.transfer.estimateGas(MERITIC_TEST_MKT_SERVICE_ADDRESS, dec_net_charge);

			// await signer.estimateGas(tx); //USDC.connect(signer).estimateGas.transfer(MERITIC_TEST_MKT_SERVICE_ADDRESS, dec_net_charge);
	
			const fee = await provider.getFeeData('latest');

		
			const transfer_gas_fee = estimate_fee(estimation_transfer, fee);
		
			const est_gas_fee = ethers.formatUnits(transfer_gas_fee.estimated_gas * transfer_gas_fee.max_fee_per_gas, decimals.get('MATIC'));

			const y = await cex_rate(pay_currency + '-USD')
			

			if(Object.keys(y).includes('data')){
	
				var num = new Number(est_gas_fee * Math.pow(y.data.amount, -1));
		
				return {value: num.toPrecision(3), status: 'success', error: null}
			}
		}catch(err){
			console.log(err)
			if(err.message.includes('JSON-RPC error')){
				if(err.data && err.data.code && err.data.code == 3){
					return {value: null, status: 'fail', error: 'Transfer amount exceeds balance'}
				}
			}
		}
	});


	return val;
}
		
		
		
		
export {
	get_gas_estimate, 
	primary_transact
}
		
		