
import AccordionItem from '../util/AccordionItem';
import { PrimaryXSettingsHeader, PrimaryXSettings } from './PrimaryXactSetting';
import { SecondaryXSettingsHeader, SecondaryXSettings } from './SecondaryXactSetting';
import {LineInput, Input, Label } from '../util/LineInput';
import { HoursOfService, HoursOfServiceHeader }  from './conditions/HoursOfService';
import React, {useState, useEffect} from 'react'
import axios from 'axios';
import Icon from "@mdi/react";
import { mdiPlus, mdiTrashCanOutline} from "@mdi/js";
import { ExpirationDate, ExpirationDateHeader } from "./conditions/ExpirationDate.js"
import { StartDate, StartDateHeader } from "./conditions/StartDate.js"
import { EndDate, EndDateHeader } from "./conditions/EndDate.js"
import { ServiceVenueHeader, ServiceVenue } from "./conditions/ServiceVenue.js"
import { PricingHeader, Pricing } from "./conditions/Pricing.js"
import { ContentHeader, Content } from "./conditions/Content.js"
import RadioOption from '../util/RadioOption';
import Parser from 'html-react-parser';

import { PreferredTimeUnit }  from "./conditions/PreferredTimeUnit";
import EventConditions from './conditions/EventConditions';

/*
const location = {
  address: '1600 Amphitheatre Parkway, Mountain View, california.',
  //address: '273 W 138th street, Apt 3D, New York, NY 10030',
  lat: 37.42216,
  lng: -122.08427,
}
*/




async function conditionsForService(sectors) {

	
	const data = await axios.get('/api/v1/service_conditions');
	
	return data;
};



const thumbInner = {
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

	
	

const TokenGroupConditionHeader = (props) => {
	
	
	var dobj = props.tokenGroupSummary;
	
	
	var issuance_value='';
	
	if(dobj.issuance_type == 'fixed'){
		issuance_value =  `<span class="text-muted font-15 mr-1">${dobj.numTokens}</span>`; 
	}
	

	
	return (
		<div className="font-weight-normal" style={{backgroundColor:'#CCCCE5', borderRadius:'1rem'}}> 
			<div className="d-flex">
				<div className="container d-flex" style={{width:'50%', display:'inline-block'}}>
					<span className="font-16 mr-1">
						 <div style={thumbInner}> <img src={dobj.imgPreview}  style={img}  /> </div>
					</span> 
					<span  className="text-black font-16 mr-1" style={{marginRight:'2rem'}}>{dobj.tokenGroupName} </span>
				</div>
				<div className="container d-flex" style={{width:'50%', display:'inline-block'}}>
					<span className="font-16 mr-1">Slot:</span>
					<span className="text-black font-16" style={{marginRight:'2rem'}}>{dobj.slotName + '(' + dobj.slotId + ')'} </span>
				</div>
			</div>
			<div className="d-flex">
				<div className="container d-flex" style={{width:'50%', display:'inline-block'}}>
					<span className="font-16" style={{marginRight:'1em', display:'inline'}}>Token type:</span>
					<span className="text-black font-16 ml-1">{dobj.contractProperties.type + ', ' +  dobj.tokenGrpType}</span>
				</div>
				<div className="container d-flex" style={{width:'50%', display:'inline-block'}}>
					<span className="font-16 ml-1 mr-1" style={{marginRight:'1em'}}>Num. of tokens:</span> 
					<span className="text-black font-16 mr-1" style={{marginRight:'1em'}}>{dobj.numTokens} </span>
				</div>
			</div>
			<div className="d-flex">
				{/*<div className="container d-flex" style={{width:'50%', display:'inline-block'}}>
					<span className="font-16" style={{marginRight:'1em'}}>Initial value:</span>
					<span className="text-black font-16 ml-1">{dobj.initial_value}</span>
				</div>*/}
				{dobj.contractProperties.type == 'value' ?
					<div className="container d-flex" style={{width:'50%', display:'inline-block'}}>
						<span className="font-15" style={{marginRight:'1em'}}>Value currency:</span>
						<span className="text-black font-16 ml-1">{dobj.contractProperties.currency}</span>
					</div>:
					dobj.contractProperties.type == 'time' ?
					<div className="container d-flex" style={{width:'50%', display:'inline-block'}}>
						<span className="font-15" style={{marginRight:'1em'}}>Time unit:</span>
						<span className="text-black font-16 ml-1">{dobj.contractProperties.unit}</span>
					</div> : null
				}
			</div>
			
		</div>
	);
	
}







function TokenGroupConditions(props){

	//const [conditions, setCondition] = useState([]);
	const [serviceTypeConditions, setServiceTypeConditions] = useState({})
	const [proceed, setProceed] = useState(false);
	const [proceedToSecondary, setProceedToSecondary] = useState(false);
	
	//const [update, setUpdate] = useState(false);
	const [conditionSearch, setConditionSearch] = useState("");
	
	const [showTokenGrpSave, setShowTokenGrpSave] = useState(false);
	//const [addCondition, setAddCondition] = useState(false);
	
	function handleRemoveCondition(event){
		event.preventDefault();
		//var condition_index = event.target.dataset.cindex;
		var condition_name =  event.target.dataset.name;
		var tokengroup = event.target.dataset.tokengroup;
		
		var obj = props.conditions[tokengroup]

		delete obj[condition_name];
		props.setConditions([  ...props.conditions.slice(0, tokengroup),
						        {...obj},
								...props.conditions.slice(tokengroup + 1)
						
							]);
		
	}
	
	
	
	
	
	
	async function addCondition(event, item) {
	
		event.preventDefault();
	
		await axios.get('/api/v1/service_conditions/' + item + '/')
		.then(({ data }) => {
			handleCreateCondition(props.number, data);
			setProceed(false);
		})
		setShowTokenGrpSave(false);
	};
	
	
	
	function handleCreateCondition(tokengroup, data) {
		var newcondition = {}

		if(data.condition !== undefined){
			newcondition[data.condition] = {};
			props.setConditions([...props.conditions.slice(0, tokengroup), {...props.conditions[tokengroup], ...newcondition}, ...props.conditions.slice(tokengroup + 1)]);
		
			
			/*if(data.condition == 'service_hours'){
				setRemoveHOS([...removeHOS.slice(0, tokengroup), [...removeHOS[tokengroup], false], ...removeHOS.slice(tokengroup + 1)]);
				
				setHoursOfService([ ...hoursOfService.slice(0, tokengroup), 
									[...hoursOfService[tokengroup], 
										{ dayofweek: 'Select', 
										  hselection_type: null, 
										  start: null, 
										  end: null,
										  session_duration_hours: null
										}
									],
									...hoursOfService.slice(tokengroup + 1)
								]);
			}*/
		}
	}
	
	
	
	
	

	
	

	var tokenConditions = [];
	
	var tokengroup = props.number;	
	
	var service_conditions = [];
	
	
	/*for(const item of Object.keys(serviceTypeConditions)){

		service_conditions.push(<a key={item} href="#" 
									onClick={(event)=>{handleConditionSearch(event); 
														addCondition(event, item);
											}}  
									data-value={item} 
									className="badge badge-light font-18 font-weight-normal mr-1"
									style={{color: '#000080'}}
								>{item.split('_').join(' ')} </a>);
	}*/
	
	
	
	
	
	const updateCondition =  (condition_name, data) => {

		props.setConditions([  ...props.conditions.slice(0, tokengroup),
						        {
									...props.conditions[tokengroup],
									[condition_name]: data
								},
								...props.conditions.slice(tokengroup + 1)
						
							]);

		if(condition_name == 'primary_transaction'){
			setProceedToSecondary(true);
		}else{
	
			/*conditionsForService(props.serviceOffer)
			.then(function(resolve, reject ){
				setServiceTypeConditions(resolve.data);
				//setProceed(true);
			});	*/
		}
		
		setShowTokenGrpSave(true);
	}
	
	
	
	
	/*useEffect(() => {
		
		if(updateConditionHeader.length > 0){
			setUpdateConditionHeader([...Array(tokengroup - 1).fill(false), true, ...Array( updateConditionHeader.length - tokengroup - 1).fill(false)]);
		}else{
			setUpdateConditionHeader([true]);
		}	
		
	}, [update]); */
	
	
	


	const  handleConditionSearch = (event) => {

		var condition_search = event.target.value;

		if(condition_search){
			setConditionSearch(condition_search);
		}else{
			condition_search = event.target.dataset.value;

			setConditionSearch(condition_search);
		}
		
	};
	
	
	const onShowTokenGrpSave = (value) =>{
		setShowTokenGrpSave(true);
	}
	
	
	
	const onProceeed = (event) =>{
		setProceed(true);
	}
	
	/*const onAddCondition=(event) => {
		setAddCondition(true)
	}*/
	
	
	/*async function onUpdateClick(event){

		var tokengroup = parseInt(event.target.dataset.tokengroup);
		var condition_name = event.target.dataset.condition;
	
	   	var dx = await conditionsForService(props.sectors);
		setServiceTypeConditions(dx.data);
		
		//props.setTokenGroupCondition({tokengroup: tokengroup, condition_name: condition_name});
		setUpdate(true);

	};*/
	
	
	
	
	
	
	

		
	var defaultContractConditions = [];
	

	if(props.token_group.contractProperties.type == 'time'){
		
		const items = ['start_date','end_date', 'service_hours', 'service_venue', 'content'];
		for(let i=0; i < items.length; ++i){
			
			const item_str = items[i].replace('_', ' ');
			
			defaultContractConditions.push(<a key={items[i]} href="#" 
												onClick={(event) => {addCondition(event, items[i])}}
												data-value={items[i]}
												className={'badge badge-meritic-light font-16 font-weight-normal mr-1'}
												>{ item_str }</a>);
		}
		
	}else if(props.token_group.contractProperties.type  == 'value'){
		const items = ['pricing', 'start_date', 'expiration_date', 'service_venue', 'content'];
		for(let i=0; i < items.length; ++i){
			
			const item_str = items[i].replace('_', ' ');
			
			defaultContractConditions.push(<a key={items[i]} href="#" 
												onClick={(event) => {addCondition(event, items[i])}}
												data-value={items[i]}
												className={'badge badge-meritic-light font-16 font-weight-normal mr-1'}
												>{ item_str }</a>);
		}
	}
	
	

				

	var tokengroup = props.number;
	var objkeys = Object.keys(props.conditions[tokengroup]);
	
	

	for (var i = 0; i < objkeys.length; i++) {

		if(objkeys[i] == 'expiration_date'){
			
			
			tokenConditions.push(
				<AccordionItem
					key={objkeys[i] + '_' + props.token_group} 
			    	heade_id={objkeys[i]  + '_' + props.token_group + '_header'} 
			    	collapse_container_id={objkeys[i] + '_collapse_container'} 
			    	parent_id={objkeys[i] + '_' + props.token_group} 
			    	header_text={(objkeys[i] in props.conditions[tokengroup]) 
				    					&& Object.keys(props.conditions[tokengroup][objkeys[i]]).length > 0 ?
			    			<ExpirationDateHeader 
			    				token_group={props.token_group}
			    				expiration={props.conditions[tokengroup].expiration_date}
			    				condition_name={objkeys[i]}
			    				condition_index={i}
			    			></ExpirationDateHeader> : objkeys[i].split('_').join(' ')} 
			    	show_collapse={props.show_collapse}
			    	body={
					
						<div>
							<ExpirationDate
								token_group={props.token_group}
								condition_name={objkeys[i]}
			    				condition_index={i}
			    				updateCondition={updateCondition}
			    				onShowTokenGrpSave={onShowTokenGrpSave}
							>
							</ExpirationDate>
							
						</div>
					} 
			    >
			    </AccordionItem>
			);
		}else if(objkeys[i] == 'start_date'){
			
			tokenConditions.push(
				<AccordionItem
						key={objkeys[i] + '_' + tokengroup} 
				    	heade_id={objkeys[i]  + '_' + tokengroup+ '_header'} 
				    	collapse_container_id={objkeys[i] + '_collapse_container'} 
				    	parent_id={objkeys[i] + '_' + tokengroup} 
				    	
				    	header_text={(objkeys[i] in props.conditions[tokengroup]) 
				    					&& Object.keys(props.conditions[tokengroup][objkeys[i]]).length > 0 ? 
			    			<StartDateHeader 
			    				token_group={tokengroup}
			    				start={props.conditions[tokengroup].start_date}
			    				condition_name={objkeys[i]}
			    			></StartDateHeader> : objkeys[i].split('_').join(' ')} 
			    			
				    	show_collapse={props.show_collapse}
				    	body={
							<StartDate
								token_group={tokengroup}
								cindex={i}
								startDateBeforeChange={props.startDateBeforeChange}
								startDateAfterChange={props.startDateAfterChange}
								startDateBefore={props.startDateBefore}
								startDateAfter={props.startDateAfter}
								number={props.number}
								condition_name={objkeys[i]}
								condition_index={i}
								handleRemoveCondition={handleRemoveCondition}
								updateCondition={updateCondition}
								//setShowTokenGrpSave={setShowTokenGrpSave}
								
							></StartDate>
						} 
			    >
			    </AccordionItem>
			);

		}else if(objkeys[i] == 'end_date'){

			tokenConditions.push(
				<AccordionItem
						key={objkeys[i] + '_' + props.token_group} 
				    	heade_id={objkeys[i]  + '_' + props.token_group + '_header'} 
				    	collapse_container_id={objkeys[i] + '_collapse_container'} 
				    	parent_id={objkeys[i] + '_' + props.token_group} 
				    	
				    	header_text={(objkeys[i] in props.conditions[tokengroup]) 
				    					&& Object.keys(props.conditions[tokengroup][objkeys[i]]).length > 0 ? 
			    			<EndDateHeader 
			    				token_group={tokengroup}
			    				end={props.conditions[tokengroup].end_date}
			    				condition_name={objkeys[i]}
			    			></EndDateHeader> : objkeys[i].split('_').join(' ')} 
			    			
				    	show_collapse={props.show_collapse}
				    	body={
							<EndDate
								token_group={tokengroup}
								endDateBeforeChange={props.endDateBeforeChange}
								endDateBefore={props.endDateBefore}
								endDateAfterChange={props.endDateAfterChange}
								endDateAfter={props.endDateAfter}
								number={props.number}
								condition_name={objkeys[i]}
								condition_index={i}
								handleRemoveCondition={handleRemoveCondition}
								updateCondition={updateCondition}
								//setShowTokenGrpSave={setShowTokenGrpSave}
							></EndDate>
						} 
			    >
			    </AccordionItem>
			);
		}else if(objkeys[i] == 'service_hours'){
			
			tokenConditions.push(
				<AccordionItem
					key={objkeys[i] + '_' + tokengroup} 
			    	heade_id={objkeys[i]  + '_' + tokengroup + '_header'} 
			    	collapse_container_id={objkeys[i] + '_collapse_container'} 
			    	parent_id={objkeys[i] + '_' + tokengroup} 
			    	header_text={(objkeys[i] in props.conditions[tokengroup]) 
				    					&& Object.keys(props.conditions[tokengroup][objkeys[i]]).length > 0 ?
			    			<HoursOfServiceHeader 
			    				token_group={tokengroup}
			    				service_hours={props.conditions[tokengroup].service_hours}
			    				condition_name={objkeys[i]}
			    				start_date={props.conditions[tokengroup].start_date}
			    				end_date={props.conditions[tokengroup].end_date}
			    			></HoursOfServiceHeader> : objkeys[i].split('_').join(' ')} 
			    			
			    	show_collapse={props.show_collapse}
			    	body={
						
						<HoursOfService
							number={tokengroup}
							token_group={tokengroup}
							condition_name="service_hours"
							updateCondition={updateCondition}
							handleRemoveCondition={handleRemoveCondition}
							//setShowTokenGrpSave={setShowTokenGrpSave}
							timezones={props.timezones}
						>
						</HoursOfService>
					}
				>
				</AccordionItem>
			);
		}else if(objkeys[i] == 'service_venue'){
			                                       

			tokenConditions.push(
				
				<AccordionItem
					key={objkeys[i] + '_' + tokengroup} 
			    	heade_id={objkeys[i]  + '_' + tokengroup + '_header'} 
			    	collapse_container_id={objkeys[i] + '_collapse_container'} 
			    	parent_id={objkeys[i] + '_' + tokengroup} 
			    	header_text={(objkeys[i] in props.conditions[tokengroup]) 
				    					&& Object.keys(props.conditions[tokengroup][objkeys[i]]).length > 0 ?
		    			<ServiceVenueHeader 
		    				token_group={tokengroup}
		    				service_venue={props.conditions[tokengroup].service_venue}
		    				condition_name={objkeys[i]}
	    				></ServiceVenueHeader> : objkeys[i].split('_').join(' ')} 
			    	show_collapse={props.show_collapse}
			    	body={
						<ServiceVenue
							tokenGroup={tokengroup}
							conditionName={objkeys[i]}
							updateCondition={updateCondition}
							places={[]}
							//setShowTokenGrpSave={setShowTokenGrpSave}
						>
						</ServiceVenue>
						
					} 	
			    >
			    </AccordionItem>
			);
		}else if(objkeys[i] == 'pricing'){
			tokenConditions.push(
				<AccordionItem
					key={objkeys[i] + '_' + tokengroup} 
			    	heade_id={objkeys[i]  + '_' + tokengroup+ '_header'} 
			    	collapse_container_id={objkeys[i] + '_collapse_container'} 
			    	parent_id={objkeys[i] + '_' + tokengroup} 
			    	
			    	header_text={(objkeys[i] in props.conditions[tokengroup]) 
			    					&& Object.keys(props.conditions[tokengroup][objkeys[i]]).length > 0 ? 
		    			<PricingHeader 
		    				token_group={tokengroup}
		    				pricing={props.conditions[tokengroup].pricing}
		    				condition_name={objkeys[i]}
		    			/> : objkeys[i].split('_').join(' ')} 
		    			
			    	show_collapse={props.show_collapse}
			    	body={
						<Pricing
							token_group={tokengroup}
							cindex={i}
							number={props.number}
							condition_name={objkeys[i]}
							condition_index={i}
							handleRemoveCondition={handleRemoveCondition}
							updateCondition={updateCondition}
							//setShowTokenGrpSave={setShowTokenGrpSave}
						></Pricing>
					} 
		    >
		    </AccordionItem>
		    );
		}else if(objkeys[i] == 'content'){
			tokenConditions.push(
				<AccordionItem
					key={objkeys[i] + '_' + tokengroup} 
			    	heade_id={objkeys[i]  + '_' + tokengroup+ '_header'} 
			    	collapse_container_id={objkeys[i] + '_collapse_container'} 
			    	parent_id={objkeys[i] + '_' + tokengroup} 
			    	
			    	header_text={(objkeys[i] in props.conditions[tokengroup]) 
			    					&& Object.keys(props.conditions[tokengroup][objkeys[i]]).length > 0 ? 
		    			<ContentHeader 
		    				token_group={tokengroup}
		    				content={props.conditions[tokengroup].content}
		    				condition_name={objkeys[i]}
		    			/> : objkeys[i].split('_').join(' ')} 
		    			
			    	show_collapse={props.show_collapse}
			    	body={
						<Content
							token_group={tokengroup}
							cindex={i}
							number={props.number}
							condition_name={objkeys[i]}
							condition_index={i}
							handleRemoveCondition={handleRemoveCondition}
							updateCondition={updateCondition}
							//setShowTokenGrpSave={onShowTokenGrpSave}
						/>
					} 
		    >
		    </AccordionItem>
		    );
		}
	}
	
	
	{/*
	tokenConditions.push(
		
		<AccordionItem
			key={'primary_transaction_' + tokengroup} 
	    	heade_id={'primary_transaction_' + tokengroup + '_header'} 
	    	collapse_container_id={'primary_transaction' + '_collapse_container'} 
	    	parent_id={'primary_transaction_' + tokengroup} 
	    	header_text={('primary_transaction' in props.conditions[tokengroup])
	    				&& (Object.keys(props.conditions[tokengroup].primary_transaction).length > 0) ?
					<PrimaryXSettingsHeader
						condition_name="primary_transaction" 
						primary_transaction={props.conditions[tokengroup].primary_transaction}
					>
					</PrimaryXSettingsHeader> : <h5>{'primary_transaction'.split('_').join(' ')}</h5>
				} 
	    	show_collapse={((props.conditions.length > 0) && ('primary_transaction' in props.conditions[tokengroup])) ? 'collapse': 'show'}
	    	body={
				<PrimaryXSettings
					token_group={tokengroup}
					condition_name={'primary_transaction'}
					updateCondition={updateCondition}
				>
				</PrimaryXSettings>
			}
		>
		</AccordionItem>
	);
			
	
	if(proceedToSecondary){
		tokenConditions.push(
			<AccordionItem
				key={'secondary_transaction_' + tokengroup} 
		    	heade_id={'secondary_transaction_' + tokengroup + '_header'} 
		    	collapse_container_id={'secondary_transaction' + '_collapse_container'} 
		    	parent_id={'secondary_transaction_' + tokengroup} 
		    	header_text={('secondary_transaction' in props.conditions[tokengroup]) 
		    				&& (Object.keys(props.conditions[tokengroup].secondary_transaction).length > 0) ? 
		    				<SecondaryXSettingsHeader
								condition_name="secondary_transaction" 
								secondary_transaction={props.conditions[tokengroup].secondary_transaction}
							>
							</SecondaryXSettingsHeader> : 'secondary_transaction'.split('_').join(' ')
				} 
		    	show_collapse='show'
		    	body={
					<SecondaryXSettings
						token_group={tokengroup}
						condition_name="secondary_transaction"
						serviceTypeConditions={serviceTypeConditions}
						updateCondition={updateCondition}
						onShowTokenGrpSave={onShowTokenGrpSave}
					>
					</SecondaryXSettings>
				}
			>
			</AccordionItem>
		);
	}*/}
	

	
	return (
			<AccordionItem
				head_id={'token_group_' + props.number}  
				collapse_container_id={'token_group_' + props.number + '_collapse_container'} 
				parent_id={props.parent} 
				header_text={<TokenGroupConditionHeader tokenGroupSummary={props.tokenGroupSummary}></TokenGroupConditionHeader>}
				show_collapse={props.show_collapse}
				body={
					<>
						<EventConditions conditions={props.conditions} 
													tokengroup={tokengroup} 
													updateCondition={updateCondition}
													handleRemoveCondition={handleRemoveCondition}
													onShowTokenGrpSave={onShowTokenGrpSave}
													timezones={props.timezones}
													 />
						{/*
							tokenConditions
						*/
						}
						<div className="form-group text-center mt-3"  style={{width:'100%', margin: '0 auto'}}>
							{/*<EventConditions
							 tokengroup={props.number}
							 conditions={props.conditions}
							 handleRemoveCondition={handleRemoveCondition}
							 updateCondition={updateCondition}
							 setShowTokenGrpSave={onShowTokenGrpSave}
							/>*/}
							{ defaultContractConditions }
						</div>
						
						{ true ?
							<div>
								
								<div id="conditions_select_container" className="container align-items-center">
									<div className="condition-select-wrapper" style={{backgroundColor:'#fff'}}>
										<p className="justify-content-center">
											{service_conditions}
										</p>
									</div>
								</div>
								<div className="app-search dropdown">
					
					        		<form className="search-wrapper cf" action="#" onSubmit={(event)=>{event.preventDefault()}}>
					        			
					        			<input className="form-control" type="text" 
												placeholder="Search . . ."
												onChange={handleConditionSearch} style={{boxShadow: 'none'}}/>
					        	
					            		<div className="dropdown-menu dropdown-lg search-dropdown" style={{width: '100%'}}></div>
					            		<button type="submit" className="align-right btn btn-sm btn-light" onClick={(event) => {addCondition(event, conditionSearch)}}>Search</button>
					            	</form>
					       		</div> 
							</div>: null
						}
						{showTokenGrpSave ?
							<div className="form-group mt-10 mb-20">
							 	<hr style={{height:'1px', width:'100%', backgroundColor:'#000000'}}/>
							 	<a href="#" onClick={(e) =>{e.preventDefault()}} style={{float: 'left'}}>
									<span><Icon path={mdiPlus} onClick={onProceeed} title="Add" size={1} data-tokengroup={props.number} /> Add condition</span>
								</a>
				     			<div className="d-flex" style={{float:'right'}}>
				     				<a type="button" onClick={props.onSaveTokenGroups} className="align-right btn-sm btn-light mr-1">Save</a>
				     			</div>
							</div> : null
						}
						
		       		</>
				}
			>
			</AccordionItem>
			
	);
}




export default function TokenServiceConditions(props){
	

	var tokenGroupConditions = [];
	
	
	
	for (var i = 0; i < props.tokenGroup.length; i++) {
		tokenGroupConditions.push(
			<TokenGroupConditions key={i} 
				number={i} 
				timezones={props.timezones}
				conditions={props.conditions}
				setConditions={props.setConditions}
				token_group={props.tokenGroup[i]}
				serviceOffer={props.serviceOffer}
				show_collapse="show"	
				tokenGroupSummary={props.tokenGroup[i]}
				handleConditionSearch={props.handleConditionSearch}
				conditionSearch={props.conditionSearch}
				serviceTypeConditions={props.serviceTypeConditions}
				onSaveTokenGroups={props.onSaveTokenGroups}
				savedTokengGroups={props.savedTokengGroups}
			/>
		);
		
	}

  

	
	return (
		
		<div className="form-group mt-0">
			<div className="accordion accordion-flush" id={props.parent}> 
			{
				tokenGroupConditions
			}
			</div>
		</div>
	
	);
};