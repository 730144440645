
import React, {useState, useEffect} from 'react'
import background from "./background_gray_gradient.png";
import './tokenImgStyle.css';






export function ForProviders(props){
	
	/*const [timeCreditIsShown, setTimeCreditIsShown] = useState(false)
	const [cashCreditIsShown, setCashCreditIsShown] = useState(false)
	const [itemsCreditIsShown, setItemsCreditIsShown] = useState(false)
	const [priorityCreditIsShown, setPriorityCreditIsShown] = useState(false)*/
	

	return (
		<>
		<section className="py-1 mb-5" id="providers" style={{marginTop:'5em', marginBottom:'0em'}}>
			<div className="container">
	        	<div className="row" style={{marginTop:'-1em', marginBottom:'1em'}}>

	           		<div className="col-lg-6">
	           			<h1><span  style={{color:'#000080'}}>Why list on a Web3 marketplace</span></h1>
	           		</div>
	          		<div className="col-lg-6"></div>
	          	</div>
	            <div className="container">
	            	<div className="row">
	           			<div className="col-sm-12">
	           				{
								   /* <h2><span className="font-weight-normal font-24" style={{color: '#000080'}}>Gain a new revenue channel</span></h2>*/
							}
	           				
	          					
							  	<div className="accordion accordion-flush" id="accordion_provider_header_1">
								  
								  
								  <div className="accordion-item" style={{backgroundColor:'#fafbfe'}}>
								    <h4 className="accordion-header" id="accordion_provider_header_1_3">
								      <a
								        className="accordion-button collapsed font-16"
										href="#" 
								        data-mdb-toggle="collapse"
								        data-mdb-target="#accordion_provider_collapse_1_3"
								        aria-expanded="false"
								        aria-controls="accordion_provider_collapse_1_3"
								        style={{backgroundColor:'#fafbfe', color:'#000080'}}
								      >
								        <img src="/images/web_two_route.png" width={props.isMobileWidth ? (0.8 *  window.innerWidth) + '' : (0.6 * window.innerWidth) + ''} />
								      </a>
								    </h4>
								    <div
								      id="accordion_provider_collapse_1_3"
								      className="accordion-collapse collapse"
								      aria-labelledby="accordion_provider_header_1_3"
								      data-mdb-parent="#accordion_provider_header_1"
								    >
								    	
								      <div className="accordion-body font-16">
								      
								      	 <div className="row">
									      <div className="col-lg-12">
									      	<ul class="list-group">
										      	<li class="list-group-item" style={{backgroundColor:'#fafbfe', color:'#000080'}}>Extractive revenue-sharing models</li>
										    	<li class="list-group-item" style={{backgroundColor:'#fafbfe', color:'#000080'}}>Some platforms charge up to 30% in commission </li>
										        <li class="list-group-item" style={{backgroundColor:'#fafbfe', color:'#000080'}}>No secondary market revenue opportunities </li>
										     </ul>
									      </div>
								      </div>
								      </div>
								    </div>
								  </div>
								  {/*height="50" width={ props.isMobileWidth ? ((props.windowWidth - props.windowWidth / 6) + "") :  Math.min((props.windowWidth - props.windowWidth / 6), 950) + ''} */}
								  <div className="accordion-item" style={{backgroundColor:'#fafbfe'}}>
								    <h4 className="accordion-header" id="accordion_provider_header_1_2">
								      <a
								        className="accordion-button collapsed font-16"
										href="#" 
								        data-mdb-toggle="collapse"
								        data-mdb-target="#accordion_provider_collapse_1_2"
								        aria-expanded="false"
								        aria-controls="accordion_provider_collapse_1_2"
								        style={{backgroundColor:'#fafbfe', color:'#000080'}}
								      >
								        <img src="/images/web_three_route.png" width={props.isMobileWidth ? (0.8 *  window.innerWidth) + '' : (0.6 * window.innerWidth) + ''} />
								      </a>
								    </h4>
								    <div
								      id="accordion_provider_collapse_1_2"
								      className="accordion-collapse collapse font-16"
								      aria-labelledby="accordion_provider_header_1_2"
								      data-mdb-parent="#accordion_provider_header_1"
								    >
								      <div className="accordion-body">
								       <div className="row">
									      <div className="col-lg-12">
									      	<ul class="list-group">
										      	<li class="list-group-item" style={{backgroundColor:'#fafbfe', color:'#000080'}}>Configure your schedule, rules, and prices in a smart contract. </li>
										    	<li class="list-group-item" style={{backgroundColor:'#fafbfe', color:'#000080'}}>Issue credits to your users or community.</li>
										        <li class="list-group-item" style={{backgroundColor:'#fafbfe', color:'#000080'}}>Merge your credit offerings with other creators to offer more value to your users. </li>
										     </ul>
									      </div>
								      </div>
								      </div>
								    </div>
								  </div>
								  
								 
								  
								</div>
							
							
			    		</div>
			    		{/*
			    		<div className="col-lg-2"></div>
	         			<div className="col-lg-3">
	         			
	         				<img src="/images/how_it_works_explainer.png" height="325" />
	         			</div>*/}
	         		</div>
	
		        	
	        	</div>
			</div>
        </section>
        <section className="token-section" style={{backgroundImage: `url(${background})`, backgroundRepeat  : 'no-repeat', backgroundSize: '100% 100%', overflow: 'visible'}}>
        	<div className="container">
	        	<div className="row" style={{marginTop:'0em', marginBottom:'1em'}}>
	        
	           		<div className="text-white col-lg-6 mt-3">
	           			<h1><span  style={{color:'#CCCCE5'}}>Tokens based on Ethereum's ERC-3525: Semi-Fungible Token</span></h1>
	           			<div className="mt-4 mb-4">
	           				<p className="font-weight-normal font-16">Service tokens adopt Ethereum's ERC-3525, a higly flexible, general-purpose token standard designed for financial instruments.</p>
	           				
	           				<p className="font-weight-normal font-16"> Service smart contracts deploy to the Polygon blockchain. </p>           			
	           			</div>
	           		</div>
	          		<div className="col-lg-6"></div>
	          	</div>
	          	
          		<>
          	
					<div className="token-images" style={{gridAutoFlow: (props.isMobileWidth ? 'row': 'column')}}>
					  <div className={'token-image-wrapper'}>
					  	<div className="container mt-1 mb-2">
						    <img className="img-1" src="/images/clean_times_token.png" style={{maxWidth: props.isMobileWidth ? '50%':  '70%'}} />
						    <div className="text-1 text-white mt-1" style={{position: 'relative', visibility: 'visible' }}>Time tokens hold time duration as value. Users or followers that hold your time tokens access your service at the specified time and duration.</div>
						</div>
					  </div>
					  <div className={'token-image-wrapper'}>
					  	<div className="container mt-1 mb-2">
					    	<img className="img-2" src="/images/clean_cash_token.png" style={{maxWidth: props.isMobileWidth ? '50%':  '70%'}} />
					    	<div className="text-2 text-white mt-1" style={{position: 'relative', visibility: 'visible' }}>Cash credit tokens hold monetary value. They are like programmable gift-cards that holders redeem for your offerings.</div>
					   	</div>
					  </div>
					  <div className={'token-image-wrapper'}>
					  	<div className="container mt-1 mb-2">
					    	<img className="img-3" src="/images/clean_items_credit.png" style={{maxWidth: props.isMobileWidth ? '50%':  '70%'}} />
					    	<div className="text-3 text-white mt-1" style={{position: 'relative', visibility: 'visible' }}>Each item credit token holds a count of one of your unique offerings. Grant holders access to a number of sessions or a count of a product item.</div>
					    </div>
					  </div>
					  <div className={'token-image-wrapper'}>
					  	<div className="container mt-1 mb-2">
					    	<img className="img-4" src="/images/priority_token.png" style={{maxWidth: props.isMobileWidth ? '50%':  '70%'}} />
					    	<div className="text-4 text-white mt-1" style={{position: 'relative', visibility: 'visible' }}>Priority tokens hold a position in a queue or waitlist. Issue these tokens to prioritize access to limited or beta service offerings.</div>
					    </div>
					  </div>
					</div>
					
          		
          		</> 
        			
				<div className="d-flex mt-5 mb-4"> <button type="button" className="btn btn-dark btn-rounded mb-2"  style={{maxHeight:"38px", maxWidth:"108px"}}><a href="https://eips.ethereum.org/EIPS/eip-3525" style={{color:'inherit'}}> ERC-3525 </a></button> <button type="button" className="btn btn-dark btn-rounded ml-2 mb-2" style={{maxHeight:"38px", maxWidth:"75px"}}> <a href="https://github.com/meritic" style={{color:'inherit'}}> Github </a> </button> <span className="ml-2"> <img className="mb-2" src="/images/polygon.png" height="35" /></span> </div>
          	</div>
    
        </section>
        </>
	);
}