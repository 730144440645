import React, {useState, useEffect} from 'react'


export const HowItWorks = (props) => {
	

	return (
		<>
		<section className="py-3" style={{marginTop:'5em', marginBottom:'0em'}}>
			<div className="container">
	        	<div className="row mb-4" style={{marginTop:'0em', marginBottom:'1em'}}>
	           		<div className="col-lg-6">
	           			<h1><span style={{color:'#000080'}}>How it works</span></h1>
	           		</div>
	          		<div className="col-lg-6"></div>
	          	</div>
	            <div className="container">
	            	<div className="card-group form-group text-center justify-content-center" style={{display: (props.isMobileWidth ? 'block' : 'flex'), alignItems: 'center'}}>
   
	          			<div className={'mb-3 rounded-5 border border-5'} style={{position: 'relative', marginRight:'5%'}}>
							<img className="rounded-5" src="/images/creator_avatar.png" height="250" />
						</div> 
						<div className={'mb-3 rounded-5 border border-5'} style={{position: 'relative', marginRight:'5%'}}>
							<img className="rounded-5" src="/images/register_service.png" height="250" />
						</div> 
						<div className={'mb-3 rounded-5 border border-5'} style={{position: 'relative', marginRight:'5%'}}>
							<img className="rounded-5" src="/images/deploy_smart_contract.png" height="250" />
						</div> 
						<div className={'mb-3 rounded-5 border border-5'} style={{position: 'relative', marginRight:'5%'}}>
							<img className="rounded-5" src="/images/issue_tokens.png" height="250" />
						</div>
					</div>
	
	        	</div>
			</div>
        </section>
		</>
	);
};

export default HowItWorks;