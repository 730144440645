
import React, { useState } from 'react';
import DateTimePicker from 'react-datetime-picker';

import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';







const isDescendant = function (parent, child) {
    let node = child.parentNode;
    while (node) {
        if (node === parent) {
            return true;
        }

        // Traverse up to the parent
        node = node.parentNode;
    }
    // Go up until the root but couldn't find the `parent`
    return false;
};

const disableNode = function (parent) {
    let node = parent;
    for(var i=0; i < node.children.length; i++){
		node = node.children[i];
		if(node){
			disableNode(node);
		}
		//node.value = '--';
		node.disabled = true;
		//node.style.backgroundColor = "#dadfe0";
	}
    parent.disabled = true;
	//parent.style.backgroundColor = "#dadfe0";
};


const enableNode = function (parent) {
    let node = parent;
    for(var i=0; i < node.children.length; i++){
		node = node.children[i];
		if(node){
			disableNode(node);
		}

		node.disabled = false;
		//node.style.backgroundColor = "";
	}
    parent.disabled = false;
	//parent.style.backgroundColor = "";
};

export default function DTimePicker(props) {
	//var start_date = new Date();
	//var end_date = new Date();
	//end_date.setFullYear(end_date.getFullYear() + 1);
	// props.endDateAfter
  return (

      <DateTimePicker id={props.id} onChange={props.onChange} value={props.value} />

  );
};

