
import React, {useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import Parser from 'html-react-parser';
import MainNav from '../util/MainNav';
import axios from 'axios';
import Footer from "../util/Footer";
import BannerNav, { BannerBaseNav, BannerAction2 } from '../util/BannerNav';
import { useLocation } from 'react-router-dom';

import AccordionItem from '../util/AccordionItem';
import { ServiceVenueHeader, ServiceVenue } from '../mint_collections/conditions/ServiceVenue';
import { HoursOfServiceHeader } from '../mint_collections/conditions/HoursOfService';
import StreamOrders from '../util/StreamOrders';

import { Label } from '../util/LineInput';
//import Select from "react-dropdown-select";
import GridItem from './GridItem';
import Select from 'react-select'

import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';


import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { differenceInCalendarDays } from 'date-fns';






function isSameDay(a, b) {
  return differenceInCalendarDays(a, b) === 0;
}


  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '30px',
      height: '30px',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
    }),
  };
  
  
  


const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
};





/*const Carousel = (props) => (

    <AliceCarousel
        mouseTracking={true}
        touchMoveDefaultEvents={true}
        disableDotsControls={true}
        disableButtonsControls={true}
        items={props.items}
        responsive={responsive}
        controlsStrategy="alternate"
    />
);*/










export default function Reserve(props){
	
	//const [reservedItems, setReservedItems] = useState([]);
	const [collectionImageURLs, setCollectionImageURLs] = useState([]);
	const [collectionName, setCollectionName] = useState("");
	const [reserveDates, setReserveDates] = useState(undefined);
	//const [reservedTokens, setReservedTokens] = useState([]);
	const [imgAspectRatio, setImgAspectRatio] = useState();
	
	
	
	
	
	
	const parsePeriod = (start, end) => {
		
		const stp = start.split(':');
		const edp = end.split(':');
		
		var vx = stp.slice(0, stp.length - 1).join(':') + ' - ' + edp.slice(0, edp.length - 1).join(':');
		return vx;
	}
	
	/*await fetch(rec.metadata_uri).then((response) => response.json())
   	.then((responseJson) => {
		
		   const image_url = responseJson.image;
		   temp.push(image_url);
   	}).catch((error) => {
		   console.error(error);
   	});*/
	const setReservedCarousel = async () => {
		
		var temp = props.tokens.map(rec => (rec.properties.image_uri + '/' + rec.properties.imgname));
		var tempDate = [];
		
		for(let i=0; i < props.tokens.length; i++){
			
			var rec = props.tokens[i];
			if(i == 0){
				setCollectionName(rec.collection_name);
			}
			
		   	var res_start = rec.properties.reserve_start;

			var res_end = rec.properties.reserve_end;
			var res_start_sp = res_start.split('T');
			var res_end_sp = res_end.split("T");
			
			var date_str = new Date(res_start_sp[0]).toDateString();
			var period = parsePeriod(res_start_sp[1], res_end_sp[1]);
			
			var date_period = date_str + ', ' + period;
			tempDate.push(date_period);
		}
		
		setCollectionImageURLs([...temp]);
		setReserveDates([...tempDate]);

	
		//var tempTokens = [];	
		//setReservedTokens(tempTokens);
	}
	
	
	useEffect(() => {
		setReservedCarousel();
	},[]);
	
	
	const onReserve =() =>{
		
	}
	
	var reservedTokens = [];
	
	
	if(collectionImageURLs.length > 0){
		for(let i=0; i < collectionImageURLs.length; i++){
			reservedTokens.push(
				<GridItem 
					index={i} 
					imgHeight='200px'
					imgAspectRatio={imgAspectRatio}
					token={props.tokens[i]}
					wallet={props.wallet}
					date_period={reserveDates[i]}
					collectionImageURL={collectionImageURLs[i]}
					collection_name={collectionName}
					tokenGroupConditions={props.tokenGroupConditions}
				/>
			);
		}
	}
		
	
	const orderData = StreamOrders(collectionName);


	//console.log(props.groupConditions.service_conditions.service_hours);
	//console.log('&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&')
	

	return (
		<>
			<section className="p-2 justify-content-center">
				{ reserveDates && reservedTokens.length > 0 ? 
					<>
					<div className="d-flex">
						<div className="table-responsive">
							
							<AliceCarousel
						        mouseTracking={true}
						        touchMoveDefaultEvents={true}
						        disableDotsControls={true}
						        disableButtonsControls={true}
						        items={reservedTokens}
						        responsive={responsive}
						        controlsStrategy="alternate"
						    />
						</div>
					
						<div className="card float-end text-center" style={{height: '340px', margin: 'auto'}}>
							<div className="card-body text-center">
								<h3>Make a reservation</h3>
								<div className="row">
									<div className="col">
										<Label for="reservation_date" className="mt-0" text="Date">	</Label>
										<DatePicker className="form-group-sm"  id="reservation_date"  onChange={props.onReserveDateChange} value={props.reserveDate} />
									</div>
									<div className="col">
										<Label for="time_selct" class="mt-0" text="Time">	</Label>
										<Select id="time_selct" className="form-group" 
											options={props.optionsTimeSelect} 	
											onChange={props.handleTimeSelect}  styles={customStyles} />
									</div>
								</div>
								<div className="row">
									<div className="col">
										<Label for="reserve_qty" className="mt-0" text="Quantity">	</Label>
									</div>
									<div className="col">
										<input type="text"  className="form-group-sm" size="10" onClick={onReserve} />
									</div>
									
								</div>
								
								<div className="row">
									<div className="col"><button type="button" onClick={onReserve} className="btn btn-sm btn-outline-secondary"> Reserve </button></div>
								</div>
							</div>
				        </div>
					   
					  </div>
				</> : null
				}
			</section> 
			<section>
			<div className="accordion accordion-flush" id="service_info_accordion_x"> 
				
					{ orderData ? 
						<AccordionItem
			            	heade_id="service_venue_header"
			            	collapse_container_id="service_venue_collapse_container"
			            	parent_id="service_info_accordion_x"
			            	header_text="Calendar"
			            	show_collapse="collapse"
			            	body={
									
								<HoursOfServiceHeader 
				    				token_group={0}
				    				service_hours={props.groupConditions.service_conditions.service_hours}
				    				condition_name={'service_hours'}
				    				start_date={props.groupConditions.service_conditions.start_date}
	    							end_date={props.groupConditions.service_conditions.end_date}
	    							orderData={orderData}
				    			></HoursOfServiceHeader>
							}
						>
						</AccordionItem> : null
					}
			    			
					
					{props.groupConditions.service_conditions.service_venue ?
						<AccordionItem
			            	heade_id="service_name_type_header"
			            	collapse_container_id="service_name_type_collapse_container"
			            	parent_id="service_info_accordion_x"
			            	header_text="Service venue"
			            	show_collapse="collapse"
			            	body={<>
			            			<ServiceVenue
			            				token_group={0}
										condition_name={"service_venue"}
										updateCondition={{}}
										places={[props.groupConditions.service_conditions.service_venue.physical[0]]}
			            			></ServiceVenue>
			    
			            		</>
								}
						>
						</AccordionItem> : null
					}
					
					
				</div>
			</section>
		</>
	);
}