import Parser from 'html-react-parser';
import React, {useState, useEffect, useMemo, useCallback, createContext, useRef	} from 'react'
import { useNavigate, useParams } from "react-router-dom"
import MainNav from '../util/MainNav';
import { useLocation } from 'react-router-dom';
import BannerNav, { TokenViewBannerNav } from './TokenViewBannerNav';
import Paragraph from '../util/Paragraph';
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import Map from '../map/Map';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import axios from 'axios';
import { BidOrder } from './orders/BidOrder';
import { ReserveOrder } from './orders/ReserveOrder';
import { OrderTable } from './orders/OrderTable';
import StreamOrdersByToken from '../util/StreamOrdersByToken';



import { connectWallet, getCurrentWalletConnected, addWalletListener } from "../util/interact.js";


const Fullpage = () => (
  <ReactFullpage
    //fullpage options

    licenseKey = {'L8EFH-1BIOJ-194IK-L578H-UVVKP'}
    scrollingSpeed = {1000} /* Options here */

    render={({ state, fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper>
          <div className="section">
            <p>Section 1 (welcome to fullpage.js)</p>
            <button onClick={() => fullpageApi.moveSectionDown()}>
              Click me to move down
            </button>
          </div>
          <div className="section">
            <p>Section 2</p>
          </div>
        </ReactFullpage.Wrapper>
      );
    }}
  />
);








	


const TokenView = () => {

	const { state } = useLocation();
	const { collection, tokenKey } = useParams();
	var props = {}
	if(state){props = state;}

	const [nftData, setNftData] = useState({});
	const [token, setToken] = useState({})
	const [tokenImageURI, setTokenImageURI] = useState()
	const [wallet, setWallet] = useState();
	const [initBid, setInitBid] = useState(false);
	const [initReserve, setInitReserve] = useState(false); 
	const [pTCondition, setPTCondition] = useState();
	const [tokenGrpConditions, setTokenGrpConditions] = useState([]);
	//const [bidData, setBidData] = useState()
	const [bidTable, setBidTable] = useState(null)
	const [bidTableData, setBidTableData] = useState(undefined);
	const [displayedBids, setDisplayedBids] = useState(undefined);
	const tXactCurrencyOptions = [
	  { 
	    value: "USDC",
	    label: "USDC"
	  },
	  {
	    value:  "MATIC",
	    label: "MATIC"
	  },
	  {
	    value:  "ETH",
	    label: "ETH"
	  }
	];
	
	const handleStatusChanged = (updatedStatus) => {
		setWallet(updatedStatus);
	}
	
	
	
	const updateWallet = async() => {
		const response = await getCurrentWalletConnected().then((walletResponse) => {
			setWallet(walletResponse);
		});
	    addWalletListener(handleWalletChanged, handleStatusChanged);
	    //return walletResponse
	}
	
	if(wallet == undefined){
		updateWallet();
	}

	const handleWalletChanged = (updatedStatus) => {
		setWallet(updatedStatus);
	}

	
		
	
		

	
	/* var tableRows = [];
	useEffect(()=> {
		if(bidTableData){
			for(let i=0; i < bidTableData.length; ++i){
				const row = bidTableData[i];
				
				tableRows.push(<tr key={i}><td>{row.address}</td><td>{row.price}</td><td>{row.qty}</td><td>{row.exp}</td><td>{row.active}</td><td>{row.hash}</td> </tr>)
			}
		}
		
	}, [bidTableData]); */
	
	
	const bidData = StreamOrdersByToken(token.uuid);

	//setBidData(data);

	
	
	useEffect(() => {
		//if(!props || (props && !props.wallet)){
			
			/*if(props && Object.keys(props).includes('token')){
				  
				  setToken(props.token);
				  console.log(props.token);
				  console.log('+++++       ++++++')
				  //setWallet(props.wallet);
				  setPTCondition(props.tokenGroupConditions.primary_transaction);
			 }else{*/
				 
				 axios.get('/api/v1/tokens/' + collection + '/' + tokenKey).then((responseJson) => {
			   		var token_data =  responseJson.data.token_data; 
			   		 
			 		 if(token_data){
						  var collection = token_data.collection;
						  var token = token_data.token_data;
						  console.log(collection);
						  console.log(collection.contract_metadata_uri + '/contract/' + collection.contract_address + '/token/' + token.uuid + '/' + responseJson.imgname)
						  setToken(token);
						  
						  fetch(collection.contract_metadata_uri + '/contract/' + collection.contract_address + '/token/' + token.uuid + '/properties.json')
						  .then((response) => response.json()).then((responseJson) => {
							  const imgURI = collection.contract_metadata_uri + '/contract/' + collection.contract_address + '/token/' + token.uuid + '/' + responseJson.imgname;
							  setTokenImageURI(imgURI);
						  }).catch((error) => {
							  console.error(error);
						  });
						  
						setPTCondition(token_data.group_conditions.primary_transaction);
					    setTokenGrpConditions(token_data.group_conditions);
					    
					    
					  }
				});				
				
	}, [token])
	
	

   	const handleSetNFTData = (data) => {setNftData(data);	}
	//const banner_action_span_html = `<a href="#contact" id="svc_provider_btn" class="btn btn-sm btn-dark btn-rounded" style="text-transform: none;">Service providers | Institutions</a> &nbsp; <a href="#contact" id="general_inquiry_btn" class="btn btn-sm btn-dark btn-rounded" style="text-transform: none;" style="text-transform: none;">General Inquiry</a>`;

	/*var teomp = async() => {
		await fetch('https://bafyreifxnllncofabx4tx4oasdepogphbyespbvs7i4p5b56pyxdqsxqgm.ipfs.nftstorage.link/metadata.json')
	   .then((response) => response.json())
	   .then((responseJson) => {
	      handleSetNFTData(responseJson);
	   }).catch((error) => {
	     console.error(error);
	   });
	}
	teomp();*/

	const newExpirationDate = () => {
	    var expiration = new Date();
	    expiration.setHours(expiration.getHours() + 1);
	    return expiration;
  	};
  	
  	const storeTokenData = async (token, refreshToken, expirationDate) => {
	    sessionStorage.setItem("accessToken", token);
	    sessionStorage.setItem("refreshToken", refreshToken);
	    sessionStorage.setItem("expirationDate", expirationDate);
	};
	
	
	
	
	
	
	
	
	
	/*const handleTokenFromQueryParams = () => {
    	const query = new URLSearchParams(window.location.search);
    	const accessToken = query.get("accessToken");
    	const refreshToken = query.get("refreshToken");
    	const expirationDate = newExpirationDate();
    	
    	console.log("App.js 30 | expiration Date", expirationDate);
    
    	if (accessToken && refreshToken) {
      		storeTokenData(accessToken, refreshToken, expirationDate);
      		//setIsLoggedIn(true);
    	}
  	};*/
  
  	
  
  
	/*useEffect(() => {
		if(props && props !== null){
			setToken(props.token);
			setWallet(props.wallet);
			setPTCondition(props.tokenGroupConditions.primary_transaction);
		}
	}, [props])*/
	
	
	const onReserve = () => {
		setInitReserve(true);
	}
	
	
	const onOrderSubmit = async(order) =>{
		
		try{
			var strdata = {
				token_uuid: token.uuid,
				collection: token.collection_name,
				order: order,
				user_wallet: wallet
			};

			const response = await axios.post("/api/v1/orders", strdata, {  "Content-Type": "application/json" });
			return  response;
		} catch(error){
			console.log(error);
		}
	}
	
	const onBid = () => {
		setInitBid(true);
	}


	
	
		
	
   return (
		<div>
			<section className="p-2">
				<MainNav brandimagepath="/brand_assets/Meritic.png" brandimageheight="40"> </MainNav>
			</section>
			<div className="card">
				<div className="row">
					<div className="col-lg-7 mb-3 mb-sm-0">
						<div className="card h-100">
							<div className="d-flex" style={{marginTop:'0em', marginBottom: '-1em'}}>
								<h3 className="font-18 my-1 ml-3 font-weight-normal">{nftData.name  }<a href='https://burn.polygon.technology'><span style={{minWidth:'68px'}} className='rounded text-dark text-right'><span datatoggle='tooltip' databoundary='viewport' datahtml='true' title='' dataoriginaltitle='Total Sum of Fees Burnt since the introduction of EIP-1559' aria-describedby='tooltip847798'>🔥Burnt Fees: </span><span>27,287.96 MATIC</span></span></a></h3>
							</div>
							
							<div className="card-body">
								<Carousel>
					                <div>
					                    <img src={tokenImageURI} />
					                </div>
					            </Carousel>
							</div>
						</div>
					</div>
					<div className="col-lg-5 mb-3 mb-sm-0">
						<div className="card widget-flat" style={{marginTop:'0em', marginBottom: '-1em', marginLeft:'-1em', marginRight:'-1em'}}>
							{/*<TokenViewBannerNav 
								span_class="font-weight-normal" 
								span_html={Parser(banner_action_span_html)} 
							>
							</TokenViewBannerNav>*/}
            				<div className="card widget-flat" style={{marginTop:'0em', marginBottom: '0em', marginLeft: '-1em', marginRight: '-1em'}}>
                            	<div className="card-body" style={{marginTop:'0em',  marginBottom:'0em', marginLeft: '-1em', marginRight: '-1em'}}>
                            		<div style={{marginTop:'0em', marginBottom:'0em', marginLeft:'0em', marginRight: '0em'}}> 
                            			<div className="row" style={{marginTop:'0em', marginBottom: '1em', marginLeft:'0em', marginRight: '0em'}}>
                            				<div className="col-sm d-flex" align="center" style={{borderColor: '#CCCCE5', borderWidth: '4px', borderStyle: 'solid',  borderRadius: '5px', marginTop: '0em',  marginBottom: '0em', marginLeft: '1em', marginRigth:'0em'}}>
                            					<div className="container" style={{marginTop:'0em', marginBottom: '1em', marginLeft: '-1em', marginRight: '-1em'}}>
                            						<div className="row" style={{marginTop:'0em', marginBottom: '1em', marginLeft: '-1em',  marginRight: '-1em'}}>
	                                    				<div className="col-sm" align="center" style={{marginTop:'0em', marginBottom: '0em', marginLeft:'0em',  marginRigth: '-1em'}}>
	                                    					<p className="text-muted font-weight-normal font-13 mt-0" title="" style={{marginTop: '0em', marginBottom:'0em'}}>current. price</p>
	                                    				</div>
	                                    			</div>
	                                    			<div className="row" style={{marginTop:'0em', marginBottom: '0em', marginLeft:'0em', marginright:'-1em'}}>
	                                    				<div className="col-sm" align="center" style={{marginTop:'-1em',  marginBottom:'0em', marginLeft:'-1em', marginRigth:'-1em'}}>
	                                    					<div className="input-group" style={{display:'inline'}}>
	                                    						<h3 className="mt-1 mb-1 mr-0" id="meritic_avg_price" align="center" style={{marginTop:'0em',  marginBottom:'0em'}}></h3>
	                 
	                                    					</div>
	                                    					<span className="badge badge-success-lighten"><span className="text-muted font-weight-normal"><i className="mdi mdi-arrow-up-bold ml-0 mr-0"></i> {pTCondition ?  pTCondition.min_bid : null} </span></span>
	                                    				</div>
	                            					</div>
                            					</div>
                            					<div className="container" style={{marginTop:'0em', marginBottom: '1em', marginLeft: '-1em', marginRight: '-1em'}}>
                            						<div className="row" style={{marginTop:'0em', marginBottom: '1em', marginLeft: '-1em',  marginRight: '-1em'}}>
	                                    				<div className="col-sm" align="center" style={{marginTop:'0em', marginBottom: '0em', marginLeft:'0em',  marginRigth: '-1em'}}>
	                                    					<p className="text-muted font-weight-normal font-13 mt-0" title="" style={{marginTop: '0em', marginBottom:'0em'}}>avg. price</p>
	                                    				</div>
	                                    			</div>
	                                    			<div className="row" style={{marginTop:'0em', marginBottom: '0em', marginLeft:'0em', marginright:'-1em'}}>
	                                    				<div className="col-sm" align="center" style={{marginTop:'-1em',  marginBottom:'0em', marginLeft:'-1em', marginRigth:'-1em'}}>
	                                    					<div className="input-group" style={{display:'inline'}}>
	                                    						<h3 className="mt-1 mb-1 mr-0" id="meritic_avg_price" align="center" style={{marginTop:'0em',  marginBottom:'0em'}}></h3>
	                                    			
	                                    					</div>
	                                    					<span className="badge badge-success-lighten"><span className="text-muted font-weight-normal"><i className="mdi mdi-arrow-up-bold ml-0 mr-0"></i> 5.27% </span></span>
	                                    				</div>
	                            					</div>
                            					</div>
                            				</div>
                            				<div className="col-sm d-flex" align="center" style={{borderColor: '#CCCCE5', borderWidth: '4px', borderStyle: 'solid',  borderRadius: '5px', marginTop: '0em',  marginBottom: '0em', marginLeft: '1em', marginRigth:'1em'}}>
                            				
                            					<button type="button" onClick={onReserve} className="btn-primary btn-square-md"> Reserve </button>
                            					{initReserve ? <ReserveOrder onOrderSubmit={onOrderSubmit} 
                            												token={token} 
                            												groupConditions={tokenGrpConditions}
                            												collection_name={collection}
                            												pTCondition={pTCondition}
                            												isOpen={initReserve} 
                            												setInitBid={setInitReserve} 
                            												tXactCurrencyOptions={tXactCurrencyOptions}
                            												/> : null }
                            					<button type="button" onClick={onBid} className="btn-primary btn-square-md">&nbsp; &nbsp; &nbsp; &nbsp;  Bid &nbsp; &nbsp; &nbsp; &nbsp; </button>
                            					{initBid ? <BidOrder onOrderSubmit={onOrderSubmit} 
                            										token={token} 
                            										isOpen={initBid} 
                            										setInitBid={setInitBid}
                            										collection_name={collection}
                            										tXactCurrencyOptions={tXactCurrencyOptions} /> : null }
                            				</div>
                            			</div>
                            			<div className="row" style={{marginTop:'1em',  marginBottom:'1em'}}>
                            				<div className="col-sm" align="center" style={{marginTop:'-1em', marginBottom:'0em', marginLeft:'-1em', marginRigth:'0em'}}>
                            					<p className="text-muted font-weight-normal font-13 mt-0" title="" style={{marginTop: '0em', marginBottom:'0em'}}>items</p>
                          						<div className="font-weight-normal font-13" id="num_items" align="center" style={{marginTop:'0em', marginBottom:'0em', marginLeft:'0em',  marginRigth:'-1em'}}>{5.27}</div>
                            				</div>
                            				<div className="col-sm" align="center" style={{marginTop:'-1em', marginBottom: '0em',  marginLeft: '0em', marginRigth: '0em'}}>
                            					<p className="text-muted font-weight-normal font-13 mt-0" title="" style={{marginTop: '0em',  marginBottom: '0em'}}>floor price</p>
                            					<div className="font-weight-normal font-13" id="floor_price" align="center" style={{marginTop:'0em',  marginBottom: '0em',  marginLeft: '0em',  marginRigth: '-1em'}}>5.27</div>
                                			</div>
                                			<div className="col-sm" align="center" style={{marginTop:'-1em',  marginBottom:'0em',  marginLeft:'0em', marginRigth:'-1em'}}>
                            					<p className="text-muted font-weight-normal font-13 mt-0" title="" style={{marginTop:'0em',  marginBottom:'0em'}}>ceil price</p>
                            					<div className="font-weight-normal font-13"  id="ceiling_price" align="center" style={{marginTop:'0em',  marginBottom:'0em', marginLeft:'0em', marginRigth:'-1em'}}>5.27</div>
                                			</div>
                                			<div className="col-sm" align="center" style={{marginTop:'-1em',  marginBottom:'0em',  marginLeft:'0em', marginRigth:'-1em'}}>
                            					<p className="text-muted font-weight-normal font-13 mt-0" title="" style={{marginTop:'0em', marginBottom:'0em'}}>vol traded</p>
                            					<div className="font-weight-normal font-13" align="center" style={{marginTop:'0em', marginBottom:'0em',  marginLeft:'0em',  marginRigth:'-1em'}}>5.27</div>
                            					
                                			</div>
                            			</div>
                            			
                            			
                            			<div className="container" style={{marginTop:'0em',  marginBottom:'0em', marginLeft:'0em', marginRigth: '-1em', height:'250px'}}>
                            				<ul className="nav nav-tabs sm-3" id="pills-tab" role="tablist">
                            					<li className="nav-item">
													<a className="active nav-link active" id="pills-description-tab" data-toggle="tab" href="#" data-target="#pills-collection-description" type="button" role="tab" aria-controls="pills-collection-description" aria-selected="true">Description</a>
												</li>
												<li className="nav-item">
													<a className="nav-link" id="pills-location-tab" data-toggle="tab" href="#" data-target="#pills-service-location" type="button" role="tab" aria-controls="pills-service-location" aria-selected="true">Venue</a>
												</li>
												<li className="nav-item">
													<a className="nav-link" id="pills-interest-tab" data-toggle="tab" href="#" data-target="#pills-service-interest" type="button" role="tab" aria-controls="pills-service-interest" aria-selected="true">Interest</a>
												</li>
									  		</ul>
								  	
                            				<div className="tab-content" id="nav-tabContent">
											  <div className="tab-pane fade show active" id="pills-collection-description" role="tabpanel" aria-labelledby="pills-description-tab" style={{overflow:'auto'}}>
											  	<p>{ nftData.description }</p>
											  </div>
											  <div className="tab-pane fade show" id="pills-service-location" role="tabpanel" aria-labelledby="pills-location-tab" style={{height: '800px',  width: '100%', overflow:'auto'}}>
											  	{/*<Map updatePlaces={null}/> */}
											  </div>
											  <div className="tab-pane fade show" id="pills-service-interest" role="tabpanel" aria-labelledby="pills-interest-tab" style={{height: '800px',  width: '100%', overflow:'auto'}}>
												<OrderTable data={bidData} />
											  	{/*<div className="container">
											  		<table className="table table-sm table-hover table-centered mb-0">
														<thead>
															<tr>
																<th>Bidder</th>
																<th>Price</th>
																<th>Quantity</th>
																<th>Expires in</th>
																<th>Active</th>
																<th>Tx</th>
															</tr>
														</thead>
														<tbody>
															{bidTableData &&  bidTableData.length > 0 ?
																bidTableData.map((row, key) => {
												                    return (
												                        <tr key={key}>
												                            <td>{row.address}</td>
												                            <td>{row.price}</td>
												                            <td>{row.qty}</td>
												                            <td>{row.exp.days}</td>
												                            <td>{row.active}</td>
												                            <td></td>
												                        </tr>
												                    )
											                	}) : null 
											                }
														</tbody>
													</table>
											  	</div> */}
											  	<div className="container">
											  	</div>
											  	<div className="container">
											  	</div>
											  </div>
											</div>
                            			</div>
                            		</div>
                            	</div>
           					</div>
            			</div>
					</div>
				</div>
			</div>
		</div>
	);
	
};

export default TokenView;
